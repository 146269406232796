import React, { useContext, useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ThemeProvider, makeStyles, withStyles, createMuiTheme } from '@material-ui/core/styles';
import btioDarkTheme from '../../utils/themes/btioDarkTheme';
import { teal, indigo } from '@material-ui/core/colors';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';


import { store } from '../../context/LadderContext';
import { formatNumber }  from '../../utils/helpers';

import Symbol from './Symbol';
import Expirations from './Expirations';
import Chain from './Chain';
import PriceScenario from './PriceScenario';
import { black, green600, red600 } from 'material-ui/styles/colors';

const axios = require('axios').default;


const darkTheme = createMuiTheme({
    palette: {
      type: 'light',
      primary: teal,
      secondary: indigo
    },
  });

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    // alignContent: 'center',
    // justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  inputroot: {
    margin: theme.spacing(2),
    width: '15ch',
    // justifyContent: 'center',
  },
  listroot: {
    width: '100%',
    maxWidth: 360,
    // backgroundColor: theme.palette.background.paper,
  },
  ladder: {
    flexDirection: 'column',
    // background: 'linear-gradient(45deg, #26A69A 30%, #B2DFDB 90%)',
    // border: 0,
    // borderRadius: 3,
    // boxShadow: '0 3px 5px 2px rgba(197, 202, 233, 1)',
    // color: 'white',
    padding: '30px',
    width: '500px',
    // height: '493.97px',
    // left: '245px',
    // top: '145px',

    /* Rectangle 1 */
    position: 'static',
    left: '0%',
    right: '0%',
    top: '4.38%',
    bottom: '3.72%',
    /* Gray 6 */
    background: '#F6FDFC',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '20px',
  },
  symbolPaper: {
    flexDirection: 'column',
    background: '#FFFFFF',
    border: 1,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
  },
  price: {
    /* Inside Auto Layout */
    // flex: 'none',
    // order: '1',
    alignSelf: 'center',
    // margin: '0px 0px',

    position: 'relative',
    width: '41px',
    height: '28px',
    // left: 'calc(50% - 41px/2 - 325.5px)',
    // top: 'calc(50% - 28px/2 + 86px)',

    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '28px',

    color: '#000000',

  },
  grid: {
    margin: theme.spacing(1),
    minWidth: '10ch',
  },
  sideSelect: {
    margin: theme.spacing(2),
    // minWidth: 60,
  },
  pxRow: {
    marginTop: -10,
    marginLeft: 15,
  },
  pxGreen: {
    color: green600
  },
  pxRed: {
    color: red600
  },
  pxBlack: {
    color: black
  }
}));


export default function Ladder(props) {
  const classes = useStyles();

  const { state, dispatch } = useContext(store);

  const [changeFmt, setChangeFmt] = useState(classes.pxBlack);

  const handleChangeContracts = (event) => {
    dispatch({type: 'contracts', value: event.target.value});
  };

  const handleSideChange = (event) => {
    dispatch({type: 'side', value: event.target.value});
  };

  useEffect(() => {
    console.log(`useEffect: format change`);

    if( state.quote.change_percentage < 0 ) {
      setChangeFmt(classes.pxRed);
    } else if( state.quote.change_percentage > 0 ) {
      setChangeFmt(classes.pxGreen);
    } else {
      setChangeFmt(classes.pxBlack);
    }

  }, [state.quote.change_percentage]);


  return (
    <ThemeProvider theme={darkTheme}>
    <div className={classes.root}>
      <Paper elevation={3} className={classes.ladder}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs>
            <Symbol num={props.num} />
          </Grid>

          <Grid className={classes.grid} item xs>
            <Expirations />
          </Grid>
          <Grid className={classes.grid} item xs>
            <FormControl className={classes.sideSelect}>
              <InputLabel shrink id="side-select-label">Side</InputLabel>
              <Select
                labelId="side-label"
                id="side-select"
                value={state.side}
                onChange={handleSideChange}
              >
                <MenuItem key={'PUT'} value={'put'}>{'PUT'}</MenuItem>
                <MenuItem key={'CALL'} value={'call'}>{'CALL'}</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid className={classes.grid} item xs>
            <TextField classNameid={`outlined-contracts-${props.num}`} variant="standard" size="small"
              label="Contracts" value={state.contracts} onChange={handleChangeContracts}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

        </Grid>

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs>
            <InputLabel shrink="true" id="outlined-price-last" variant="outlined" margin="dense">Last</InputLabel>
            <Typography className={classes.pxRow} variant="h5" align="left" display="block">{state.quote.last}</Typography>
          </Grid>
          <Grid className={classes.grid} item xs>
            <InputLabel shrink="true" id="outlined-price-change" variant="outlined" margin="dense">Change</InputLabel>
            <Typography className={classes.pxRow} variant="h5" align="left" display="block">
              <span className={changeFmt}>{formatNumber(state.quote.change_percentage, 2)}%</span>
            </Typography>
          </Grid>
          <Grid className={classes.grid} item xs>
            <InputLabel shrink="true" id="outlined-price-margin" variant="outlined" margin="dense">Margin</InputLabel>
            <Typography className={classes.pxRow} variant="h5" align="left" display="block">${state.margin}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs>
            <PriceScenario />
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid className={classes.grid} item xs>
            <Chain />
          </Grid>
        </Grid>
      </Paper>
    </div>
    </ThemeProvider>
  );
}