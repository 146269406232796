import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from "react-device-detect";

import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import btioDarkTheme from '../../utils/themes/btioDarkTheme';
import * as muicolors from 'material-ui/styles/colors';

import {Card, CardTitle, CardText} from 'material-ui/Card';

import Drawer from 'material-ui/Drawer';

import { Helmet } from 'react-helmet';
import fcWidget from 'freshchat-widget';

const style = {
  refresh: {
    // display: 'inline-block',
    display: 'block',
    margin: 'auto',
    // position: 'relative',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // 'z-index': '2',
  },
  toggle: {
    marginBottom: 16,
  },
  button: {
    margin: 12,
  },
  cardstyle: {
    // marginTop: 0,
    // marginBottom: -25,
    // padding: '0px',
    // borderBottom: '1px solid #404040',  // used to see total area
  },
  iconstyle: {
    marginTop: 10,
    // paddingRight: 10,
  },
  cardtextstyle: {
    // backgroundColor: muicolors.blueGrey600,
  },
  historyfont: {
    marginTop: 10,
    marginBottom: 5,
    fontFamily: 'Permanent Marker',
    fontSize: '1.75rem',
    textAlign: 'center',
    // borderBottom: '1px solid #404040',  // used to see total area
  },
  alignright: {
    textAlign: 'right',
  },
  titlefont: {
    marginTop: 10,
    marginBottom: 5,
    // fontFamily: 'Permanent Marker',
    fontFamily: 'Century Gothic, Muli, sans-serif',
    fontSize: '2rem',
    fontWeight: 600,
    letterSpacing: '1px',
    // textAlign: 'center',
  },
  shadedCard: {
    backgroundColor: muicolors.cyan50,
  },
  trialNote: {
    backgroundColor: muicolors.cyan50,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '.75rem',
    marginTop: 15,
    textAlign: 'center',
    //fontWeight: 600,    
  },
  roboto1: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1rem',
  },
  mediumCardTitle: {
    fontSize: '24px',
    marginLeft: -8,
  },
  toggleLabel: {
    whiteSpace: 'pre',
    fontSize: '14px',
    // textAligh: 'left',
    // marginLeft: '-10px',
    // paddingRight: '10px',
  },
  reduceCardMargin40: {
    marginTop: -40,
  },  
  reduceCardMargin25: {
    marginTop: -25,
  },
  reduceCardMargin10: {
    marginTop: -10,
  },  
  cardSpacing: {
    marginTop: 10,
    marginBottom: 10,
  },
  tutorialP: {
    border: '2px solid #ebebeb',
    minWidth: '100%',
    borderBottom: '0 none',
    height: '501px',
    // backgroundColor: muicolors.blueGrey100,
  },
  tutorialIF: {
    border: '0 none',
    borderBottom: '2px solid #ebebeb',
    minWidth: '100%',
  }
  
};




class Help extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
    }

    console.log("help component constructor");
  }

  componentWillMount()
  {
    const { isAuthenticated } = this.props.auth;
    if( !isAuthenticated() )
    {
      this.props.history.push('/');
    }
    
  }

  componentDidMount()
  {
    console.log("help componentDidMount");

    window.fcWidget.init({
      token: "59366a55-7227-4b4c-a54b-78d3a53dcf04",
      host: "https://wchat.freshchat.com",
      tags: ["member"],
      faqTags: {
        tags: ['member'],
        filterType: 'category' //Or filterType: 'article'
      },
      open: true,
      config: {
        showFAQOnOpen: true,
        hideFAQ: false,
        agent: {
          hideName: false,
          hidePic: false,
          hideBio: false,
        },
        content: {
          placeholders: {
            search_field: 'Search Knowledgebase',        
          },
          actions: {
            tab_faq: 'Knowledgebase',
            tab_chat: 'Chat',
          },
          headers: {
            faq: 'Knowledgebase',
            faq_help: 'Browse our help',
            channel_response: {
              offline: 'We are currently away. Leave us a message!',
              online: {
                minutes: {
                  one: "You should get a reply in a minute",
                  more: "You should get a reply within {!time!} minutes"
                },
                hours: {
                  one: "You should get a reply in an hour",
                  more: "You should get a reply within {!time!} hours",
                }
              }
            }
          }
        }
      }
    });

    if( window.fcWidget.isInitialized() ) {
      window.fcWidget.open();
    }

    window.fcWidget.track('React Page Visit', {
      page: 'Help',      
    });
  }


  render() {
    // const { isAuthenticated } = this.props.auth;

    return (
      <div className="animated fadeIn">
        <Helmet><title>backtest.io | help</title></Helmet>
        <br/>
        <div className="row">
          <div className="col-sm-4 col-md-4 col-lg-4">
            <h2 style={style.titlefont}>Help</h2>
          </div>
          <div className="col-sm-5 col-md-5 col-lg-5">
            
          </div>
          <div className="col-sm-3 col-md-3 col-lg-3">            
          </div>
        </div>
        <div style={style.cardSpacing} className="row">
          <div className="col-md-6">
            <MuiThemeProvider>
              <Card>
                <CardTitle title={
                  <div className="row">
                    <div className="col-md-9">
                      Knowledgebase
                    </div>
                    <div className="col-md-3">
                    </div>
                  </div>
                }/>
                <CardText>
                  <div style={style.roboto1}>Browse our handy knowledgebase from any page via the chat bubble in the lower right corner.</div>
                </CardText>
              </Card>
            </MuiThemeProvider>
          </div>
          <div className="col-md-6">
            <MuiThemeProvider>
              <Card>
                <CardTitle title={
                  <div className="row">
                    <div className="col-md-9">
                      Contact Us
                    </div>
                    <div className="col-md-3">
                    </div>
                  </div>
                }/>                    
                <CardText>
                  <div style={style.roboto1}>
                    The fastest way to contact us is via live chat from the chat bubble in the lower right corner.
                    <br/>
                    If you prefer email, we're also available at support@backtest.io
                    <br/><br/>
                    Note: if you don't see the chat bubble, please make sure third-party cookies are enabled.
                  </div>
                </CardText>
              </Card>
            </MuiThemeProvider>
          </div>
        </div>
        {/* <br/>
        <div style={style.cardSpacing} className="row">
          <div className="col-md-12">
            <MuiThemeProvider>
              <Card>
                <CardTitle title={
                  <div className="row">
                    <div className="col-md-9">
                      Tutorials
                    </div>
                    <div className="col-md-3">                    
                    </div>
                  </div>
                }/>                    
                <CardText>
                  Browse through some of our walkthrough tutorials.  If you'd like a walkthrough of another features, don't hesitate to contact us via the chat bubble in the lower right corner.  
                  <br/>We can give you a live demo via screen share or create a walkthrough for you!
                <p style={style.tutorialP} >
                  <Iframe url="https://www.iorad.com/player/127487/Load-Backtest-from-History?src=iframe"
                    width="90%"
                    height="100%"
                    id="tut1"
                    // style={style.tutorialIF}
                    // className="myClassname"
                    // display="initial"
                    // position="relative"
                    allowFullScreen/>
                </p>
                
                </CardText>
              </Card>
            </MuiThemeProvider>
          </div>
        </div> */}
        
        {
          !isMobile && (
          <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, btioDarkTheme)}>
            <Drawer containerClassName="drawerstyle" open={true} width={250}>
              {/* <MenuItem></MenuItem>             */}
              {/* <div style={style.historyfont}>History
                <IconButton onClick={this.getUserHistory} tooltip="Account" touch={true} tooltipPosition="bottom-left"><AccountIcon /></IconButton>
              </div>             */}
            </Drawer>
          </MuiThemeProvider>
          )
        }

      </div>
    );
  }
}

export default withRouter(Help);
