import React, { useContext, useState, useEffect } from 'react';

import { ThemeProvider, makeStyles, withStyles, createMuiTheme } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { TRADIER_BASE_URL, TRADIER_ACCESS_TOKEN }  from '../../constants/services';
import { store } from '../../context/LadderContext';

const axios = require('axios').default;


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    // alignContent: 'center',
    // justifyContent: 'center',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 90,
  }
}));


export default function Expirations(props) {
  const classes = useStyles();

  const { state, dispatch } = useContext(store);

  const [expirations, setExpirations] = useState(['']);

  useEffect(() => {
    console.log(`useEffect: Expirations`);
    console.log(state);
    // Update list of expirations given a symbol
    let ignore = false;

    async function fetchExpirations() {
      if( !state.symbol ) {
        setExpirations(['']);
        return;
      }

      var config = {
        method: 'get',
        url: `${TRADIER_BASE_URL}/markets/options/expirations?symbol=${state.symbol}&includeAllRoots=true&strikes=false`,
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${TRADIER_ACCESS_TOKEN}`
        }
      };

      console.log('config:');
      console.log(config);

      try {
        const resp = await axios(config);
        console.log(resp.data);
        if (!ignore) {
          let exp = [''];
          if( resp.data.expirations) {
            // add an empty entry at the start of the select list
            exp = ["", ...resp.data.expirations.date];
          }
          setExpirations(exp);
        }
      } catch (err) {
        console.error(err);
      }
    }

    fetchExpirations();
    return () => { ignore = true; }
  }, [state.symbol, state.exp]);  // make useEffect dependent on symbol and exp since we're passing in exp now

  const handleChange = (event) => {
    dispatch({type: 'expiration', value: event.target.value});
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel shrink id="demo-simple-select-label">Expirations</InputLabel>
      <Select
        labelId="expiration-label"
        id="expiration-select"
        value={state.exp}
        onChange={handleChange}
      >
        {expirations.map( exp => {
          return (
            <MenuItem key={exp} value={exp}>{exp}</MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
