import React from "react"
import ContentLoader from "react-content-loader"

const HistoryTextLoader = (props) => (
  <ContentLoader
    speed={2}
    width={80}
    height={15}
    viewBox="0 0 80 15"
    backgroundColor="#f3f3f3"
    foregroundColor="#BEBDBD"
    {...props}
  >
    <rect x="0" y="0" rx="5" ry="5" width="100%" height="15" />
  </ContentLoader>
)

export default HistoryTextLoader;
