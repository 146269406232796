import React, {createContext, useReducer} from 'react';
import { red500 } from 'material-ui/styles/colors';


const initialState = {
  symbol: '',
  exp: '',
  quote: {"last": 0, "change_percentage": 0, "week_52_high": 0, "week_52_low": 0},
  side: 'put',
  contracts: 1,
  margin: 0,
  scenarioPx: '',
  highlightPx: 0,
  disable: true,
  refresh: false,
  scenarioCommit: 0,
};
const store = createContext(initialState);
const { Provider } = store;

function reducer(state, action) {
  console.log(`dispatch: ${action.type}=${action.value}`);
  switch (action.type) {
    case 'symbol':
      console.log('in symbol');
      return {
        ...state,
        symbol: action.value,
        exp: '',
        disable: true,
        quote: {"last": 0, "change_percentage": 0, "week_52_high": 0, "week_52_low": 0},
        margin: 0,
      }
    case 'expiration':
      return {
        ...state,
        exp: action.value,
      }
    case 'quote':
      return {
        ...state,
        quote: action.value,
        scenarioPx: action.value.last,
        scenarioCommit: action.value.last
      }
    case 'side':
      return {
        ...state,
        side: action.value,
      }
    case 'contracts':
      return {
        ...state,
        contracts: action.value,
      }
    case 'margin':
      return {
        ...state,
        margin: action.value,
      }
    case 'scenario':
      return {
        ...state,
        scenarioPx: action.value,
      }
    case 'highlightPx':
      return {
        ...state,
        highlightPx: action.value,
      }
    case 'disable':
      return {
        ...state,
        disable: action.value,
      }
    case 'refresh':
      return {
        ...state,
        refresh: action.value,
      }
    case 'scenarioCommit':
      return {
        ...state,
        scenarioCommit: action.value,
      }
    default:
      return state;
  }
}

const LadderContext = ( { children } ) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, LadderContext };