import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
// import FontIcon from 'material-ui/FontIcon';
import {blueGrey300, orange500} from 'material-ui/styles/colors';

const muistyles = {
  errorStyle: {
    color: orange500,
  },
  underlineStyle: {
    borderColor: orange500,
  },
  floatingLabelStyle: {
    color: blueGrey300,
  },
  // floatingLabelFocusStyle: {
  //   color: blue500,
  // },
};


// textfield with icon
const IconTextField2 = (props) => (

  <div style={{position: 'relative', display: 'inline-block'}}>
    {/* <FontIcon style={{position: 'absolute', left: props.left, right: props.right, top: 38, width: 20, height: 20}} className="material-icons">
      {props.icon}
    </FontIcon> */}
    <i style={{position: 'absolute', left: props.left, top: 40, width: 20, height: 20}} className={props.icon}></i>
    <TextField
      // icon={props.icon}
      // style={{textIndent: 22}}
      id={props.id}
      floatingLabelText={props.label}
      floatingLabelStyle={muistyles.floatingLabelStyle}
      type={props.type ? props.type : 'text'}
      // fullWidth={true}
      onChange={props.controlFunc}
      value={props.content}
      disabled={props.disabled}
      errorText={props.error}
    />
  </div>
);

IconTextField2.propTypes = {
	icon: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
  controlFunc: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  left: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};


export default IconTextField2;
