import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";

import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import btioDarkTheme from '../../utils/themes/btioDarkTheme';

import * as muicolors from 'material-ui/styles/colors';
import Drawer from 'material-ui/Drawer';
import Snackbar from 'material-ui/Snackbar';

import {GridList, GridTile} from 'material-ui/GridList';
import IconButton from 'material-ui/IconButton';
import ArrowForwardIcon from 'material-ui/svg-icons/navigation/arrow-forward';

import ShareMenu from '../../components/ShareComp/ShareMenu';

import fcWidget from 'freshchat-widget';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Butter from 'buttercms';

import loading from '../../utils/auth0/Callback/loading.svg';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const butter = Butter('f1f702e8d72d311c4ed62be6daed59dec33413e2');

const style = {
    titlefont: {
        marginTop: 10,
        marginBottom: 5,
        // fontFamily: 'Permanent Marker',
        fontFamily: 'Century Gothic, Muli, sans-serif',
        fontSize: '2rem',
        fontWeight: 600,
        letterSpacing: '1px',
        // textAlign: 'center',
    },
    historyfont: {
        marginTop: 10,
        marginBottom: 5,
        // fontFamily: 'Permanent Marker',
        fontFamily: 'Century Gothic, Muli, sans-serif',
        fontWeight: 600,
        letterSpacing: '3px',
        fontSize: '1.75rem',
        textAlign: 'center',
        // borderBottom: '1px solid #404040',  // used to see total area
      },
    blogbanner: {
        marginLeft: -30,
        marginRight: -30,
        padding: 100,
        backgroundColor: muicolors.teal500,
        color: muicolors.darkWhite,
        textAlign: 'center',
        fontFamily: 'Lucida Console, Courier New, monospace',        
    },
    blogtitle: {
        fontFamily: 'Lobster, Monaco, monospace',
        letterSpacing: '2px',
    },
    blogcontent: {
        backgroundColor: muicolors.fullWhite,
        marginLeft: -30,
				marginRight: -30,
				// marginTop: -30,
				paddingTop: 20,
				// marginTop: 10,
				marginBottom: -15,
		},    
		root: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'space-around',
			// from blogcontent
			backgroundColor: muicolors.fullWhite,
			marginLeft: -30,
			marginRight: -30,
		},
		gridListY: {
			width: 800,
			height: 450,
			overflowY: 'auto',
		},
		gridListX: {
			display: 'flex',
			flexWrap: 'nowrap',
			overflowX: 'auto',
			backgroundColor: muicolors.fullWhite,
		},
		tileTitle: {
			fontFamily: 'Muli, sans-serif',
			// fontWeight: 400,
			fontSize: '1.10rem',
			// letterSpacing: '1px',
		},
		tileborder: {
			borderStyle: 'solid',
			borderWidth: '1px',
			color: muicolors.faintBlack,
		},
    paper: {
        margin: 100,
        marginTop: 50,
        marginBottom: 50,
        // marginLeft: 100,
        // marginRight: 100,
    
        padding: 50,
        paddingTop: 40,
        // textAlign: 'center',
        display: 'inline-block',
    },
    drawercenter: {
      textAlign: 'center',
    },
    verticalshare: {
      display: 'inline-block',
      width: '50px',
    },
    linkpad: {
        padding: 1,
    },
    paperpostlink: {
        margin: 20,
				// padding: 15,
				paddingTop: 30,
        color: muicolors.teal700,
        fontSize: '1.25rem',
        display: 'inline-block',
        width: 290,
    },
    postsummary: {
				fontFamily: 'monospace',
        fontSize: '1.0rem',
        color: muicolors.darkBlack,
    },
    refresh: {
        display: 'inline-block',
        position: 'relative',
      },
  };

class BlogHome extends Component {

  constructor(props) {
    super(props);

    this.state = {
        drawerOpen: true,
        
        loaded: false,
        resp: '',

        shareURL: "https://backtest.io/blog",
        shareText: "Check out the backtest.io blog for backtesting tips, featured backtests, and options commentary!",

        snackopen: false,
        snacktext: '',
        snackAutoHide: 5000,
      }
      
    this.fetchPosts = this.fetchPosts.bind(this);

    this.shareCopyDone          = this.shareCopyDone.bind(this);
    this.handleSnackClose       = this.handleSnackClose.bind(this);
  }
  
  fetchPosts(page) {
    console.log('butter fetching posts... page', page);
    butter.post.list({page: page, page_size: 100}).then((resp) => {
      this.setState({
        loaded: true,
        resp: resp.data
      }, () => console.log('butter list:', this.state.resp));
    });
  }

  shareCopyDone(text, result)
  {
    console.log('copied link', text, result);
    this.setState({ snacktext: 'Your backtest link has been copied to clipboard.', snackAutoHide: (5*1000), snackopen: true }, () => console.log('snacktext:', this.state.snacktext));    
  }

  handleSnackClose() {
    this.setState({ snackopen: false });
  };

  componentWillMount()
  {
    const { isAuthenticated } = this.props.auth;
    if( !isAuthenticated() )
    {
        localStorage.setItem('redir', this.props.match.url);
    }
    
    console.log('match url', this.props.match.url);
    console.log('params', this.props.match.params);

    let page = this.props.match.params.page || 1;

    this.fetchPosts(page);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({loaded: false});

    let page = nextProps.match.params.page || 1;

    this.fetchPosts(page);
  }
    

  render() {
    
    if (this.state.loaded) 
    {
        const { next_page, previous_page } = this.state.resp.meta;

        var generalPosts = this.state.resp.data.filter(post => post.categories[0].slug === "general" );
        var backtestHandbook = this.state.resp.data.filter(post => post.categories[0].slug === "backtesting-handbook" );
        var featuredBacktests = this.state.resp.data.filter(post => post.categories[0].slug === "featured-backtests" );
        // console.log('backtestHandbook:', backtestHandbook);
        // console.log('featuredBacktests:', featuredBacktests);

        return (
            <div className="animated fadeIn">
                <Helmet><title>backtest.io | blog</title></Helmet>
                

                <div className="blog-background" style={style.blogbanner}>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h2 style={style.blogtitle}>Blog</h2>
                        </div>
                    </div>
                    <br/><br/><br/>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <h5>Check out our backtesting handbook, featured backtests, and options commentary</h5>
                        </div>
                    </div>
                </div>

								<div className="row">
                	<div className="col-sm-12 col-md-12 col-lg-12">
										<div style={style.blogcontent}>
											<h1 style={style.historyfont}>{backtestHandbook[0].categories[0].name}</h1><br/>
										</div>
										<div style={style.root}>
											<MuiThemeProvider>
											<GridList
												cols={backtestHandbook.length}
												cellHeight={200}
												padding={10}
												style={style.gridListX}
											>
												{backtestHandbook.map((post) => (
													<Link key={post.slug} to={`/post/${post.slug}`}>
														<GridTile
															title={post.title}
															titleStyle={style.tileTitle}
															// subtitle={post.summary}
															actionIcon={<IconButton><ArrowForwardIcon color="white" /></IconButton>}
															actionPosition="right"
															titlePosition="top"
															// titleBackground="linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.3) 70%,rgba(0,0,0,0) 100%)"
															titleBackground="rgba(99, 107, 192, 1.0)"
															cols={1}
															rows={1}
															style={style.tileborder}
														>				
															<div style={style.paperpostlink}>
																<span style={style.postsummary}>{post.summary}</span>
															</div>
														</GridTile>
													</Link>
												))}
											</GridList>
											</MuiThemeProvider>
										</div>
									</div>
								</div>

								<div className="row">
                	<div className="col-sm-12 col-md-12 col-lg-12">
										<div style={style.blogcontent}>
											<h1 style={style.historyfont}>{featuredBacktests[0].categories[0].name}</h1><br/>
										</div>
										<div style={style.root}>
											<MuiThemeProvider>
											<GridList
												cols={featuredBacktests.length}
												cellHeight={200}
												padding={10}
												style={style.gridListX}
											>
												{featuredBacktests.map((post) => (
													<Link key={post.slug} to={`/post/${post.slug}`}>
														<GridTile
															title={post.title}
															titleStyle={style.tileTitle}
															// subtitle={post.summary}
															actionIcon={<IconButton><ArrowForwardIcon color="white" /></IconButton>}
															actionPosition="right"
															titlePosition="top"
															// titleBackground="linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.3) 70%,rgba(0,0,0,0) 100%)"
															titleBackground="rgba(77, 182, 172, 1.0)"
															// titleBackground="rgba(255, 112, 67, 1.0)"
															cols={1}
															rows={1}
															style={style.tileborder}
														>				
															<div style={style.paperpostlink}>
																<span style={style.postsummary}>{post.summary}</span>
															</div>
														</GridTile>
													</Link>
												))}
											</GridList>
											</MuiThemeProvider>
										</div>
									</div>
								</div>

								<div className="row">
                	<div className="col-sm-12 col-md-12 col-lg-12">
										<div style={style.blogcontent}>
											<h1 style={style.historyfont}>{generalPosts[0].categories[0].name}</h1><br/>
										</div>
										<div style={style.root}>
											<MuiThemeProvider>
											<GridList
												cols={generalPosts.length}
												cellHeight={200}
												padding={10}
												style={style.gridListX}
											>
												{generalPosts.map((post) => (
													<Link key={post.slug} to={`/post/${post.slug}`}>
														<GridTile
															title={post.title}
															titleStyle={style.tileTitle}
															// subtitle={post.summary}
															actionIcon={<IconButton><ArrowForwardIcon color="white" /></IconButton>}
															actionPosition="right"
															titlePosition="top"
															// titleBackground="linear-gradient(to bottom, rgba(0,0,0,0.7) 0%,rgba(0,0,0,0.3) 70%,rgba(0,0,0,0) 100%)"
															titleBackground="rgba(96, 125, 139, 1.0)"
															cols={1}
															rows={1}
															style={style.tileborder}
														>				
															<div style={style.paperpostlink}>
																<span style={style.postsummary}>{post.summary}</span>
															</div>
														</GridTile>
													</Link>
												))}
											</GridList>
											</MuiThemeProvider>
										</div>
									</div>
								</div>

                {
                  !isMobile && (
                  <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, btioDarkTheme)}>
                    <Drawer containerClassName="drawerstyle" open={true} width={250}>
                      <br/><br/><br/>
                      <div style={style.historyfont}>Share Blog</div>
                      <div style={style.drawercenter}>
                        <div style={style.verticalshare}>
                          <ShareMenu link={this.state.shareURL} title={this.state.shareText} onCopy={this.shareCopyDone} />
                        </div>
                      </div>
                    </Drawer>
                  </MuiThemeProvider>
                  )
                }

                <MuiThemeProvider>
                  <Snackbar
                      open={this.state.snackopen}
                      message={this.state.snacktext}
                      autoHideDuration={this.state.snackAutoHide}
                      // onRequestClose={this.handleSnackClose}
                      // onClick={this.handleSnackAction}
                  />
                </MuiThemeProvider>
            </div>
        );
    } 
    else 
    {
        const style = {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'white',
          }

        return (
            <div className="animated fadeIn">            
              <div style={style}>
                  <img src={loading} alt="loading"/>
              </div>
            </div>
        )
    }

  }
}

export default withRouter(BlogHome);
