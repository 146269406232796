import React from 'react';
import { withRouter } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import fcWidget from 'freshchat-widget';

import LogRocket from 'logrocket';

LogRocket.init('qm4ige/backtestio');

var MY_SLACK_WEBHOOK_URL = 'https://hooks.slack.com/services/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';
var slack = require('slack-notify')(MY_SLACK_WEBHOOK_URL);

const style = {

  titlefont: {
    marginTop: 10,
    marginBottom: 5,
    fontFamily: 'Century Gothic, Muli, sans-serif',
    fontSize: '2rem',
    // textAlign: 'center',
    letterSpacing: '1px',
  },

};

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }


  componentDidCatch(error, info)
  {
    // Display fallback UI
    this.setState({ hasError: true }, () => console.log('error boundary caught | hasError:', this.state.hasError));

    LogRocket.captureException(error, {
      tags: {
        page: 'ErrorBoundary',
      },
      extra: {
        error: error ? error.toString() : '',
        info: info ? info.toString() : '',
      }
    });

    //log the error to an error reporting service
    var id_token = localStorage.getItem('id_token') || {};

    slack.alert({
      channel: '#errors',
      // icon_url: 'http://example.com/my-icon.png',
      text: 'Error boundary caught crash:',
      fields: {
        error: error ? error.toString() : (info ? info.toString() : ''),
        id_token: id_token
      }
      // unfurl_links: 1,
      // username: 'Jimmy'
    });

  }


  componentDidMount()
  {
    window.fcWidget.init({
      token: "59366a55-7227-4b4c-a54b-78d3a53dcf04",
      host: "https://wchat.freshchat.com",
      tags: ["member"],
      faqTags: {
        tags: ['splash'],
        filterType: 'article' //Or filterType: 'article'
      },
      // open: true,
      config: {
        showFAQOnOpen: false,
        hideFAQ: false,
        agent: {
          hideName: false,
          hidePic: false,
          hideBio: false,
        },
        content: {
          placeholders: {
            search_field: 'Search Knowledgebase',
          },
          actions: {
            tab_faq: 'Knowledgebase',
            tab_chat: 'Chat',
          },
          headers: {
            faq: 'Knowledgebase',
            faq_help: 'Browse our help',
            channel_response: {
              offline: 'We are currently away. Leave us a message!',
              online: {
                minutes: {
                  one: "You should get a reply in a minute",
                  more: "You should get a reply within {!time!} minutes"
                },
                hours: {
                  one: "You should get a reply in an hour",
                  more: "You should get a reply within {!time!} hours",
                }
              }
            }
          }
        }
      }
    });

    // if( window.fcWidget.isInitialized() ) {
    //   window.fcWidget.open();
    // }

    window.fcWidget.track('React Page Visit', {
      page: 'Route Error Boundary',
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="animated fadeIn">
          <Helmet><title>backtest.io | error</title></Helmet>
          <br/>
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4">
              <h2 style={style.titlefont}>Sorry!</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 col-md-10 col-lg-10">
              Something went wrong...  Please refresh the page and try again.<br/>
              If the problem persists, please chat with us (lower right corner).  Thanks!
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
