import getMuiTheme from 'material-ui/styles/getMuiTheme';
import baseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import * as Colors from 'material-ui/styles/colors';
import { fade } from 'material-ui/utils/colorManipulator'

export default {
  "palette": {
      "primary1Color": Colors.blueGrey700,
      "primary2Color": Colors.blueGrey500,
      "primary3Color": Colors.grey600,
      "accent1Color": Colors.deepPurpleA200,
      "accent2Color": Colors.deepPurpleA400,
      "accent3Color": Colors.deepPurpleA100
  },
  "toggle": {
      "thumbOnColor": Colors.deepPurple500,
      "thumbOffColor": Colors.blueGrey300
  },
  "snackbar": {
      "backgroundColor": fade(Colors.darkBlack, 0.87),
      "textColor": Colors.white
  },
  "checkbox": {
      "checkedColor": Colors.deepPurple500
  },
  "radioButton": {
      "checkedColor": Colors.deepPurple500
  },
  "textField": {
      "focusColor": Colors.deepPurple500,
      "errorColor": Colors.redA400
  },
  "datePicker": {
      "selectColor": Colors.deepPurple700
  },
  "timePicker": {
      "accentColor": Colors.deepPurple700,
      "headerColor": Colors.deepPurple700
  },
  "raisedButton": {
    "color": Colors.blueGrey800,
    "primaryColor": Colors.deepPurple700,
    "primaryTextColor": Colors.white,
    "fontWeight": 500,    
    "textColor": Colors.white,
    "secondaryColor": Colors.teal400,
    "secondaryTextColor": Colors.white,
  },
  "refreshIndicator": {
      "loadingStrokeColor": Colors.deepPurple700
  },
  "flatButton": {
      "primaryTextColor": Colors.blueGrey400
  }
};
  
