import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Footer from '../../components/Footer/';

import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import btioDarkTheme from '../../utils/themes/btioDarkTheme';

import AppBarComp from '../../components/AppBarComp/';
import Dashboard from '../Dashboard/Dashboard';
import Wheel from '../Wheel/Wheel';
import History from '../History/History';
import Account from '../Account/Account';
import Help from '../Help/Help';
import BlogHome from '../Blog/BlogHome';
import BlogPost from '../Blog/BlogPost';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

import axios from 'axios';
import fcWidget from 'freshchat-widget';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


class Full extends Component {

  constructor(props) {
    super(props);

    this.handleSidebar = this.handleSidebar.bind(this);

    this.linkUserInfo = this.linkUserInfo.bind(this);
    this.initExteralID = this.initExteralID.bind(this);

    this.state = {
      asideMenuToggleState: false,
      // activeTab: '1',
      drawerOpen: true,
      hasError: false,
    }
  }

  componentWillMount()
  {
    var byPassAuth = this.props.page === "bt" || this.props.page === "blog" || this.props.page === "post";

    const { isAuthenticated } = this.props.auth;
    if( !isAuthenticated() )
    {
      if( !byPassAuth ) {
        this.props.history.push('/');
      }
      else {
        console.log("hitting bt or blog page... bypass auth");
        return;
      }
    }

    // link to freshchat here
    const { userProfile, getProfile } = this.props.auth;
    if (!userProfile) {
      getProfile((err, profile) => {
        console.log('1 - userProfile:', this.props.auth.userProfile);
        this.linkUserInfo(profile);
      });
    } else {
      console.log('2 - userProfile:', this.props.auth.userProfile);
      this.linkUserInfo(userProfile);
    }

  }

  linkUserInfo(profile) {
    // var user_payload = {
    //   'sub': profile.sub,
    //   'given_name':  profile.given_name,
    //   'family_name':  profile.family_name,
    //   'nickname':  profile.nickname,
    //   'name':  profile.name,
    //   'picture':  profile.picture,
    //   'gender':  profile.gender,
    //   'locale':  profile.locale,
    //   'updated_at':  profile.updated_at,
    //   'email':  profile.email,
    //   'email_verified':  profile.email_verified,
    // };
    // console.log('userpayload:', user_payload);
    console.log('auth0 profile info:', profile);
    let valid = profile || "undefined";
    if( valid === "undefined" ) {
      console.log('auth0 profile is undefined... return');
      return;
    }

    // link to freshchat user
    var fc_resp = window.fcWidget.user.setProperties({
      firstName: profile.given_name,
      lastName: profile.family_name,
      email: profile.email,
      "locale": profile.locale,
      "gender": profile.gender,
      "picture": profile.picture
    });
    console.log('fcWidget set user props:', fc_resp);

    this.initExteralID(profile.email);
  }

  initExteralID(email)
  {
    window.fcWidget.init({
      token: "59366a55-7227-4b4c-a54b-78d3a53dcf04",
      host: "https://wchat.freshchat.com",
      externalId: email,
      tags: ["member"],
      faqTags: {
        tags: ['member'],
        filterType: 'category' //Or filterType: 'article'
      },
      open: false,
      config: {
        showFAQOnOpen: true,
        hideFAQ: false,
        agent: {
          hideName: false,
          hidePic: false,
          hideBio: false,
        },
        content: {
          placeholders: {
            search_field: 'Search Knowledgebase',
          },
          actions: {
            tab_faq: 'Knowledgebase',
            tab_chat: 'Chat',
          },
          headers: {
            faq: 'Knowledgebase',
            faq_help: 'Browse our help',
            channel_response: {
              offline: 'We are currently away. Leave us a message!',
              online: {
                minutes: {
                  one: "You should get a reply in a minute",
                  more: "You should get a reply within {!time!} minutes"
                },
                hours: {
                  one: "You should get a reply in an hour",
                  more: "You should get a reply within {!time!} hours",
                }
              }
            }
          }
        }
      }
    });
  }

  handleSidebar() {
    console.log('Toggle sidebar...');
    //this.refs.dashref.handleHistoryToggle();
    this.setState({ drawerOpen: !this.state.drawerOpen }, () => console.log('drawerOpen:', this.state.drawerOpen));
  }

  render() {

    return (
      <div className="app">
        {/* <Header /> */}
        <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, btioDarkTheme)}>
          <AppBarComp {...this.props} />
        </MuiThemeProvider>
        <div className="app-body">
          {/* <Sidebar {...this.props}/> */}
          <main className="main">
            <div className="container-fluid">
              <ErrorBoundary>
                {/* {this.props.children} */}
                {/* {React.cloneElement(this.props.children, {drawerOpen: this.state.drawerOpen} )} */}
                {
                  (this.props.page === "dash" || this.props.page === "bt" ) && (<Dashboard {...this.props} />)
                }
                {
                  (this.props.page === "wheel" ) && (<Wheel {...this.props} />)
                }
                {
                  (this.props.page === "history" ) && (<History {...this.props} />)
                }
                {
                  (this.props.page === "account" ) && (<Account {...this.props} />)
                }
                {
                  (this.props.page === "help" ) && (<Help {...this.props} />)
                }
                {
                  (this.props.page === "blog" ) && (<BlogHome {...this.props} />)
                }
                {
                  (this.props.page === "post" ) && (<BlogPost {...this.props} />)
                }
              </ErrorBoundary>
            </div>
          </main>
          {/* <Aside ref={(asideref) => { this.asideref = asideref; }} /> */}
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Full);
