import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import frontPageDarkTheme from '../../utils/themes/frontPageDarkTheme';
import { styled } from '@material-ui/core/styles';

import AlertComp from '../AlertComp/';
import AppBar from 'material-ui/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from 'material-ui/IconButton';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';

import InsertChartIcon from '@material-ui/icons/InsertChart';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import HistoryIcon from '@material-ui/icons/History';

import { ENABLE_WHEEL }  from '../../constants/services';

import fcWidget from 'freshchat-widget';


const BTButton = styled(Button)({
  background: 'linear-gradient(45deg, #00897B 30%, #26A69A 70%)',
  border: 0,
  borderRadius: 3,
  // boxShadow: '0 3px 5px 2px rgba(255, 255, 255, .1)',
  color: 'white',
  fontWeight: 600,
  height: 38,
  padding: '0 20px',
  marginTop: 6,
  marginBottom: 6,
});

const TopButton = styled(Button)({
  // background: 'linear-gradient(45deg, #00897b 30%, #26a69a 70%)',
  border: '1px solid #80CBC4',
  borderRadius: 3,
  // boxShadow: '0 2px 1px 1px rgba(255, 255, 255, .3)',
  color: 'white',
  fontWeight: 500,
  height: 38,
  padding: '0 20px',
  marginTop: 6,
  marginBottom: 6,
});

/**
 * This example is taking advantage of the composability of the `AppBar`
 * to render different components depending on the application state.
 */
const appBarStyle = {
 titlefont: {
   fontFamily: 'Lobster, Century Gothic, Muli, sans-serif',
   fontSize: '2.25rem',
   color: '#1f292d',
   // 'z-index': '2',
 },
 bar: {
   marginTop: '-5px',
   position: 'fixed',
   zIndex: '1020',
   width: '100%',
   height: '60px',
   // flex: '0 0 55px',
 }
};

const style = {
  whitetext: {
    'color': '#FFFFFF',
  },
  button: {
    margin: 12,
    borderRadius: '5px',
  },
  buttonflat: {
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 6,
    marginRight: 6,
  },
  boldbutton: {
    //margin: 12,
    //color: '#5e35b1',
    borderRadius: '5px',
  },
  refresh: {
    // display: 'inline-block',
    display: 'block',
    margin: 'auto',
    // position: 'relative',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // 'z-index': '2',
  },
  century12: {
    fontFamily: 'Century Gothic, Muli, sans-serif',
    fontSize: '8px',
  }
};

class AppBarComp extends Component {

  constructor(props) {
    super(props);

    this.handleLogoutClick  = this.handleLogoutClick.bind(this);
    this.handleLogout       = this.handleLogout.bind(this);
    this.cancelLogout       = this.cancelLogout.bind(this);

    this.loadDashboard      = this.loadDashboard.bind(this);
    this.loadWheel          = this.loadWheel.bind(this);
    this.loadHistory        = this.loadHistory.bind(this);
    this.loadAccount        = this.loadAccount.bind(this);
    this.loadHelp           = this.loadHelp.bind(this);
    this.loadBlog           = this.loadBlog.bind(this);

    this.signup             = this.signup.bind(this);
    this.login              = this.login.bind(this);
    this.delayedAuth        = this.delayedAuth.bind(this);
  }

  componentWillMount()
  {
    // auth will be handled by main component, no need to do it in app bar, which can be present for unauthenticated routes
    // const { isAuthenticated } = this.props.auth;

    // console.log('cwm | appbar is auth?', isAuthenticated() );

    // weird hackery to get correct icons to show up after login
    setTimeout(() => {
      this.delayedAuth();
    }, 5000);

  }

  delayedAuth() {

    const { isAuthenticated } = this.props.auth;
    console.log('delayedAuth | appbar is auth?', isAuthenticated() );

    this.setState({ state: this.state });
  }


login() {
  this.props.auth.login();
}

signup() {
  this.props.auth.signup();
}


  handleItemClick = (event, child) => {
    console.log('item click');
  };

  handleLogoutClick() {
    console.log('Logout clicked...');
    this.alertref.handleOpen();
  }

  handleLogout() {
    console.log('Logging out...');
    this.alertref.handleClose();

    this.props.auth.logout();

    window.fcWidget.track('React Page Visit', {
      page: 'Logout',
    });

    this.props.history.push('/');
  };

  cancelLogout() {
    console.log('cancelling logout...');
    this.alertref.handleClose();
  }

  loadDashboard() {
    const { isAuthenticated } = this.props.auth;

    if( isAuthenticated() ) {
      this.props.history.push('/app');
    }
    else {
      this.props.history.push('/');
    }
  }

  loadWheel() {
    if( ENABLE_WHEEL === true ) {
      this.props.history.push('/wheel');
    }
  }

  loadHistory() {
    this.props.history.push('/history');
  }

  loadAccount() {
    this.props.history.push('/account');
  }

  loadHelp() {
    this.props.history.push('/help');
  }

  loadBlog() {
    this.props.history.push('/blog');
  }

  render() {
    const { isAuthenticated } = this.props.auth;

    const rightIcons = (
        <div>
          {isAuthenticated() ?
            <div>

              <BTButton onClick={this.loadDashboard} size="medium" variant="outlined" color="default" startIcon={<InsertChartIcon />}>Backtester</BTButton>
              &nbsp;&nbsp;
              { ENABLE_WHEEL === true && (
                <TopButton onClick={this.loadWheel} size="medium" variant="outlined" color="default" startIcon={<BlurCircularIcon />}>Wheel</TopButton>
              )}

              <TopButton onClick={this.loadHistory} size="medium" variant="outlined" color="default" startIcon={<HistoryIcon />}>History</TopButton>
              &nbsp;&nbsp;

              {/* <IconButton onClick={this.props.handlemessages} tooltip="Messages" touch={true} tooltipPosition="bottom-left"><MessageIcon /></IconButton> */}
              {/* <IconButton className="jrstep_blog" onClick={this.loadBlog} tooltip="Blog" touch={true} tooltipPosition="bottom-left"><LibraryBooksIcon /></IconButton> */}
              <TopButton onClick={this.loadHelp} size="medium" variant="outlined" color="default" startIcon={<HelpOutlineIcon />}>Help</TopButton>
              &nbsp;&nbsp;

              {/* <IconButton onClick={this.props.handlesettings} tooltip="Settings" touch={true} tooltipPosition="bottom-left"><SettingsIcon /></IconButton> */}
              <TopButton onClick={this.loadAccount} size="medium" variant="outlined" color="default" startIcon={<AccountCircleIcon />}>Account</TopButton>
              &nbsp;&nbsp;
              <TopButton onClick={this.handleLogoutClick} size="medium" variant="outlined" color="default" startIcon={<ExitToAppIcon />}>Exit</TopButton>

            </div>
            :
              (this.props.match.url.includes("post") ?
                <div>
                  <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, frontPageDarkTheme)}>
                    <RaisedButton label="Back to Blog" overlayStyle={style.boldbutton} buttonStyle={style.boldbutton} style={style.button} onClick={this.loadBlog} />
                  </MuiThemeProvider>
                </div>
              :
                <div>
                  <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, frontPageDarkTheme)}>
                    <RaisedButton label="Sign Up" overlayStyle={style.boldbutton} buttonStyle={style.boldbutton} primary={true} style={style.button} onClick={this.signup} />
                  </MuiThemeProvider>
                  <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, frontPageDarkTheme)}>
                    <RaisedButton label="Log In" overlayStyle={style.boldbutton} buttonStyle={style.boldbutton} style={style.button} onClick={this.login} />
                  </MuiThemeProvider>
                </div>
              )
            }

        </div>
      );

    const titleRocket = (
      <div className="handcursor">
        {
          <div onClick={this.loadDashboard}><img className="btlogo" src="/img/backtestio-logosm.png" alt="backtest.io"/>&nbsp;
            <i className="fa fa-rocket" aria-hidden="true" />
          </div>
        }
      </div>
    );

    return (
      <div>
        <AppBar
          title={titleRocket}
          style={appBarStyle.bar}
          titleStyle={appBarStyle.titlefont}
          // iconElementLeft={<IconButton onClick={this.props.handlesidebar}><NavigationMenu /></IconButton>}
          showMenuIconButton={false}
          iconElementRight={rightIcons}
        />
        <AlertComp ref={(alertref) => { this.alertref = alertref; }}
          yes="Logout" no="Stay" handleyes={this.handleLogout} handleno={this.cancelLogout} open={false} modal={false}
          message="Are you sure you want to logout?" />

      </div>
    );
  }
}


export default withRouter(AppBarComp);
