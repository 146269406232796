import React from 'react';

import IconButton from 'material-ui/IconButton';
import LinkIcon from 'material-ui/svg-icons/content/link';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import { FacebookShareButton, GooglePlusShareButton, LinkedinShareButton, TwitterShareButton, RedditShareButton, WhatsappShareButton, EmailShareButton,
  FacebookIcon, TwitterIcon, GooglePlusIcon, LinkedinIcon, WhatsappIcon, RedditIcon, EmailIcon } from 'react-share';


const style = {
  iconstyle: {
    marginTop: -5,
    marginLeft: -5,
    // paddingRight: 10,
  },
};


export default class ShareMenu extends React.Component {
  state = {
    link: this.props.link,
    title: this.props.title,
  };

  render() {
       

    return (
        <div>          
            <div className="sharebuttondiv">
              <FacebookShareButton url={this.state.link} quote={this.state.title} className="sharebutton">
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </div>

            <div className="sharebuttondiv">
              <TwitterShareButton url={this.state.link} title={this.state.title} className="sharebutton">
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </div>

            <div className="sharebuttondiv">
              <GooglePlusShareButton url={this.state.link} className="sharebutton">
                <GooglePlusIcon size={32} round />
              </GooglePlusShareButton>
            </div>

            <div className="sharebuttondiv">
              <LinkedinShareButton url={this.state.link} title={this.state.title} className="sharebutton">
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </div>

            <div className="sharebuttondiv">
              <RedditShareButton url={this.state.link} title={this.state.title} className="sharebutton">
                <RedditIcon size={32} round />
              </RedditShareButton>
            </div>

            <div className="sharebuttondiv">
              <WhatsappShareButton url={this.state.link} title={this.state.title} separator=" => " className="sharebutton">
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>

            <div className="sharebuttondiv">
              <EmailShareButton url={this.state.link} title={this.state.title} body="Head over to backtest.io and check it out (no signup required).  And it's free!" className="sharebutton">
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
            
            <div className="sharebuttondiv">
              <CopyToClipboard className="sharebutton" text={this.state.link} onCopy={this.props.onCopy}>
                <IconButton style={style.iconstyle} iconStyle={style.iconstyle}>
                  <LinkIcon/>
                </IconButton>
              </CopyToClipboard>
            </div>

        </div>
    );
  }
}
