import React from "react"
import ContentLoader from "react-content-loader"

const PlanInfoLoader = (props) => (
  <ContentLoader
    speed={2}
    width={400}
    height={84}
    viewBox="0 0 400 84"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="5" y="5" rx="5" ry="5" width="95%" height="17" />
    <rect x="5" y="30" rx="5" ry="5" width="100%" height="17" />
  </ContentLoader>
)

export default PlanInfoLoader;
