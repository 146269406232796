import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from "react-device-detect";

import { ThemeProvider, makeStyles, withStyles, createMuiTheme } from '@material-ui/core/styles';
// import btioDarkTheme from '../../utils/themes/btioDarkTheme';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Ladder from './Ladder';
import { LadderContext } from '../../context/LadderContext';

// import DataTables from 'material-ui-datatables';

import axios from 'axios';
import { Helmet } from 'react-helmet';
import fcWidget from 'freshchat-widget';


const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  },
});

// {
//   "palette": {
//       "primary1Color": Colors.teal500,
//       "primary2Color": Colors.blueGrey500,
//     //   "primary3Color": Colors.grey600,
//       "accent1Color": Colors.pinkA200,
//     //   "accent2Color": Colors.indigoA400,
//     //   "accent3Color": Colors.indigoA100,
//       "pickerHeaderColor": Colors.teal500
//   },
//   "textField": {
//     "errorColor": Colors.deepOrangeA200
//   },
//   "drawer": {
//     "color": Colors.blueGrey900
//   }
// }

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  titlefont: {
    marginTop: 10,
    marginBottom: 5,
    // fontFamily: 'Permanent Marker',
    fontFamily: 'Century Gothic, Muli, sans-serif',
    fontSize: '2rem',
    fontWeight: 600,
    letterSpacing: '1px',
    // textAlign: 'center',
  },
  drawerTitle: {
    marginTop: 10,
    marginBottom: 5,
    // fontFamily: 'Permanent Marker',
    fontFamily: 'Century Gothic, Muli, sans-serif',
    fontWeight: 600,
    letterSpacing: '3px',
    fontSize: '1.75rem',
    textAlign: 'center',
    // borderBottom: '1px solid #404040',  // used to see total area
  },
  cardSpacing: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

var historyList = [];
var rawHistory = {};

// gotta store state for all this globally to persist between page changes
var acSymbolSource = [];
var acStrategySource = [];
var backtestTableData = [];
var backtestDataShown = [];
var numRowsShown = 10;
var totalResults = 0;
var currentTradePage = 1;
var archiveToggleState = true;
var sampleToggleState = true;
var showFooter = true;
var showNoResultsCard = false;
var symbolFilter = '';
var stratFilter = '';
var sortField = 'id';
var sortDir = 'desc';

const backtestTableCols = [
  // { key: 'HistoryID', label: '#', sortable: true,  },
  // { key: 'Date', label: 'Run Date', sortable: true,  },
  { key: 'Symbol', label: 'Symbol',  },
  { key: 'Strat', label: 'Strategy',   },
  { key: 'StartDate', label: 'Start Date',  },
  { key: 'EndDate', label: 'End Date',  },
  { key: 'NetPNL', label: 'Net PNL', sortable: true,  },
  // { key: 'NetROM', label: 'Net ROM', sortable: true,  },
  { key: 'NetROC', label: 'Net ROC', sortable: true,  },
  { key: 'Trades', label: 'Trades', sortable: true,  },
  { key: 'DailySharpe', label: 'Sharpe', sortable: true,  },
  { key: 'Calmar', label: 'Calmar', sortable: true,  },
  { key: 'MonthlySortino', label: 'Sortino', sortable: true,  },
  { key: 'View', label: 'Actions', },
  // { key: 'Archive', label: '', },
];


function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

function stripLeadingZero(number) {
  return number.replace(/^0+(?=\d)/, '');
}

function queryStringToJSON(queryString) {
  if(queryString.indexOf('?') > -1){
    queryString = queryString.split('?')[1];
  }
  var pairs = queryString.split('&');
  var result = {};
  pairs.forEach(function(pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });
  return result;
}

function formatDateTimeObjToString(d)
{
  return d.getUTCFullYear() + '-' + (d.getUTCMonth()+1) + '-' + d.getUTCDate() + ' '
    + d.getUTCHours() + ':' + d.getUTCMinutes() + ':' + d.getUTCSeconds();
}

class Wheel extends Component {

  constructor(props) {
    super(props);

    this.state = {
      helmetTitle: 'wheel',
      // numRowsShown: 10,
      // totalResults: 0,
      // currentTradePage: 1,
      // archiveToggleState: true,
      // showFooter: true,
      // showNoResultsCard: false,
      hideProgress: true,
      // symbolFilter: '',
      // stratFilter: '',
      // sortField: 'id',
      // sortDir: 'desc',
      nextPagePendingReq: false,
      rowSizePendingReq: false,

      // backtestTableData: [],
      // backtestDataShown: [],

      // acSymbolSource: [],
      // acStrategySource: [],

      snackopen: false,
      snacktext: '',
      snackAutoHide: 5000,
    }

    this.handleSnackOpen       = this.handleSnackOpen.bind(this);
    this.handleSnackClose       = this.handleSnackClose.bind(this);
  }

  componentWillMount()
  {
    const { isAuthenticated } = this.props.auth;
    if( !isAuthenticated() )
    {
      this.props.history.push('/');
    }
  }

  componentDidMount()
  {
    //this.pagedUserHistory();

    window.fcWidget.init({
      token: "59366a55-7227-4b4c-a54b-78d3a53dcf04",
      host: "https://wchat.freshchat.com",
      tags: ["member"],
      faqTags: {
        tags: ['member'],
        filterType: 'category' //Or filterType: 'article'
      },
      open: false,
      config: {
        showFAQOnOpen: true,
        hideFAQ: false,
        agent: {
          hideName: false,
          hidePic: false,
          hideBio: false,
        },
        content: {
          placeholders: {
            search_field: 'Search Knowledgebase',
          },
          actions: {
            tab_faq: 'Knowledgebase',
            tab_chat: 'Chat',
          },
          headers: {
            faq: 'Knowledgebase',
            faq_help: 'Browse our help',
            channel_response: {
              offline: 'We are currently away. Leave us a message!',
              online: {
                minutes: {
                  one: "You should get a reply in a minute",
                  more: "You should get a reply within {!time!} minutes"
                },
                hours: {
                  one: "You should get a reply in an hour",
                  more: "You should get a reply within {!time!} hours",
                }
              }
            }
          }
        }
      }
    });

    window.fcWidget.track('React Page Visit', {
      page: 'Wheel',
    });
  }

  handleSnackOpen() {
    this.setState({ snackopen: true, snacktext: 'nihaooo bitchezzz' });
  };

  handleSnackClose() {
    this.setState({ snackopen: false });
  };


  render() {


    return (
      <>
      <ThemeProvider theme={darkTheme}>
      <div className="animated fadeIn">
        <Helmet><title>backtest.io | {this.state.helmetTitle}</title></Helmet>
        <div className="row">
          <div className="col-sm-4 col-md-4 col-lg-4">
            <h2 className={this.props.classes.titlefont}>Wheel</h2>
          </div>
          <div className="col-sm-5 col-md-5 col-lg-5">
          </div>
          <div className="col-sm-3 col-md-3 col-lg-3">
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <LadderContext>
              <Ladder className={this.props.classes.cardSpacing} num={1} />
            </LadderContext>
          </div>
          <div className="col-md-6">
            <LadderContext>
              <Ladder className={this.props.classes.cardSpacing} num={2} />
            </LadderContext>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {/* <Button variant="contained" color="primary" onClick={this.handleSnackOpen}>
              Primary
            </Button> */}
          </div>
        </div>
        <br/>



        {
          !isMobile && (
          // <ThemeProvider theme={darkTheme}>
            <Drawer
              className={this.props.classes.drawer}
              variant="permanent"
              classes={{
                paper: this.props.classes.drawerPaper,
              }}
              anchor="left"
            >
               <div className={this.props.classes.toolbar} />
                <Divider />

                <div className={this.props.classes.drawerTitle}>Market</div>
                {/* <Typography variant="h2" noWrap>
                  Market
                </Typography> */}
            </Drawer>
          )
        }
      </div>
      </ThemeProvider>
      <Snackbar
        open={this.state.snackopen}
        message={this.state.snacktext}
        autoHideDuration={this.state.snackAutoHide}
        // onRequestClose={this.handleSnackClose}
        // onClick={this.handleSnackAction}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={this.handleSnackAction}>
              UNDO
            </Button>
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.handleSnackClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
    );
  }
}

export default withStyles(useStyles)(withRouter(Wheel));
