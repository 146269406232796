// import React from 'react';
import ReactDOM from 'react-dom';
// import { HashRouter, Switch, Route } from 'react-router-dom';
//import routes from './routes';
//import './index.css';
//import App from './App';

// import Simple from './containers/Simple/';
// import Full from './containers/Full/'

import { makeMainRoutes } from './utils/auth0/routes';

import registerServiceWorker from './registerServiceWorker';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

LogRocket.init('qm4ige/backtestio');
setupLogRocketReact(LogRocket);

// var jwtDecode = require('jwt-decode');

const routes = makeMainRoutes();

ReactDOM.render(
  routes,
  document.getElementById('root')
);


// function loggedIn() {
//     let token = sessionStorage.getItem("jwtToken"); //awsToken
//      if(!token || token === "") {//if there is no token, dont bother
//        console.log("loggedIn: sessionStorage invalid token");
//        return false;
//      }
//     //  else {
//     //    var decoded = jwtDecode(token);
//     //    console.log("decoded token:", decoded);
//       // this.setState( {firstname: decoded.firstname}, () => console.log('firstname:', this.state.firstname));

//       //this.props.history.push('app');
//     //  }

//      return true;
//   }

//   function loggedOut() {
//     let token = sessionStorage.getItem("jwtToken"); //awsToken
//      if(!token || token === "") {//if there is no token, dont bother
//        console.log("loggedOut: sessionStorage invalid token");
//        //this.props.history.push('app/login');
//        return true;
//      }
//      return false;
//   }



// //ReactDOM.render(<App />, document.getElementById('root'));
// ReactDOM.render(
//     (
//     <HashRouter>
//         <Switch>
//             <Route exact path="/" render={() => (
//                 loggedIn() ? (
//                     <Full />
//                 ) : (
//                     <Simple />
//                 )
//                 )}/>
//             <Route path="/:id_token" render={() => (
//                 loggedIn() ? (
//                     <Full />
//                 ) : (
//                     <Simple />
//                 )
//                 )}/>
//             <Route path="/app" component={Full} />
//         </Switch>
//     </HashRouter>
//     ), document.getElementById('root')
// );
registerServiceWorker();


function noop() {}

if (process.env.NODE_ENV !== 'development') {
  console.log = LogRocket.log;
  // console.warn = noop;
  // console.error = noop;
}
else{
  console.log("we're in development mode!");
}
// <HashRouter>
//         <Switch>
//             <Route path="/" component={Simple} />
//             <Route path="/app" component={Full} />
//             <Redirect from="/login" to="/" />
//         </Switch>
//     </HashRouter>