import React, { useContext, useState, useEffect } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { teal } from '@material-ui/core/colors';

import Slider from '@material-ui/core/Slider';

import { store } from '../../context/LadderContext';
import { formatNumber }  from '../../utils/helpers';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    // alignContent: 'center',
    // justifyContent: 'center',
  },
  grid: {
    margin: theme.spacing(1),
    minWidth: '10ch',
  },
  margin: {
    height: theme.spacing(1)
  }
}));

const iOSBoxShadow = "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const PriceSlider = withStyles({
  root: {
    color: "#3880ff",
    height: 2,
    padding: "15px 0"
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    marginTop: -12,
    marginLeft: -14,
    "&:focus, &:hover, &$active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow
      }
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 12px)",
    top: -22,
    "& *": {
      background: "transparent",
      color: "#000"
    }
  },
  track: {
    height: 2,
    borderRadius: 4
  },
  rail: {
    height: 6,
    opacity: 0.5,
    backgroundColor: teal['500'],
    // borderRadius: 6
  },
  mark: {
    backgroundColor: teal['500'],
    height: 16,
    width: 4,
    marginTop: -5,
    marginLeft: -2
  },
  markActive: {
    opacity: 1,
    backgroundColor: "currentColor"
  }
})(Slider);


export default function PriceScenario(props) {
  const classes = useStyles();

  const { state, dispatch } = useContext(store);

  const [marks, setMarks] = useState([]);
  const [label, setLabel] = useState('auto');

  // useEffect(() => {
  //   console.log(`useEffect: setPx`);
  //   dispatch('scenario', px);
  // }, [px]);

  useEffect(() => {
    if( state.disable ) {
      setMarks([]);
      setLabel('auto');
      return;
    }
    console.log(`useEffect: setMarks`);
    setMarks(defaultMarks);
    setLabel('on');
  }, [state.disable]);

  const defaultMarks = [
    {
      value: state.quote.week_52_low,
      label: formatNumber(state.quote.week_52_low),
    },
    {
      value: state.quote.last,
      label: formatNumber(state.quote.last),
    },
    {
      value: state.quote.week_52_high,
      label: formatNumber(state.quote.week_52_high),
    }
  ];

  const handleChange = (event, val) => {
    dispatch({type: 'scenario', value: val});
  };

  const handleChangeCommitted = (event, val) => {
    dispatch({type: 'scenarioCommit', value: val});
  };


  return (
      <Grid className={classes.grid} item xs>
        <div className={classes.margin} />
        <PriceSlider
          disabled={state.disable}
          valueLabelDisplay={label}
          aria-label="price scenarios"
          value={state.scenarioPx}
          min={state.quote.week_52_low}
          max={state.quote.week_52_high}
          step={0.1}
          marks={marks}
          onChange={handleChange}
          onChangeCommitted={handleChangeCommitted}
          track={false}
          // scale
        />
      </Grid>
  );
}
