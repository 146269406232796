import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import frontPageDarkTheme from '../../utils/themes/frontPageDarkTheme';

import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';

import axios from 'axios';
import { Helmet } from 'react-helmet';
import fcWidget from 'freshchat-widget';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const style = {
  whitetext: {
    'color': '#FFFFFF',
  },
  button: {
    margin: 12,
    borderRadius: '5px',
  },
  buttonflat: {
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 6,
    marginRight: 6,
  },
  boldbutton: {
    //margin: 12,
    //color: '#5e35b1',
    borderRadius: '5px',
  },
  refresh: {
    // display: 'inline-block',
    display: 'block',
    margin: 'auto',
    // position: 'relative',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // 'z-index': '2',
  },
  moreheight: {
    height: '1500px',
  }
};

class SAVerify extends Component {

constructor(props) {
  super(props);


  this.state = {
    emailerror: '',
    loadstatus: 'hide',
    open: false,
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'bottom',
    },
    targetOrigin: {
      horizontal: 'left',
      vertical: 'top',
    },

    regopen: false,
    reganchorOrigin: {
      horizontal: 'right',
      vertical: 'bottom',
    },
    regtargetOrigin: {
      horizontal: 'right',
      vertical: 'top',
    },
    isAuthPath: (this.props.match.path == "/:id_token" && this.props.match.path != "/app" ),

  };

  this.goTo = this.goTo.bind(this);
  this.signup = this.signup.bind(this);
  this.login = this.login.bind(this);
  this.logout = this.logout.bind(this);

  this.openChat = this.openChat.bind(this);
  this.trackSolClick = this.trackSolClick.bind(this);

  // commenting out since we're handling this in index.js
  console.log('path:', this.props.match.path);

  console.log("match:", this.props.match);
  // this.lock.on("authenticated", this.handleAuthentication);


}

goTo(route) {
  this.props.history.replace(`/${route}`)
}

login() {

  // this.lock.show();
  this.props.auth.login();
}

signup() {
  // this.lock.show();
  this.props.auth.signup();
}

logout() {
  this.props.auth.logout();
}

isLoading = () => {
  return false;
}

openChat()
{
  window.fcWidget.open();
}

trackSolClick()
{
  console.log('trackSolClick');
  window.fcWidget.track('click_solutions', {
    click: true
  });
}


componentWillMount()
{
  console.log("sa0 cwm match:", this.props.match);

  const { isAuthenticated } = this.props.auth;
  if( isAuthenticated() )
  {
    this.props.history.push('/app');
  }
}

componentDidMount()
{
  // var token = getUrlParameter("id_token");
  // console.log('token', token);
  console.log('sa0 cdm querystring:',  window.location.search);

  window.fcWidget.init({
    token: "59366a55-7227-4b4c-a54b-78d3a53dcf04",
    host: "https://wchat.freshchat.com",
    faqTags: {
      tags: ['splash'],
      filterType: 'article' //Or filterType: 'article'
    },
    // open: true,
    config: {
      showFAQOnOpen: false,
      hideFAQ: false,
      agent: {
        hideName: false,
        hidePic: false,
        hideBio: false,
      },
      // headerProperty: {
      //   backgroundColor: '#FFFF00',
      //   foregroundColor: '#333333',
      //   backgroundImage: 'https://wchat.freshchat.com/assets/images/texture_background_1-bdc7191884a15871ed640bcb0635e7e7.png'
      // },
      content: {
        placeholders: {
          search_field: 'Search FAQs',
          // reply_field: 'Reply in my widget',
          // csat_reply: 'Reply for csat'
        },
        actions: {
        //   csat_yes: 'Yes, Resolved',
        //   csat_no: 'No, Resolved',
        //   push_notify_yes: 'Notify',
        //   push_notify_no: 'No Notify',
          tab_faq: 'FAQ',
          tab_chat: 'Chat',
        //   csat_submit: 'Submit Review Comments'
        },
        headers: {
        //   chat: 'Chat with us',
        //   chat_help: 'Reach out to us if you have any questions',
          faq: 'Frequently Asked Questions',
          faq_help: 'Browse answers to common ?s',
        //   faq_not_available: 'No FAQs',
        //   faq_search_not_available: 'No FAQs available for {{query}}',
        //   faq_useful: 'FAQs are useful',
        //   faq_thankyou: 'Thanks for feedback',
        //   faq_message_us: 'Message Us For FAQs',
        //   push_notification: 'you want to not miss conversation',
        //   csat_question: 'Did we address your question?',
        //   csat_yes_question: 'Did we resolve the conversation?',
        //   csat_no_question: 'Did we not resolve the conversation?',
        //   csat_thankyou: 'Thanks for the response',
        //   csat_rate_here: 'Give your rating here',
          channel_response: {
            offline: 'We are currently away. Leave us a message!',
            online: {
              minutes: {
                one: "You should get a reply in a minute",
                more: "You should get a reply within {!time!} minutes"
              },
              hours: {
                one: "You should get a reply in an hour",
                more: "You should get a reply within {!time!} hours",
              }
            }
          }
        }
      }
    }
  });

  console.log("sa0 cdm match:", this.props.match);

}



  render() {
    const { isAuthenticated } = this.props.auth;

    return (
      // <div className="app flex-row align-items-center">
      <div>
        {/* {this.props.children} */}
        <Helmet>
          <title>backtest.io | options research platform</title>
          <script src="/js/grayscale.js" type="text/javascript" async={true} />
        </Helmet>


        {/* <!-- Navigation --> */}
        <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
          <div className="container">
            <a className="navbar-brand js-scroll-trigger" href="#page-top">backtest.io</a>
            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
              Menu
              <i className="fa fa-bars"></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto list-inline banner-social-buttons">
                <li className="list-inline-item">
                  <a className="js-scroll-trigger" href="#features">
                    <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, frontPageDarkTheme)}>
                      <FlatButton label="Features" style={style.button} />
                    </MuiThemeProvider>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a className="js-scroll-trigger" href="#pricing">
                    <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, frontPageDarkTheme)}>
                      <FlatButton label="Pricing" style={style.button} />
                    </MuiThemeProvider>
                  </a>
                </li>
                {/* <li className="list-inline-item">
                  <a className="js-scroll-trigger" href="#why">
                    <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, frontPageDarkTheme)}>
                      <FlatButton label="Why Us?" style={style.button} />
                    </MuiThemeProvider>
                  </a>
                </li> */}
                <li className="list-inline-item">
                  <a className="js-scroll-trigger" href="#solutions" onClick={this.trackSolClick}>
                    <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, frontPageDarkTheme)}>
                      <FlatButton label="Solutions" style={style.button} />
                    </MuiThemeProvider>
                  </a>
                </li>
                <li className="list-inline-item">
                {
                  !isAuthenticated() && (
                    <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, frontPageDarkTheme)}>
                      <RaisedButton label="Sign Up" overlayStyle={style.boldbutton} buttonStyle={style.boldbutton} secondary={true} style={style.button} onClick={this.signup} />
                    </MuiThemeProvider>
                    )
                }
                </li>
                <li className="list-inline-item">
                {
                  !isAuthenticated() && (
                    <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, frontPageDarkTheme)}>
                      <RaisedButton label="Log In" overlayStyle={style.boldbutton} buttonStyle={style.boldbutton} style={style.button} onClick={this.login} />
                    </MuiThemeProvider>
                    )
                }
                {
                  isAuthenticated() && (
                    <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, frontPageDarkTheme)}>
                      <RaisedButton label="Log Out" overlayStyle={style.boldbutton} buttonStyle={style.boldbutton} secondary={true} style={style.button} onClick={this.logout} />
                    </MuiThemeProvider>
                    )
                }
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div>
        <header className="masthead">
          <div className="intro-body">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
                  <div className="padding-shift">
                    {/* <h1 className="brand-heading">Grayscale</h1> */}
                    <br/><br/><br/><br/>
                    <p className="mastheadpermsm">Thanks for signing up!</p>
                    <p className="mastheadpermsm">Please verify your email and then log in.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>


        <section id="features" className="features-section" style={style.moreheight}>
        </section>

        </div>



        {/* <!-- Map Section --> */}
        {/* <div id="map"></div> */}

        {/* <!-- Footer --> */}
        <footer className="footer-section">
          <div className="container text-center">
            <p>Copyright &copy; { new Date().getFullYear() } backtest.io</p>
          </div>
        </footer>


      </div>
    );
  }
}

export default withRouter(SAVerify);