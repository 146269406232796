import history from '../history';
// import auth0 from 'auth0-js';
import { Auth0Lock } from 'auth0-lock';
import { AUTH_CONFIG_PROD } from './auth0-variables-prod';
import { AUTH_CONFIG_DEV } from './auth0-variables-dev';

import branch from 'branch-sdk';
import axios from 'axios';
import LogRocket from 'logrocket';

LogRocket.init('qm4ige/backtestio');

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';


var auth0clientid = AUTH_CONFIG_PROD.clientId;
var auth0domain = AUTH_CONFIG_PROD.domain;
var auth0callback = AUTH_CONFIG_PROD.callbackUrl;
if (process.env.NODE_ENV === 'development') {
  console.log("we're in development mode!");
  auth0clientid = AUTH_CONFIG_DEV.clientId;
  auth0domain = AUTH_CONFIG_DEV.domain;
  auth0callback = AUTH_CONFIG_DEV.callbackUrl;
}

export default class Lock {
  userProfile;
  tokenRenewalTimeout;

  lock = new Auth0Lock(auth0clientid, auth0domain, {
      configurationBaseUrl: 'https://cdn.auth0.com',
      auth: {
        params: {
          //audience: `https://${AUTH_CONFIG.domain}/userinfo`,
          audience: `https://backtest.io/btapi`,
          responseType: 'token id_token',
          scope: 'openid email profile plan:basic',
          // redirectUrl: auth0callback,
        },
        sso: true,
      },
      theme: {
        logo: 'https://backtest.io/img/bt-large-bg700.png',
        primaryColor: '#455A64',
        foregroundColor: "#FFAB00",
        // labeledSubmitButton: false
      },
      languageDictionary: {
        title: "Log In"
      },
      socialButtonStyle: 'big',
      rememberLastLogin: true,
      loginAfterSignup: false,
      additionalSignUpFields: [{
        name: "full_name",
        placeholder: "Enter your full name",
        // The following properties are optional
        icon: "https://s3.us-east-2.amazonaws.com/backtest-io/img/baseline_person_outline_black_18dp.png",
        // validator: this.validateFullName
      }]
    });

  // auth0 = new auth0.WebAuth({
  //   domain: AUTH_CONFIG.domain,
  //   clientID: AUTH_CONFIG.clientId,
  //   //redirectUri: AUTH_CONFIG.callbackUrl,
  //   audience: `https://${AUTH_CONFIG.domain}/userinfo`,
  //   responseType: 'token id_token',
  //   scope: 'openid profile'
  // });

  constructor() {
    this.login = this.login.bind(this);
    this.signup = this.signup.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.handleUnAuthorized = this.handleUnAuthorized.bind(this);
    this.setSession = this.setSession.bind(this);
    this.hasAccessToken = this.hasAccessToken.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.renewToken = this.renewToken.bind(this);
    this.scheduleRenewal = this.scheduleRenewal.bind(this);
    this.validateFullName = this.validateFullName.bind(this);
    this.validateUserInfo = this.validateUserInfo.bind(this);
    this.parseBranchLink = this.parseBranchLink.bind(this);

    this.scheduleRenewal();

    this.lock.on("authenticated", this.handleAuthentication);
    this.lock.on("authorization_error", this.handleUnAuthorized);
  }

  login() {
    this.lock.show( {
      allowSignUp: false,
      initialScreen: 'login',
    });
  }

  signup() {
    this.lock.show({
      allowLogin: false,
      initialScreen: 'signUp',
    });
  }

  handleAuthentication(authResult) {
    console.log('in lock::handleAuthentication');

    if (authResult && authResult.accessToken && authResult.idToken) {
      this.setSession(authResult);
      console.log('auth successful', authResult);
      // this.getProfile();

      var redir = localStorage.getItem('redir');
      if( redir ) {
        console.log('in auth...localstorage found... redirecting from', history.location, ' to', redir);
        localStorage.removeItem('redir');

        if( history.location.pathname !== redir ) {
          history.replace(redir);
        }
      }
      else {
        console.log('redirecting to default dashboard');
        history.replace('/app');
      }

      // auth was successful... make the call to the backend to set storage items here
      if (!this.userProfile) {
        this.getProfile((err, profile) => {
          console.log('1 - userProfile:', this.userProfile);
          this.validateUserInfo(profile);
        });
      } else {
        console.log('2 - userProfile:', this.userProfile);
        this.validateUserInfo(this.userProfile);
      }

    } else {
      console.log('handleAuthentication error');
      history.replace('/');
      // alert(`Error: ${err.error}. Check the console for further details.`);

    }

  }

  handleUnAuthorized(error) {
    console.log("in lock::handleUnAuthorized", error);

    if( error.error=="unauthorized" && error.errorDescription.includes("verify") ) {
      history.replace('/verify');
    }
  }

  setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime()
    );

    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);

    // schedule a token renewal
    this.scheduleRenewal();

    // navigate to the app route
    // history.replace('/app');   // already handled
  }

  hasAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      console.log('No access token found');
      return false;
    }
    return true;
  }

  getAccessToken() {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      console.log('No access token found');
      this.logout();
      //throw new Error('No access token found');
    }
    return accessToken;
  }

  getIdToken() {
    const idToken = localStorage.getItem('id_token');
    if (!idToken) {
      console.log('No id token found');
      throw new Error('No id token found');
    }
    return idToken;
  }

  getProfile(cb) {
    let accessToken = this.getAccessToken();

    this.lock.getUserInfo(accessToken, (err, profile) => {
      if (profile) {
        this.userProfile = profile;
        // console.log('lock/auth0 retrieved user profile:', profile);
        // console.log("profile.email_verified: ", profile.email_verified);
        // console.log("profile.email: ", profile.email);
        // console.log("profile.clientID: ", profile.clientID);
        // console.log("profile.updated_at: ", profile.updated_at);
        // console.log("profile.name: ", profile.name);
        // console.log("profile.picture: ", profile.picture);
        // console.log("profile.user_id: ", profile.user_id);
        // console.log("profile.nickname: ", profile.nickname);
        // console.log("profile.created_at: ", profile.created_at);
        // console.log("profile.sub: ", profile.sub);
      }
      else {
        console.log("lock: error retrieving user profile");
      }

      cb(err, profile);
    });
  }

  logout() {
    this.lock.logout();

    // Clear access token and ID token from local storage
    // localStorage.removeItem('access_token');
    // localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('new_user');
    localStorage.removeItem('token');
    // localStorage.clear();

    clearTimeout(this.tokenRenewalTimeout);

    branch.logout( function (err) {
      console.log('branch logout', err);
    });

    // navigate to the landing page route
    history.replace('/');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }

  renewToken() {
    this.lock.checkSession({},
      (err, result) => {
        if (err) {
          var errmsg = `Could not get a new token (${err.error}: ${err.error_description}).`;
          console.log(errmsg);
          //alert(errmsg);
          this.logout();
        } else {
          this.setSession(result);
          //alert(`Successfully renewed auth!`);
          console.log("Successfully renewed auth!");
        }
      }
    );
  }

  scheduleRenewal() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const delay = expiresAt - Date.now() - 60000; // leave a minute to refresh
    if (delay > 0) {
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken();
      }, delay);
    }
    console.log("token renewal scheduled in", delay);
  }

  validateFullName(a)
  {

      /*this will give alert if field is empty*/
      if(a == "")
      {
          alert("fullname must be filled");
          return {valid:false};
      }

      /*this will give alert if you entered any special characters*/
      var i=0;
      for(var j=0;j<=a.length;j++)
      {
          /*you can check all ascii character table on http://ascii.cl/htmlcodes.htm*/
          if(a.charCodeAt(j)>=33 && a.charCodeAt(j)<=47 || a.charCodeAt(j)>=58 && a.charCodeAt(j)<=64 || a.charCodeAt(j)>=91 && a.charCodeAt(j)<=96 || a.charCodeAt(j)>=123 && a.charCodeAt(j)<=126)
          {
              i++;
          }
      }
      /*if special character found after i++ increament of i*/
      if(i!=0)
      {
          alert("Special characters not allowed");
          return {valid:false};
      }


      /*this will give alert if you =entered any number*/
      var i=0;
      for(j=0;j<=a.length;j++)
      {
          /*you can check all ascii character table on http://ascii.cl/htmlcodes.htm*/
          if(a.charCodeAt(j)>=48 && a.charCodeAt(j)<=57)
          {
              i++;
          }
      }
      /*give alert if number not found after i++ increament of i*/
      if(i!=0)
      {
          alert("numbers not allowed");
          return {valid:false};
      }

      if ((a.length < 5) || (a.length > 15))
      {
          alert("Your Character must be between 5 to 15 Character");
          return {valid:false};
      }else
      {
        alert("You have successfully submited a fullname: "+a);
        return {valid:true};
      }
  }


  validateUserInfo(profile) {

    // auth info
    const API_URL = '/ValidateAuth0User';
    var token = this.getAccessToken();
    //console.log('token: ' + token );
    const headers = { 'Authorization': `Bearer ` + token }
    //console.log('headers: ', headers );

    var config = {
      'headers': headers
    }

    console.log('config', config);

    axios.post(API_URL, JSON.stringify(profile), config )
    .then(res => {
      // console.log('res', res);
      console.log('resdata',res.data);
      // can check if valid user flag is returned... if not, log a message or send an error alert
      // localStorage.setItem('user_id', res.data.UserID);
      localStorage.setItem('new_user', res.data.NewUser);
      localStorage.setItem('token', res.data.Token);
      localStorage.setItem('reflink', res.data.Link);
      //alert('setting localstorage newUser DISABLED for testing');
    })
    .catch( function(error) {
      console.log('lock | validateauth0user error' + error);
      LogRocket.captureException(error, {
        tags: {
          page: 'Lock',
          action: 'validateUserInfo'
        },
      });
    });;


    // init Branch
    if (process.env.NODE_ENV === 'development') {
      console.log("branch init development mode!");
      branch.init('key_test_neqXrN3pvlhIdVao2rqTCnfmByiIt6b2', this.parseBranchLink );
    }
    else {
      branch.init('key_live_get0rG8dshfHhMpn5qpS4ialDEgLE0bI', this.parseBranchLink );
    }

    // register user with branch.io
    branch.setIdentity(profile.sub, function (err, data) {
      console.log('lock | branch identity callback:', err, data);
      // do any track or event stuff here?
    } );

    LogRocket.identify(profile.sub, {
      name: profile.name,
      email: profile.email,

      // Add your own custom user variables here, ie:
      nickname: profile.nickname
    });

  }

  parseBranchLink(err, data)
  {
    console.log('lock parseBranchLink', err, data);

    if( data && data.data_parsed ) {
      console.log('data_parsed:', data.data_parsed);
    }
  }

}
