import getMuiTheme from 'material-ui/styles/getMuiTheme';
import baseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import * as Colors from 'material-ui/styles/colors';
import { fade } from 'material-ui/utils/colorManipulator';

export default {
  "palette": {
      "primary1Color": Colors.teal500,
      "primary2Color": Colors.blueGrey500,
    //   "primary3Color": Colors.grey600,
      "accent1Color": Colors.pinkA200,
    //   "accent2Color": Colors.indigoA400,
    //   "accent3Color": Colors.indigoA100,
      "pickerHeaderColor": Colors.teal500
  },
  "textField": {
    "errorColor": Colors.deepOrangeA200
  },
  "drawer": {
    "color": Colors.blueGrey900
  }
//   "toggle": {
//     "thumbOnColor": Colors.indigoA400,
//     "trackOnColor": fade(Colors.indigo700, 0.5),
//     "thumbOffColor": Colors.grey100,
//     "thumbDisabledColor": Colors.grey300,
//     "thumbRequiredColor": Colors.blueGrey700,
//     "trackOffColor": Colors.grey400,
//     "trackDisabledColor": Colors.grey400,
//     "labelDisabledColor": fade(Colors.black, 0.3),
//     "labelColor": fade(Colors.darkBlack, 0.87),
//     "trackRequiredColor": fade(Colors.blueGrey700, 0.5)
//   },
//   "snackbar": {
//       "backgroundColor": fade(Colors.darkBlack, 0.87),
//       "textColor": Colors.white
//   },
//   "checkbox": {
//       "checkedColor": Colors.indigoA400
//   },
//   "radioButton": {
//       "checkedColor": Colors.indigoA400
//   },
//   "textField": {
//       "focusColor": Colors.indigoA400,
//       "errorColor": Colors.redA400
//   },
//   "datePicker": {
//       "selectColor": Colors.indigo700
//   },
//   "timePicker": {
//       "accentColor": Colors.indigo700,
//       "headerColor": Colors.indigo700
//   },
//   "raisedButton": {
//       "primaryTextColor": fade(Colors.darkWhite, 0.87),
//       "fontWeight": 500,
//   },
//   "refreshIndicator": {
//       "loadingStrokeColor": Colors.indigo700
//   },
//   "flatButton": {
//       "primaryTextColor": Colors.blueGrey400
//   },
};

