import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";


import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import btioDarkTheme from '../../utils/themes/btioDarkTheme';

import * as muicolors from 'material-ui/styles/colors';
import Paper from 'material-ui/Paper';
import RaisedButton from 'material-ui/RaisedButton';
import Drawer from 'material-ui/Drawer';
import Snackbar from 'material-ui/Snackbar';

import ShareMenu from '../../components/ShareComp/ShareMenu';

import fcWidget from 'freshchat-widget';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import Butter from 'buttercms';

import loading from '../../utils/auth0/Callback/loading.svg';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const butter = Butter('f1f702e8d72d311c4ed62be6daed59dec33413e2');

const style = {
    titlefont: {
        marginTop: 10,
        marginBottom: 5,
        // fontFamily: 'Permanent Marker',
        fontFamily: 'Century Gothic, Muli, sans-serif',
        fontSize: '2rem',
        fontWeight: 600,
        letterSpacing: '1px',
        // textAlign: 'center',
    },
    historyfont: {
        marginTop: 10,
        marginBottom: 5,
        // fontFamily: 'Permanent Marker',
        fontFamily: 'Century Gothic, Muli, sans-serif',
        fontWeight: 600,
        letterSpacing: '3px',
        fontSize: '1.75rem',
        textAlign: 'center',
        // borderBottom: '1px solid #404040',  // used to see total area
      },
    blogbanner: {
      marginLeft: -30,
      marginRight: -30,
      padding: 100,
      backgroundColor: muicolors.teal500,
      color: muicolors.darkWhite,
      textAlign: 'center',
      fontFamily: 'Lucida Console, Courier New, monospace',
  },
  blogtitle: {
      fontFamily: 'Lobster, Monaco, monospace',
      letterSpacing: '2px',
      // marginRight: -100,
  },
  blogcontent: {
      backgroundColor: muicolors.fullWhite,
      marginLeft: -30,
      marginRight: -30,

  },
  paper: {
    // height: 100,
    // width: '80%',
    margin: 100,
    marginTop: 50,
    marginBottom: 50,
    // marginLeft: 100,
    // marginRight: 100,

    padding: 50,
    paddingTop: 40,
    // textAlign: 'center',
    display: 'inline-block',
  },
  mobilepaper: {
    // height: 100,
    // width: '80%',
    margin: 0,
    // marginTop: 50,
    // marginBottom: 50,
    // marginLeft: 100,
    // marginRight: 100,

    padding: 25,
    // paddingTop: 20,
    // textAlign: 'center',
    // display: 'inline-block',
  },
  drawercenter: {
    textAlign: 'center',
  },
  verticalshare: {
    display: 'inline-block',
    width: '50px',
  },
  linkpad: {
      padding: 1,
  },
  boldbutton: {
    //margin: 12,
    //color: '#5e35b1',
    borderRadius: '5px',
  }, 
  button: {
    margin: 12,
    borderRadius: '5px',
  },
  rowpad: {
      paddingRight: 20,
  },
  refresh: {
    display: 'inline-block',
    position: 'relative',
  },
};

function formatDateStringToObj(d)
{
  var darr = d.split('-');
  return new Date( darr[0], darr[1]-1, darr[2], 0, 0, 0, 0 );
}

class BlogPost extends Component {

  constructor(props) {
    super(props);

    this.state = {
      drawerOpen: true,
      
      loaded: false,
      post: '',

      shareURL: this.props.match.url,
      shareText: "Check out this post on backtest.io!",

      snackopen: false,
      snacktext: '',
      snackAutoHide: 5000,
    }

    this.shareCopyDone          = this.shareCopyDone.bind(this);
    this.handleSnackClose       = this.handleSnackClose.bind(this);
  }
    
  shareCopyDone(text, result)
  {
    console.log('copied link', text, result);
    this.setState({ snacktext: 'Your backtest link has been copied to clipboard.', snackAutoHide: (5*1000), snackopen: true }, () => console.log('snacktext:', this.state.snacktext));    
  }

  handleSnackClose() {
    this.setState({ snackopen: false });
  };

  componentWillMount() 
  {
    const { isAuthenticated } = this.props.auth;
    if( !isAuthenticated() )
    {
        localStorage.setItem('redir', this.props.match.url);
    }

    let slug = this.props.match.params.slug;
    console.log('slug:', slug);

    butter.post.retrieve(slug).then((resp) => {
      var share = resp.data.data.tags.filter(tag => tag.name.includes('https'));  // placeholder for the branch.io encode link... so we can track
      this.setState({
        loaded: true,
        post: resp.data.data,
        shareURL: share[0].name,
        shareText: resp.data.data.summary,
      });
    });
  }

  
  render() {
    
    if (this.state.loaded) 
    {
        const post = this.state.post;
        // console.log('post:', post);

        var postdate = new Date(post.published);
        var category = post.categories[0].name || 'Blog';

   
        return (
            <div className="animated fadeIn">
                <Helmet>
                    <title>backtest.io | {post.seo_title}</title>
                    <meta name="description" content={post.meta_description} />
                    <meta name="og:image" content={post.featured_image} />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@backtest_io" />
                    <meta name="twitter:title" content={post.title} />
                    <meta name="twitter:description" content={post.summary} />
                    <meta name="twitter:image" content={post.featured_image} />
                </Helmet>                
                
                <div className="blog-background" style={style.blogbanner}>
                    <div className="row">
                        <div className="col-sm-1 col-md-1 col-lg-1">
                        </div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <h2 style={style.blogtitle}>{category}</h2>
                        </div>                    
                        <div className="col-sm-1 col-md-1 col-lg-1">
                          <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, lightBaseTheme)}>
                              <Link to={`/blog`}>
                                  <RaisedButton label="Back" overlayStyle={style.boldbutton} buttonStyle={style.boldbutton} style={style.button} />
                              </Link>
                          </MuiThemeProvider>
                        </div>
                    </div>
                </div>
                  
                <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                <MuiThemeProvider>
                <Paper style={style.blogcontent} zDepth={0}>

                  <MuiThemeProvider>
                  <Paper style={ isMobile ? style.mobilepaper : style.paper } zDepth={3}>
                      <div className="row">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                              <h2 style={style.titlefont}>{post.title}</h2>
                              <h6 className="h400c txtleft">{ postdate.toLocaleDateString() }</h6>
                          </div>
                      </div>

                      {/* <h5 className="h400c_nopad">By {post.author.first_name} {post.author.last_name}</h5> */}                      
                      {/* <h5 className="h400c_nopad">Tags 
                      {post.tags.map((tag) => {
                          return (
                              <a style={style.linkpad} href={"/post/"+tag.slug} key={tag.slug}>{tag.name}</a>
                          )
                          })}
                      </h5> */}
                      <div dangerouslySetInnerHTML={{__html: post.body}} />

                      <br/><br/>
                      <div>Note: This content is presented for educational and informational purposes only and should not be construed as investment advice.  Consult a financial advisor to determine your suitability to trade options.  Past performance does not indicate future success.</div>
                  </Paper>
                  </MuiThemeProvider>                    
                </Paper>
                </MuiThemeProvider>
                </div>
                </div>

                {
                  !isMobile && (
                  <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, btioDarkTheme)}>
                    <Drawer containerClassName="drawerstyle" open={true} width={250}>
                      <br/><br/><br/>
                      <div style={style.historyfont}>Share Post</div>
                      <div style={style.drawercenter}>
                        <div style={style.verticalshare}>
                          <ShareMenu link={this.state.shareURL} title={this.state.shareText} onCopy={this.shareCopyDone} />
                        </div>
                      </div>
                    </Drawer>
                  </MuiThemeProvider>
                  )
                }

                <MuiThemeProvider>
                  <Snackbar
                      open={this.state.snackopen}
                      message={this.state.snacktext}
                      autoHideDuration={this.state.snackAutoHide}
                      // onRequestClose={this.handleSnackClose}
                      // onClick={this.handleSnackAction}
                  />
                </MuiThemeProvider>
            </div>
        );
    } 
    else 
    {
        const style = {
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            width: '100vw',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'white',
          }

        return (
            <div className="animated fadeIn">
              <div style={style}>
                  <img src={loading} alt="loading"/>
              </div>
          </div>
        )
    }

  }
}

export default withRouter(BlogPost);
