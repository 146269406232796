import React, { useContext, useState, useEffect, useCallback } from 'react';

import { ThemeProvider, makeStyles, withStyles, createMuiTheme } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { green } from '@material-ui/core/colors';

import { TRADIER_BASE_URL, TRADIER_ACCESS_TOKEN }  from '../../constants/services';
import { store } from '../../context/LadderContext';


const axios = require('axios').default;

const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
    },
  });

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    // alignContent: 'center',
    // justifyContent: 'center',
  },
  grid: {
    margin: theme.spacing(1),
    minWidth: '10ch',
  }
}));


export default function Symbol(props) {
  const classes = useStyles();

  const { state, dispatch } = useContext(store);

  const [symbolErr, setSymbolErr] = useState(false);

  const fetchSymbol = useCallback( async () => {
    console.log(state);
    if( !state.symbol ) {
      return;
    }

    var config = {
      method: 'get',
      url: `${TRADIER_BASE_URL}/markets/quotes?symbols=${state.symbol}`,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${TRADIER_ACCESS_TOKEN}`
      }
    };

    console.log('config:');
    console.log(config);

    try {
      const resp = await axios(config);
      console.log(resp.data);
      if( resp.data.quotes.unmatched_symbols ) {
        setSymbolErr(true);
      }
      else {
        setSymbolErr(false);
        dispatch({type: 'quote', value: resp.data.quotes.quote});
      }
    } catch (err) {
      console.error(err);
    }
  }, [state.symbol]);

  useEffect(() => {
    console.log(`useEffect: Symbol`);
    let ignore = false;

    fetchSymbol();
    return () => {
      ignore = true;
      dispatch({type: 'refresh', value: false});
    }
  }, [fetchSymbol, state.symbol, state.refresh]);

  const handleChangeSymbol = (event) => {
    console.log(`sym ${event.target.value}`);
    console.log(state.state);
    dispatch({type: 'symbol', value: event.target.value.toUpperCase()});
  };


  return (
    <TextField id={`outlined-symbol-${props.num}`} variant="standard" size="small"
      label="Symbol" value={state.symbol} onChange={handleChangeSymbol} error={symbolErr}
      InputLabelProps={{
        shrink: true,
        autoFocus: true
      }}
    />
  );
}
