import React from 'react';

import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import btioDarkTheme from '../../utils/themes/btioDarkTheme';
import btioDarkPopover from '../../utils/themes/btioDarkPopover';

import Popover from 'material-ui/Popover/Popover';

import ShareMenu from './ShareMenu';

export default class ShareComp extends React.Component {
  state = {
    open: false,
    link: '',
    title: '',
  };

  handleClick = (event, link, title ) => {
    // This prevents ghost click.
    event.preventDefault();

    console.log('passed in link=', link, 'title=', title);

    this.setState({
      open: true,
      anchorEl: event.currentTarget,
      link: link,
      title: title,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };


  render() {
       

    return (
        <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, btioDarkPopover)}>
          <Popover
            open={this.state.open}
            anchorEl={this.state.anchorEl}

            anchorOrigin={{horizontal: 'right', vertical: 'top'}}
            targetOrigin={{horizontal: 'left', vertical: 'top'}}

            onRequestClose={this.handleRequestClose}
          >
            <ShareMenu link={this.state.link} title={this.state.title} onCopy={this.props.onCopy} />

          </Popover>
        </MuiThemeProvider>
    );
  }
}
