import React, {Component} from 'react';
import { withRouter, Link } from 'react-router-dom';
import { isMobile } from "react-device-detect";

import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import btioDarkTheme from '../../utils/themes/btioDarkTheme';
import * as muicolors from 'material-ui/styles/colors';

import {Card, CardTitle, CardText} from 'material-ui/Card';
import {Table, TableBody, TableHeader, TableHeaderColumn, TableRow, TableRowColumn} from 'material-ui/Table';

import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import Drawer from 'material-ui/Drawer';
import Snackbar from 'material-ui/Snackbar';

import ShareMenu from '../../components/ShareComp/ShareMenu';
import PlanInfoLoader from '../../components/Loaders/PlanInfoLoader';

import axios from 'axios';
import { Helmet } from 'react-helmet';
import fcWidget from 'freshchat-widget';
import branch from 'branch-sdk';
import LogRocket from 'logrocket';

LogRocket.init('qm4ige/backtestio');

const style = {
  refresh: {
    // display: 'inline-block',
    display: 'block',
    margin: 'auto',
    // position: 'relative',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // 'z-index': '2',
  },
  toggle: {
    marginBottom: 16,
  },
  button: {
    margin: 12,
    borderRadius: '5px',
  },
  buttonflat: {
    marginTop: 12,
    marginBottom: 12,
    marginLeft: 6,
    marginRight: 6,
  },
  boldbutton: {
    //margin: 12,
    //color: '#5e35b1',
    borderRadius: '5px',
  },
  cardstyle: {
    // marginTop: 0,
    // marginBottom: -25,
    // padding: '0px',
    // borderBottom: '1px solid #404040',  // used to see total area
  },
  iconstyle: {
    marginTop: 10,
    // paddingRight: 10,
  },
  cardtextstyle: {
    // backgroundColor: muicolors.blueGrey600,
  },
  historyfont: {
    marginTop: 10,
    marginBottom: 5,
    fontFamily: 'Century Gothic, Muli',
    fontSize: '1.75rem',
    textAlign: 'center',
    // borderBottom: '1px solid #404040',  // used to see total area
  },
  alignright: {
    textAlign: 'right',
  },
  titlefont: {
    marginTop: 10,
    marginBottom: 5,
    // fontFamily: 'Permanent Marker',
    fontFamily: 'Century Gothic, Muli, sans-serif',
    fontSize: '2rem',
    fontWeight: 600,
    letterSpacing: '1px',
    // textAlign: 'center',
  },
  shadedCard: {
    backgroundColor: muicolors.cyan50,
  },
  trialNote: {
    backgroundColor: muicolors.cyan50,
    fontFamily: 'Century Gothic, Muli, sans-serif',
    fontSize: '.75rem',
    marginTop: 15,
    textAlign: 'center',
    //fontWeight: 600,
  },
  mediumCardTitle: {
    fontSize: '24px',
    marginLeft: -8,
  },
  toggleLabel: {
    whiteSpace: 'pre',
    fontSize: '14px',
    // textAligh: 'left',
    // marginLeft: '-10px',
    // paddingRight: '10px',
  },
  reduceCardMargin40: {
    marginTop: -40,
  },
  reduceCardMargin25: {
    marginTop: -25,
  },
  reduceCardMargin10: {
    marginTop: -10,
  },
  cardSpacing: {
    marginTop: 10,
    marginBottom: 10,
  },
  sz1rem: {
    fontSize: '1.00rem',
  },
  ol: {
    fontSize: '1rem',
    fontStyle: 'italic',
  },
  underline: {
    fontSize: '1.00rem',
    // fontWeight: 600,
    textDecoration: 'underline',
  },
  floatingLabelStyle: {
    color: muicolors.blueGrey300,
  },
  redeem: {
    color: muicolors.teal700,
    fontStyle: 'italic',
  },

};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

class Account extends Component {

  constructor(props) {
    super(props);

    this.state = {
      plan: '',
      planEnd: '',
      planTxt: '',
      planCount: 0,
      planLimit: '',
      totalCount: 0,
      numSymbols: 0,
      topSymbolsList: [],
      symbolCounts: {},
      topStratsList: [],
      stratCounts: {},
      eligible: false,
      eligibleText: '',
      credits: 0,
      identity: '', // profile.sub from auth0
      amount: 0,
      redeemText: '',
      amountErr: '',

      reflink: '',
      shareText: "Hey! Check out backtest.io!",
      snackopen: false,
      snacktext: '',
      snackAutoHide: 5000,

      // loading status
      loadingBTInfo: true,
      loadingPlanInfo: true,
      loadingLoyalty: true,
    }

    this.getPlanInfo = this.getPlanInfo.bind(this);
    this.loadPlanInfo = this.loadPlanInfo.bind(this);
    this.getBacktestInfo = this.getBacktestInfo.bind(this);
    this.loadBacktestInfo = this.loadBacktestInfo.bind(this);

    // branch
    this.setupBranch            = this.setupBranch.bind(this);
    this.generateRefLink         = this.generateRefLink.bind(this);
    this.updateRefLink           = this.updateRefLink.bind(this);
    this.setupCredits            = this.setupCredits.bind(this);
    this.loadBranchCredits       = this.loadBranchCredits.bind(this);
    this.loadBranchCreditHistory = this.loadBranchCreditHistory.bind(this);
    this.redeem                  = this.redeem.bind(this);
    this.processRedeem           = this.processRedeem.bind(this);
    this.handleAmountChange      = this.handleAmountChange.bind(this);

    this.shareCopyDone          = this.shareCopyDone.bind(this);
    this.handleSnackClose       = this.handleSnackClose.bind(this);
  }

  componentWillMount()
  {
    const { isAuthenticated } = this.props.auth;
    if( !isAuthenticated() )
    {
      this.props.history.push('/');
    }

    this.getPlanInfo();
    this.getBacktestInfo();
  }

  componentDidMount()
  {
    this.setupBranch();

    window.fcWidget.init({
      token: "59366a55-7227-4b4c-a54b-78d3a53dcf04",
      host: "https://wchat.freshchat.com",
      tags: ["member"],
      faqTags: {
        tags: ['member'],
        filterType: 'category' //Or filterType: 'article'
      },
      open: false,
      config: {
        showFAQOnOpen: true,
        hideFAQ: false,
        agent: {
          hideName: false,
          hidePic: false,
          hideBio: false,
        },
        content: {
          placeholders: {
            search_field: 'Search Knowledgebase',
          },
          actions: {
            tab_faq: 'Knowledgebase',
            tab_chat: 'Chat',
          },
          headers: {
            faq: 'Knowledgebase',
            faq_help: 'Browse our help',
            channel_response: {
              offline: 'We are currently away. Leave us a message!',
              online: {
                minutes: {
                  one: "You should get a reply in a minute",
                  more: "You should get a reply within {!time!} minutes"
                },
                hours: {
                  one: "You should get a reply in an hour",
                  more: "You should get a reply within {!time!} hours",
                }
              }
            }
          }
        }
      }
    });

    window.fcWidget.track('React Page Visit', {
      page: 'Account',
    });
  }

  shareCopyDone(text, result)
  {
    console.log('copied link', text, result);
    this.setState({ snacktext: 'Your backtest link has been copied to clipboard.', snackAutoHide: (5*1000), snackopen: true }, () => console.log('snacktext:', this.state.snacktext));
  }

  handleSnackClose() {
    this.setState({ snackopen: false });
  };


  setupBranch()
  {
    // plan not loaded yet... need this
    if( this.state.plan === '' ) {
      setTimeout(() => {
        this.setupBranch();
      }, 1000);
      return;
    }

    // init Branch
    if (process.env.NODE_ENV === 'development') {
      console.log("account | branch init development mode!");
      branch.init('key_test_neqXrN3pvlhIdVao2rqTCnfmByiIt6b2' );  // not going to parse any callback
    }
    else {
      branch.init('key_live_get0rG8dshfHhMpn5qpS4ialDEgLE0bI' );
    }

    // get sub via callback
    const { userProfile, getProfile } = this.props.auth;
    if (!userProfile) {
      getProfile((err, profile) => {
        console.log('account | branch 1 - userProfile:', this.props.auth.userProfile);
        this.setupCredits(profile);
        this.generateRefLink(profile);
      });
    } else {
      console.log('account | branch 2 - userProfile:', this.props.auth.userProfile);
      this.setupCredits(userProfile);
      this.generateRefLink(userProfile);
    }
  }

  setupCredits(profile)
  {
    console.log('account | setupCredits | sub:', profile.sub);
    this.setState({identity: profile.sub}, () => console.log('identity:', this.state.identity));

    // must set identity for this init session before calling credit stuff.
    branch.setIdentity(profile.sub, function (err, data) {
      console.log('account | branch identity callback:', err, data);
    } );

    // call to get credit summary
    branch.credits( this.loadBranchCredits );

    // branch.creditHistory(
    // //   {
    // //   "bucket": "default",
    // //   "identity": profile.sub,
    // // },
    // this.loadBranchCreditHistory );
  }

  loadBranchCredits(err, data)
  {
    console.log('loadBranchCredits', err, data );
    if( data?.length ) {
      var sumCredits = data => Object.values(data).reduce( (a,b) => a + b );
      var c = sumCredits(data);
      this.setState({credits: c }, () => console.log('set user credits:', this.state.credits));

      var elig = false;
      var eligtxt = "";
      // set bility here
      if( this.state.plan === "advanced") {
        if( c >= 100 ) {
          elig = true;
          eligtxt = "You can redeem 100 credits for 25% your next monthly bill";
        }
      }
      else if( this.state.plan !== "") {
        if( c >= 20 ) {
          elig = true;
          eligtxt = "You can redeem 20 credits for 10 extra backtests in the next monthly cycle";
        }
      }

      this.setState({eligible: elig, eligibleText: eligtxt, loadingLoyalty: false }, () => console.log('redeem eligible:', this.state.eligible));
    }

  }

  loadBranchCreditHistory(err, data)
  {
    console.log('loadBranchCreditHistory', err, data );
  }

  handleAmountChange(e) {
    var toRedeem = e.target.value;
    console.log("toRedeem:", toRedeem);
    if( toRedeem > this.state.credits ) {
      this.setState({ amountErr: "You can only redeem up to " + this.state.credits + " credits." }, () => console.log('amountErr:', this.state.amountErr));
      toRedeem = this.state.credits;
    }
    this.setState({ amount: toRedeem }, () => console.log('amount:', this.state.amount));
  }

  redeem()
  {
    console.log('redeem credits api call');
    this.setState({ amountErr: "" });

    if( this.state.amount <= 0 ) {
      this.setState({ amountErr: "Please enter a positive amount of credits to redeem." }, () => console.log('amountErr:', this.state.amountErr));
      return;
    }

    const formPayload = {
      identity: this.state.identity,
      amount: this.state.amount,
      test: (process.env.NODE_ENV === 'development') ? "true" : "false",
      token: localStorage.getItem('token'),
    };

    // auth info
    const { getAccessToken } = this.props.auth;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/RedeemCredit`;
    var token = getAccessToken();
    const headers = { 'Authorization': `Bearer ` + token }

    var config = {
      'headers': headers,
      'params': formPayload
    }

    console.log('config', config);

    axios.get(API_URL, config)
      .then( function(response) {
        if( response.status === 200 )
        {
          return response.data;
        }
        else {
          console.log("redeem: not ok response: ", response);
        }
      })
      .then( this.processRedeem )
      .catch( function(error) {
        console.log('redeem: There has been a problem with your fetch operation: ' + error.message);
      });

  }

  processRedeem(json)
  {
    console.log('processRedeem | json', json);
    var result = JSON.parse(json);
    console.log('processRedeem | result', result);

    if( typeof result.error == 'undefined') {
      this.setState({redeemText: "Your credits have been successfully redeemed."}, () => console.log('redeemText:', this.state.redeemText));
    }
    else {
      this.setState({redeemText: result.error.message }, () => console.log('redeemText:', this.state.redeemText));
    }

    branch.credits( this.loadBranchCredits ); // refresh numbers
  }

  generateRefLink(profile)
  {
    // only logged in users should be able to generate links for their own backtests
    if( this.state.unauthuser ) {
      return;
    }

    // ref link set up?
    var reflink = localStorage.getItem('reflink');
    console.log('account | reflink:', reflink);
    if( reflink )
    {
      this.setState({reflink: reflink}, () => console.log('account reflink set', this.state.reflink));
      return;
    }

    console.log('generating branch link for', profile.name, profile.sub);
    var branchURLBase = (process.env.NODE_ENV === 'development') ? "http://localhost:3000" : "https://backtest.io";
    var name = profile.given_name;
    // console.log('given name', name);
    if (typeof name == 'undefined')
    {
      var ns = profile.name.split(" ");
      // console.log('ns split:', ns);
      if( ns.length > 0 ) {
        name = ns[0];
      } else {
        name = profile.name;
      }
    }

    branch.link({
      tags: [ 'profile', profile.name, profile.sub ],
      channel: 'twitter',
      feature: 'account',
      // stage: 'new user',
      data: {
          creatorID: profile.sub,
          creatorName: profile.name,
          // plan: 'advanced',
          "$canonical_identifier": "btio/account/" + profile.sub.toString(),
          "$canonical_url": branchURLBase + "/ref",
          "$fallback_url": branchURLBase + "/ref",
          '$web_only': true,
          '$og_title': "backtest.io | options research platform",
          '$og_description': name + ' has invited you to try backtest.io!',
      }
    }, this.updateRefLink );
  }

  updateRefLink(err, link)
  {
    console.log('branch reflink callback', err, link);

    if( err ) {
      console.log('branch error generating reflink', err);
      return;
    }

    localStorage.setItem('reflink', link);
    this.setState({reflink: link}, () => console.log('account reflink set', this.state.reflink));


    const formPayload = {
      link: link,
      token: localStorage.getItem('token'),
    };

    // auth info
    const { getAccessToken } = this.props.auth;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/UpdateAccountLink`;
    var token = getAccessToken();
    const headers = { 'Authorization': `Bearer ` + token }

    var config = {
      'headers': headers,
      'params': formPayload
    }

    console.log('config', config);

    axios.get(API_URL, config)
      .then( function(response) {
        if( response.status === 200 )
        {
          console.log("updateRefLink: success", link);
        }
        else {
          console.log("updateRefLink: not ok response: ", response);
        }
      })
      .catch( function(error) {
        console.log('updateRefLink: There has been a problem with your fetch operation: ' + error.message);
      });
  }

  getPlanInfo()
  {
    console.log('getPlanInfo request');

    const formPayload = {
      token: localStorage.getItem('token'),
    };

    // auth info
    const { getAccessToken } = this.props.auth;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/GetPlanInfo`;
    var token = getAccessToken();
    //console.log('token: ' + token );
    const headers = { 'Authorization': `Bearer ` + token }
    //console.log('headers: ', headers );

    var config = {
      'headers': headers,
      'params': formPayload
    }

    console.log('config', config);

    axios.get(API_URL, config)
    .then( function(response) {
      if( response.status === 200 )
      {
        return response.data;
      }
      else {
        var contentType = response.headers.get("content-type");
        if(contentType && contentType.indexOf("application/json") !== -1) {
          console.log(response.data);
        }
        else {
          console.log("getPlanInfo: Oops, we haven't got JSON!", response);
        }
        console.log("getPlanInfo: not ok response: ", response);
        if( response.status === 504 ) {
          console.log("getPlanInfo: endpoint timeout");
        }
        else {
          throw new Error('getPlanInfo: Network response was not OK.');
        }
      }
    })
    .then( this.loadPlanInfo )
    .catch( function(error) {
      console.log('getPlanInfo: There has been a problem with your fetch operation: ' + error.message);
      LogRocket.captureException(error, {
        tags: {
          page: 'Account',
          action: 'getPlanInfo'
        },
      });
    });
}

loadPlanInfo(json)
{
  console.log('json:', json);

  this.setState({ plan: json.Plan, planEnd: json.PlanEnd, loadingPlanInfo: false, }, () => console.log('plan:', this.state.plan, 'planEnd:', this.state.planEnd));

  if(json.Plan === "trial") {
    this.setState({ plantxt: "Your plan will revert to the Basic Plan unless you upgrade." }, () => console.log('plantxt:', this.state.plantxt));
  }
  else {
    this.setState({ plantxt: "Your plan will auto-renew." }, () => console.log('plantxt:', this.state.plantxt));
  }
}

getBacktestInfo()
{
  console.log('getBacktestInfo request');

  const formPayload = {
    token: localStorage.getItem('token'),
  };

  // auth info
  const { getAccessToken } = this.props.auth;
  const API_URL = `${process.env.REACT_APP_API_BASE_URL}/GetBacktestInfo`;
  var token = getAccessToken();
  //console.log('token: ' + token );
  const headers = { 'Authorization': `Bearer ` + token }
  //console.log('headers: ', headers );

  var config = {
    'headers': headers,
    'params': formPayload
  }

  console.log('config', config);

  axios.get(API_URL, config)
  .then( function(response) {
    if( response.status === 200 )
    {
      return response.data;
    }
    else {
      var contentType = response.headers.get("content-type");
      if(contentType && contentType.indexOf("application/json") !== -1) {
        console.log(response.data);
      }
      else {
        console.log("getBacktestInfo: Oops, we haven't got JSON!", response);
      }
      console.log("getBacktestInfo: not ok response: ", response);
      if( response.status === 504 ) {
        console.log("getBacktestInfo: endpoint timeout");
      }
      else {
        throw new Error('getBacktestInfo: Network response was not OK.');
      }
    }
  })
  .then( this.loadBacktestInfo )
  .catch( function(error) {
    console.log('getBacktestInfo: There has been a problem with your fetch operation: ' + error.message);
    LogRocket.captureException(error, {
      tags: {
        page: 'Account',
        action: 'getBacktestInfo'
      },
    });
  });
}

loadBacktestInfo(json)
{
  console.log('json:', json);

  this.setState({ planCount: json.PlanCount, totalCount: json.TotalCount, numSymbols: json.NumSymbols }, () => console.log('planCount:', this.state.PlanCount, 'totalCount:', this.state.TotalCount, 'numSymbols:', this.state.NumSymbols));
  this.setState({ topSymbolsList: json.TopSymbols, symbolCounts: json.GroupSym });
  this.setState({ topStratsList: json.TopStrats, stratCounts: json.GroupStrats, loadingBTInfo: false });

  if(json.Plan === "basic") {
    this.setState({ planLimit: '25 for the current plan period.' }, () => console.log('planLimit:', this.state.planLimit));
  }
  else if(json.Plan === "trial") {
    this.setState({ planLimit: 'unlimited for your trial period.' }, () => console.log('planLimit:', this.state.planLimit));
  }
  else {
    this.setState({ planLimit: 'unlimited for the current plan period.' }, () => console.log('planLimit:', this.state.planLimit));
  }
}

  render() {
    // const { isAuthenticated } = this.props.auth;

    return (
      <div className="animated fadeIn">
        <Helmet><title>backtest.io | account</title></Helmet>
        <div className="row">
          <div className="col-sm-4 col-md-4 col-lg-4">
            <h2 style={style.titlefont}>Account</h2>
          </div>
          <div className="col-sm-5 col-md-5 col-lg-5">

          </div>
          <div className="col-sm-3 col-md-3 col-lg-3">
          </div>
        </div>
        <div style={style.cardSpacing} className="row">
          <div className="col-md-6">
            <MuiThemeProvider>
              <Card>
                <CardTitle title={
                  <div className="row">
                    <div className="col-md-9">
                      Current Plan
                    </div>
                    <div className="col-md-3">
                    </div>
                  </div>
                }/>
                <CardText>
                {
                 this.state.loadingPlanInfo ? <PlanInfoLoader /> :
                  <span style={style.sz1rem}>
                      You are currently on the {capitalizeFirstLetter(this.state.plan)} plan until {this.state.planEnd}.<br/>
                      {this.state.plantxt}
                  </span>
                }
                </CardText>
              </Card>
            </MuiThemeProvider>
          </div>
          <div className="col-md-6">
            <MuiThemeProvider>
              <Card>
                <CardTitle title={
                  <div className="row">
                    <div className="col-md-9">
                      Payment
                    </div>
                    <div className="col-md-3">
                    </div>
                  </div>
                }/>
                <CardText>
                  We accept the following payment methods:
                  <br/>
                  <h3><i className="fa fa-cc-stripe"></i>&nbsp;&nbsp;&nbsp;
                  <i className="fa fa-cc-paypal"></i>&nbsp;&nbsp;&nbsp;
                  <i className="fa fa-btc"></i></h3>
                </CardText>
              </Card>
            </MuiThemeProvider>
          </div>
        </div>
        <br/>
        {/* <div style={style.cardSpacing} className="row">
          <div className="col-md-6">
            <MuiThemeProvider>
              <Card>
                <CardTitle title={
                  <div className="row">
                    <div className="col-md-9">
                      Backtests
                    </div>
                    <div className="col-md-3">
                    </div>
                  </div>
                }/>
                <CardText>
                  <li style={style.sz1rem}>You have used {this.state.planCount} backtests out of {this.state.planLimit}</li>
                  <li style={style.sz1rem}>You have performed {this.state.totalCount} backtests across {this.state.numSymbols} symbols since you joined!</li>
                  <li style={style.sz1rem}>Top Symbols:<br/>
                    <Table height={'300px'} >
                      <TableHeader displaySelectAll={false}>
                        <TableRow>
                          <TableHeaderColumn>Symbol</TableHeaderColumn>
                          <TableHeaderColumn># Backtests</TableHeaderColumn>
                        </TableRow>
                      </TableHeader>
                      <TableBody displayRowCheckbox={false} >
                        {this.state.topSymbolsList.map( (sym) =>
                          <TableRow key={sym}>
                            <TableRowColumn>{sym}</TableRowColumn>
                            <TableRowColumn>{this.state.symbolCounts[sym]}</TableRowColumn>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </li>
                  <li style={style.sz1rem}>Top Strategies:<br/>
                    <Table height={'300px'} >
                      <TableHeader displaySelectAll={false}>
                        <TableRow>
                          <TableHeaderColumn>Strategy</TableHeaderColumn>
                          <TableHeaderColumn># Backtests</TableHeaderColumn>
                        </TableRow>
                      </TableHeader>
                      <TableBody displayRowCheckbox={false} >
                        {this.state.topStratsList.map( (strat) =>
                          <TableRow key={strat}>
                            <TableRowColumn>{strat}</TableRowColumn>
                            <TableRowColumn>{this.state.stratCounts[strat]}</TableRowColumn>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </li>
                </CardText>
              </Card>
            </MuiThemeProvider>
          </div>
          <div className="col-md-6">
            <MuiThemeProvider>
              <Card>
                <CardTitle title={
                  <div className="row">
                    <div className="col-md-9">
                      Loyalty Program
                    </div>
                    <div className="col-md-3">
                    </div>
                  </div>
                }/>
                <CardText>
                  <span style={style.redeem}>{this.state.redeemText}</span>
                  <br/>
                  <span style={style.sz1rem}>You have {this.state.credits} credits available.</span>
                  {
                    (this.state.eligible &&
                      (
                        <div>
                          <br/>
                          {this.state.eligibleText}<br/>
                          <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                            <TextField id="amount_txt" type="text" value={this.state.amount} onChange={this.handleAmountChange} floatingLabelText="Amount" floatingLabelStyle={style.floatingLabelStyle} errorText={this.state.amountErr} style={{width: '300px'}} /><br/>
                          </MuiThemeProvider>
                          <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, btioDarkTheme)}>
                            <RaisedButton label="Redeem" overlayStyle={style.boldbutton} buttonStyle={style.boldbutton} style={style.button} onClick={this.redeem} />
                          </MuiThemeProvider>
                        </div>
                      )
                    )
                  }
                  <br/>
                  <span style={style.sz1rem}>
                    <Link to={`/post/loyalty-program`}>Check out the details of the program here.</Link>
                  </span>
                  <br/><br/>
                  Use your custom referral link below to share us on social media.<br/>
                  Any blog posts or backtests you create & share will also include your referral info so you'll automatically get credit!<br/>
                  <ShareMenu link={this.state.reflink} title={this.state.shareText} onCopy={this.shareCopyDone} />
                </CardText>
              </Card>
            </MuiThemeProvider>
          </div>
        </div> */}

        {
          !isMobile && (
          <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, btioDarkTheme)}>
            <Drawer containerClassName="drawerstyle" open={true} width={250}>
              {/* <MenuItem></MenuItem>             */}
              {/* <div style={style.historyfont}>History
                <IconButton onClick={this.getUserHistory} tooltip="Account" touch={true} tooltipPosition="bottom-left"><AccountIcon /></IconButton>
              </div>             */}
            </Drawer>
          </MuiThemeProvider>
          )
        }

        <MuiThemeProvider>
          <Snackbar
              open={this.state.snackopen}
              message={this.state.snacktext}
              autoHideDuration={this.state.snackAutoHide}
              // onRequestClose={this.handleSnackClose}
              // onClick={this.handleSnackAction}
          />
        </MuiThemeProvider>

      </div>
    );
  }
}

export default withRouter(Account);
