import React from 'react';
import PropTypes from 'prop-types';

const SingleInput = (props) => (
		<input
			type={props.inputType}
			id={props.name}
			name={props.name}
			className={props.clsname}
			value={props.content}
			onChange={props.controlFunc}
			disabled={props.disabled}
			placeholder={props.placeholder}
			onBlur={props.blurFunc}
		/>
);

SingleInput.propTypes = {
	inputType: PropTypes.oneOf(['text', 'number']).isRequired,
	name: PropTypes.string.isRequired,
	clsname: PropTypes.string.isRequired,
	controlFunc: PropTypes.func.isRequired,
	content: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
	disabled: PropTypes.bool,
	placeholder: PropTypes.string,
	blurFunc: PropTypes.func,
};

export default SingleInput;
