import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import Full from '../../containers/Full/Full';
import SimpleAuth0 from '../../containers/Simple/SimpleAuth0';
import SAVerify from '../../containers/Simple/SAVerify';
import SAVerified from '../../containers/Simple/SAVerified';
import SAPassword from '../../containers/Simple/SAPassword';

import Lock from './Auth/Lock';
import history from './history';

import ErrorBoundary from '../../containers/ErrorBoundary/ErrorBoundary';

const lock = new Lock();

// const handleAuthentication = ({location}) => {
//   if (/access_token|id_token|error/.test(location.hash)) {
//     auth.handleAuthentication();
//   }
// }

export const makeMainRoutes = () => {
    return (
        <Router history={history}>
          <div>
            <ErrorBoundary>
            <Switch>
              <Route exact path="/" render={(props) => <SimpleAuth0 auth={lock} {...props} />} />
              <Route path="/ref" render={(props) => <SimpleAuth0 auth={lock} {...props} />} />

              <Route path="/verify" render={(props) => <SAVerify auth={lock} page="verify" {...props} />} />
              <Route path="/verified" render={(props) => <SAVerified auth={lock} page="verified" {...props} />} />
              <Route path="/password" render={(props) => <SAPassword auth={lock} page="password" {...props} />} />
              <Route path="/app" render={(props) => <Full auth={lock} page="dash" {...props} />} />
              <Route path="/wheel" render={(props) => <Full auth={lock} page="wheel" {...props} />} />
              <Route path="/history" render={(props) => <Full auth={lock} page="history" {...props} />} />
              <Route path="/account" render={(props) => <Full auth={lock} page="account" {...props} />} />
              <Route path="/help" render={(props) => <Full auth={lock} page="help" {...props} />} />

              <Route path="/bt" render={(props) => <Full auth={lock} page="bt" {...props} />} />

              <Route exact path="/blog" render={(props) => <Full auth={lock} page="blog" {...props} />} />
              <Route path="/blog/:page" render={(props) => <Full auth={lock} page="blog" {...props} />} />
              <Route path="/post/:slug" render={(props) => <Full auth={lock} page="post" {...props} />} />

              {/* <Route path="/:callback" render={(props) => {
                handleAuthentication(props);
                return <Callback {...props} />
              }}/> */}
            </Switch>
            </ErrorBoundary>
          </div>
        </Router>
    );
}
