import React, { useContext, useState, useEffect, useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { teal50, teal500 } from 'material-ui/styles/colors';

import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SyncIcon from '@material-ui/icons/Sync';


import { TRADIER_BASE_URL, TRADIER_ACCESS_TOKEN }  from '../../constants/services';
import { store } from '../../context/LadderContext';
import { formatNumber }  from '../../utils/helpers';


const axios = require('axios').default;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    // alignContent: 'center',
    // justifyContent: 'center',
  },
  grid: {
    margin: theme.spacing(1),
    minWidth: '10ch',
  },
  table: {
    // minWidth: 250,
    // margin: theme.spacing(0),
    // padding: theme.spacing(0),
  },
  romBasic: {
    fontSize: 13
  },
  romHighlight: {
    fontSize: 13,
    fontWeight: "bolder",
    color: teal500,
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: teal50,
    // color: theme.palette.common.white,
    fontWeight: "bold",
    // fontVariantCaps: "petite-caps",
    fontSize: 13,
  },
  body: {
    fontSize: 13,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


export default function Chain(props) {
  const classes = useStyles();

  const { state, dispatch } = useContext(store);

  const [chain, setChain] = useState([]);
  const [lastUpdated, setLastUpdated] = useState('Enter a symbol to get started');

  const fetchChain = useCallback( async () => {
    // clear chain if we don't have a symbol or expiration
    if(!state.symbol || !state.exp) {
      setChain([]);
      dispatch({type: 'disable', value: true});
      return;
    }

    var config = {
      method: 'get',
      url: `${TRADIER_BASE_URL}/markets/options/chains?symbol=${state.symbol}&expiration=${state.exp}&greeks=true`,
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${TRADIER_ACCESS_TOKEN}`
      }
    };

    console.log('config:');
    console.log(config);

    try {
      const resp = await axios(config);
      console.log(resp.data);
      if( resp.data.options) {
        setChain(resp.data.options.option);
      }
      else {
        setChain([]);
        dispatch({type: 'disable', value: true});
      }
    } catch (err) {
      console.error(err);
    }
    dispatch({type: 'refresh', value: true});

  }, [state.symbol, state.exp]);

  useEffect(() => {
    console.log(`useEffect: Chain`);
    console.log(state);

    // Update list of chain given a chain
    let ignore = false;

    fetchChain();
    return () => { ignore = true; }
  }, [fetchChain, state.symbol, state.exp]);

  useEffect(() => {
    if(chain.length == 0) {
      return;
    }
    console.log(`useEffect: setLastUpdated`);
    // var d = new Date().toLocaleTimeString();

    let dateCallback = ( acc, cur ) => acc.greeks?.updated_at >= cur.greeks?.updated_at ? acc : cur;
    var d = chain.reduce(dateCallback);
    var localDate = new Date(d.greeks.updated_at + ' UTC').toLocaleString();
    setLastUpdated(`Last updated at: ${localDate}`);
    dispatch({type: 'disable', value: false});

  }, [chain]);


  useEffect(() => {
    if(chain.length == 0) {
      return;
    }
    console.log(`useEffect: scenarioCommit`);
    let maxROM = 0;
    let strk = 0;
    chain.filter(c => c.option_type === state.side && c.last > 0)
      .forEach( c => {
        let mid = (c.bid+c.ask)/2.0;
        let rom = calcROM(c.strike, mid);
        if(rom > maxROM) {
          maxROM = rom;
          strk = c.strike;
        }
        // console.log(`strike=${c.strike} rom=${rom} max=${maxROM}`);
      });
    dispatch({type: 'highlightPx', value: strk});
    console.log(`maxROM strike: ${strk}`);
  }, [chain, state.scenarioCommit]);


  const handleClick = (event, strike, credit) => {
    let baseMargin = (strike-credit) * 100 * state.contracts;
    dispatch({type: 'margin', value: formatNumber(baseMargin, 0) });
  };


  function calcROM(strike, credit) {
    var diff = Math.max(strike - state.scenarioPx, 0);
    var adjustedCredit = credit - diff;
    return (adjustedCredit/strike*100);
  }

  return (
    // <Grid
    //   container
    //   direction="row"
    //   justify="space-between"
    //   alignItems="center"
    // >
    //   <Grid className={classes.grid} item xs>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="option chain">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Delta</StyledTableCell>
                <StyledTableCell align="right">Strike</StyledTableCell>
                <StyledTableCell align="right">IV</StyledTableCell>
                <StyledTableCell align="right">Credit</StyledTableCell>
                <StyledTableCell align="right">ROM</StyledTableCell>
                {/* <StyledTableCell align="right">Last</StyledTableCell> */}
                <StyledTableCell align="right">Trade</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {chain.filter(c => c.option_type === state.side && c.last > 0 && Math.abs(c.greeks?.delta) >= 0 && Math.abs(c.greeks?.delta) <= 1 ).map( c => {
                let highlight = c.strike === state.highlightPx ? classes.romHighlight : classes.romBasic;
                return (
                  <StyledTableRow key={c.strike} hover={true} onClick={(event) => handleClick(event, c.strike, c.last)} >
                    <StyledTableCell>
                      <span className={highlight}>
                        {`${formatNumber(c.greeks.delta, 2)}`}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell align="right"><span className={highlight}>{`${c.strike}`}</span></StyledTableCell>
                    <StyledTableCell align="right"><span className={highlight}>{`${formatNumber(c.greeks.smv_vol*100)}`}</span></StyledTableCell>
                    <StyledTableCell align="right"><span className={highlight}>{`$${formatNumber((c.bid+c.ask)/2.0, 2)}`}</span></StyledTableCell>
                    <StyledTableCell align="right"><span className={highlight}>{`${formatNumber(calcROM(c.strike, (c.bid+c.ask)/2.0), 2)}%`}</span></StyledTableCell>
                    {/* <StyledTableCell align="right"><span className={highlight}>{`$${formatNumber(c.last, 2)}`}</span></StyledTableCell> */}
                    <StyledTableCell align="right">
                      <IconButton size="small" edge="end" aria-label="trade">
                        <ControlPointIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
            <TableFooter>
            <StyledTableRow>
                <StyledTableCell colSpan={5}>{lastUpdated}</StyledTableCell>
                <StyledTableCell align="right">
                  <IconButton size="small" edge="end" aria-label="refresh" onClick={fetchChain}>
                    <SyncIcon />
                  </IconButton>
                </StyledTableCell>
                {/* <StyledTableCell colSpan={1}></StyledTableCell> */}
              </StyledTableRow>
            </TableFooter>
          </Table>
        </TableContainer>
    //   </Grid>
    // </Grid>
  );
}
