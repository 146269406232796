import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from "react-device-detect";

import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import btioDarkTheme from '../../utils/themes/btioDarkTheme';
import * as muicolors from 'material-ui/styles/colors';

import {Card, CardTitle, CardText} from 'material-ui/Card';
// import AutoComplete from 'material-ui/AutoComplete';
import SelectField from 'material-ui/SelectField';

import LinearProgress from 'material-ui/LinearProgress';
import Drawer from 'material-ui/Drawer';
import MenuItem from 'material-ui/MenuItem';

import RaisedButton from 'material-ui/RaisedButton';
import Toggle from 'material-ui/Toggle';
import Snackbar from 'material-ui/Snackbar';

import IconButton from 'material-ui/IconButton';
import PageViewIcon from 'material-ui/svg-icons/action/pageview';
import ArchiveIcon from 'material-ui/svg-icons/content/archive';
import UnArchiveIcon from 'material-ui/svg-icons/content/unarchive';
import ShareIcon from 'material-ui/svg-icons/social/share';

import ShareComp from '../../components/ShareComp/';

import DataTables from 'material-ui-datatables';

import axios from 'axios';
import { Helmet } from 'react-helmet';
import fcWidget from 'freshchat-widget';
import LogRocket from 'logrocket';

LogRocket.init('qm4ige/backtestio');

const style = {
  refresh: {
    // display: 'inline-block',
    display: 'block',
    margin: 'auto',
    // position: 'relative',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // 'z-index': '2',
  },
  errorStyle: {
    color: muicolors.orange500,
  },
  underlineStyle: {
    borderColor: muicolors.orange500,
  },
  floatingLabelStyle: {
    color: muicolors.blueGrey300,
  },
  toggle: {
    marginBottom: 16,
  },
  button: {
    margin: 12,
  },
  cardstyle: {
    // marginTop: 0,
    // marginBottom: -25,
    // padding: '0px',
    // borderBottom: '1px solid #404040',  // used to see total area
  },
  iconstyle: {
    marginLeft: -6,
    marginRight: -6,
    // paddingRight: 10,
  },
  cardtextstyle: {
    // backgroundColor: muicolors.blueGrey600,
  },
  historyfont: {
    marginTop: 10,
    marginBottom: 5,
    // fontFamily: 'Permanent Marker',
    fontFamily: 'Century Gothic, Muli, sans-serif',
    fontWeight: 600,
    letterSpacing: '3px',
    fontSize: '1.75rem',
    textAlign: 'center',
    // borderBottom: '1px solid #404040',  // used to see total area
  },
  infonote: {
    marginTop: 10,
    // marginBottom: 5,
    // fontFamily: 'Century Gothic, Muli, sans-serif',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '1.25rem',
    textAlign: 'center',
  },
  alignright: {
    textAlign: 'right',
  },
  titlefont: {
    marginTop: 10,
    marginBottom: 5,
    // fontFamily: 'Permanent Marker',
    fontFamily: 'Century Gothic, Muli, sans-serif',
    fontSize: '2rem',
    fontWeight: 600,
    letterSpacing: '1px',
    // textAlign: 'center',
  },
  shadedCard: {
    backgroundColor: muicolors.cyan50,
  },
  trialNote: {
    backgroundColor: muicolors.cyan50,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '.75rem',
    marginTop: 15,
    textAlign: 'center',
    //fontWeight: 600,
  },
  mediumCardTitle: {
    fontSize: '24px',
    marginLeft: -8,
  },
  toggleLabel: {
    whiteSpace: 'pre',
    fontSize: '14px',
    // textAligh: 'left',
    // marginLeft: '-10px',
    // paddingRight: '10px',
  },
  reduceCardMargin40: {
    marginTop: -40,
  },
  reduceCardMargin25: {
    marginTop: -25,
  },
  reduceCardMargin10: {
    marginTop: -10,
  },
  cardSpacing: {
    marginTop: 10,
    marginBottom: 10,
  },
  negativePNL: {
    color: muicolors.red500,
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  positivePNL: {
    color: muicolors.green500,
    paddingLeft: '15px',
    paddingRight: '15px',
  },
  sidePadding: {
    // padding: 10,
    // margin: '1em',
    marginLeft: '10px',
    marginRight: '-15px',

  },

};

const toggleStyles = {
  block: {
    maxWidth: 250,
  },
  toggle: {
    marginBottom: 16,
  },
  toggleMargins: {
    // marginTop: 16,
    // marginBottom: 16,
    paddingTop: 16,
    paddingBotton: 16,
  },
  select: {
    marginTop: -30,
    marginBottom: 25,
  },
  select2: {
    marginTop: -25,
    // marginBottom: 10,
  },
  thumbOff: {
    backgroundColor: '#ffcccc',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: 'red',
  },
  trackSwitched: {
    backgroundColor: '#ff9d9d',
  },
  labelStyle: {
    color: 'red',
  },
  hiddenDiv: {
    visibility: 'hidden',
  },
  missingDiv: {
    display: 'none',
  },
  showDiv: {
    display: 'block',
  },
  readOnlyInput: {
    readOnly: 'true',
  },
  wspWidth: {
    width: 15,
  },
  toggleNoWrap: {
    whiteSpace: 'pre',
    // textAligh: 'left',
    // marginLeft: '-10px',
    // paddingRight: '10px',
  },
  toggleLabel: {
    whiteSpace: 'pre',
    fontSize: '14px',
    color: muicolors.darkWhite,
  },
};

const tdstyle = {
  headerStyle: {
    // fontFamily: 'Roboto, sans-serif',
    fontFamily: 'Century Gothic, Muli, sans-serif',
    // fontFamily: 'Lobster, cursive',
    // fontFamily: 'Raleway, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: muicolors.fullBlack,
    backgroundColor: muicolors.blueGrey50,
    paddingLeft: '5px',
    paddingRight: '5px',
    textAlign: 'center',
    whiteSpace: 'normal',
  },
  tableSpacing: {
    // borderSpacing: '30px 0px',
    cellSpacing: '30',
  },
  tradecolL: {
    'whiteSpace': 'normal',
    paddingLeft: '2px',
    paddingRight: '2px',
    'textAlign': 'left',
  },
  tradecolR: {
    'whiteSpace': 'normal',
    'paddingLeft': '2px',
    'paddingRight': '2px',
    'textAlign': 'right',
  },
  tradecolC: {
    whiteSpace: 'normal',
    paddingLeft: '5px',
    paddingRight: '5px',
    textAlign: 'center',
  },
  padded: {
    // whiteSpace: 'pre',
    paddingLeft: '15px',
    paddingRight: '15px',
    // backgroundColor: muicolors.cyan50,
  },
  nowrap: {
    whiteSpace: 'nowrap',
    // marginRight: 10,
    // paddingRight: '20px',
  },
};

var historyList = [];
var rawHistory = {};

// gotta store state for all this globally to persist between page changes
var acSymbolSource = [];
var acStrategySource = [];
var backtestTableData = [];
var backtestDataShown = [];
var numRowsShown = 10;
var totalResults = 0;
var currentTradePage = 1;
var archiveToggleState = true;
var sampleToggleState = true;
var showFooter = true;
var showNoResultsCard = false;
var symbolFilter = '';
var stratFilter = '';
var sortField = 'id';
var sortDir = 'desc';

const backtestTableCols = [
  // { key: 'HistoryID', label: '#', sortable: true,  },
  // { key: 'Date', label: 'Run Date', sortable: true,  },
  { key: 'Symbol', label: 'Symbol',  },
  { key: 'Strat', label: 'Strategy',   },
  { key: 'StartDate', label: 'Start Date',  },
  { key: 'EndDate', label: 'End Date',  },
  { key: 'NetPNL', label: 'Net PNL', sortable: true,  },
  // { key: 'NetROM', label: 'Net ROM', sortable: true,  },
  { key: 'NetROC', label: 'Net ROC', sortable: true,  },
  { key: 'Trades', label: 'Trades', sortable: true,  },
  { key: 'DailySharpe', label: 'Sharpe', sortable: true,  },
  { key: 'Calmar', label: 'Calmar', sortable: true,  },
  { key: 'MonthlySortino', label: 'Sortino', sortable: true,  },
  { key: 'View', label: 'Actions', },
  // { key: 'Archive', label: '', },
];


function round(value, decimals) {
  return Number(Math.round(value+'e'+decimals)+'e-'+decimals);
}

function formatNumber(number, digits) {
  if( number == null || isNaN(number) || !isFinite(number) ) {
    return '0';
  }

  var n = (number).toFixed(digits).replace(/[.,]00$/, "");
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function stripLeadingZero(number) {
  return number.replace(/^0+(?=\d)/, '');
}

function queryStringToJSON(queryString) {
  if(queryString.indexOf('?') > -1){
    queryString = queryString.split('?')[1];
  }
  var pairs = queryString.split('&');
  var result = {};
  pairs.forEach(function(pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });
  return result;
}

function formatDateTimeObjToString(d)
{
  return d.getUTCFullYear() + '-' + (d.getUTCMonth()+1) + '-' + d.getUTCDate() + ' '
    + d.getUTCHours() + ':' + d.getUTCMinutes() + ':' + d.getUTCSeconds();
}

class History extends Component {

  constructor(props) {
    super(props);

    this.state = {
      helmetTitle: 'history',
      // numRowsShown: 10,
      // totalResults: 0,
      // currentTradePage: 1,
      // archiveToggleState: true,
      // showFooter: true,
      // showNoResultsCard: false,
      hideProgress: true,
      // symbolFilter: '',
      // stratFilter: '',
      // sortField: 'id',
      // sortDir: 'desc',
      nextPagePendingReq: false,
      rowSizePendingReq: false,

      // backtestTableData: [],
      // backtestDataShown: [],

      // acSymbolSource: [],
      // acStrategySource: [],

      snackopen: false,
      snacktext: '',
      snackAutoHide: 5000,
    }

    this.handleArchiveToggle    = this.handleArchiveToggle.bind(this);
    this.handleSampleToggle     = this.handleSampleToggle.bind(this);
    this.handleSymbolChange   = this.handleSymbolChange.bind(this);
    this.handleStrategyChange = this.handleStrategyChange.bind(this);
    this.handleFormSubmit       = this.handleFormSubmit.bind(this);
    this.handleFormClear       = this.handleFormClear.bind(this);

    this.requestFreshHistory   = this.requestFreshHistory.bind(this);
    this.pagedUserHistory      = this.pagedUserHistory.bind(this);
    this.populateBTHistory    = this.populateBTHistory.bind(this);

    this.hidePastBacktest     = this.hidePastBacktest.bind(this);
    this.removeBacktest       = this.removeBacktest.bind(this);

    this.getAutoComplete       = this.getAutoComplete.bind(this);
    this.loadAutocomplete     = this.loadAutocomplete.bind(this);

    // bt table funcs
    this.handleNextPageClick    = this.handleNextPageClick.bind(this);
    this.handlePrevPageClick    = this.handlePrevPageClick.bind(this);
    this.handleRowSizeChange   = this.handleRowSizeChange.bind(this);
    this.handleSortOrderChange   = this.handleSortOrderChange.bind(this);

    this.openShareMenu          = this.openShareMenu.bind(this);
    this.shareCopyDone          = this.shareCopyDone.bind(this);
    this.handleSnackClose       = this.handleSnackClose.bind(this);
  }

  componentWillMount()
  {
    const { isAuthenticated } = this.props.auth;
    if( !isAuthenticated() )
    {
      this.props.history.push('/');
    }

    this.getAutoComplete();

    console.log('backtestTableData size:', backtestTableData.length );
    console.log('backtestDataShown size:', backtestDataShown.length );

    // we'll preserve the last used state (filter, sort, results etc) of the history page if we switch away
    if( backtestDataShown.length == 0 ) {
      this.requestFreshHistory();
    }
  }

  componentDidMount()
  {
    //this.pagedUserHistory();

    window.fcWidget.init({
      token: "59366a55-7227-4b4c-a54b-78d3a53dcf04",
      host: "https://wchat.freshchat.com",
      tags: ["member"],
      faqTags: {
        tags: ['member'],
        filterType: 'category' //Or filterType: 'article'
      },
      open: false,
      config: {
        showFAQOnOpen: true,
        hideFAQ: false,
        agent: {
          hideName: false,
          hidePic: false,
          hideBio: false,
        },
        content: {
          placeholders: {
            search_field: 'Search Knowledgebase',
          },
          actions: {
            tab_faq: 'Knowledgebase',
            tab_chat: 'Chat',
          },
          headers: {
            faq: 'Knowledgebase',
            faq_help: 'Browse our help',
            channel_response: {
              offline: 'We are currently away. Leave us a message!',
              online: {
                minutes: {
                  one: "You should get a reply in a minute",
                  more: "You should get a reply within {!time!} minutes"
                },
                hours: {
                  one: "You should get a reply in an hour",
                  more: "You should get a reply within {!time!} hours",
                }
              }
            }
          }
        }
      }
    });

    window.fcWidget.track('React Page Visit', {
      page: 'History',
    });
  }

  handleArchiveToggle(e, isToggled) {
    archiveToggleState = isToggled;
    console.log('archiveToggleState:', archiveToggleState);
    // still gotta force a state change to get global vars to update datatable?
    this.setState({ state: this.state });
  }

  handleSampleToggle(e, isToggled) {
    sampleToggleState = isToggled;
    console.log('sampleToggleState:', sampleToggleState);
    // still gotta force a state change to get global vars to update datatable?
    this.setState({ state: this.state });
  }

  handleSymbolChange(event, index, value) {
    symbolFilter = value;
    console.log('symbolFilter:', symbolFilter);
    // still gotta force a state change to get global vars to update datatable?
    this.setState({ state: this.state });
  }

  handleStrategyChange(event, index, value) {
    stratFilter = value;
    console.log('stratFilter:', stratFilter);
    // still gotta force a state change to get global vars to update datatable?
    this.setState({ state: this.state });
  }

  getAutoComplete()
  {
    console.log('getAutoComplete request');

    const formPayload = {
      token: localStorage.getItem('token'),
      ac: true,
    };


    // auth info
    const { getAccessToken } = this.props.auth;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/PagedUserHistory`;
    var token = getAccessToken();
    //console.log('token: ' + token );
    const headers = { 'Authorization': `Bearer ` + token }
    //console.log('headers: ', headers );

    var config = {
      'headers': headers,
      'params': formPayload
    }

    console.log('config', config);

    axios.get(API_URL, config)
      .then( function(response) {
        if( response.status === 200 )
        {
          return response.data;
        }
        else {
          var contentType = response.headers.get("content-type");
          if(contentType && contentType.indexOf("application/json") !== -1) {
            console.log(response.data);
          }
          else {
            console.log("getAutoComplete: Oops, we haven't got JSON!", response);
          }
          console.log("getAutoComplete: not ok response: ", response);
          if( response.status === 504 ) {
            console.log("getAutoComplete: endpoint timeout");
          }
          else {
            throw new Error('getAutoComplete: Network response was not OK.');
          }
        }
      })
      .then( this.loadAutocomplete )
      .catch( function(error) {
        console.log('getAutoComplete: There has been a problem with your fetch operation: ' + error.message);
        LogRocket.captureException(error, {
          tags: {
            page: 'History',
            action: 'getAutoComplete'
          },
        });
      });
  }

  loadAutocomplete(json)
  {
    console.log('json:', json);

    acSymbolSource = json.symbols;
    acStrategySource = json.strats;
    console.log('autocomplete sources:', acSymbolSource, acStrategySource);

    // still gotta force a state change to get global vars to update datatable?
    this.setState({ state: this.state });
  }

  openShareMenu(historyID, event)
  {
    // This prevents ghost click.
    event.preventDefault();

    var histObj = backtestDataShown.filter(hist => hist.ID === historyID);
    console.log('preparing share menu for ', historyID, histObj);

    var xshareURL = histObj[0].Link;
    var xshareText = "Check out this " + histObj[0].Symbol + " " + histObj[0].Strat + " backtest!";

    this.shareref.handleClick(event, xshareURL, xshareText );
  }

  shareCopyDone(text, result)
  {
    console.log('copied link', text, result);
    this.setState({ snacktext: 'Your backtest link has been copied to clipboard.', snackAutoHide: (5*1000), snackopen: true }, () => console.log('snacktext:', this.state.snacktext));
  }

  handleSnackClose() {
    this.setState({ snackopen: false });
  };


  handleFormSubmit(e) {
    e.preventDefault();

    backtestTableData = [];
    backtestDataShown = [];
    totalResults = 0;
    currentTradePage = 1;

    console.log('backtestTableData:', backtestTableData.length);

    console.log('hfs sortField:', sortField);
    console.log('hfs sortDir:', sortDir);

    this.setState( {helmetTitle: symbolFilter + ' ' + stratFilter + ' history' } );

    window.fcWidget.track('search_history', {
      symbol: symbolFilter,
      strategy: stratFilter
    });

    if( sortField == "id") {
      this.requestFreshHistory();
    }
    else {
      // we previously sorted, preserve this sort across requests
      this.handleSortOrderChange(sortField, sortDir);
    }
  }

  handleFormClear(e) {
    e.preventDefault();

    symbolFilter = "";
    stratFilter = "";

    console.log('symbolFilter:', symbolFilter, "stratFilter:", stratFilter);

    // still gotta force a state change to get global vars to update datatable?
    this.setState({ state: this.state });
  }

  loadBacktest(historyID)
  {
    console.log('loading backtest...', historyID);

    var btraw = {
      historyID: historyID,
      rawObj: rawHistory[historyID],
    }

    var raw = JSON.stringify(btraw);
    // console.log('pre b64 raw:', raw);

    var b64 = btoa(raw);
    // console.log('b64:', b64);

    localStorage.setItem('bt', b64);

    this.props.history.push('/app');
  }

  hidePastBacktest(historyID, unhide)
  {
    console.log('hiding historyID',historyID);

    const formPayload = {
      number: 0,  // we're not loading, we're hiding
      offset: historyID,
      token: localStorage.getItem('token'),
      unhide: unhide,
    };


    // auth info
    const { getAccessToken } = this.props.auth;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/LoadUserHistory`;
    var token = getAccessToken();
    //console.log('token: ' + token );
    const headers = { 'Authorization': `Bearer ` + token }
    //console.log('headers: ', headers );

    var config = {
      'headers': headers,
      'params': formPayload
    }

    console.log('config', config);

    axios.get(API_URL, config)
      .then( function(response) {
        if( response.status === 200 )
        {
          var cardid = "histcard" + historyID;
          console.log('remove element:', cardid);
          // document.getElementById(cardid).style.display = 'none';
          return historyID;
        }
        else {
          var contentType = response.headers.get("content-type");
          if(contentType && contentType.indexOf("application/json") !== -1) {
            console.log(response.data);
          }
          else {
            console.log("hidePastBacktest: Oops, we haven't got JSON!", response);
          }
          console.log("hidePastBacktest: not ok response: ", response);
          if( response.status === 504 ) {
            console.log("hidePastBacktest: endpoint timeout");
          }
          else {
            throw new Error('hidePastBacktest: Network response was not OK.');
          }
        }
      })
      .then( this.removeBacktest )
      .catch( function(error) {
        console.log('hidePastBacktest: There has been a problem with your fetch operation: ' + error.message);
        LogRocket.captureException(error, {
          tags: {
            page: 'History',
            action: 'hidePastBacktest'
          },
        });
      });
  }

  removeBacktest(histid)
  {
    console.log('remove history ID', histid);
    // var spliced = backtestTableData.splice(idx,1);
    // console.log('spliced:', spliced);

    if( sortField == "id") {
      this.requestFreshHistory();
    }
    else {
      // we archived while in a sorted state
      this.handleSortOrderChange(sortField, sortDir);
    }

    // // redraw shown rows
    // var startRow = (currentTradePage-1) * numRowsShown;
    // var endRow = Math.min( startRow+numRowsShown, backtestTableData.length );
    // console.log('startRow:', startRow, 'endRow:', endRow, 'total:', backtestTableData.length);
    // backtestDataShown = backtestTableData.slice(startRow, endRow);
    // this.setState({ state: this.state });
  }


  pagedUserHistory(cursor)
  {
    this.setState({ hideProgress: false }, () => console.log('hideProgress:', this.state.hideProgress));

    showFooter = true;
    console.log('showFooter:', showFooter);
    showNoResultsCard = false;
    console.log('showNoResultsCard:', showNoResultsCard);

    console.log('cursor:', cursor);

    const formPayload = {
      token: localStorage.getItem('token'),
      number: cursor.rows,
      before: cursor.before,
      offset: cursor.offset, // formatDateTimeObjToString(new Date()),  // no paging of backtest results
      hidden: archiveToggleState,
      sample: sampleToggleState,
      sym: symbolFilter,
      strat: stratFilter,
      sortkey: cursor.sortkey,
      sortdir: cursor.sortdir,
    };

    // auth info
    const { getAccessToken } = this.props.auth;
    const API_URL =  `${process.env.REACT_APP_API_BASE_URL}/PagedUserHistory`;
    var token = getAccessToken();
    //console.log('token: ' + token );
    const headers = { 'Authorization': `Bearer ` + token }
    //console.log('headers: ', headers );

    var config = {
      'headers': headers,
      'params': formPayload
    }

    console.log('config', config);

    axios.get(API_URL, config)
      .then( function(response) {
        // console.log('response status: ', response.status);
        // console.log('response:', response);
        if( response.status === 200 )
        {
          return response.data;
        }
        else {
          var contentType = response.headers.get("content-type");
          if(contentType && contentType.indexOf("application/json") !== -1) {
            console.log(response.data);
          }
          else {
            console.log("pagedUserHistory: Oops, we haven't got JSON!", response);
          }
        }
        console.log("pagedUserHistory: not ok response: ", response);
        if( response.status === 504 ) {
          // we had an endpont time out... backtest is going longer than 30 seconds... start pinging
          console.log("pagedUserHistory: endpoint timeout");
          // throw new Error('pingBTR: Endpoint Timeout.');
          return "";
        }
        else {
          throw new Error('pagedUserHistory: Network response was not OK.');
        }
      })
      .then( this.populateBTHistory )
      .catch( function(error) {
        console.log('pagedUserHistory: ', error);
        console.log('pagedUserHistory: There has been a problem with your fetch operation: ', error.message);
        LogRocket.captureException(error, {
          tags: {
            page: 'History',
            action: 'pagedUserHistory'
          },
        });
        // document.getElementById("odiv").style.display = "none";
        // this.setState({ loadstatus: 'hide' }, () => console.log('dashboard loadstatus:', this.state.loadstatus));
      });
    // once we get results, return the json
    // document.getElementById("odiv").style.display = "none";
  }

  populateBTHistory(json)
  {
    // process your JSON further
    console.log("json:", json);
    // document.getElementById("odiv").style.display = "none";
    this.setState({ hideProgress: true }, () => console.log('hideProgress:', this.state.hideProgress));

    if( json.length == 0 ) {
      // we'll just assume the user doesn't have any history... if it is an endpoint timeout, user can manually refresh
      // if successful, this should return results into populateBTHistory
      // this.pagedUserHistory();
      return;
    }

    historyList = [];
    rawHistory = {};
    totalResults = json.Total;
    console.log('totalResults:', totalResults);

    if( json.Total == 0 ) {
      showFooter = false;
      console.log('showFooter:', showFooter);
      showNoResultsCard = true;
      console.log('showNoResultsCard:', showNoResultsCard);
    }

    // console.log(backtestTableCols);

    // backtestTableData.length = 0;
    backtestDataShown.length = 0;
    console.log('backtestDataShown:', backtestDataShown.length);

    for( var i = 0; i < json.Results.length; i++)
    {
      var request = queryStringToJSON(json.Results[i].Request);
      // console.log("request", request);

      // skip backtests with malformed result data
      if( json.Results[i]?.Result ) {
        var result = JSON.parse(json.Results[i].Result);
        if( result == null || result == "" ) {
          continue;
        }
      } else {
        continue;
      }

      // console.log("result", result);
      var hidden = json.Results[i].Hidden;
      var userid = json.Results[i].UserID;
      var link = json.Results[i].Link;

      var startdate = request.start.split('-');
      var enddate = request.end.split('-');
      var sdate = stripLeadingZero(startdate[1]) + '/' + stripLeadingZero(startdate[2]) + '/' + startdate[0];
      var edate = stripLeadingZero(enddate[1]) + '/' + stripLeadingZero(enddate[2]) + '/' + enddate[0];
      var dates = sdate + " to " + edate;
      var subtitle = request.strattype + '  ' + dates;

      var historyobj =
      {
        ID: result.HistoryID,
        // ResultNum: i,
        HistoryID: (
          <div id={'histcard' + result.HistoryID}>
            {result.HistoryID}
          </div>
        ),
        Symbol: request.symbol,
        // subtitle: subtitle,
        Strat: request.strattype,
        StartDate: (
          <div style={tdstyle.padded}>{sdate}</div>
        ),
        EndDate: (
          <div style={tdstyle.padded}>{edate}</div>
        ),
        NetPNL: (
          <div style={tdstyle.padded}>{'$' + formatNumber(Number(result.NetPNL),2)}</div>
        ),
        NetROM: (
        <div style={result.NetROM < 0 ? style.negativePNL : style.positivePNL}>
          {formatNumber(Number(result.NetROM)*100,2)}%
        </div>
        ),
        NetROC: (
        <div style={result.NetROC < 0 ? style.negativePNL : style.positivePNL}>
          {formatNumber(Number(result.NetROC)*100,2)}%
        </div>
        ),
        Trades: result.NumTrades,
        DailySharpe: formatNumber(Number(result.DailySharpe),2),
        Calmar: formatNumber(Number(result.Calmar),2),
        MonthlySortino: formatNumber(Number(result.MonthlySortino),2),
        View: (
          <div style={tdstyle.nowrap}>
            <IconButton style={style.iconstyle} onClick={this.loadBacktest.bind(this,result.HistoryID)} touch={true}><PageViewIcon/></IconButton>
            {hidden ?
            <IconButton style={style.iconstyle} onClick={this.hidePastBacktest.bind(this,result.HistoryID,true)} touch={true}><UnArchiveIcon/></IconButton>
            :
            <IconButton style={style.iconstyle} onClick={this.hidePastBacktest.bind(this,result.HistoryID,false)} touch={true}><ArchiveIcon/></IconButton>
            }
            <IconButton style={style.iconstyle} onClick={this.openShareMenu.bind(this,result.HistoryID)} touch={true}><ShareIcon/></IconButton>
            <ShareComp ref={(shareref) => { this.shareref = shareref; }} onCopy={this.shareCopyDone} />
          </div>
        ),
        Link: link,
        Date: json.Results[i].Date,
        UserID: userid,
        // expanded: false,
      };
      // historyList.push( historyobj );
      //console.log(historyobj);

      backtestTableData.push( historyobj );
      if( i < numRowsShown ) {
        backtestDataShown.push( historyobj );
        console.log(historyobj);
      }

      var rawobj =
      {
        request: request,
        result: result,
      }
      rawHistory[result.HistoryID] = rawobj;
    }

    console.log('backtestTableData.length:', backtestTableData.length );
    console.log('backtestDataShown.length:', backtestDataShown.length );

    if( this.state.nextPagePendingReq ) {
      this.setState({ nextPagePendingReq: false }, () => console.log('nextPagePendingReq:', this.state.nextPagePendingReq));
      this.handleNextPageClick();
    }

    if( this.state.rowSizePendingReq ) {
      this.setState({ rowSizePendingReq: false }, () => console.log('rowSizePendingReq:', this.state.rowSizePendingReq));
      this.handleRowSizeChange(0, numRowsShown);
    }

    this.setState({ state: this.state });

    // for( var backtest = 0; backtest < historyList.length; backtest++)
    // {
    //   backtestTableData.push( historyList[backtest] );
    //   if( backtest < numRowsShown ) {
    //     backtestDataShown.push( historyList[backtest] );
    //     console.log(historyList[backtest]);
    //   }
    // }

    //console.log(backtestDataShown);

  }

  requestFreshHistory()
  {
    backtestTableData.length = 0;
    sortField = 'id';
    console.log('sortField:', sortField);
    sortDir = 'desc';
    console.log('sortDir:', sortDir);

    var cursor = {
      before: true,
      rows: numRowsShown,
      sortkey: "rdate",
      sortdir: "desc",
      offset: formatDateTimeObjToString(new Date()),
    };

    this.pagedUserHistory(cursor);
  }

  // trade table stuff
  handleNextPageClick()
  {
    console.log('in handleNextPageClick', backtestTableData.length);
    console.log(backtestTableData);
    var startRow = currentTradePage * numRowsShown;
    console.log('startRow+1:', startRow+1, 'currentTradePage:', currentTradePage, 'bTD.length:', backtestTableData.length);
    if( (startRow+1) > backtestTableData.length ) {

      var offsetVal = backtestTableData[backtestTableData.length-1].ID;
      if( sortField != 'id' ) {
        offsetVal = startRow;
      }

      // need to request new data
      var cursor = {
        before: true,
        //rdate: formatDateTimeObjToString(new Date(backtestTableData[backtestTableData.length-1].RDate)),
        rows: numRowsShown,
        sortkey: sortField,
        sortdir: sortDir,
        offset: offsetVal,
      };

      this.setState({ nextPagePendingReq: true }, () => console.log('nextPagePendingReq:', this.state.nextPagePendingReq));
      this.pagedUserHistory(cursor);

      return;
    }
    var endRow = Math.min( startRow+numRowsShown, backtestTableData.length );
    console.log('startRow:', startRow, 'endRow:', endRow, 'total:', backtestTableData.length);

    backtestDataShown = backtestTableData.slice(startRow, endRow);
    currentTradePage = currentTradePage + 1;
    console.log('currentTradePage:', currentTradePage);

    // still gotta force a state change to get global vars to update datatable?
    this.setState({ state: this.state });
  }

  handlePrevPageClick()
  {
    console.log('in handlePrevPageClick');
    var startRow = (currentTradePage-2) * numRowsShown;
    if( startRow < 0 ) {
      return;
    }
    var endRow = (currentTradePage-1) * numRowsShown;
    console.log('startRow:', startRow, 'endRow:', endRow, 'total:', backtestTableData.length);

    backtestDataShown = backtestTableData.slice(startRow, endRow);
    currentTradePage = currentTradePage - 1;
    console.log('currentTradePage:', currentTradePage);

    // still gotta force a state change to get global vars to update datatable?
    this.setState({ state: this.state });
  }

  handleRowSizeChange(idx, val)
  {
    console.log('in handleRowSizeChange', idx, val, backtestTableData.length, totalResults);

    if( backtestTableData.length < val && totalResults > backtestTableData.length ) {

      var offsetVal = backtestTableData[backtestTableData.length-1].ID;
      if( sortField != 'id' ) {
        offsetVal = backtestTableData.length;
      }

      // need to request new data
      var cursor = {
        before: true,
        //rdate: formatDateTimeObjToString(new Date(backtestTableData[backtestTableData.length-1].RDate)),
        rows: val - backtestTableData.length,
        sortkey: sortField,
        sortdir: sortDir,
        offset: offsetVal,
      };

      numRowsShown = val;
      console.log('numRowsShown:', numRowsShown);
      this.setState({ rowSizePendingReq: true }, () => console.log('rowSizePendingReq:', this.state.rowSizePendingReq));
      this.pagedUserHistory(cursor);

      return;
    }

    var endRow = Math.min( val, backtestTableData.length );
    console.log('startRow:', 0, 'endRow:', endRow, 'total:', backtestTableData.length);

    backtestDataShown = backtestTableData.slice(0, endRow);

    numRowsShown = val;
    console.log('numRowsShown:', numRowsShown);
    currentTradePage = 1;
    console.log('currentTradePage:', currentTradePage);

    console.log('backtestTableData', backtestTableData);

    // still gotta force a state change to get global vars to update datatable?
    this.setState({ state: this.state });
  }

  handleSortOrderChange(key, order)
  {
    console.log('handleSortOrderChange', key, order);

    // reset to fresh results
    backtestTableData.length = 0;
    totalResults = 0;
    currentTradePage = 1;

    console.log('backtestTableData:', backtestTableData.length);

    sortField = key;
    console.log('sortField:', sortField);
    sortDir = order;
    console.log('sortDir:', sortDir);

    var cursor = {
      before: true,
      rows: numRowsShown,
      sortkey: key,
      sortdir: order,
      offset: 0,
    };

    this.pagedUserHistory(cursor);
  }

  render() {
    // const { isAuthenticated } = this.props.auth;

    return (
      <div className="animated fadeIn">
        <Helmet><title>backtest.io | {this.state.helmetTitle}</title></Helmet>
        <div className="row">
          <div className="col-sm-4 col-md-4 col-lg-4">
            <h2 style={style.titlefont}>History</h2>
          </div>
          <div className="col-sm-5 col-md-5 col-lg-5">

          </div>
          <div className="col-sm-3 col-md-3 col-lg-3">
          </div>
        </div>
        <div style={style.cardSpacing} className="row">
          <div className="col-md-12">
            {/* <div className="card jrstep_trades"> */}
              {/* <div> */}
            <MuiThemeProvider>
              <Card>
                <CardTitle title={
                  <div className="row">
                    <div className="col-md-9">
                      Backtests
                    </div>
                    <div className="col-md-3">
                    </div>
                  </div>
                }/>
                <CardText>
                <div id="odiv" style={this.state.hideProgress ? toggleStyles.missingDiv : toggleStyles.showDiv}>
                  <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                    <LinearProgress mode="indeterminate" />
                  </MuiThemeProvider>
                </div>
                <div style={style.reduceCardMargin33}>
                  <DataTables
                    height={'auto'}
                    showHeaderToolbar={false}
                    // title={'Trades'}
                    titleStyle={style.mediumCardTitle}
                    tableStyle={tdstyle.tableSpacing}
                    tableHeaderColumnStyle={tdstyle.headerStyle}
                    tableRowColumnStyle={tdstyle.tradecolC}
                    // tableRowStyle={tdstyle.tradecolR}
                    selectable={false}
                    showRowHover={true}
                    showRowSizeControls={true}
                    showFooterToolbar={showFooter}
                    rowSize={numRowsShown}
                    rowSizeList={[10,25,50,100]}
                    columns={backtestTableCols}
                    data={backtestDataShown}
                    showCheckboxes={false}
                    showHeaderToolbarFilterIcon={false}
                    //onCellClick={this.handleCellClick}
                    //onCellDoubleClick={this.handleCellDoubleClick}
                    //onFilterValueChange={this.handleFilterValueChange}
                    onSortOrderChange={this.handleSortOrderChange}
                    onNextPageClick={this.handleNextPageClick}
                    onPreviousPageClick={this.handlePrevPageClick}
                    //onRowSelection={this.handleRowSelection}
                    onRowSizeChange={this.handleRowSizeChange}
                    page={currentTradePage}
                    count={totalResults}
                  />
                </div>
                {
                  showNoResultsCard && (
                    <div style={style.infonote}>You have not performed any backtests{symbolFilter=="" && stratFilter==""? "." : " for " }{symbolFilter} {stratFilter}</div>
                  )
                }
                </CardText>
              </Card>
            </MuiThemeProvider>
              {/* </div> */}
            {/* </div> */}
          </div>
        </div>
        <br/>

        <MuiThemeProvider>
          <Snackbar
            open={this.state.snackopen}
            message={this.state.snacktext}
            autoHideDuration={this.state.snackAutoHide}
            // onRequestClose={this.handleSnackClose}
            // onClick={this.handleSnackAction}
          />
        </MuiThemeProvider>

        {
          !isMobile && (
          <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, btioDarkTheme)}>
            <Drawer containerClassName="drawerstyle" open={true} width={250}>
              <MenuItem></MenuItem>
              <div style={style.historyfont}>Filter</div>
              <MenuItem>
                <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                <Toggle
                  label="Hide Archived Backtests"
                  defaultToggled={true}
                  labelPosition="right"
                  style={toggleStyles.toggleMargins}
                  onToggle={this.handleArchiveToggle}
                  toggled={archiveToggleState}
                  labelStyle={toggleStyles.toggleLabel}
                />
                </MuiThemeProvider>
              </MenuItem>
              <MenuItem>
                <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                <Toggle
                  label="Show Sample Backtests"
                  defaultToggled={true}
                  labelPosition="right"
                  style={toggleStyles.toggleMargins}
                  onToggle={this.handleSampleToggle}
                  toggled={sampleToggleState}
                  labelStyle={toggleStyles.toggleLabel}
                />
                </MuiThemeProvider>
              </MenuItem>
              {/* <MenuItem><AutoComplete id="symbolfilter" dataSource={acSymbolSource} openOnFocus={true} searchText={symbolFilter} onUpdateInput={this.handleSymbolChange} floatingLabelText="Symbol" floatingLabelStyle={style.floatingLabelStyle} /></MenuItem> */}
              {/* <MenuItem><AutoComplete id="stratfilter" dataSource={acStrategySource} openOnFocus={true} searchText={stratFilter} onUpdateInput={this.handleStrategyChange} floatingLabelText="Strategy" floatingLabelStyle={style.floatingLabelStyle} /></MenuItem> */}
              <div className="row">
                <div className="col-md-8" style={style.sidePadding} >
                <SelectField id="symbolfilter" floatingLabelText="Symbol" floatingLabelStyle={style.floatingLabelStyle} value={symbolFilter} onChange={this.handleSymbolChange} >
                  <MenuItem value="" primaryText="" />
                  { acSymbolSource.map( (opt, index) =>
                    <MenuItem key={index} value={opt} primaryText={opt} />
                  )}
                </SelectField>
                </div>
                <div className="col-md-4">
                </div>
              </div>
              <div className="row">
                <div className="col-md-8" style={style.sidePadding} >
                  <SelectField id="stratfilter" floatingLabelText="Strategy" floatingLabelStyle={style.floatingLabelStyle} value={stratFilter} onChange={this.handleStrategyChange} >
                    <MenuItem value="" primaryText="" />
                    { acStrategySource.map( (opt, index) =>
                      <MenuItem key={index} value={opt} primaryText={opt} />
                    )}
                  </SelectField>
                </div>
                <div className="col-md-4"/>
              </div>

              <div className="row" >
                <div className="col-md-6" style={style.sidePadding}>
                <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                  <RaisedButton label="Apply" primary={true} fullWidth={false} onClick={this.handleFormSubmit} />
                </MuiThemeProvider>
                </div>
                <div className="col-md-6" style={style.sidePadding}>
                  <RaisedButton label="Clear" fullWidth={false} onClick={this.handleFormClear} />
                </div>
              </div>
              {/* </MuiThemeProvider> */}
            </Drawer>
          </MuiThemeProvider>
          )
        }

      </div>
    );
  }
}

export default withRouter(History);
