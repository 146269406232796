import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { isMobile } from "react-device-detect";

import { Bar, Line } from 'react-chartjs-2';
import { Progress, Tooltip } from 'reactstrap';
import BTFormTabbed from '../BTForm/BTFormTabbed';

import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import btioDarkTheme from '../../utils/themes/btioDarkTheme';

import * as muicolors from 'material-ui/styles/colors';

import Divider from 'material-ui/Divider';
import Snackbar from 'material-ui/Snackbar';
import AlertComp from '../../components/AlertComp/';
import ShareComp from '../../components/ShareComp/';

import HistoryTextLoader from '../../components/Loaders/HistoryTextLoader';

import Toggle from 'material-ui/Toggle';
import Drawer from 'material-ui/Drawer';
import {Card, CardHeader, CardTitle, CardText} from 'material-ui/Card';
import MenuItem from 'material-ui/MenuItem';
import RaisedButton from 'material-ui/RaisedButton';
import IconButton from 'material-ui/IconButton';

import RefreshIcon from 'material-ui/svg-icons/navigation/refresh';
import TrendingDownIcon from 'material-ui/svg-icons/action/trending-down';
import TrendingUpIcon from 'material-ui/svg-icons/action/trending-up';
import PageViewIcon from 'material-ui/svg-icons/action/pageview';
import ArchiveIcon from 'material-ui/svg-icons/content/archive';
import ShareIcon from 'material-ui/svg-icons/social/share';
import ArrowForwardIcon from 'material-ui/svg-icons/navigation/arrow-forward';

import Paper from 'material-ui/Paper';
import DataTables from 'material-ui-datatables';

import {Queue} from '../../utils/Queue';
import loading from '../../utils/auth0/Callback/loading.svg';

import axios from 'axios';
import { Helmet } from 'react-helmet';
import fcWidget from 'freshchat-widget';
import branch from 'branch-sdk';
import LogRocket from 'logrocket';

import * as constants  from '../../constants/services';
import ReconnectingWebSocket from 'reconnecting-websocket';

LogRocket.init('qm4ige/backtestio');

var branchURLBase = "";

const brandPrimary =  '#20a8d8';
const brandSuccess =  '#4dbd74';
const brandInfo =     '#63c2de';
const brandDanger =   '#f86c6b';
const brandInfoHover = '#96d4e9';
const brandInfoDarkBorder = '#57badb';

const tradeTableData = [];
var tradeDataShown = [];
const tradeTableCols = [];
var historyList = [];
var rawHistory = {};

const coldBtFactor = 2; // 2021: seems to be a longer delay here. // .65;  // seconds per month of backtesting... cold start
var lastBTMonths = 0;
var lastBTFactor = coldBtFactor;

const style = {
  refresh: {
    // display: 'inline-block',
    display: 'block',
    margin: 'auto',
    // position: 'relative',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // 'z-index': '2',
  },
  loaderTextBt: {
    fontFamily: 'Century Gothic, Muli, sans-serif',
    fontWeight: 600,
    letterSpacing: '3px',
    fontSize: '2.25rem',
    // textAlign: 'center',
    color: muicolors.indigo500,
  },
  toggle: {
    marginBottom: 16,
  },
  button: {
    margin: 12,
  },
  roundbutton: {
    margin: 12,
    borderRadius: '5px',
  },
  boldbutton: {
    borderRadius: '5px',
  },
  boldtxt: {
    fontWeight: 600,
  },
  cardstyle: {
    // marginTop: 0,
    // marginBottom: -25,
    // padding: '0px',
    // borderBottom: '1px solid #404040',  // used to see total area
    backgroundColor: muicolors.blueGrey900,
  },
  cardstyle_inprogress: {
    backgroundColor: muicolors.blueGrey900,
    opacity: 0.7,

    border: '2px solid #dadada',
    borderRadius: '7px',
    outline: 'none',
    borderColor: '#9ecaed',
    boxShadow: '5px 5px 20px #9ecaed',

  },
  glowstyle: {
    backgroundColor: muicolors.blueGrey900,
    border: '2px solid #dadada',
    borderRadius: '7px',
    outline: 'none',
    borderColor: '#9ecaed',
    boxShadow: '5px 5px 30px #9ecaed',
  },
  subtitle_inprogress: {
    color: muicolors.teal500,
    fontWeight: 600,
    fontSize: '1.25rem',
    fontVariant: 'small-caps',
  },
  subtitle_normal: {
    color: muicolors.darkWhite,
  },
  iconstyle: {
    marginTop: 10,
    // paddingRight: 10,
  },
  cardtextstyle: {
    // backgroundColor: muicolors.blueGrey600,
  },
  historyfont: {
    marginTop: 10,
    marginBottom: 5,
    // fontFamily: 'Permanent Marker',
    fontFamily: 'Century Gothic, Muli, sans-serif',
    fontWeight: 600,
    letterSpacing: '3px',
    fontSize: '1.75rem',
    textAlign: 'center',
    // borderBottom: '1px solid #404040',  // used to see total area
  },
  alignright: {
    textAlign: 'right',
  },
  titlefont: {
    marginTop: 10,
    marginBottom: 5,
    // fontFamily: 'Permanent Marker',
    fontFamily: 'Century Gothic, Muli, sans-serif',
    fontSize: '2rem',
    fontWeight: 600,
    letterSpacing: '1px',
    // textAlign: 'center',
  },
  trialNote: {
    backgroundColor: muicolors.teal50,
    fontFamily: 'Century Gothic, Muli, sans-serif',
    fontSize: '.75rem',
    marginTop: 15,
    textAlign: 'center',
    borderRadius: '15px',
    //fontWeight: 600,
  },
  mediumCardTitle: {
    fontSize: '24px',
    marginLeft: -8,
  },
  privateBT: {
    // fontSize: '20px',
    fontFamily: 'Century Gothic, Muli, monospace',
    fontSize: '1.15rem',
    // marginTop: 15,
    textAlign: 'center',
  },
  toggleLabel: {
    whiteSpace: 'pre',
    fontSize: '14px',
    // textAligh: 'left',
    // marginLeft: '-10px',
    // paddingRight: '10px',
  },
  reduceCardMargin40: {
    marginTop: -40,
  },
  reduceCardMargin33: {
    marginTop: -33,
  },
  reduceCardMargin25: {
    marginTop: -25,
  },
  reduceCardMargin10: {
    marginTop: -10,
  },
  hiddenDiv: {
    visibility: 'hidden',
  },
  missingDiv: {
    display: 'none',
  },
};

const tdstyle = {
  headerStyle: {
    // fontFamily: 'Roboto, sans-serif',
    fontFamily: 'Century Gothic, Muli, sans-serif',
    // fontFamily: 'Lobster, cursive',
    // fontFamily: 'Raleway, sans-serif',
    fontSize: '14px',
    fontWeight: 500,
    color: muicolors.fullBlack,
    backgroundColor: muicolors.blueGrey50,
    paddingLeft: '5px',
    paddingRight: '5px',
    textAlign: 'center',
    whiteSpace: 'normal',
  },
  tradecolL: {
    'whiteSpace': 'normal',
    'paddingLeft': '4px',
    'paddingRight': '2px',
    'textAlign': 'left',
  },
  tradecolR: {
    'whiteSpace': 'normal',
    'paddingLeft': '2px',
    'paddingRight': '2px',
    'textAlign': 'right',
  },
  tradecolC: {
    'whiteSpace': 'normal',
    'paddingLeft': '4px',
    'paddingRight': '2px',
    'textAlign': 'center',
  },
};

// tooltip stuff
const tooltipDelay = { show: 0, hide: 0 };
const tooltipOpenMap = {};
function setupTooltipMap()
{
  var tooltips = ['netpnl', 'commish', 'trades', 'netroc', 'sharpe', 'drawdown',
  'winningtrades', 'losingtrades', 'volume', 'commishimpact', 'undperf', 'betaund',
  'maxrunning', 'minrunning', 'maxwinner', 'maxloser', 'maxmargin',
  'annualret', 'annualvol', 'calmar', 'sortino', 'msharpe', 'ysharpe',
  'avgnetrom', 'avgtradepnl', 'avgholding', 'marketvol', 'volentry', 'volexit'];

  for( var i = 0; i < tooltips.length; i++ )
  {
    tooltipOpenMap[ tooltips[i] ] = false;
  }

  return tooltipOpenMap;
}

// Card Chart 1
const cardChartData1 = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: brandPrimary,
      borderColor: 'rgba(255,255,255,.55)',
      data: [65, 59, 84, 84, 51, 55, 40]
    }
  ],
};

const cardChartOpts1 = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  scales: {
    xAxes: [{
      gridLines: {
        color: 'transparent',
        zeroLineColor: 'transparent'
      },
      ticks: {
        fontSize: 2,
        fontColor: 'transparent',
      }

    }],
    yAxes: [{
      display: false,
      ticks: {
        display: false,
        min: Math.min.apply(Math, cardChartData1.datasets[0].data) - 5,
        max: Math.max.apply(Math, cardChartData1.datasets[0].data) + 5,
      }
    }],
  },
  elements: {
    line: {
      borderWidth: 1
    },
    point: {
      radius: 4,
      hitRadius: 10,
      hoverRadius: 4,
    },
  }
}

// Card Chart 2
const cardChartData2 = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: brandInfo,
      borderColor: 'rgba(255,255,255,.55)',
      data: [1, 18, 9, 17, 34, 22, 11]
    }
  ],
};

const cardChartOpts2 = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  scales: {
    xAxes: [{
      gridLines: {
        color: 'transparent',
        zeroLineColor: 'transparent'
      },
      ticks: {
        fontSize: 2,
        fontColor: 'transparent',
      }

    }],
    yAxes: [{
      display: false,
      ticks: {
        display: false,
        min: Math.min.apply(Math, cardChartData2.datasets[0].data) - 5,
        max: Math.max.apply(Math, cardChartData2.datasets[0].data) + 5,
      }
    }],
  },
  elements: {
    line: {
      tension: 0.00001,
      borderWidth: 1
    },
    point: {
      radius: 4,
      hitRadius: 10,
      hoverRadius: 4,
    },
  }
}

// Card Chart 3
const cardChartData3 = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: 'rgba(255,255,255,.2)',
      borderColor: 'rgba(255,255,255,.55)',
      data: [78, 81, 80, 45, 34, 12, 40]
    }
  ],
};

const cardChartOpts3 = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  scales: {
    xAxes: [{
      display: false
    }],
    yAxes: [{
      display: false
    }],
  },
  elements: {
    line: {
      borderWidth: 2
    },
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
    },
  }
}

// Card Chart 4
const cardChartData4 = {
  labels: ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: 'rgba(255,255,255,.3)',
      borderColor: 'transparent',
      data: [78, 81, 80, 45, 34, 12, 40, 75, 34, 89, 32, 68, 54, 72, 18, 98]
    }
  ],
};

const cardChartOpts4 = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  scales: {
    xAxes: [{
      display: false,
      barPercentage: 0.6,
    }],
    yAxes: [{
      display: false,
    }]
  }
}

// Main Chart

// convert Hex to RGBA
function convertHex(hex,opacity) {
  hex = hex.replace('#','');
  var r = parseInt(hex.substring(0,2), 16);
  var g = parseInt(hex.substring(2,4), 16);
  var b = parseInt(hex.substring(4,6), 16);

  var result = 'rgba('+r+','+g+','+b+','+opacity/100+')';
  return result;
}

//Random Numbers
function random(min,max) {
  return Math.floor(Math.random()*(max-min+1)+min);
}

var elements = 27;
var data1 = [];
var data2 = [];
var data3 = [];
var data4 = [];
var data5 = [];

for (var i = 0; i <= elements; i++) {
  data1.push(random(50,200));
  data2.push(random(80,100));
  data3.push(65);
}

const mainChart = {
  labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S', 'M', 'T', 'W', 'T', 'F', 'S', 'S', 'M', 'T', 'W', 'T', 'F', 'S', 'S', 'M', 'T', 'W', 'T', 'F', 'S', 'S'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: convertHex(brandInfo,10),
      borderColor: brandInfo,
      pointHoverBackgroundColor: '#fff',
      borderWidth: 2,
      data: data1
    },
    {
      label: 'My Second dataset',
      backgroundColor: 'transparent',
      borderColor: brandSuccess,
      pointHoverBackgroundColor: '#fff',
      borderWidth: 2,
      data: data2
    },
    {
      label: 'My Third dataset',
      backgroundColor: 'transparent',
      borderColor: brandDanger,
      pointHoverBackgroundColor: '#fff',
      borderWidth: 1,
      borderDash: [8, 5],
      data: data3
    }
  ]
}

const mainChartOpts = {
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  scales: {
    xAxes: [{
      gridLines: {
        drawOnChartArea: false,
      }
    }],
    yAxes: [{
      ticks: {
        beginAtZero: true,
        maxTicksLimit: 5,
        stepSize: Math.ceil(250 / 5),
        max: 250
      }
    }]
  },
  elements: {
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3,
    }
  }
}

function round(value, decimals) {
  var rounded = Number(Math.round(value+'e'+decimals)+'e-'+decimals);
  return Number.isNaN(rounded) ? 0 : rounded;
}

function formatNumber(number, digits) {
  if( number == null || isNaN(number) || !isFinite(number) ) {
    return '0';
  }

  var n = (number).toFixed(digits).replace(/[.,]00$/, "");
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function queryStringToJSON(queryString) {
  if(queryString.indexOf('?') > -1){
    queryString = queryString.split('?')[1];
  }
  var pairs = queryString.split('&');
  var result = {};
  pairs.forEach(function(pair) {
    pair = pair.split('=');
    result[pair[0]] = decodeURIComponent(pair[1] || '');
  });
  return result;
}

var linkQueue = new Queue();
var btResultQueue = new Queue();

class Dashboard extends Component {

  constructor(props) {
    super(props);

    console.log('dashboard passedprops:', this.props);
    // console.log('constructor drawerOpen', this.props.drawerOpen);
    console.log('preload bt:', this.props.match.params.bt);

    this.ws = null;

    this.state = {
      helmetTitle: 'dashboard',
      dropdownOpen: false,
      loadstatus: 'hide',
      loadstart: new Date(),
      loadattempts: 0,
      snackopen: false,
      snacktext: '',
      snackAutoHide: 5000,
      snackAction: 'Close',
      draweropen: true,
      loadnew: false,
      unauthuser: true,

      btresults: '',
      errormsg: '',
      jwtToken: '',
      startDate: '',
      endDate: '',
      grossPnl: 0,
      commish: 0,
      commishImpact: 0,
      trades: 0,
      grossRom: 0,
      dSharpe: 0,
      drawdown: 0,
      netPnl: 0,
      maxPnl: 0,
      maxUpnl: 0,
      minUpnl: 0,
      maxRunPnl: 0,
      minRunPnl: 0,
      volume: 0,
      blockedTrades: 0,
      blockedHedges: 0,
      numWinners: 0,
      numLosers: 0,
      winPercentage: 0,
      losePercentage: 0,
      capture: 0,
      trueMaxMargin: 0,
      capitalAtRisk: 0,
      netRom: 0,
      netRoc: 0,
      mSharpe: 0,
      ySharpe: 0,
      calmar: 0,
      mSortino: 0,
      annualizedVol: 0,
      annualizedRet: 0,
      underlyingPerf: 0,
      betaToUnderlying: 0,
      vixCashBackCount: 0,
      vxFutBackCount: 0,
      vixCashBackDays: 0,
      vxFutBackDays: 0,
      avgHoldPeriod: 0,
      avgHoldPeriodW: 0,
      avgHoldPeriodL: 0,
      maxWinner: 0,
      maxLoser: 0,
      avgNetRom: 0,
      avgNetRomW: 0,
      avgNetRomL: 0,
      avgTradePnl: 0,
      avgTradePnlW: 0,
      avgTradePnlL: 0,

      avgHVEntry: 0,
      avgHVEntryW: 0,
      avgHVEntryL: 0,
      avgIVEntry: 0,
      avgIVEntryW: 0,
      avgIVEntryL: 0,

      avgHVExit: 0,
      avgHVExitW: 0,
      avgHVExitL: 0,
      avgIVExit: 0,
      avgIVExitW: 0,
      avgIVExitL: 0,

      avgVixEntryW: 0,
      avgVixEntryL: 0,
      avgVvixEntryW: 0,
      avgVvixEntryL: 0,

      avgVixExitW: 0,
      avgVixExitL: 0,
      avgVvixExitW: 0,
      avgVvixExitL: 0,

      numRowsShown: 10,
      currentTradePage: 1,

      monthlyPnl: {},
      mainChart: {},
      mainChartOpts: {},
      btform: '',

      loadShared: 0,
      viewOnlyTitle: '',
      viewOnlyText: '',
      viewOnlyModal: true,

      tooltipMap: setupTooltipMap(),
      tooltipToggleState: true,

      loadingBT: false,
      loadingHistory: false,
      showHistorySnackMsg: true,
    };

    this.toggle                 = this.toggle.bind(this);
    this.fetchBacktest          = this.fetchBacktest.bind(this);
    this.displayBacktestResults = this.displayBacktestResults.bind(this);
    this.finishBacktest         = this.finishBacktest.bind(this);
    this.setMetrics             = this.setMetrics.bind(this);
    this.setChart               = this.setChart.bind(this);
    this.pingBacktestResults    = this.pingBacktestResults.bind(this);
    this.addBacktestEntry       = this.addBacktestEntry.bind(this);
    this.handleBacktestError    = this.handleBacktestError.bind(this);
    this.getUserHistory         = this.getUserHistory.bind(this);
    this.populateBTHistory      = this.populateBTHistory.bind(this);
    this.loadPastBacktest       = this.loadPastBacktest.bind(this);
    this.hidePastBacktest       = this.hidePastBacktest.bind(this);
    this.processChart           = this.processChart.bind(this);
    this.handleSnackClose       = this.handleSnackClose.bind(this);
    this.handleSnackAction      = this.handleSnackAction.bind(this);
    this.handleHistoryToggle    = this.handleHistoryToggle.bind(this);
    this.handleExpandChange     = this.handleExpandChange.bind(this);
    this.handleFirstLoad        = this.handleFirstLoad.bind(this);
    this.gotoHistory            = this.gotoHistory.bind(this);

    // trade table funcs
    this.handleNextPageClick    = this.handleNextPageClick.bind(this);
    this.handlePrevPageClick    = this.handlePrevPageClick.bind(this);
    this.handleRowSizeChange   = this.handleRowSizeChange.bind(this);

    // tooltips
    this.handleTooltip          = this.handleTooltip.bind(this);
    this.handleTooltipToggle    = this.handleTooltipToggle.bind(this);

    // branch
    this.setupBranch            = this.setupBranch.bind(this);
    this.parseBranchLink        = this.parseBranchLink.bind(this);
    this.generateBTLink         = this.generateBTLink.bind(this);
    this.updateBTLink           = this.updateBTLink.bind(this);
    this.loadSharedBT           = this.loadSharedBT.bind(this);
    this.showBranchStatus       = this.showBranchStatus.bind(this);
    this.displayLoginCTA        = this.displayLoginCTA.bind(this);

    this.openShareMenu          = this.openShareMenu.bind(this);
    this.shareCopyDone          = this.shareCopyDone.bind(this);
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  handleSnackClose() {
    this.setState({ snackopen: false, snackAction: 'Close' });
  };

  handleSnackAction() {
    this.setState({ snackopen: false });
    if( !btResultQueue.isEmpty() ) {
      // this loads a fresh backtest that was just run and contains a once-decoded json object with request and result. have to also decode the result string
      var json = btResultQueue.dequeue();
      var jsonResult = JSON.parse(json.Result);
      this.displayBacktestResults(jsonResult);
    }
  };

  handleHistoryToggle() {
    this.setState({ draweropen: !this.state.draweropen });
    document.body.classList.toggle('sidebar-hidden');

    if( this.state.draweropen ) {
      this.getUserHistory();
    }
  };

  // doesn't seem like this function is necessary. it still works without this callback. not sure why
  handleExpandChange(histkey)
  {
    var histObj = historyList.filter(hist => hist.key === histkey);
    console.log("expand histkey", histkey, histObj);
    histObj.expanded = !histObj.expanded;
  };

  displayLoginCTA()
  {
    this.setState({
      viewOnlyTitle: 'View Only Mode!',
      viewOnlyText: 'Please sign up or login to run backtests!  It\'s free - And you automatically get a trial of all advanced features!',
      viewOnlyModal: false,
    }, () => this.alertprivateref.handleOpen());
  }



  fetchBacktest(runparams) {

  // a preview / branch user tried to run a backtest. CTA to sign up
  if( this.state.unauthuser ) {
    this.displayLoginCTA();
    return;
  }

  // set up websocket on-demand
  this.rws.reconnect();
  btResultQueue.purge();

  //runparams['token'] = this.state.jwtToken;
  runparams['token'] = localStorage.getItem('token');

  this.setState( {startDate: runparams['start']} );
  this.setState( {endDate: runparams['end']} );
  tradeTableData.length = 0;
  tradeDataShown.length = 0;
  tradeTableCols.length = 0;

  // var d = new Date();
  var btstart = new Date();// d.getUTCFullYear() + '-' + (d.getUTCMonth()+1) + '-' + d.getUTCDate() + ' ' + d.getUTCHours() + ':' + d.getUTCMinutes() + ':' + d.getUTCSeconds();

  var monthspan = runparams.datespan / (1000 * 3600 * 24 * 30);

  var currBTFactor = coldBtFactor;
  if( lastBTMonths !== 0 ) {
    currBTFactor = Math.min(coldBtFactor, lastBTFactor);  // in case of estimate errors
  }

  var estimatedTime = Math.ceil(monthspan * currBTFactor);
  lastBTMonths = monthspan;

  this.addBacktestEntry(runparams);

  // loading feedback
  this.setState({ loadstart: btstart, loadingBT: true }, () => console.log('loadstart:', this.state.loadstart));

  this.setState({ snacktext: 'Your backtest is running.  It could take around ' + estimatedTime + ' seconds.', snackAction: 'Close', snackAutoHide: (5*60*1000) }, () => console.log('snacktext:', this.state.snacktext));
  this.setState({ snackopen: true });

  this.setState({ loadattempts: 1 }, () => console.log('loadattempts:', this.state.loadattempts));
  this.setState({ loadnew: true }, () => console.log('loadnew:', this.state.loadnew));

  // auth info
  const { getAccessToken } = this.props.auth;
  const API_URL = `${process.env.REACT_APP_API_BASE_URL}/Backtest`;
  var token = getAccessToken();
  //console.log('token: ' + token );
  const headers = { 'Authorization': `Bearer ` + token }
  //console.log('headers: ', headers );

  var config = {
    'headers': headers,
    'params': runparams
  }

  this.setState( {helmetTitle: runparams.symbol + ' ' + runparams.strattype + ' backtest' } );

  window.fcWidget.track('submit_backtest', {
    symbol: runparams.symbol,
    strategy: runparams.strattype
  });

  console.log('config', config);

  axios.get(API_URL, config)
    //.then(response => console.log("response message: ", response.data ))
    .then( function(response) {
      if( response.status === 200 )
      {
        return response.data;
      }
      else {
        var contentType = response.headers.get("content-type");
        if(contentType && contentType.indexOf("application/json") !== -1) {
          console.log(response.data);
        }
        else {
          console.log("fetchBacktest: Oops, we haven't got JSON!", response);
        }
      }
      console.log("fetchBacktest: not ok response: ", response);
      if( response.status === 504 ) {
        console.log("fetchBacktest: endpoint timeout, wait more");
        // can't call pingBacktestResults from here for some reason
        return "";
      }
      else {
        throw new Error('fetchBacktest: Network response was not OK.');
      }
    })
    // .then( this.displayBacktestResults )
    .catch( error => this.handleBacktestError(error) );

  }

  addBacktestEntry(runparams)
  {
    console.log("adding history for backtest in progress...");
    // insert history entry for visibility
    var startsplit = runparams['start'].split('-');
    var endsplit = runparams['end'].split('-');
    var historyobj =
    {
      key: `${runparams['symbol']}-temp`,
      symbol: runparams['symbol'],
      subtitle: 'subtitle',
      strat: runparams['strattype'],
      dates: `${startsplit[1]}/${startsplit[2]}/${startsplit[0]} - ${endsplit[1]}/${endsplit[2]}/${endsplit[0]}`,
      netpnl: 0,
      netrom: 0,
      netroc: 0,
      trades: 0,
      dsharpe: 0,
      expanded: true,
      link: '',
      views: 0,
      running: true,
      newentry: true,
    }

    historyList.unshift( historyobj );
  }

  handleBacktestError(error)
  {
    // var jsonerr = JSON.stringify(error);
    LogRocket.captureException(error);
    var btSnackText = 'We\'re sorry! There was a problem with your backtest.';
    this.setState({ snackopen: true, snackAction: 'Contact Us', snacktext: btSnackText, snackAutoHide: (5*60*1000) }, () => console.log('snacktext:', this.state.snacktext));
    this.rws.close();

    // detect a quick return (no response - 502 error probably)
    var d = new Date();
    var timespan = (d.getTime() - this.state.loadstart.getTime()) / 1000;
    // reducing time to 10 to avoid false alarms
    if( timespan < 10 ) {
      // likely a unauth error
      console.log('timespan reject:', timespan);
      this.displayLoginCTA();
      this.setState({ loadingBT: false }, () => console.log('timespan reject error:', this.state.loadstatus));
      return;
    }


    if( error.message.indexOf("Network Error") !== -1 ) {
      this.pingBacktestResults();
    }
  }

  pingBacktestResults()
  {
    this.setState({ snacktext: 'Hang tight! Your backtest is still running, it\'s just taking a bit long.' }, () => console.log('snacktext:', this.state.snacktext));
    this.setState( {snackopen: true} );

    const formPayload = {
      number: 1,  // we just want the last one for this
      offset: 0,  // no paging of backtest results
      btstart: this.state.loadstart,
      //token: this.state.jwtToken,
      token: localStorage.getItem('token'),
    };

    var loadincr = this.state.loadattempts + 1;
    this.setState({ loadattempts: loadincr }, () => console.log('loadattempts:', this.state.loadattempts));

    // auth info
    const { getAccessToken } = this.props.auth;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/LoadUserHistory`;
    var token = getAccessToken();
    //console.log('token: ' + token );
    const headers = { 'Authorization': `Bearer ` + token }
    //console.log('headers: ', headers );

    var config = {
      'headers': headers,
      'params': formPayload
    }

    console.log('config', config);

    // do a fresh fetch here
    //for( i = 1; i <= 4; i++)   // we'll try for a max extra 2 minutes...
    {
        console.log("pingBacktestResults:", this.state.loadattempts);

        axios.get(API_URL, config)
          .then( function(response) {
            if( response.status === 200 )
            {
              return response.data;
            }
            else {
              var contentType = response.headers.get("content-type");
              if(contentType && contentType.indexOf("application/json") !== -1) {
                console.log(response.data);
              }
              else {
                console.log("pingBacktestResults: Oops, we haven't got JSON!", response);
              }
            }
            console.log("not ok response: ", response);
            if( response.status === 504 ) {
              // we had an endpont time out... backtest is going longer than 30 seconds... start pinging
              console.log("pingBacktestResults: endpoint timeout,", loadincr);
              // throw new Error('pingBTR: Endpoint Timeout.');
              return "";
            }
            else {
              throw new Error('Network response was not OK.');
            }
          })
          .then( this.displayBacktestResults )
          .catch( function(error) {
            console.log('pingBacktestResults: There has been a problem with your fetch operation: ' + error.message);
          });
        // once we get results, return the json
    }
  }

  async setMetrics(json)
  {
    this.setState( {btresults: json,
    errormsg: json.Error,

    grossPnl: json.PNL,
    commish: json.Commish,
    commishImpact: json.Commish/Math.abs(json.PNL)*100,
    trades: json.NumTrades,
    grossRom: json.GrossROM*100,
    dSharpe: json.DailySharpe,
    drawdown: json.Drawdown,
    netPnl: json.NetPNL,
    maxPnl: json.MaxPNL,
    maxUpnl: json.MaxUPNL,
    minUpnl: json.MinUPNL,
    maxRunPnl: json.MaxRunningPNL,
    minRunPnl: json.MinRunningPNL,
    volume: json.Volume,
    blockedTrades: json.BlockedTrades,
    blockedHedges: json.BlockedHedges,
    numWinners: json.NumWinners,
    numLosers: json.NumLosers,
    winPercentage: json.WinPercentage*100,
    losePercentage: json.LosePercentage*100,
    capture: json.Capture*100,
    trueMaxMargin: json.TrueMaxMargin,
    capitalAtRisk: json.CapitalAtRisk || 0,
    netRom: json.NetROM*100,
    netRoc: json.NetROC*100,
    mSharpe: json.MonthlySharpe,
    ySharpe: json.YearSharpe,
    calmar: json.Calmar,
    mSortino: json.MonthlySortino,
    annualizedVol: json.AnnualizedVolatility*100,
    annualizedRet: json.AnnualizedReturn*100,
    underlyingPerf: json.UnderlyingPerformance*100,
    betaToUnderlying: json.BetaToUnderlying,
    vixCashBackCount: json.VIXCashBackwardationCount,
    vxFutBackCount: json.VXBackwardationCount,
    vixCashBackDays: json.VIXCashBackwardationDays,
    vxFutBackDays: json.VXBackwardationDays,
    avgHoldPeriod: json.AverageHoldingPeriod,
    avgHoldPeriodW: json.AverageHoldingPeriodW,
    avgHoldPeriodL: json.AverageHoldingPeriodL,
    maxWinner: json.MaxWinner,
    maxLoser: json.MaxLoser,
    avgNetRom: json.NetROM/json.NumTrades*100,
    avgNetRomW: json.AvgNetROMW*100,
    avgNetRomL: json.AvgNetROML*100,
    avgTradePnl: json.NetPNL/json.NumTrades,
    avgTradePnlW: json.AvgTradePNLW,
    avgTradePnlL: json.AvgTradePNLL,

    avgHVEntry: json.AverageHVEntry,
    avgHVEntryW: json.AverageHVEntryW,
    avgHVEntryL: json.AverageHVEntryL,
    avgIVEntry: json.AverageIVEntry,
    avgIVEntryW: json.AverageIVEntryW,
    avgIVEntryL: json.AverageIVEntryL,

    avgHVExit: json.AverageHVExit,
    avgHVExitW: json.AverageHVExitW,
    avgHVExitL: json.AverageHVExitL,
    avgIVExit: json.AverageIVExit,
    avgIVExitW: json.AverageIVExitW,
    avgIVExitL: json.AverageIVExitL,

    avgVixEntryW: json.AverageVIXEntryW,
    avgVixEntryL: json.AverageVIXEntryL,
    avgVvixEntryW: json.AverageVVIXEntryW,
    avgVvixEntryL: json.AverageVVIXEntryL,

    avgVixExitW: json.AverageVIXExitW,
    avgVixExitL: json.AverageVIXExitL,
    avgVvixExitW: json.AverageVVIXExitW,
    avgVvixExitL: json.AverageVVIXExitL}, () => console.log('setMetrics setState finished') );

    return new Promise(resolve => resolve('resolved'));
  }

  async setChart(json)
  {
    // tradeTableData = tradeTableData.concat( json.tradeList );

    if( json.TradeList.length > 0 ) {

      tradeTableData.length = 0;
      tradeDataShown.length = 0;
      tradeTableCols.length = 0;

      for( var tcol = 0; tcol < json.TradeColKeys.length; tcol++)
      {
        // var lbl = json.tradeCols[tcol].charAt(0).toUpperCase() + json.tradeCols[tcol].substring(1);
        var colobj =
        {
          key: json.TradeColKeys[tcol],
          label: json.TradeColLabels[tcol],
          // sortable: true,
        }
        tradeTableCols.push( colobj );
        //console.log(colobj);
      }

      for( var trade = 0; trade < json.TradeList.length; trade++)
      {
        tradeTableData.push( json.TradeList[trade] );
        if( trade < this.state.numRowsShown ) {
          tradeDataShown.push( json.TradeList[trade] );
          console.log(json.TradeList[trade]);
        }
      }
      //this.setState({ totalTradeRows: json.TradeList.length }, () => console.log('totalTradeRows:', this.state.totalTradeRows));
      // this.setState( {tradeList: json.tradeList } );
    }

    this.processChart(json);

    return new Promise(resolve => resolve('resolved'));
  }

  checkBTError(result)
  {
    console.log(`raw result error?: ${result.Error}`);
    if( result.Error ) {
      LogRocket.captureMessage(result.Error, {
        tags: {
          SnackbarErrorDisplayed: true,
        }
      });

      if( result.Error.includes('AuroraPaused') ) {
        var btSnackText = 'Hang tight! Your backtest is taking a little longer than expected.';
        this.setState({ snackopen: true, snackAction: 'Close', snacktext: btSnackText, snackAutoHide: (5*60*1000) }, () => console.log('snacktext:', this.state.snacktext));
        btResultQueue.dequeue();  // this isn't a real result anyway
      }
      else {
        var btSnackText = 'We\'re sorry! There was a problem with your backtest.';
        this.setState({ snackopen: true, snackAction: 'Contact Us', snacktext: btSnackText, snackAutoHide: (5*60*1000) }, () => console.log('snacktext:', this.state.snacktext));
        this.rws.close();
      }
      return true;
    }
    return false;
  }

  displayBacktestResults(json)
  {
    if( this.checkBTError(json) ) {
      return;
    }

    window.fcWidget.track('display_backtest', {
      historyid: json.HistoryID
    });

    console.log("grosspnl:", json.PNL);
    console.log("sharpe:", json.DailySharpe);

    var result = this.setMetrics(json);
    console.log('setMetrics async call result:', result );

    var result2 = this.setChart(json);
    console.log('setChart async call result:', result2 );

    document.getElementById('perfmarker').scrollIntoView();
  }

  finishBacktest()
  {
    console.log(`finish backtest. queue length: ${btResultQueue.getLength()}`);
    var json = btResultQueue.peek();
    if( !this.checkBTError(JSON.parse(json.Result)) ) {
      // show snackbar text to load
      this.setState({ snackopen: true, snackAction: 'View Results', snacktext: 'Your backtest is ready.', snackAutoHide: (5*60*1000), showHistorySnackMsg: false }, () => console.log('snacktext:', this.state.snacktext));

      this.setState({ loadingBT: false }, () => console.log('dashboard loadstatus DISPLAY:', this.state.loadstatus));

      // update bt estimate running times
      if(lastBTMonths !== 0) {
        var d = new Date();
        var timespan = (d.getTime() - this.state.loadstart.getTime()) / 1000;
        var actualBTFactor = timespan / lastBTMonths;
        console.log('lastBTFactor:', lastBTFactor, 'actualBTFactor:', actualBTFactor);
        lastBTFactor = (lastBTFactor + actualBTFactor) / 2;
        console.log('new lastBTFactor:', lastBTFactor);
      }

      if( this.state.loadnew ) {
        // populate the results instead of fetching the entire list again
        historyList.shift();
        console.log('populate SINGLE:', json);
        this.populateBTHistory([json], true);
      }

      this.rws.close();
    }
  }

  gotoHistory()
  {
    if( this.state.unauthuser ) {
      return;
    }

    this.props.history.push('/history');
  }

  getUserHistory(showStatus=true)
  {
    if( this.state.unauthuser ) {
      return;
    }

    this.setState({ loadingHistory: true, showHistorySnackMsg: showStatus });
    if( showStatus ) {
      this.setState({ snacktext: 'Refreshing history...', snackAutoHide: (5*1000) }, () => console.log('snacktext:', this.state.snacktext));
      this.setState({ snackopen: true });
    }

    const formPayload = {
      number: 10,
      offset: 0,  // no paging of backtest results
      //token: this.state.jwtToken,
      token: localStorage.getItem('token')
    };


    var loadincr = 1;
    this.setState({ loadattempts: loadincr }, () => console.log('getUserHistory loadattempts:', this.state.loadattempts));

    // auth info
    const { getAccessToken } = this.props.auth;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/LoadUserHistory`;
    var token = getAccessToken();
    //console.log('token: ' + token );
    const headers = { 'Authorization': `Bearer ` + token }
    //console.log('headers: ', headers );

    var config = {
      'headers': headers,
      'params': formPayload
    }

    console.log('config', config);

    axios.get(API_URL, config)
      .then( function(response) {
        // console.log('response status: ', response.status);
        // console.log('response:', response);
        if( response.status === 200 )
        {
          return response.data;
        }
        else {
          var contentType = response.headers.get("content-type");
          if(contentType && contentType.indexOf("application/json") !== -1) {
            console.log(response.data);
          }
          else {
            console.log("getUserHistory: Oops, we haven't got JSON!", response);
          }
        }
        console.log("getUserHistory: not ok response: ", response);
        if( response.status === 504 ) {
          // we had an endpont time out... backtest is going longer than 30 seconds... start pinging
          console.log("getUserHistory: endpoint timeout,", loadincr);
          // throw new Error('pingBTR: Endpoint Timeout.');
          return "";
        }
        else {
          throw new Error('getUserHistory: Network response was not OK.');
        }
      })
      .then( this.populateBTHistory )
      .catch( function(error) {
        console.log('getUserHistory: ', error);
        console.log('getUserHistory: There has been a problem with your fetch operation: ', error.message);

        LogRocket.captureException(error, {
          tags: {
            page: 'Dashboard',
            action: 'getUserHistory'
          },
        });
      });
    // once we get results, return the json
  }

  populateBTHistory(json, addSingle = false)
  {
    // process your JSON further
    console.log("populateBTHistory json:", json, json.length);

    if( json.length == 0 ) {
      // we'll just assume the user doesn't have any history... if it is an endpoint timeout, user can manually refresh
      // if successful, this should return results into populateBTHistory
      // this.getUserHistory();

      if( this.state.loadShared > 0 ) {
        this.setState({ loadShared: 0 }, () => console.log('no json to populate... must be a private backtest'));
        this.showBranchStatus();
      }

      return;
    }

    if( !addSingle ) {
      historyList = [];
      rawHistory = {};
    }

    for( var i = 0; i < json.length; i++)
    {
      var request = queryStringToJSON(json[i].Request);
      // console.log("request", request);

      // skip backtests with malformed result data
      if( json[i]?.Result ) {
        var result = JSON.parse(json[i].Result);
        if( result == null || result == "" ) {
          continue;
        }
      } else {
        continue;
      }

      // console.log("result", result);
      // var rdate = json[i].Date;
      var link = json[i].Link;
      var views = json[i].Views;

      var startdate = request.start.split('-');
      var enddate = request.end.split('-');
      var dates = startdate[1] + '/' + startdate[2] + '/' + startdate[0] + " to " + enddate[1] + '/' + enddate[2] + '/' + enddate[0];
      var subtitle = request.strattype + '  ' + dates;

      var historyobj =
      {
        key: result.HistoryID,
        symbol: request.symbol,
        subtitle: subtitle,
        strat: request.strattype,
        dates: dates,
        netpnl: formatNumber(Number(result.NetPNL),2),
        netrom: formatNumber(Number(result.NetROM)*100,2),
        netroc: formatNumber(Number(result.NetROC)*100,2),
        trades: result.NumTrades,
        dsharpe: formatNumber(Number(result.DailySharpe),2),
        expanded: addSingle,
        link: link,
        views: views,
        running: false,
        newentry: addSingle,
      }

      if( addSingle ) {
        historyList.unshift( historyobj );
      } else {
        historyList.push( historyobj );
      }
      // console.log('historyobj',historyobj);

      var rawobj =
      {
        request: request,
        result: result,
        // rdate: rdate,
      }
      rawHistory[result.HistoryID] = rawobj;

      if( !link ) {
        console.log('no link for history', historyobj.key);
        this.generateBTLink(historyobj);
      }
      else {
        console.log('link for history', historyobj.key, historyobj.link);
      }

    }

    this.setState({loadingHistory: false});
    if( this.state.showHistorySnackMsg ) {
      this.setState({ snacktext: 'History loaded', snackAutoHide: (3*1000) }, () => console.log('snacktext:', this.state.snacktext));
    }

    if( this.state.loadShared > 0 ) {
      this.loadPastBacktest(this.state.loadShared);
      var histObj = historyList.filter(hist => hist.key === this.state.loadShared);

      this.setState({ loadShared: 0, loadingBT: false });

      // credit the person that shared this bt since it was opened - first load only
      if( histObj[0].views === 0 ) {
        // set it here even tho its already set in db
        console.log('branch adding bt_share_load credits', histObj[0].key, histObj[0].views);
        histObj[0].views = 1;
        branch.track( "bt_share_load", {histID: histObj[0].key, views: histObj[0].views} );
      }
    }


    // expand first 2 results
    // if( historyList.length > 1)
    // {
    //   this.handleExpandChange(historyList[0].key);
    //   this.handleExpandChange(historyList[1].key);
    // }
  }

  loadPastBacktest(historyID)
  {
    var raw = rawHistory[historyID];

    this.setState({ loadnew: false }, () => console.log('loadnew:', this.state.loadnew));
    this.setState( {startDate: raw.request.start} );
    this.setState( {endDate: raw.request.end} );
    tradeTableData.length = 0;
    tradeTableCols.length = 0;

    // var rdate = new Date(Date.parse(raw.rdate));
    // console.log('rdate:', rdate);

    this.setState( {helmetTitle: raw.request.symbol + ' ' + raw.request.strattype + ' backtest' } );

    this.state.btform.loadPastRequest( raw.request );
    this.displayBacktestResults( raw.result );
  }

  hidePastBacktest(historyID)
  {
    console.log('hiding historyID', historyID);

    const formPayload = {
      number: 0,  // we're not loading, we're hiding
      offset: historyID,
      token: localStorage.getItem('token'),
      unhide: false,
    };


    // auth info
    const { getAccessToken } = this.props.auth;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/LoadUserHistory`;
    var token = getAccessToken();
    //console.log('token: ' + token );
    const headers = { 'Authorization': `Bearer ` + token }
    //console.log('headers: ', headers );

    var config = {
      'headers': headers,
      'params': formPayload
    }

    console.log('config', config);

    axios.get(API_URL, config)
      .then( function(response) {
        if( response.status === 200 )
        {
          var cardid = "histcard" + historyID;
          console.log('hide card element:', cardid);
          document.getElementById(cardid).style.display = 'none';
        }
        else {
          var contentType = response.headers.get("content-type");
          if(contentType && contentType.indexOf("application/json") !== -1) {
            console.log(response.data);
          }
          else {
            console.log("hidePastBacktest: Oops, we haven't got JSON!", response);
          }
          console.log("hidePastBacktest: not ok response: ", response);
          if( response.status === 504 ) {
            console.log("hidePastBacktest: endpoint timeout");
          }
          else {
            throw new Error('hidePastBacktest: Network response was not OK.');
          }
        }
      })
      .catch( function(error) {
        console.log('hidePastBacktest: There has been a problem with your fetch operation: ' + error.message);
        LogRocket.captureException(error, {
          tags: {
            page: 'Dashboard',
            action: 'hidePastBacktest'
          },
        });
      });
  }


  processChart(json)
  {
    var elements = Object.keys(json.MonthlyPNL).length;
    var labels = [];
    data1 = [];
    data2 = [];
    data3 = [];
    data4 = [];
    data5 = [];

    var sum = 0;
    var keyidx = 0;
    // for( var key in json.monthlyPNL)
    console.log('monthlypnl length:', json.MonthlyPNL.length);
    for( var i = 0; i < json.MonthlyPNL.length; i++)
    {
      var mpnlsplit = json.MonthlyPNL[i].split('|');
      labels.push( mpnlsplit[0] );
      data1.push( round( +mpnlsplit[1], 2) );
      sum += +mpnlsplit[1];
      data2.push( round(sum, 2) );
      data3.push( json.MonthlyUnderlyingPx[keyidx] ); // post increment the index

      if( json.MonthlyHV != null ) {
        data4.push( round(json.MonthlyHV[keyidx], 2) ); // post increment the index
      }

      if( json.MonthlyIV != null ) {
        data5.push( round(json.MonthlyIV[keyidx], 2) ); // post increment the index
      }

      keyidx++;
    }

    var maxpnl = Math.max(...data1);
    var minpnl = Math.min(...data1);
    var chartmax = Math.round(maxpnl / 1000) + 1000;
    var maxticks = chartmax / 1000;


    const bar = {
      labels: labels,
      datasets: [
        {
          label: 'Monthly PNL',
          yAxisID: 'y-axis-0',
          backgroundColor: convertHex(muicolors.indigo200,50),
          borderColor: muicolors.blueGrey500,
          borderWidth: 1,
          hoverBackgroundColor: muicolors.blueGrey300,
          hoverBorderColor: muicolors.blueGrey600,
          data: data1
        },
        {
          label: 'Running PNL',
          yAxisID: 'y-axis-0',
          fill: false,
          // lineTension: 0.1,
          backgroundColor: convertHex(muicolors.teal300,30),
          borderColor: muicolors.teal500,
          borderWidth: 2,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: muicolors.teal600,
          pointBackgroundColor: muicolors.teal100,
          pointBorderWidth: 1,
          // pointHoverRadius: 5,
          pointHoverBackgroundColor: muicolors.teal100,
          pointHoverBorderColor: muicolors.teal300,
          pointHoverBorderWidth: 2,
          // pointRadius: 1,
          pointHitRadius: 20,
          type: 'line',
          data: data2
        },
        {
          label: json.StrategyParams.Product.Symbol + ' Price',
          yAxisID: 'y-axis-1',
          fill: false,
          backgroundColor: convertHex(muicolors.amber500,10),
          borderColor: muicolors.amber800,
          borderWidth: 1,
          hoverBackgroundColor: muicolors.amber100,
          hoverBorderColor: muicolors.amber300,
          type: 'line',
          data: data3
        }
      ]
    };

    const seriesHV = {
      label: 'HV' + json.StrategyParams.HVPeriod,
      yAxisID: 'y-axis-2',
      fill: false,
      backgroundColor: convertHex(muicolors.brown500,10),
      borderColor: muicolors.brown800,
      borderWidth: 1,
      hoverBackgroundColor: muicolors.brown100,
      hoverBorderColor: muicolors.brown300,
      type: 'line',
      data: data4
    };

    if( json.MonthlyHV != null && json.StrategyParams.HVPeriod > 0 ) {
      bar.datasets.push(seriesHV);
    }

    const seriesIV = {
      label: 'IV' + json.StrategyParams.IVPeriod,
      yAxisID: 'y-axis-2',
      fill: false,
      backgroundColor: convertHex(muicolors.blueGrey500,10),
      borderColor: muicolors.blueGrey800,
      borderWidth: 1,
      hoverBackgroundColor: muicolors.blueGrey100,
      hoverBorderColor: muicolors.blueGrey300,
      type: 'line',
      data: data5
    };

    if( json.MonthlyIV != null && json.StrategyParams.IVPeriod ) {
      bar.datasets.push(seriesIV);
    }


    const barOpts = {
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            drawOnChartArea: false,
          }
        }],
        yAxes: [{
          position: 'left',
          'id': 'y-axis-0',
          ticks: {
            beginAtZero: true,
            maxTicksLimit: maxticks,
            stepSize: 1000,
            max: chartmax
          }
        },
        {
          position: 'right',
          'id': 'y-axis-1',
          ticks: {
            beginAtZero: false,
            maxTicksLimit: maxticks,
            stepSize: 10,
            max: chartmax
          }
        }]
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        }
      }
    };

    this.setState( {monthlyPnl: json.MonthlyPNL } );
    this.setState( {mainChart: bar} );
    this.setState( {mainChartOpts: barOpts} );

  }



  switchChartRes(reso)
  {
    switch(reso)
    {
      case 'day':

        break;
      case 'month':
        break;
      case 'year':
        break;
      default:
        break;
    }
  }

  componentWillMount()
  {
    const { hasAccessToken } = this.props.auth;
    if( hasAccessToken() ) {
      // we're loading a backtest for an unauthenticated user... flag this throughout app
      this.setState( {unauthuser: false} );
    }

    this.setupBranch();

    // why do we check auth again here?  we do this in full...
    // const { isAuthenticated } = this.props.auth;
    // if( !isAuthenticated() )
    // {
    //   // if we're processing a branch link then allow...
    //   if( !this.props.match.url.includes('branch') ) {
    //     this.props.history.push('/');
    //   }
    //   else {
    //     console.log('branch bypass authenticated user');
    //     // set inability to run backtests etc and show call to action sign up
    //   }

    // }

  }

  componentDidMount()
  {
    // const { isAuthenticated } = this.props.auth;
    // if( !isAuthenticated() )
    // {
    //   this.props.history.push('/');
    //   return;
    // }

    // don't need to do any loading and stuff if user is visiting / viewing a backtest
    if( this.state.unauthuser ) {
      console.log('unauth user... skipping load');
      return;
    }

    this.setState({loadingBT: true, snacktext: "Welcome! Give us a moment while we set everything up..." }); // using this to blur screen for first load
    this.handleFirstLoad();

    // since these are global vars not state... switch this maybe
    tradeTableData.length = 0;
    tradeDataShown.length = 0;
    tradeTableCols.length = 0;

    var b64 = localStorage.getItem('bt');
    if( b64 ) {
      console.log('preload bt FOUND:');
      var raw = atob(b64);
      var btraw = JSON.parse(raw);
      console.log('historyID:', btraw.historyID);
      console.log('rawObj:', btraw.rawObj);

      rawHistory[btraw.historyID] = btraw.rawObj;
      this.loadPastBacktest(btraw.historyID);
      localStorage.removeItem('bt');
    }
    else {
      console.log('preload bt NOT found:');
    }

    window.fcWidget.track('React Page Visit', {
      page: 'Dashboard',
    });
  }

  componentWillReceiveProps(nextProps)
  {
    // console.log('cwrp drawerOpen', nextProps.drawerOpen);
    // if( nextProps.drawerOpen !== this.state.draweropen )
    // {
    //   console.log("dashboard: componentWillReceiveProps: handleHistoryToggle");
    //   this.handleHistoryToggle();
    // }
  }

  setupBranch()
  {
    // init Branch
    if (process.env.NODE_ENV === 'development') {
      console.log("branch init development mode!");
      branch.init('key_test_neqXrN3pvlhIdVao2rqTCnfmByiIt6b2', this.parseBranchLink );
      branchURLBase = "http://localhost:3000";
    }
    else {
      branch.init('key_live_get0rG8dshfHhMpn5qpS4ialDEgLE0bI', this.parseBranchLink );
      branchURLBase = "https://backtest.io";
    }

  }

  parseBranchLink(err, data)
  {
    console.log('parseBranchLink', err, data);

    if( data && data.data_parsed ) {
      console.log('data_parsed:', data.data_parsed);
      console.log('loading shared bt:', data.data_parsed.historyID );
      this.loadSharedBT(data.data_parsed.historyID);
    } else {
      console.log('parseBranchLink NULL objects -----');
    }

    // if( !this.state.unauthuser ) {
    //   // if this is the first time this new user has "opened" the dashboard, referrer will get credits
    //   branch.track( "open" );
    // }
  }

  generateBTLink(hist)
  {
    // only logged in users should be able to generate links for their own backtests
    if( this.state.unauthuser ) {
      return;
    }

    var sub = '';
    var name = '';
    const { userProfile, getProfile } = this.props.auth;
    if (!userProfile) {
      getProfile((err, profile) => {
        console.log('branch 1 generateBTLink - userProfile:', this.props.auth.userProfile);
        sub = profile.sub;
        name = profile.name;
      });
    } else {
      console.log('branch 2 generateBTLink - userProfile:', this.props.auth.userProfile);
      sub = userProfile.sub;
      name = userProfile.name;
    }

    console.log('generating branch link for', hist.key, hist.symbol, hist.strat);
    linkQueue.enqueue(hist);
    console.log('queue length:', linkQueue.getLength());

    branch.link({
      tags: [ 'backtest', hist.symbol, hist.strat ],
      channel: 'twitter',
      feature: 'dashboard',
      // stage: 'new user',
      data: {
          historyID: hist.key,
          creatorID: sub,
          creatorName: name,
          // plan: 'advanced',
          "$canonical_identifier": "btio/history/" + hist.key.toString(),
          "$canonical_url": branchURLBase + "/bt/" + hist.key.toString(),
          "$fallback_url": branchURLBase + "/bt",
          '$web_only': true,
          '$og_title': this.state.helmetTitle,
          '$og_description': 'Check out this backtest\'s performance & metrics at backtest.io',
      }
    }, this.updateBTLink );
  }

  updateBTLink(err, link)
  {
    console.log('branch link callback', err, link);

    if( err ) {
      console.log('branch error generating link', err);
      return;
    }

    // branch implements a command queue so responses should come in the exact order as called
    var hist = linkQueue.dequeue();
    console.log('history object link generated', hist);
    hist.link = link; // will this update the object stored in the historyList tho?
    console.log('historyList updated?', historyList);

    const formPayload = {
      histid: hist.key,  // we're not loading, we're hiding
      link: link,
      token: localStorage.getItem('token'),
    };

    // auth info
    const { getAccessToken } = this.props.auth;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/UpdateBacktestLink`;
    var token = getAccessToken();
    const headers = { 'Authorization': `Bearer ` + token }

    var config = {
      'headers': headers,
      'params': formPayload
    }

    console.log('config', config);

    axios.get(API_URL, config)
      .then( function(response) {
        if( response.status === 200 )
        {
          console.log("updateBTLink: success", link);
        }
        else {
          console.log("updateBTLink: not ok response: ", response);
        }
      })
      .catch( function(error) {
        console.log('updateBTLink: There has been a problem with your fetch operation: ' + error.message);
        LogRocket.captureException(error, {
          tags: {
            page: 'Dashboard',
            action: 'updateBTLink'
          },
        });
      });
  }

  loadSharedBT(historyID)
  {
    console.log('branch loadSharedBT', historyID);

    if( historyID > 0 )
    {
      console.log('loading shared backtest:', historyID);
      this.setState({ loadShared: historyID });
      this.setState({ snacktext: 'Give us a few seconds, we\'re loading your backtest...', loadingBT: true }, () => console.log('snacktext:', this.state.snacktext));

      const formPayload = {
        histid: historyID,
        token: localStorage.getItem('token')  // may not be set if user is not logged in (viewing publicly)
      };

      // no auth needed for this endpoint
      // // auth info
      // const { getAccessToken } = this.props.auth;
      const API_URL = `${process.env.REACT_APP_API_BASE_URL}/LoadSharedBacktest`;
      // var token = getAccessToken();
      // //console.log('token: ' + token );
      // const headers = { 'Authorization': `Bearer ` + token }
      // //console.log('headers: ', headers );

      var config = {
        // 'headers': headers,
        'params': formPayload
      }

      console.log('config', config);

      axios.get(API_URL, config)
        .then( function(response) {
          // console.log('response status: ', response.status);
          // console.log('response:', response);
          if( response.status === 200 )
          {
            if( response.data.length == 0 ) {
              console.log('branch => cannot view private backtest');
            }

            return response.data;
          }
          else {
            var contentType = response.headers.get("content-type");
            if(contentType && contentType.indexOf("application/json") !== -1) {
              console.log(response.data);
            }
            else {
              console.log("LoadSharedBacktest: Oops, we haven't got JSON!", response);
            }
          }
          console.log("LoadSharedBacktest: not ok response: ", response);
          if( response.status === 504 ) {
            // we had an endpont time out... api call is going longer than 30 seconds
            console.log("LoadSharedBacktest: endpoint timeout");
            return "";
          }
          else {
            throw new Error('LoadSharedBacktest: Network response was not OK.');
          }
        })
        .then( this.populateBTHistory )
        .catch( function(error) {
          console.log('LoadSharedBacktest: ', error);
          console.log('LoadSharedBacktest: There has been a problem with your fetch operation: ', error.message);
          LogRocket.captureException(error, {
            tags: {
              page: 'Dashboard',
              action: 'LoadSharedBacktest'
            },
          });
        });
      }
      else {
        console.log('branch history not present', historyID);
      }
  }

  showBranchStatus()
  {
    this.setState({
      viewOnlyTitle: 'Private Backtest',
      viewOnlyText: 'We\'re sorry but the creator of this backtest has marked this as private.  Please sign up and explore the platform for free!',
      viewOnlyModal: true,
      snackopen: false,
    }, () => this.alertprivateref.handleOpen());
  }

  openShareMenu(historyID, event)
  {
    // This prevents ghost click.
    event.preventDefault();

    var histObj = historyList.filter(hist => hist.key === historyID);
    console.log('preparing share menu for ', historyID, histObj);

    var xshareURL = histObj[0].link;
    var xshareText = "Check out this " + histObj[0].symbol + " " + histObj[0].strat + " backtest!";

    this.shareref.handleClick(event, xshareURL, xshareText );
  }

  shareCopyDone(text, result)
  {
    console.log('copied link', text, result);
    this.setState({ snacktext: 'Your backtest link has been copied to clipboard.', snackAutoHide: (5*1000), snackopen: true }, () => console.log('snacktext:', this.state.snacktext));
  }

  handleFirstLoad()
  {
    var newUser = localStorage.getItem('new_user');
    console.log('newUser:', newUser);

    if( newUser == null ) {
      console.log("storage not set... delaying load", newUser);

      setTimeout(() => {
        this.handleFirstLoad();
      }, 1000);

      return;
    }
    this.setState({ loadingBT: false });

    // setTimeout(() => {
    if( historyList.length == 0 ) {
      this.getUserHistory();
    }
    // }, 1000);

    console.log('BT_WEBSOCKET_ADDRESS: ' + constants.BT_WEBSOCKET_ADDRESS);
    const ws_options = {
      debug: true,
      startClosed: true,
    };
    this.rws = new ReconnectingWebSocket(constants.BT_WEBSOCKET_ADDRESS, [], ws_options);

    this.rws.addEventListener('open', () => {
        console.log('ws connected');
        // link logged in user with this connection
        const wsPayload = {
          link: true,
          token: localStorage.getItem('token'),
        };
        this.rws.send(JSON.stringify(wsPayload));
    });

    this.rws.addEventListener('close', () => {
      console.log('ws disconnected');
   });

    this.rws.addEventListener('message', (msg) => {

      console.log('ws message received: ' + msg.data );
      btResultQueue.enqueue(JSON.parse(msg.data));
      this.finishBacktest();
    });

    this.rws.addEventListener('error', () => {
      console.log('ws error');
    });

    console.log("fcWidget isOpen:", window.fcWidget.isOpen());
    console.log("fcWidget isInitialized:", window.fcWidget.isInitialized());
    console.log("fcWidget isLoaded:", window.fcWidget.isLoaded());

    window.fcWidget.init({
      token: "59366a55-7227-4b4c-a54b-78d3a53dcf04",
      host: "https://wchat.freshchat.com",
      tags: ["member"],
      // externalId: "",
      faqTags: {
        tags: ['member'],
        filterType: 'category' //Or filterType: 'article'
      },
      open: false,
      config: {
        showFAQOnOpen: false,
        hideFAQ: false,
        agent: {
          hideName: false,
          hidePic: false,
          hideBio: false,
        },
        headerProperty: {
          // fontName: 'Century Gothic, Muli',
          // fontUrl: 'https://fonts.googleapis.com/css?family=Century+Gothic'
        //   backgroundColor: '#FFFF00',
        //   foregroundColor: '#333333',
        //   backgroundImage: 'https://wchat.freshchat.com/assets/images/texture_background_1-bdc7191884a15871ed640bcb0635e7e7.png'
        },
        content: {
          placeholders: {
            search_field: 'Search Knowledgebase',
            // reply_field: 'Reply in my widget',
            // csat_reply: 'Reply for csat'
          },
          actions: {
          //   csat_yes: 'Yes, Resolved',
          //   csat_no: 'No, Resolved',
          //   push_notify_yes: 'Notify',
          //   push_notify_no: 'No Notify',
            tab_faq: 'Knowledgebase',
            tab_chat: 'Chat',
          //   csat_submit: 'Submit Review Comments'
          },
          headers: {
          //   chat: 'Chat with us',
          //   chat_help: 'Reach out to us if you have any questions',
            faq: 'Knowledgebase',
            faq_help: 'Browse our help',
          //   faq_not_available: 'No FAQs',
          //   faq_search_not_available: 'No FAQs available for {{query}}',
          //   faq_useful: 'FAQs are useful',
          //   faq_thankyou: 'Thanks for feedback',
          //   faq_message_us: 'Message Us For FAQs',
          //   push_notification: 'you want to not miss conversation',
          //   csat_question: 'Did we address your question?',
          //   csat_yes_question: 'Did we resolve the conversation?',
          //   csat_no_question: 'Did we not resolve the conversation?',
          //   csat_thankyou: 'Thanks for the response',
          //   csat_rate_here: 'Give your rating here',
            channel_response: {
              offline: 'We are currently away. Leave us a message!',
              online: {
                minutes: {
                  one: "You should get a reply in a minute",
                  more: "You should get a reply within {!time!} minutes"
                },
                hours: {
                  one: "You should get a reply in an hour",
                  more: "You should get a reply within {!time!} hours",
                }
              }
            }
          }
        }
      }
    });

    // if( window.fcWidget.isInitialized() ) {

    //   window.fcWidget.setFaqTags({
    //     // For ex: ["public", "paid"]
    //     tags : ['member'],
    //     //For articles, the below value should be article.
    //     //For article category, the below value should be category.
    //     filterType: 'category' //Or filterType: 'category'
    //   });

    // }

  }

  // trade table stuff
  handleNextPageClick()
  {
    console.log('in handleNextPageClick');
    var startRow = this.state.currentTradePage * this.state.numRowsShown;
    if( (startRow+1) > tradeTableData.length ) {
      return;
    }
    var endRow = Math.min( startRow+this.state.numRowsShown, tradeTableData.length );
    console.log('startRow:', startRow, 'endRow:', endRow, 'total:', tradeTableData.length);

    tradeDataShown = tradeTableData.slice(startRow, endRow);
    this.setState({ currentTradePage: this.state.currentTradePage + 1 }, () => console.log('currentTradePage:', this.state.currentTradePage));
  }

  handlePrevPageClick()
  {
    console.log('in handlePrevPageClick');
    var startRow = (this.state.currentTradePage-2) * this.state.numRowsShown;
    if( startRow < 0 ) {
      return;
    }
    var endRow = (this.state.currentTradePage-1) * this.state.numRowsShown;
    console.log('startRow:', startRow, 'endRow:', endRow, 'total:', tradeTableData.length);

    tradeDataShown = tradeTableData.slice(startRow, endRow);
    this.setState({ currentTradePage: this.state.currentTradePage - 1 }, () => console.log('currentTradePage:', this.state.currentTradePage));
  }

  handleRowSizeChange(idx, val)
  {
    console.log('in handleRowSizeChange', idx, val);

    var endRow = Math.min( val, tradeTableData.length );
    console.log('startRow:', 0, 'endRow:', endRow, 'total:', tradeTableData.length);

    tradeDataShown = tradeTableData.slice(0, endRow);

    this.setState({ numRowsShown: val }, () => console.log('numRowsShown:', this.state.numRowsShown));
    this.setState({ currentTradePage: 1 }, () => console.log('currentTradePage:', this.state.currentTradePage));
  }

  handleTooltipToggle(e, isToggled) {
    this.setState({ tooltipToggleState: isToggled }, () => console.log('tooltipToggleState:', this.state.tooltipToggleState));
  }

  handleTooltip(elem) {
    if( !this.state.tooltipToggleState ) {
      return;
    }

    tooltipOpenMap[elem] = !tooltipOpenMap[elem];
    this.setState({ tooltipMap: tooltipOpenMap }, () => console.log('tooltipMap[' + elem + ']:', this.state.tooltipMap[elem]) );
  }


  render() {
    const spinner = <img src={loading} alt="loading..."/>;
    const spinnerBt = <span>{this.state.snacktext}<br/><img src={loading} alt=""/></span>;

    return (
      <div className="animated fadeIn">
        <Helmet><title>backtest.io | {this.state.helmetTitle}</title></Helmet>
        <div className="row">
          <div className="col-sm-6 col-md-6 col-lg-6">
            <h2 style={style.titlefont}>Backtest Parameters</h2>
          </div>
          <div style={style.alignright} className="col-sm-2 col-md-2 col-lg-2">
          </div>
          <div style={style.trialNote} className="col-sm-4 col-md-4 col-lg-4">
            (Shaded blocks denote free trial features normally in the Advanced plan)
          </div>
        </div>
        <div className="row text-center">
          <h6>{this.state.errormsg}</h6>
        </div>

        <BTFormTabbed submitFunc={this.fetchBacktest} token={this.state.jwtToken} ref={instance => { this.state.btform = instance; }}  {...this.props} />

        <MuiThemeProvider>
          <Snackbar
            open={this.state.snackopen}
            message={this.state.snacktext}
            autoHideDuration={this.state.snackAutoHide}
            onRequestClose={this.handleSnackClose}
            action={this.state.snackAction}
            onActionClick={this.handleSnackAction}
          />
        </MuiThemeProvider>

        {
          !isMobile && (
          // {/* <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme)}> */}
          <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, btioDarkTheme)}>
            <Drawer containerClassName="drawerstyle" open={this.state.draweropen} width={250} >
              <MenuItem></MenuItem>
              {/* <MenuItem>Logout</MenuItem> */}
              <div style={style.historyfont}>History
                <IconButton onClick={this.getUserHistory} tooltip="Refresh" touch={true} tooltipPosition="bottom-left"><RefreshIcon /></IconButton>
              </div>

              {historyList.map( hist =>
                <div key={hist.key} id={"histcard" + hist.key}>
                  <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, btioDarkTheme)}>
                  <Paper zDepth={5} >
                    <Card containerStyle={hist.running ? style.cardstyle_inprogress : (hist.newentry ? style.glowstyle : style.cardstyle)} expanded={(hist.running || hist.newentry) ? true : historyList.filter(hfil => hfil.key === hist.key).expanded} onExpandChange={this.handleExpandChange.bind(this,hist.key)}>
                      <CardHeader actAsExpander={true} showExpandableButton={true} style={{padding:'0px'}}>
                        <div className="row">
                          <div className="col-sm-5 col-md-5 col-lg-5">
                            <CardTitle title={hist.symbol} subtitle={hist.strat} />
                          </div>
                          {hist.running ?
                          <div className="col-sm-7 col-md-7 col-lg-7">
                            <br/>
                            <span style={style.subtitle_inprogress}>Running...</span>
                          </div> :
                          <div className="col-sm-7 col-md-7 col-lg-7">
                            {/* <IconButton iconStyle={style.iconstyle} iconClassName="icon-magnifier" tooltip="View" touch={true} tooltipPosition="bottom-right" onClick={this.loadPastBacktest.bind(this,hist.key)} /> */}
                            <IconButton iconStyle={style.iconstyle} tooltip="View" touch={true} tooltipPosition="bottom-right" onClick={this.loadPastBacktest.bind(this,hist.key)}><PageViewIcon/></IconButton>
                            {/* <IconButton iconStyle={style.iconstyle} tooltip="Archive" touch={true} tooltipPosition="bottom-right" onClick={this.hidePastBacktest.bind(this,hist.key)}><ArchiveIcon/></IconButton> */}
                            <IconButton iconStyle={style.iconstyle} tooltip="Share" touch={true} tooltipPosition="bottom-right" onClick={this.openShareMenu.bind(this,hist.key)}><ShareIcon/></IconButton>

                            {/* <IconButton iconStyle={style.iconstyle} tooltip="View" touch={true} tooltipPosition="bottom-right" onClick={this.loadPastBacktest.bind(this,hist.key)}><ZoomIcon /></IconButton>
                            <IconButton iconStyle={style.iconstyle} tooltip="Hide" touch={true} tooltipPosition="bottom-right" onClick={this.hidePastBacktest.bind(this,hist.key)}><CloseIcon /></IconButton> */}

                            <ShareComp ref={(shareref) => { this.shareref = shareref; }} onCopy={this.shareCopyDone} />

                          </div>}
                        </div>
                      </CardHeader>
                      <CardText style={style.cardtextstyle} expandable={true}>
                        <div className="row">
                          <div className="col-sm-1 col-md-1 col-lg-1"/>
                          <div className="col-sm-11 col-md-11 col-lg-11 text-muted">
                            {hist.dates}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 col-md-6 col-lg-6">
                            <div className="callout callout-info">
                              <small className="text-muted">Net PNL</small><br/>
                              {hist.running ? <HistoryTextLoader /> : <strong className="h6">${hist.netpnl}</strong>}
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6">
                            <div className="callout callout-success">
                              <small className="text-muted">Net ROC</small><br/>
                              {hist.running ? <HistoryTextLoader /> : <strong className="h6">{hist.netroc}%</strong>}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6 col-md-6 col-lg-6">
                            <div className="callout callout-warning">
                              <small className="text-muted">Trades</small><br/>
                              {hist.running ? <HistoryTextLoader /> : <strong className="h6">{hist.trades}</strong>}
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6">
                            <div className="callout">
                              <small className="text-muted">Sharpe</small><br/>
                              {hist.running ? <HistoryTextLoader /> : <strong className="h6">{hist.dsharpe}</strong>}
                            </div>
                          </div>
                        </div>
                      </CardText>
                    </Card>
                  </Paper>
                  </MuiThemeProvider>
                </div>
              )}

              {
                (historyList.length > 0) && (!this.state.unauthuser) &&
                (
                  <div style={style.historyfont} className="handcursor" onClick={this.gotoHistory}>View All <ArrowForwardIcon/></div>
                )
              }

            </Drawer>
          </MuiThemeProvider>
          )
        }

        {/* <div className="row">
                    <div className="col-sm-6 col-lg-3">
                    <div className="card card-inverse card-primary">
                    <div className="card-block pb-0">
                    <div className="btn-group float-right">
                    <Dropdown isOpen={this.state.card1} toggle={() => { this.setState({ card1: !this.state.card1 }); }}>
                    <button onClick={() => { this.setState({ card1: !this.state.card1 }); }} className="btn btn-transparent active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.card1}>
                      <i className="icon-settings"></i>
                    </button>
                    <DropdownMenu>
                      <DropdownItem>Action</DropdownItem>
                      <DropdownItem>Another action</DropdownItem>
                      <DropdownItem>Something else here</DropdownItem>
                    </DropdownMenu>
                    </Dropdown>
                    </div>
                    <h4 className="mb-0">9.823</h4>
                    <p>Members online</p>
                    </div>
                    <div className="chart-wrapper px-3">
                    <Line data={cardChartData1} options={cardChartOpts1} height={70}/>
                    </div>
                    </div>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                    <div className="card card-inverse card-info">
                    <div className="card-block pb-0">
                    <div className="btn-group float-right">
                    <Dropdown isOpen={this.state.card2} toggle={() => { this.setState({ card2: !this.state.card2 }); }}>
                    <button onClick={() => { this.setState({ card2: !this.state.card2 }); }} className="btn btn-transparent active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.card2}>
                      <i className="icon-settings"></i>
                    </button>
                    <DropdownMenu>
                      <DropdownItem>Action</DropdownItem>
                      <DropdownItem>Another action</DropdownItem>
                      <DropdownItem>Something else here</DropdownItem>
                    </DropdownMenu>
                    </Dropdown>
                    </div>
                    <h4 className="mb-0">9.823</h4>
                    <p>Members online</p>
                    </div>
                    <div className="chart-wrapper px-3">
                    <Line data={cardChartData2} options={cardChartOpts2} height={70}/>
                    </div>
                    </div>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                    <div className="card card-inverse card-warning">
                    <div className="card-block pb-0">
                    <div className="btn-group float-right">
                    <Dropdown isOpen={this.state.card3} toggle={() => { this.setState({ card3: !this.state.card3 }); }}>
                    <button onClick={() => { this.setState({ card3: !this.state.card3 }); }} className="btn btn-transparent active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.card3}>
                      <i className="icon-settings"></i>
                    </button>
                    <DropdownMenu>
                      <DropdownItem>Action</DropdownItem>
                      <DropdownItem>Another action</DropdownItem>
                      <DropdownItem>Something else here</DropdownItem>
                    </DropdownMenu>
                    </Dropdown>
                    </div>
                    <h4 className="mb-0">9.823</h4>
                    <p>Members online</p>
                    </div>
                    <div className="chart-wrapper">
                    <Line data={cardChartData3} options={cardChartOpts3} height={70}/>
                    </div>
                    </div>
                    </div>

                    <div className="col-sm-6 col-lg-3">
                    <div className="card card-inverse card-danger">
                    <div className="card-block pb-0">
                    <div className="btn-group float-right">
                    <Dropdown isOpen={this.state.card4} toggle={() => { this.setState({ card4: !this.state.card4 }); }}>
                    <button onClick={() => { this.setState({ card4: !this.state.card4 }); }} className="btn btn-transparent active dropdown-toggle p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded={this.state.card4}>
                      <i className="icon-settings"></i>
                    </button>
                    <DropdownMenu>
                      <DropdownItem>Action</DropdownItem>
                      <DropdownItem>Another action</DropdownItem>
                      <DropdownItem>Something else here</DropdownItem>
                    </DropdownMenu>
                    </Dropdown>
                    </div>
                    <h4 className="mb-0">9.823</h4>
                    <p>Members online</p>
                    </div>
                    <div className="chart-wrapper px-3">
                    <Bar data={cardChartData4} options={cardChartOpts4} height={70}/>
                    </div>
                    </div>
                    </div>
        </div> */}

        <div id="perfmarker"></div>
        <MuiThemeProvider>
          <Card>
            <CardTitle title="Performance" subtitle={this.state.startDate + '➜' + this.state.endDate} />
            <CardText>
              <div className="chart-wrapper" style={{height: 450 + 'px', marginTop: 0 + 'px'}}>
                {/* <Line data={this.state.mainChart} options={this.state.mainChartOpts} height={300}/> */}
                <Bar data={this.state.mainChart}
                  options={{
                    maintainAspectRatio: false,
                    scales: {
                      yAxes: [{
                        position: 'left',
                        'id': 'y-axis-0',
                        scaleLabel: {
                          display: true,
                          labelString: 'PNL'
                        }
                      },
                      {
                        position: 'right',
                        'id': 'y-axis-1',
                        scaleLabel: {
                          display: true,
                          labelString: 'Price'
                        }
                      },
                      {
                        position: 'right',
                        'id': 'y-axis-2',
                        scaleLabel: {
                          display: true,
                          labelString: 'HV/IV'
                        }
                      }]
                    }
                  }}
                />
              </div>
              <Divider />
              <div className="figure-caption pt-2">
                Note: Click on any of the data labels in the legend to toggle the data series
              </div>
            </CardText>
          </Card>
        </MuiThemeProvider>
        <br/>

        <div className="row">
          <div className="col-md-12">
            <MuiThemeProvider>
              <Card>
                <CardTitle title={
                  <div className="row">
                    <div className="col-md-9">
                      Metrics
                    </div>
                    <div className="col-md-3">
                      <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                      <Toggle
                          label="Show Tooltips"
                          defaultToggled={true}
                          labelPosition="right"
                          style={style.toggle}
                          onToggle={this.handleTooltipToggle}
                          toggled={this.state.tooltipToggleState}
                          labelStyle={style.toggleLabel}
                        />
                      </MuiThemeProvider>
                    </div>
                  </div>
                }/>
                <CardText>
                  <div style={style.reduceCardMargin25} className="row">
                    <div className="col-sm-12 col-lg-4">
                      <div className="row">
                        <div id="netpnldiv" className="col-sm-6">
                          <div className="callout callout-info">
                            <small className="text-muted">Net PNL</small><br/>
                            <strong className="h4">${ formatNumber(this.state.netPnl,2) }</strong>
                            <div className="chart-wrapper">
                              <canvas id="sparkline-chart-1" width="100" height="30"></canvas>
                            </div>
                          </div>
                        </div>
                        <div id="commishdiv" className="col-sm-6">
                          <div className="callout callout-danger">
                            <small className="text-muted">Commission</small><br/>
                            <strong className="h4">${formatNumber(this.state.commish,2)}</strong>
                            <div className="chart-wrapper">
                              <canvas id="sparkline-chart-2" width="100" height="30"></canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-0"/>
                      <ul className="horizontal-bars type-2">
                        <li id="winningtradesdiv">
                          <i className="fa fa-plus"></i>
                          <span className="title">Winning Trades</span>
                          <span className="value">{this.state.numWinners} <span className="text-muted">({formatNumber(this.state.winPercentage,0)}%)</span></span>
                          <div className="bars">
                            <Progress className="progress-xs" color="success" value={this.state.winPercentage} />
                          </div>
                        </li>
                        <li id="losingtradesdiv">
                          <i className="fa fa-minus"></i>
                          <span className="title">Losing Trades</span>
                          <span className="value">{this.state.numLosers} <span className="text-muted">({formatNumber(this.state.losePercentage,0)}%)</span></span>
                          <div className="bars">
                            <Progress className="progress-xs" color="danger" value={this.state.losePercentage} />
                          </div>
                        </li>
                        {/* <li className="divider"></li> */}
                        <br/>
                        <li id="maxrunningdiv">
                          {/* <i className="fa fa-arrow-circle-o-up"></i> */}
                          <i><TrendingUpIcon /></i>
                          <span className="title">Max Running PNL</span>
                          <span className="value">${formatNumber(this.state.maxRunPnl,2)}</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="success" value={this.state.maxRunPnl} max={Math.max(this.state.maxRunPnl,Math.abs(this.state.minRunPnl))} />
                          </div>
                        </li>
                        <li id="minrunningdiv">
                          {/* <i className="fa fa-arrow-circle-o-down"></i> */}
                          <i><TrendingDownIcon /></i>
                          <span className="title">Min Running PNL</span>
                          <span className="value">${formatNumber(this.state.minRunPnl,2)}</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="danger" value={Math.abs(this.state.minRunPnl)} max={Math.max(this.state.maxRunPnl,Math.abs(this.state.minRunPnl))} />
                          </div>
                        </li>
                        {/* <li className="divider"></li> */}
                        <br/>
                        <li id="annualretdiv">
                          <i className="fa fa-line-chart"></i>
                          <span className="title">Annualized Return</span>
                          <span className="value">{formatNumber(this.state.annualizedRet,2)}%</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="success" value={this.state.annualizedRet} />
                          </div>
                        </li>
                        <li id="annualvoldiv">
                          <i className="fa fa-area-chart"></i>
                          <span className="title">Annualized Volatility</span>
                          <span className="value">{formatNumber(this.state.annualizedVol,2)}%</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="warning" value={this.state.annualizedVol} />
                          </div>
                        </li>
                        {/* <MuiThemeProvider>
                        <Card>
                          <CardHeader
                            title={
                              <div>
                                <span className="title">IntraTrade Stats</span>
                              </div>
                            }
                            actAsExpander={true}
                            showExpandableButton={true}
                          />
                          <CardText expandable={true}>
                          <li>
                            <i className="icon-social-twitter"></i>
                            <span className="title"># Winning Trades That Lost</span>
                            <span className="value">{this.state.grossRom}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="warning" value={this.state.grossRom} />
                            </div>
                          </li>
                          <li>
                            <i className="icon-social-linkedin"></i>
                            <span className="title"># Trades Never A Winner</span>
                            <span className="value">{this.state.avgHoldPeriod}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="warning" value={this.state.avgHoldPeriod} />
                            </div>
                          </li>
                          </CardText>
                        </Card>
                        </MuiThemeProvider>*/}
                        {/* <li className="divider"></li> */}
                        <br/>
                        <div id="avgnetromdiv">
                        <MuiThemeProvider>
                        <Card>
                          <CardHeader
                            title={
                              <div>
                                <span className="title">Average Net ROM</span>
                              </div>
                            }
                            actAsExpander={true}
                            showExpandableButton={true}
                          />
                          <CardText expandable={true}>
                          <li>
                            {/* <i className="fa fa-play"></i> */}
                            <span className="title">All Trades</span>
                            <span className="value">{formatNumber(this.state.avgNetRom,2)}%</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="info" value={this.state.avgNetRom} max={Math.max(this.state.avgNetRomW,Math.abs(this.state.avgNetRomL))} />
                            </div>
                          </li>
                          <li>
                            {/* <i className="fa fa-plus"></i> */}
                            <span className="title">Winners</span>
                            <span className="value">{formatNumber(this.state.avgNetRomW,2)}%</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="success" value={this.state.avgNetRomW} max={Math.max(this.state.avgNetRomW,Math.abs(this.state.avgNetRomL))} />
                            </div>
                          </li>
                          <li>
                            {/* <i className="fa fa-minus"></i> */}
                            <span className="title">Losers</span>
                            <span className="value">{formatNumber(this.state.avgNetRomL,2)}%</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="danger" value={Math.abs(this.state.avgNetRomL)} max={Math.max(this.state.avgNetRomW,Math.abs(this.state.avgNetRomL))} />
                            </div>
                          </li>
                          </CardText>
                        </Card>
                        </MuiThemeProvider>
                        </div>
                        {/* <li className="divider"></li> */}
                        {/* <br/>
                        <MuiThemeProvider>
                        <Card>
                          <CardHeader
                            title={
                              <div>
                                <span className="title">Average HV Entry</span>
                              </div>
                            }
                            actAsExpander={true}
                            showExpandableButton={true}
                          />
                          <CardText expandable={true}>
                          <li>
                            <i className="fa fa-play"></i>
                            <span className="title">All Trades</span>
                            <span className="value">{this.state.avgHVEntry}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="info" value={this.state.avgHVEntry} />
                            </div>
                          </li>
                          <li>
                            <i className="fa fa-plus"></i>
                            <span className="title">Winners</span>
                            <span className="value">{this.state.avgHVEntryW}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="success" value={this.state.avgHVEntryW} />
                            </div>
                          </li>
                          <li>
                            <i className="fa fa-minus"></i>
                            <span className="title">Losers</span>
                            <span className="value">{this.state.avgHVEntryL}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="danger" value={this.state.avgHVEntryL} />
                            </div>
                          </li>
                          </CardText>
                        </Card>
                        </MuiThemeProvider> */}
                        {/* <li className="divider"></li> */}
                        {/* <br/>
                        <MuiThemeProvider>
                        <Card>
                          <CardHeader
                            title={
                              <div>
                                <span className="title">Average HV Exit</span>
                              </div>
                            }
                            actAsExpander={true}
                            showExpandableButton={true}
                          />
                          <CardText expandable={true}>
                          <li>
                            <i className="fa fa-play"></i>
                            <span className="title">All Trades</span>
                            <span className="value">{this.state.avgHVExit}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="info" value={this.state.avgHVExit} />
                            </div>
                          </li>
                          <li>
                            <i className="fa fa-plus"></i>
                            <span className="title">Winners</span>
                            <span className="value">{this.state.avgHVExitW}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="success" value={this.state.avgHVExitW} />
                            </div>
                          </li>
                          <li>
                            <i className="fa fa-minus"></i>
                            <span className="title">Losers</span>
                            <span className="value">{this.state.avgHVExitL}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="danger" value={this.state.avgHVExitL} />
                            </div>
                          </li>
                          </CardText>
                        </Card>
                        </MuiThemeProvider> */}
                        {/* <li className="divider"></li> */}
                        <br/>
                        <div id="marketvoldiv">
                        <MuiThemeProvider>
                        <Card>
                          <CardHeader
                            title={
                              <div>
                                <span className="title">Market Volatility</span>
                              </div>
                            }
                            actAsExpander={true}
                            showExpandableButton={true}
                          />
                          <CardText expandable={true}>
                          <li>
                            {/* <i className="fa fa-plus"></i> */}
                            <span className="title">Periods VIX Cash Backwardation</span>
                            <span className="value">{this.state.vixCashBackCount}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="info" value={this.state.vixCashBackCount} max={this.state.vixCashBackCount+this.state.vxFutBackCount} />
                            </div>
                          </li>
                          <li>
                            {/* <i className="fa fa-plus"></i> */}
                            <span className="title">Periods VX Futures Backwardation</span>
                            <span className="value">{this.state.vxFutBackCount}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="warning" value={this.state.vxFutBackCount} max={this.state.vixCashBackCount+this.state.vxFutBackCount} />
                            </div>
                          </li>
                          <br/>
                          <li>
                            {/* <i className="fa fa-plus"></i> */}
                            <span className="title">Days VIX Cash Backwardation</span>
                            <span className="value">{this.state.vixCashBackDays}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="info" value={this.state.vixCashBackDays} max={this.state.vixCashBackDays+this.state.vxFutBackDays} />
                            </div>
                          </li>
                          <li>
                            {/* <i className="fa fa-plus"></i> */}
                            <span className="title">Days VX Futures Backwardation</span>
                            <span className="value">{this.state.vxFutBackDays}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="warning" value={this.state.vxFutBackDays} max={this.state.vixCashBackDays+this.state.vxFutBackDays} />
                            </div>
                          </li>
                          </CardText>
                        </Card>
                        </MuiThemeProvider>
                        </div>

                        {/* <li className="divider text-center">
                          <button type="button" className="btn btn-sm btn-link text-muted" data-toggle="tooltip" data-placement="top" title="" data-original-title="show more"><i className="icon-options"></i></button>
                        </li> */}
                      </ul>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <div className="row">
                        <div id="tradesdiv" className="col-sm-6">
                          <div className="callout callout-warning">
                            <small className="text-muted">Trades</small><br/>
                            <strong className="h4">{this.state.trades}</strong>
                            <div className="chart-wrapper">
                              <canvas id="sparkline-chart-3" width="100" height="30"></canvas>
                            </div>
                          </div>
                        </div>
                        <div id="netrocdiv" className="col-sm-6">
                          <div className="callout callout-success">
                            <small className="text-muted">Net ROC</small><br/>
                            <strong className="h4">{formatNumber(this.state.netRoc,2)}%</strong>
                            <div className="chart-wrapper">
                              <canvas id="sparkline-chart-4" width="100" height="30"></canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-0"/>
                      <ul className="horizontal-bars type-2">
                        <li id="volumediv">
                          <i className="fa fa-bar-chart"></i>
                          <span className="title">Volume</span>
                          <span className="value">{this.state.volume}</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="info" value={this.state.volume} />
                          </div>
                        </li>
                        <li id="commishimpactdiv">
                          <i className="fa fa-money"></i>
                          <span className="title">Commission Impact</span>
                          <span className="value">{formatNumber(this.state.commishImpact,2)}%</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="warning" value={this.state.commishImpact} />
                          </div>
                        </li>
                        {/* <li className="divider"></li> */}
                        <br/>
                        <li id="maxwinnerdiv">
                          <i className="fa fa-level-up"></i>
                          <span className="title">Max Winner</span>
                          <span className="value">${formatNumber(this.state.maxWinner,2)}</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="success" value={this.state.maxWinner} max={Math.max(this.state.maxWinner,Math.abs(this.state.maxLoser))} />
                          </div>
                        </li>
                        <li id="maxloserdiv">
                          <i className="fa fa-level-down"></i>
                          <span className="title">Max Loser</span>
                          <span className="value">${formatNumber(this.state.maxLoser,2)}</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="danger" value={Math.abs(this.state.maxLoser)} max={Math.max(this.state.maxWinner,Math.abs(this.state.maxLoser))} />
                          </div>
                        </li>
                        {/* <li className="divider"></li> */}
                        <br/>
                        <li id="calmardiv">
                          <i className="fa fa-codiepie"></i>
                          <span className="title">Calmar Ratio</span>
                          <span className="value">{formatNumber(this.state.calmar,2)}</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="warning" value={this.state.calmar} max={10} />
                          </div>
                        </li>
                        <li id="sortinodiv">
                          <i className="fa fa-eercast"></i>
                          <span className="title">Monthly Sortino</span>
                          <span className="value">{formatNumber(this.state.mSortino,2)}</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="info" value={this.state.mSortino} max={10} />
                          </div>
                        </li>
                        {/* <MuiThemeProvider>
                        <Card>
                          <CardHeader
                            title={
                              <div>
                                <span className="title">Stats Here</span>
                              </div>
                            }
                            actAsExpander={true}
                            showExpandableButton={true}
                          />
                          <CardText expandable={true}>
                          <li>
                            <i className="fa fa-long-arrow-alt-right"></i>
                            <span className="title">All Trades</span>
                            <span className="value">{this.state.avgHoldPeriod}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="info" value={this.state.avgHoldPeriod} />
                            </div>
                          </li>
                          <li>
                            <i className="fa fa-plus"></i>
                            <span className="title">Winners</span>
                            <span className="value">{this.state.avgHoldPeriodW}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="success" value={this.state.avgHoldPeriodW} />
                            </div>
                          </li>
                          <li>
                            <i className="fa fa-minus"></i>
                            <span className="title">Losers</span>
                            <span className="value">{this.state.avgHoldPeriodL}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="danger" value={this.state.avgHoldPeriodL} />
                            </div>
                          </li>
                          </CardText>
                        </Card>
                        </MuiThemeProvider> */}
                        {/* <li className="divider"></li> */}
                        <br/>
                        <div id="avgtradepnldiv">
                        <MuiThemeProvider>
                        <Card>
                          <CardHeader
                            title={
                              <div>
                                <span className="title">Average Trade PNL</span>
                              </div>
                            }
                            actAsExpander={true}
                            showExpandableButton={true}
                          />
                          <CardText expandable={true}>
                          <li>
                            {/* <i className="fa fa-play"></i> */}
                            <span className="title">All Trades</span>
                            <span className="value">${formatNumber(this.state.avgTradePnl,2)}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="info" value={this.state.avgTradePnl} max={Math.max(this.state.avgTradePnlW,Math.abs(this.state.avgTradePnlL))} />
                            </div>
                          </li>
                          <li>
                            {/* <i className="fa fa-plus"></i> */}
                            <span className="title">Winners</span>
                            <span className="value">${formatNumber(this.state.avgTradePnlW,2)}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="success" value={this.state.avgTradePnlW} max={Math.max(this.state.avgTradePnlW,Math.abs(this.state.avgTradePnlL))} />
                            </div>
                          </li>
                          <li>
                            {/* <i className="fa fa-minus"></i> */}
                            <span className="title">Losers</span>
                            <span className="value">${formatNumber(this.state.avgTradePnlL,2)}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="danger" value={Math.abs(this.state.avgTradePnlL)} max={Math.max(this.state.avgTradePnlW,Math.abs(this.state.avgTradePnlL))} />
                            </div>
                          </li>
                          </CardText>
                        </Card>
                        </MuiThemeProvider>
                        </div>
                        {/* <li className="divider"></li> */}
                        {/* <br/>
                        <MuiThemeProvider>
                        <Card>
                          <CardHeader
                            title={
                              <div>
                                <span className="title">Average IV Entry</span>
                              </div>
                            }
                            actAsExpander={true}
                            showExpandableButton={true}
                          />
                          <CardText expandable={true}>
                          <li>
                            <i className="fa fa-play"></i>
                            <span className="title">All Trades</span>
                            <span className="value">{this.state.avgIVEntry}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="info" value={this.state.avgIVEntry} />
                            </div>
                          </li>
                          <li>
                            <i className="fa fa-plus"></i>
                            <span className="title">Winners</span>
                            <span className="value">{this.state.avgIVEntryW}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="success" value={this.state.avgIVEntryW} />
                            </div>
                          </li>
                          <li>
                            <i className="fa fa-minus"></i>
                            <span className="title">Losers</span>
                            <span className="value">{this.state.avgIVEntryL}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="danger" value={this.state.avgIVEntryL} />
                            </div>
                          </li>
                          </CardText>
                        </Card>
                        </MuiThemeProvider> */}
                        {/* <li className="divider"></li> */}
                        {/* <br/>
                        <MuiThemeProvider>
                        <Card>
                          <CardHeader
                            title={
                              <div>
                                <span className="title">Average IV Exit</span>
                              </div>
                            }
                            actAsExpander={true}
                            showExpandableButton={true}
                          />
                          <CardText expandable={true}>
                          <li>
                            <i className="fa fa-play"></i>
                            <span className="title">All Trades</span>
                            <span className="value">{this.state.avgIVExit}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="info" value={this.state.avgIVExit} />
                            </div>
                          </li>
                          <li>
                            <i className="fa fa-plus"></i>
                            <span className="title">Winners</span>
                            <span className="value">{this.state.avgIVExitW}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="success" value={this.state.avgIVExitW} />
                            </div>
                          </li>
                          <li>
                            <i className="fa fa-minus"></i>
                            <span className="title">Losers</span>
                            <span className="value">{this.state.avgIVExitL}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="danger" value={this.state.avgIVExitL} />
                            </div>
                          </li>
                          </CardText>
                        </Card>
                        </MuiThemeProvider> */}
                        <br/>
                        <div id="volentrydiv">
                        <MuiThemeProvider>
                        <Card>
                          <CardHeader
                            title={
                              <div>
                                <span className="title">Volatility Entry Stats</span>
                              </div>
                            }
                            actAsExpander={true}
                            showExpandableButton={true}
                          />
                          <CardText expandable={true}>
                          <li>
                            {/* <i className="icon-social-twitter"></i> */}
                            <span className="title">Avg VIX Entry - Winners</span>
                            <span className="value">{formatNumber(this.state.avgVixEntryW,2)}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="success" value={this.state.avgVixEntryW} />
                            </div>
                          </li>
                          <li>
                            {/* <i className="icon-social-linkedin"></i> */}
                            <span className="title">Avg VIX Entry - Losers</span>
                            <span className="value">{formatNumber(this.state.avgVixEntryL,2)}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="danger" value={this.state.avgVixEntryL} />
                            </div>
                          </li>
                          {/* <li className="divider"></li> */}
                          <br/>
                          <li>
                            {/* <i className="icon-social-twitter"></i> */}
                            <span className="title">Avg VVIX Entry - Winners</span>
                            <span className="value">{formatNumber(this.state.avgVvixEntryW,2)}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="success" value={this.state.avgVvixEntryW} />
                            </div>
                          </li>
                          <li>
                            {/* <i className="fa fa-chart"></i> */}
                            <span className="title">Avg VVIX Entry - Losers</span>
                            <span className="value">{formatNumber(this.state.avgVvixEntryL,2)}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="danger" value={this.state.avgVvixEntryL} />
                            </div>
                          </li>
                          </CardText>
                        </Card>
                        </MuiThemeProvider>
                        </div>
                        {/* <li className="divider text-center">
                          <button type="button" className="btn btn-sm btn-link text-muted" data-toggle="tooltip" data-placement="top" title="" data-original-title="show more"><i className="icon-options"></i></button>
                        </li> */}
                      </ul>
                    </div>
                    <div className="col-sm-6 col-lg-4">
                      <div className="row">
                        <div id="sharpediv" className="col-sm-6">
                          <div className="callout">
                            <small className="text-muted">Sharpe</small><br/>
                            <strong className="h4">{formatNumber(this.state.dSharpe,2)}</strong>
                            <div className="chart-wrapper">
                              <canvas id="sparkline-chart-5" width="100" height="30"></canvas>
                            </div>
                          </div>
                        </div>
                        <div id="drawdowndiv" className="col-sm-6">
                          <div className="callout callout-primary">
                            <small className="text-muted">Drawdown</small><br/>
                            <strong className="h4">-${formatNumber(this.state.drawdown,2)}</strong>
                            <div className="chart-wrapper">
                              <canvas id="sparkline-chart-6" width="100" height="30"></canvas>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-0"/>
                      <ul className="horizontal-bars type-2">
                        <li id="undperfdiv">
                          <i className="fa fa-industry"></i>
                          <span className="title">Underlying Performance</span>
                          <span className="value">{formatNumber(this.state.underlyingPerf,2)}%</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="info" value={this.state.underlyingPerf} />
                          </div>
                        </li>
                        <li id="betaunddiv">
                          <i className="fa fa-asterisk"></i>
                          <span className="title">Beta to Underlying</span>
                          <span className="value">{formatNumber(this.state.betaToUnderlying,2)}</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="warning" value={Math.abs(this.state.betaToUnderlying)} max={1} />
                          </div>
                        </li>
                        {/* <li className="divider"></li> */}
                        <br/>
                        <li id="maxmargindiv">
                          <i className="fa fa-bullseye"></i>
                          <span className="title">Max Margin</span>
                          <span className="value">${formatNumber(this.state.trueMaxMargin,2)}</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="info" value={this.state.trueMaxMargin} max={this.state.capitalAtRisk} />
                          </div>
                        </li>
                        <li>
                        <i className="fa fa-exclamation-triangle"></i>
                          <span className="title">Capital At Risk</span>
                          <span className="value">${formatNumber(this.state.capitalAtRisk,2)}</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="warning" value={this.state.capitalAtRisk} />
                          </div>
                        </li>
                        {/* <li className="divider"></li> */}
                        <br/>
                        <li id="msharpediv">
                          <i className="fa fa-calendar-times-o"></i>
                          <span className="title">Monthly Sharpe</span>
                          <span className="value">{formatNumber(this.state.mSharpe,2)}</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="info" value={this.state.mSharpe} max={5} />
                          </div>
                        </li>
                        <li id="ysharpediv">
                          <i className="fa fa-calendar"></i>
                          <span className="title">Yearly Sharpe</span>
                          <span className="value">{formatNumber(this.state.ySharpe,2)}</span>
                          <div className="bars">
                            <Progress className="progress-xs" color="warning" value={this.state.ySharpe} max={5} />
                          </div>
                        </li>
                        {/* <li className="divider"></li> */}
                        <br/>
                        <div id="avgholdingdiv">
                        <MuiThemeProvider>
                        <Card>
                          <CardHeader
                            title={
                              <div>
                                <span className="title">Average Holding Period</span>
                              </div>
                            }
                            actAsExpander={true}
                            showExpandableButton={true}
                          />
                          <CardText expandable={true}>
                          <li>
                            {/* <i className="fa fa-long-arrow-alt-right"></i> */}
                            <span className="title">All Trades</span>
                            <span className="value">{formatNumber(this.state.avgHoldPeriod,2)}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="info" value={this.state.avgHoldPeriod} max={this.state.avgHoldPeriodW+this.state.avgHoldPeriodL} />
                            </div>
                          </li>
                          <li>
                            {/* <i className="fa fa-plus"></i> */}
                            <span className="title">Winners</span>
                            <span className="value">{formatNumber(this.state.avgHoldPeriodW,2)}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="success" value={this.state.avgHoldPeriodW} max={this.state.avgHoldPeriodW+this.state.avgHoldPeriodL} />
                            </div>
                          </li>
                          <li>
                            {/* <i className="fa fa-minus"></i> */}
                            <span className="title">Losers</span>
                            <span className="value">{formatNumber(this.state.avgHoldPeriodL,2)}</span>
                            <div className="bars">
                              <Progress className="progress-xs" color="danger" value={this.state.avgHoldPeriodL} max={this.state.avgHoldPeriodW+this.state.avgHoldPeriodL} />
                            </div>
                          </li>
                          </CardText>
                        </Card>
                        </MuiThemeProvider>
                        </div>
                        {/* <li className="divider"></li> */}
                        <br/>
                        <div id="volexitdiv">
                        <MuiThemeProvider>
                        <Card>
                          <CardHeader
                            title={
                              <div>
                                <span className="title">Volatility Exit Stats</span>
                              </div>
                            }
                            actAsExpander={true}
                            showExpandableButton={true}
                          />
                          <CardText expandable={true}>
                          <li>
                            {/* <i className="icon-social-twitter"></i> */}
                            <span className="title">Avg VIX Exit - Winners</span>
                            <span className="value">{formatNumber(this.state.avgVixExitW,2)} <span className="text-muted">({formatNumber(((this.state.avgVixExitW/this.state.avgVixEntryW)-1)*100,2)}%)</span></span>
                            <div className="bars">
                              <Progress className="progress-xs" color="success" value={this.state.avgVixExitW} />
                            </div>
                          </li>
                          <li>
                            {/* <i className="icon-social-linkedin"></i> */}
                            <span className="title">Avg VIX Exit - Losers</span>
                            <span className="value">{formatNumber(this.state.avgVixExitL,2)} <span className="text-muted">({formatNumber(((this.state.avgVixExitL/this.state.avgVixEntryL)-1)*100,2)}%)</span></span>
                            <div className="bars">
                              <Progress className="progress-xs" color="danger" value={this.state.avgVixExitL} />
                            </div>
                          </li>
                          {/* <li className="divider"></li> */}
                          <br/>
                          <li>
                            {/* <i className="icon-social-twitter"></i> */}
                            <span className="title">Avg VVIX Exit - Winners</span>
                            <span className="value">{formatNumber(this.state.avgVvixExitW,2)} <span className="text-muted">({formatNumber(((this.state.avgVvixExitW/this.state.avgVvixEntryW)-1)*100,2)}%)</span></span>
                            <div className="bars">
                              <Progress className="progress-xs" color="success" value={this.state.avgVvixExitW} />
                            </div>
                          </li>
                          <li>
                            {/* <i className="fa fa-chart"></i> */}
                            <span className="title">Avg VVIX Exit - Losers</span>
                            <span className="value">{formatNumber(this.state.avgVvixExitL,2)} <span className="text-muted">({formatNumber(((this.state.avgVvixExitL/this.state.avgVvixEntryL)-1)*100,2)}%)</span></span>
                            <div className="bars">
                              <Progress className="progress-xs" color="danger" value={this.state.avgVvixExitL} />
                            </div>
                          </li>
                          </CardText>
                        </Card>
                        </MuiThemeProvider>
                        </div>
                        {/* <li className="divider text-center">
                          <button type="button" className="btn btn-sm btn-link text-muted" data-toggle="tooltip" data-placement="top" title="" data-original-title="show more"><i className="icon-options"></i></button>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </CardText>
              </Card>
            </MuiThemeProvider>
          </div>
        </div>
        <br/>

        {/* <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header">
                Trades
              </div>
              <div>

        {/* <TradeTable tableHeight={500} tableWidth={1000} scrollLeft={0} scrollTop={0} data={this.state.tradeList} /> */}
        {/* <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme)}> */}
        {/* <MuiThemeProvider>
          <MUITradeTable tcols={tradeTableCols} data={tradeTableData} />
          </MuiThemeProvider>

          </div>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-12">
            {/* <div className="card jrstep_trades"> */}
              {/* <div> */}
            <MuiThemeProvider>
              <Card>
                <CardTitle title={
                  <div className="row">
                    <div className="col-md-9">
                      Trades
                    </div>
                    <div className="col-md-3">
                    </div>
                  </div>
                }/>
                <CardText>
                <div style={style.reduceCardMargin33}>
                  <DataTables
                    height={'auto'}
                    showHeaderToolbar={false}
                    // title={'Trades'}
                    titleStyle={style.mediumCardTitle}
                    tableHeaderColumnStyle={tdstyle.headerStyle}
                    tableRowColumnStyle={tdstyle.tradecolC}
                    // tableRowStyle={tdstyle.tradecolC}
                    selectable={false}
                    showRowHover={true}
                    showRowSizeControls={true}
                    showFooterToolbar={true}
                    rowSize={this.state.numRowsShown}
                    rowSizeList={[10,25,50,100]}
                    columns={tradeTableCols}
                    data={tradeDataShown}
                    showCheckboxes={false}
                    showHeaderToolbarFilterIcon={false}
                    //onCellClick={this.handleCellClick}
                    //onCellDoubleClick={this.handleCellDoubleClick}
                    //onFilterValueChange={this.handleFilterValueChange}
                    //onSortOrderChange={this.handleSortOrderChange}
                    onNextPageClick={this.handleNextPageClick}
                    onPreviousPageClick={this.handlePrevPageClick}
                    //onRowSelection={this.handleRowSelection}
                    onRowSizeChange={this.handleRowSizeChange}
                    page={this.state.currentTradePage}
                    count={tradeTableData.length}
                  />
                </div>
                </CardText>
              </Card>
            </MuiThemeProvider>
              {/* </div> */}
            {/* </div> */}
          </div>
        </div>
        <br/>
        {/* <div className="row">
          <div className="col-md-12">
            <MuiThemeProvider>
            <Card>
              <CardTitle title="Insights" />
              <CardText>
              <li>
                <span className="title">Your winners seem to occur more frequently during periods of low volatility.  Consider screening for this.</span>
              </li>
              <li>
                <span className="title">You currently don't have any trade management set up.  Consider experimenting with this.</span>
              </li>
              <li>
                <span className="title">Your Net ROM outperformed the underlying over the same period of time.  Congrats!</span>
              </li>
              </CardText>
            </Card>
            </MuiThemeProvider>
          </div>
        </div>
        <br/> */}

        <MuiThemeProvider muiTheme={getMuiTheme(darkBaseTheme, btioDarkTheme)}>
          <AlertComp ref={(alertprivateref) => { this.alertprivateref = alertprivateref; }}
            yes="Log In" no="Sign up" handleyes={this.props.auth.login} handleno={this.props.auth.signup} open={false} modal={this.state.viewOnlyModal}
            message={
              <Card>
              <CardTitle style={style.historyfont} title={this.state.viewOnlyTitle} />
              <CardText>
                <div style={style.privateBT}>
                  {this.state.viewOnlyText}
                </div>
              </CardText>
              </Card>
             }
            />
        </MuiThemeProvider>

        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['netpnl']} target="netpnldiv" toggle={this.handleTooltip.bind(this,'netpnl')}>PNL after commissions are deducted.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['commish']} target="commishdiv" toggle={this.handleTooltip.bind(this,'commish')}>Commission expense based on the cost per contract specified.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['trades']} target="tradesdiv" toggle={this.handleTooltip.bind(this,'trades')}>Number of roundtrip trades executed. Exiting a trade incrementally still counts as a single trade.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['netroc']} target="netrocdiv" toggle={this.handleTooltip.bind(this,'netroc')}>After commissions, the net return based on the max capital at risk.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['sharpe']} target="sharpediv" toggle={this.handleTooltip.bind(this,'sharpe')}>Risk metric based on daily returns and how much daily PNL fluctuates (up or down). Higher numbers are better.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['drawdown']} target="drawdowndiv" toggle={this.handleTooltip.bind(this,'drawdown')}>The largest decline in PNL experienced by the strategy, calculated from the max running PNL. Unrealized PNL is included.</Tooltip>


        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['winningtrades']} target="winningtradesdiv" toggle={this.handleTooltip.bind(this,'winningtrades')}>Number of trades that showed a net profit.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['losingtrades']} target="losingtradesdiv" toggle={this.handleTooltip.bind(this,'losingtrades')}>Number of trades that showed a net loss.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['volume']} target="volumediv" toggle={this.handleTooltip.bind(this,'volume')}>Total roundtrip contract volume, inclusive of all legs.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['commishimpact']} target="commishimpactdiv" toggle={this.handleTooltip.bind(this,'commishimpact')}>The percent of gross PNL that was spent on commissions.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['undperf']} target="undperfdiv" toggle={this.handleTooltip.bind(this,'undperf')}>The performance of the underlying stock/index over the same time period.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['betaund']} target="betaunddiv" toggle={this.handleTooltip.bind(this,'betaund')}>How closely the strategy's daily returns mirror the underlying security's returns. Lower (or negative) numbers show less similarity.</Tooltip>

        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['maxrunning']} target="maxrunningdiv" toggle={this.handleTooltip.bind(this,'maxrunning')}>The most PNL seen by the strategy including unrealized PNL. Calculated daily.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['minrunning']} target="minrunningdiv" toggle={this.handleTooltip.bind(this,'minrunning')}>The least PNL seen by the strategy including unrealized PNL. Calculated daily.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['maxwinner']} target="maxwinnerdiv" toggle={this.handleTooltip.bind(this,'maxwinner')}>The largest winning trade the strategy had.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['maxloser']} target="maxloserdiv" toggle={this.handleTooltip.bind(this,'maxloser')}>The largest losing trade the strategy had.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['maxmargin']} target="maxmargindiv" toggle={this.handleTooltip.bind(this,'maxmargin')}>The most margin consumed by a single trade. Used to calculate Net Return on Margin.</Tooltip>

        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['annualret']} target="annualretdiv" toggle={this.handleTooltip.bind(this,'annualret')}>The strategy's returns scaled to a yearly basis, assuming the performance stays the same.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['annualvol']} target="annualvoldiv" toggle={this.handleTooltip.bind(this,'annualvol')}>The strategy's volatility scaled to a yearly basis, assuming the volatility stays the same.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['calmar']} target="calmardiv" toggle={this.handleTooltip.bind(this,'calmar')}>Risk metric that compares strategy returns to its maximum drawdown risk. Higher numbers are better.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['sortino']} target="sortinodiv" toggle={this.handleTooltip.bind(this,'sortino')}>Risk metric that compares strategy returns to the amount of negative PNL fluctuation. It does not penalize for upside return volatility. Higher numbers are better.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['msharpe']} target="msharpediv" toggle={this.handleTooltip.bind(this,'msharpe')}>Sharpe ratio calculated from monthly PNL data.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['ysharpe']} target="ysharpediv" toggle={this.handleTooltip.bind(this,'ysharpe')}>Sharpe ratio calculated from yearly PNL data.</Tooltip>

        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['avgnetrom']} target="avgnetromdiv" toggle={this.handleTooltip.bind(this,'avgnetrom')}>The average returns for individual trades, also split into winning and losing trades.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['avgtradepnl']} target="avgtradepnldiv" toggle={this.handleTooltip.bind(this,'avgtradepnl')}>The average PNL for individual trades, also split into winning and losing trades.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['avgholding']} target="avgholdingdiv" toggle={this.handleTooltip.bind(this,'avgholding')}>The average time an individual trade was held, also split into winning and losing trades.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['marketvol']} target="marketvoldiv" toggle={this.handleTooltip.bind(this,'marketvol')}>Shows the number of instances and days spent in a period of backwardation over the timeframe specified.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['volentry']} target="volentrydiv" toggle={this.handleTooltip.bind(this,'volentry')}>Shows the average VIX and VVIX values for trades at the time of entry, split into winning and losing trades.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['volexit']} target="volexitdiv" toggle={this.handleTooltip.bind(this,'volexit')}>Shows the average VIX and VVIX values for trades at the time of exit along with how much each changed, split into winning and losing trades.</Tooltip>


      </div>
    )
  }
}

export default withRouter(Dashboard);
