import React, { Component } from 'react';
//import { Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SingleInput from '../../components/FormElements/SingleInput';
//import CheckboxOrRadioGroup from '../../components/FormElements/CheckboxOrRadioGroup';
import Select from '../../components/FormElements/Select';
import DatePicker from 'material-ui/DatePicker';

import IconTextField2 from '../../components/FormElements/IconTextField2';
import TextFieldIcon from 'material-ui-textfield-icon';
import { CurrencyUsdIcon, PercentIcon } from 'mdi-react';

// import HelpDiv from '../../components/FormElements/HelpDiv';
// import Tooltip from '../../components/FormElements/Tooltip';
import { Tooltip } from 'reactstrap';

import lightBaseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import darkBaseTheme from 'material-ui/styles/baseThemes/darkBaseTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import btioDarkTheme from '../../utils/themes/btioDarkTheme';

import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import Toggle from 'material-ui/Toggle';
import {Card, CardTitle, CardText} from 'material-ui/Card';
import Divider from 'material-ui/Divider';

import {Tabs, Tab} from 'material-ui/Tabs';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Snackbar from 'material-ui/Snackbar';

import RaisedButton from 'material-ui/RaisedButton';
import FontIcon from 'material-ui/FontIcon';
import ResetIcon from 'material-ui/svg-icons/content/block';

import {blueGrey300, orange500, darkBlack} from 'material-ui/styles/colors';
import * as muicolors from 'material-ui/styles/colors';

import axios from 'axios';

const muistyles = {
  errorStyle: {
    color: orange500,
  },
  underlineStyle: {
    borderColor: orange500,
  },
  floatingLabelStyle: {
    color: blueGrey300,
  },
  // floatingLabelFocusStyle: {
  //   color: blue500,
  // },
  robotoFont: {
    fontFamily: 'Roboto, sans-serif',
  },
  button: {
   margin: 12,
  },
  boldtxt: {
    fontWeight: 600,
  },
  smallerCardTitle: {
    fontSize: '18px',
  },
  cardText16: {
    fontSize: '16px',
  },
  roundedCard: {
    borderRadius: '15px'
  },
  shadedCard: {
    backgroundColor: muicolors.teal50,
    borderRadius: '15px'
  },
  reduceCardMargin40: {
    marginTop: -40,
  },
  reduceCardMargin25: {
    marginTop: -25,
  },
  reduceCardMargin10: {
    marginTop: -10,
  },

};

const radioStyles = {
  block: {
    maxWidth: 250,
  },
  horiz: {
    display: 'flex',
  },
  radioButton: {
    marginBottom: 16,
    // width: 'fit-content',
  },
  radioNoWrap: {
    whiteSpace: 'pre',
    textAligh: 'left',
    marginLeft: '-10px',
    paddingRight: '10px',
  },
  dividerGap: {
    marginTop: 16,
    marginBottom: 16,
  },
};

const tooltipStyles = {
  default: {
    fontSize: '13px',
    // whiteSpace: 'pre-line',
    padding: '4px 8px',
  },
};

const toggleStyles = {
  block: {
    maxWidth: 250,
  },
  toggle: {
    marginBottom: 16,
  },
  select: {
    marginTop: -30,
    marginBottom: 25,
  },
  select2: {
    marginTop: -25,
    // marginBottom: 10,
  },
  thumbOff: {
    backgroundColor: '#ffcccc',
  },
  trackOff: {
    backgroundColor: '#ff9d9d',
  },
  thumbSwitched: {
    backgroundColor: 'red',
  },
  trackSwitched: {
    backgroundColor: '#ff9d9d',
  },
  labelStyle: {
    color: 'red',
  },
  hiddenDiv: {
    visibility: 'hidden',
  },
  missingDiv: {
    display: 'none',
  },
  readOnlyInput: {
    readOnly: 'true',
  },
  wspWidth: {
    width: 15,
  },
  toggleNoWrap: {
    whiteSpace: 'pre',
    // textAligh: 'left',
    // marginLeft: '-10px',
    // paddingRight: '10px',
  },
  toggleLabel: {
    // whiteSpace: 'pre',
    fontSize: '14px',
  },
};

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function setupStratWinLose()
{
  var valid = {};

  valid["Call|Long"] = "DEBIT";
  valid["Call|Short"] = "CREDIT";
  valid["Put|Long"] = "DEBIT";
  valid["Put|Short"] = "CREDIT";

  valid["Vertical|Long"] = "DEBIT";
  valid["Vertical|Short"] = "CREDIT";

  valid["Straddle|Long"] = "DEBIT";
  valid["Straddle|Short"] = "CREDIT";
  valid["Strangle|Long"] = "DEBIT";
  valid["Strangle|Short"] = "CREDIT";

  valid["Calendar|Long"] = "DEBIT";
  valid["Calendar|Short"] = "CREDIT";
  valid["Diagonal|Long"] = "DEBIT";
  valid["Diagonal|Short"] = "CREDIT";

  valid["Butterfly|Long"] = {};
  valid["Butterfly|Long"]["Regular"] = "DEBIT";
  valid["Butterfly|Long"]["Iron"] = "CREDIT";

  valid["Butterfly|Short"] = {};
  valid["Butterfly|Short"]["Regular"] = "DEBIT";
  valid["Butterfly|Short"]["Iron"] = "DEBIT";

  valid["Condor|Long"] = {};
  valid["Condor|Long"]["Regular"] = "DEBIT";
  valid["Condor|Long"]["Iron"] = "CREDIT";

  valid["Condor|Short"] = {};
  valid["Condor|Short"]["Regular"] = "DEBIT";
  valid["Condor|Short"]["Iron"] = "DEBIT";

  return valid;
}

function round(value, decimals) {
  var rounded = Number(Math.round(value+'e'+decimals)+'e-'+decimals);
  return Number.isNaN(rounded) ? 0 : rounded;
}

function formatDateObjToString(d)
{
  return d.getUTCFullYear() + '-' + (d.getUTCMonth()+1) + '-' + d.getUTCDate();
}

function formatDateStringToObj(d)
{
  var darr = d.split('-');
  return new Date( darr[0], darr[1]-1, darr[2], 0, 0, 0, 0 );
}

function btDisabledDate(d)
{
  if(d.getDay() === 0 || d.getDay() === 6 ) {
    return true;
  }
  return false;
}

function getLatestDataDate()
{
  var today = new Date();
  var offset = -8 * 60 - 15; // we load the data at 8am UTC. add 15 min for padding
  return new Date(today.getTime() + offset*60*1000);
}

function getBTEndDataDate()
{
  var loadDate = getLatestDataDate();

  var dayOffset = 1; // "yesterday"
  if( loadDate.getDay() === 0 ) { // its sunday, subtract 2 days
    dayOffset = 2;
  }
  var d = new Date(loadDate - dayOffset*24*3600*1000);
  return d;

  // for formatted dates
  var newmonth = d.getMonth() + 1.0
  var lastDateFmt = d.getFullYear() + '-' + newmonth + '-' + d.getDate();
  // console.log('lastdate:', lastDateFmt );
  return lastDateFmt;
}

function getBTPastDate(offset)
{
  var loadDate = getLatestDataDate();
  var dayOffset = offset; // "yesterday"
  if( loadDate.getDay() === 0 ) { // its sunday, subtract 2 days
    dayOffset += 2;
  }
  var d = new Date(loadDate - dayOffset*24*3600*1000);
  while(d.getDay() === 0 || d.getDay() === 6 ) {
    d = new Date(d - 24*3600*1000);
  }

  return d;

  // for formatted dates
  var newmonth = d.getMonth() + 1.0
  var lastDateFmt = d.getFullYear() + '-' + newmonth + '-' + d.getDate();
  // console.log('lastdate:', lastDateFmt );
  return lastDateFmt;
}

const tooltipDelay = { show: 0, hide: 100 };
const tooltipOpenMap = {};
function setupTooltipMap()
{
  var tooltips = ['dir','onetrade', 'maxalloc', 'execmeth', 'portcap', 'tradecap',
  'entrydelta', 'diagfardelta', 'spwidth', 'advanced', 'wingratio', 'stradratio',
  'dterange', 'dteclosest', 'dteminimum', 'mindte', 'maxdte',
  'vix', 'vvix', 'contango', 'backward', 'daily', 'hv', 'iv',
  'currpnl', 'cromlimW', 'cromlimL', 'cromcloseW', 'cromcloseL', 'closedte'];

  for( var i = 0; i < tooltips.length; i++ )
  {
    tooltipOpenMap[ tooltips[i] ] = false;
  }

  return tooltipOpenMap;
}

const btStartYear = Number('2013');
const btStartDataDate = new Date(2013,1,1,0,0,0,0);


class BTFormTabbed extends Component {
  constructor(props) {
    super(props);

    // prefilling these values for testing

    this.state = {
      fetchBacktest: this.props.submitFunc,
      jwtToken: this.props.token,
      errormsg: '',
      symbol: '',
      startDate: getBTPastDate(365),
      endDate: getBTEndDataDate(),
      aum: '',
      allocmeth: 'dollars',
      tradealloc: '',
      expmarginlim: '',
      oneTradeToggleState: true,
      ignorepnl: true,
      strategytype: '',
      dir: '',
      commish: '',
      execmeth: 'Mid',

      dteRangeToggleState: false,
      dtemeth: 'closest',
      mindteLabel: 'Days to Expiry',
      mindte: '',
      maxdte: '',
      entrydelta: '',
      spwidth: '',
      dailyToggleState: false,
      dailychange: '',
      vvixLabelText: 'VVIX is Above/Below',
      vvixToggleState: false,
      vvixmethod: '',
      vvixlim: 100,
      vixLabelText: 'Above/Below',
      vixToggleState: false,
      contangoLabelText: 'Cash or Futures',
      vixContangoToggleState: false,
      vixContangoVal: '',
      backwardLabelText: 'Cash or Futures',
      vixBackwardToggleState: false,
      vixBackwardVal: '',
      vixmethod: '',
      vixlim: 16,
      hvToggleState: false,
      hvper: 'HV10',
      hvmethod: '',
      hvlim: 0,
      ivToggleState: false,
      ivper: 'IV30',
      ivmethod: '',
      ivlim: 0,

      flyright: '',
      advToggleState: false,
      wa1right: 'Call',
      wa1ratio: 1,
      wa1delta: .22,
      wa1width: 10,
      wa2right: 'Put',
      wa2ratio: 1,
      wa2delta: .22,
      wa2width: 10,

      strad1ratio: 1,
      strad1delta: .50,
      strad2ratio: 1,
      strad2delta: .50,

      diagfardelta: '',

      closedte: '',
      winmethod: 'Percent Price',
      winmethodtext: 'Percent of Margin Used',
      winthresh: '',
      loserguidetext: '',
      trom: 5,
      losermult: '',
      stratValid: setupStratWinLose(),

      cromToggleState: false,
      cromlabel: 'Increment',
      cromlimW: '',
      cromcloseW: '',
      cromlimL: '',
      cromcloseL: '',
      cromResetToggleState: false,

      closedteToggleState: true,
      winnerToggleState: false,
      loserToggleState: false,

      tabValue: 0,
      snackopen: false,
      snacktext: '',
      snackhelp: '',

      allocleft: -22,
      allocicon: 'fa fa-usd',

      deltalabel: 'Delta',
      spwidthlabel: 'Spread Width',
      deltatooltip: 'Pick the minimum delta for the options (may be greater)',
      spwidthtooltip: 'Pick the spread width between short and long options',

      symbolErr: '',
      strategytypeErr: '',
      dirErr: '',
      aumErr: '',
      allocErr: '',
      expMarginErr: '',
      mindteErr: '',
      maxdteErr: '',
      deltaErr: '',
      diagErr: '',
      widthErr: '',
      chngErr: '',
      vvixErr: '',
      vixErr: '',
      contangoErr: '',
      backwardErr: '',
      hvErr: '',
      ivErr: '',
      closedteErr: '',
      winErr: '',
      loseErr: '',
      cromlimErr: '',
      cromcloseErr: '',
      cromlimLErr: '',
      cromcloseLErr: '',

      dteTooltipLabel: '',
      dteTooltipShow: false,
      dteRangeTooltipShow: false,
      tooltipMap: setupTooltipMap(),
      tooltipToggleState: true,

      strategyOptions : ["Call", "Put", "Vertical", "Straddle", "Strangle", "Calendar", "Diagonal", "Butterfly", "Condor"],
      dirOptions: ["Long", "Short"],
      rightOptions: ["Call", "Put"],
      hvOptions: ["HV10", "HV20", "HV60", "HV120", "HV250"],
      ivOptions: ["IV30", "IV60", "IV90"],
      abOptions: ["Above", "Below"],
      contangoOptions: ["Cash Contango|VIX Cash below VX1", "Futures Contango|VX1 below VX2"],
      backwardOptions: ["Cash Backwardation|VIX Cash above VX1", "Futures Backwardation|VX1 above VX2"],
      winnerOptions: ["Percent Price", "Percent Delta"],
      // vvixOptions: ["On", "Off"],
      execOptions: ["Mid", "Smart Mid"],
      lastBTDate: getBTEndDataDate(),
      payoffChart: {},
      payoffChartOpts: {},
    };

    this.preloadSymbol          = this.preloadSymbol.bind(this);
    this.symbolResponse         = this.symbolResponse.bind(this);
    this.handleFormSubmit       = this.handleFormSubmit.bind(this);
		this.handleClearForm        = this.handleClearForm.bind(this);
    this.handleClearAdvanced    = this.handleClearAdvanced.bind(this);
    this.loadPastRequest        = this.loadPastRequest.bind(this);
    this.loadPastUXRequest      = this.loadPastUXRequest.bind(this);
    this.handleSymbolChange     = this.handleSymbolChange.bind(this);
    this.handleStartDateChange  = this.handleStartDateChange.bind(this);
    this.handleEndDateChange    = this.handleEndDateChange.bind(this);
    this.handleAUMChange        = this.handleAUMChange.bind(this);
    this.handleAllocMethChange  = this.handleAllocMethChange.bind(this);
    this.handleTradeAllocChange = this.handleTradeAllocChange.bind(this);
    this.handleExpLimChange     = this.handleExpLimChange.bind(this);
    this.handleOneTradeChange   = this.handleOneTradeChange.bind(this);
    this.handleIgnoreTradePnl   = this.handleIgnoreTradePnl.bind(this);
    this.handleStratTypeSelect  = this.handleStratTypeSelect.bind(this);
    this.handleDirSelect        = this.handleDirSelect.bind(this);
    this.handleCommishChange    = this.handleCommishChange.bind(this);
    this.handleExecChange       = this.handleExecChange.bind(this);

    this.handleDTERangeToggle   = this.handleDTERangeToggle.bind(this);
    this.handleDTEMethChange    = this.handleDTEMethChange.bind(this);
    this.handleMinDTEChange     = this.handleMinDTEChange.bind(this);
    this.handleMaxDTEChange     = this.handleMaxDTEChange.bind(this);
    this.handleEntryDeltaChange = this.handleEntryDeltaChange.bind(this);
    this.handleSPWidthChange    = this.handleSPWidthChange.bind(this);
    this.handleDailyToggle      = this.handleDailyToggle.bind(this);
    this.handleDailyChange      = this.handleDailyChange.bind(this);
    this.handleVVIXToggle       = this.handleVVIXToggle.bind(this);
    this.handleVVIXChange       = this.handleVVIXChange.bind(this);
    this.handleVIXToggle        = this.handleVIXToggle.bind(this);
    this.handleVIXChange        = this.handleVIXChange.bind(this);
    this.handleVolToggle        = this.handleVolToggle.bind(this);
    this.handleContangoSelect   = this.handleContangoSelect.bind(this);
    this.handleBackwardSelect   = this.handleBackwardSelect.bind(this);
    this.handleHVToggle         = this.handleHVToggle.bind(this);
    this.handleHVPerSelect      = this.handleHVPerSelect.bind(this);
    this.handleIVToggle         = this.handleIVToggle.bind(this);
    this.handleIVPerSelect      = this.handleIVPerSelect.bind(this);
    this.handleVVIXSelect       = this.handleVVIXSelect.bind(this);
    this.handleVIXSelect        = this.handleVIXSelect.bind(this);
    this.handleHVSelect         = this.handleHVSelect.bind(this);
    this.handleHVChange         = this.handleHVChange.bind(this);
    this.handleIVSelect         = this.handleIVSelect.bind(this);
    this.handleIVChange         = this.handleIVChange.bind(this);
    this.handleCloseDTEChange   = this.handleCloseDTEChange.bind(this);
    this.getTradeNetType        = this.getTradeNetType.bind(this);
    this.handleWinMethodSelect  = this.handleWinMethodSelect.bind(this);
    this.setWinMethodText       = this.setWinMethodText.bind(this);
    this.handleWinThreshChange  = this.handleWinThreshChange.bind(this);
    this.handleTROMChange       = this.handleTROMChange.bind(this);
    this.handleLoserMultChange  = this.handleLoserMultChange.bind(this);
    this.getLoserGuideText      = this.getLoserGuideText.bind(this);

    this.handleAdvancedToggle = this.handleAdvancedToggle.bind(this);
    this.handleWingAdvChange    = this.handleWingAdvChange.bind(this);
    this.handleFlyrightChange   = this.handleFlyrightChange.bind(this);

    this.handleCROMToggle       = this.handleCROMToggle.bind(this);
    this.handleCROMLimChange    = this.handleCROMLimChange.bind(this);
    this.handleCROMCloseChange  = this.handleCROMCloseChange.bind(this);
    this.handleCROMResetToggle  = this.handleCROMResetToggle.bind(this);
    this.handleLegPNLVisibility = this.handleLegPNLVisibility.bind(this);

    this.handleCloseDTEToggle   = this.handleCloseDTEToggle.bind(this);
    this.handleWinnerToggle     = this.handleWinnerToggle.bind(this);
    this.handleLoserToggle      = this.handleLoserToggle.bind(this);

    // this.validateWinLose        = this.validateWinLose.bind(this);
    this.handleDTETooltip       = this.handleDTETooltip.bind(this);
    this.handleTooltip          = this.handleTooltip.bind(this);
    this.handleTooltipToggle    = this.handleTooltipToggle.bind(this);

    // this.setHelpText            = this.setHelpText.bind(this);
    this.handleTabChange        = this.handleTabChange.bind(this);
    this.handleSnackClose       = this.handleSnackClose.bind(this);

    this.validateForm           = this.validateForm.bind(this);
    this.resetErrorMessages     = this.resetErrorMessages.bind(this);

    this.makePayoffChart        = this.makePayoffChart.bind(this);

    // form elements prep
    // document.getElementById("maxallocdiv").style.visibility = 'hidden';
    // document.forms['btform']['dailychange-input'].readOnly = true;
    //console.log('stratvalid:', this.state.stratValid);
  }

  validateForm()
  {
    // reset these vars
    var errors = false;
    this.resetErrorMessages();

    if( this.state.symbol === "" ) {
      this.setState({ symbolErr: "Pick a symbol" }, () => console.log('symbolErr:', this.state.symbolErr));
      errors = true;
    }

    if( this.state.strategytype === "" ) {
      this.setState({ strategytypeErr: "Pick a strategy type" }, () => console.log('strategytypeErr:', this.state.strategytypeErr));
      errors = true;
    }

    if( this.state.dir === "" ) {
      this.setState({ dirErr: "Pick a long or short strategy" }, () => console.log('dirErr:', this.state.dirErr));
      errors = true;
    }

    if( this.state.commish === "" ) {
      this.setState({ commishErr: "Enter estimated commission or zero to ignore" }, () => console.log('commishErr:', this.state.commishErr));
      errors = true;
    }

    if( this.state.aum === "" ) {
      this.setState({ aumErr: "Enter a starting capital amount." }, () => console.log('dirErr:', this.state.dirErr));
      errors = true;
    }

    if( this.state.tradealloc === "" ) {
      this.setState({ allocErr: "Enter how much capital should be allocated per trade" }, () => console.log('allocErr:', this.state.allocErr));
      errors = true;
    }

    if( !this.state.oneTradeToggleState && this.state.expmarginlim === "" ) {
      this.setState({ expMarginErr: "If you to limit how much capital is concentrated on a single expiration, set it here." }, () => console.log('expMarginErr:', this.state.expMarginErr));
      errors = true;
    }

    if( this.state.mindte === "" ) {
      this.setState({ mindteErr: "Enter number of days such that expirations will not be closer than this number" }, () => console.log('mindteErr:', this.state.mindteErr));
      errors = true;
    }

    if( this.state.dteRangeToggleState && this.state.maxdte === "" ) {
      this.setState({ maxdteErr: "Enter number of days such that expirations will not be farther than this number" }, () => console.log('maxdteErr:', this.state.maxdteErr));
      errors = true;
    }

    if( this.state.entrydelta === "" ) {
      if( !(this.state.strategytype === "Condor" && this.state.advToggleState) ) {
        this.setState({ deltaErr: "Enter the delta closest to which you want strikes" }, () => console.log('deltaErr:', this.state.deltaErr));
        errors = true;
      }
    }

    if( this.state.strategytype === "Diagonal" && this.state.diagfardelta === "" ) {
      this.setState({ diagErr: "Enter the delta closest to which you want strikes" }, () => console.log('diagErr:', this.state.diagErr));
      errors = true;
    }

    var spwidthcheck = (this.state.strategytype === "Call" || this.state.strategytype === "Put" || this.state.strategytype === "Straddle" || this.state.strategytype === "Strangle" || (this.state.strategytype === "Condor" && this.state.advToggleState) );
    if( !spwidthcheck && this.state.spwidth === "" ) {
      this.setState({ widthErr: "Enter the minimum width for the spread" }, () => console.log('widthErr:', this.state.widthErr));
      errors = true;
    }

    if( this.state.dailyToggleState && this.state.dailychange === "" ) {
      this.setState({ chngErr: "Enter the daily % change for a trade to be executed" }, () => console.log('chngErr:', this.state.chngErr));
      errors = true;
    }

    if( this.state.vvixToggleState && this.state.vvixlim === "" ) {
      this.setState({ vvixErr: "Enter the VVIX level for a trade to be executed" }, () => console.log('vvixErr:', this.state.vvixErr));
      errors = true;
    }

    if( this.state.vixToggleState && this.state.vixlim === "" ) {
      this.setState({ vixErr: "Enter the VIX level for a trade to be executed" }, () => console.log('vixErr:', this.state.vixErr));
      errors = true;
    }

    if( this.state.vixContangoToggleState && this.state.vixContangoVal === "" ) {
      this.setState({ contangoErr: "Select whether to use VIX Cash or Futures contango" }, () => console.log('contangoErr:', this.state.contangoErr));
      errors = true;
    }

    if( this.state.vixBackwardToggleState && this.state.vixBackwardVal === "" ) {
      this.setState({ backwardErr: "Select whether to use VIX Cash or Futures backwardation" }, () => console.log('backwardErr:', this.state.backwardErr));
      errors = true;
    }

    if( this.state.hvToggleState && this.state.hvlim === "" ) {
      this.setState({ hvErr: "Enter the HV level for a trade to be executed" }, () => console.log('hvErr:', this.state.hvErr));
      errors = true;
    }

    if( this.state.ivToggleState && this.state.ivlim === "" ) {
      this.setState({ ivErr: "Enter the IV level for a trade to be executed" }, () => console.log('ivErr:', this.state.ivErr));
      errors = true;
    }

    if( !this.state.closedteToggleState && this.state.closedte === "" ) {
      this.setState({ closedteErr: "Enter the maximum number of days before expiration to close trades" }, () => console.log('closedteErr:', this.state.closedteErr));
      errors = true;
    }


    if( this.state.winnerToggleState && this.state.winthresh === "" ) {
      this.setState({ winErr: "Enter a threshold to close winning trades" }, () => console.log('winErr:', this.state.winErr));
      errors = true;
    }

    if( this.state.loserToggleState && this.state.losermult === "" ) {
      this.setState({ loseErr: "Enter a threshold to close losing trades" }, () => console.log('loseErr:', this.state.loseErr));
      errors = true;
    }

    console.log("errors: ", errors);
    return errors;
  }

  handleFormSubmit(e) {
    e.preventDefault();

    if( this.validateForm() ) {
      return;   // there are errors
    }

    // some variable cleanup/formatting
    var stype = this.state.strategytype.replace(/\s+/g, '').toUpperCase();
    var dtype = this.state.dir.replace(/\s+/g, '').toUpperCase();
    var etype = this.state.execmeth.replace(/\s+/g, '').toUpperCase();

    var mxdte = this.state.dteRangeToggleState ? this.state.maxdte : 0;

    var hvlim = 0;
    var hvper = 0;
    if( this.state.hvToggleState )
    {
      hvper = this.state.hvper.substring(2);

      hvlim = this.state.hvlim; // values in db are already x 100
      if( this.state.hvmethod === 'Below' ) {
        hvlim *= -1;
      }
    }

    var ivlim = 0;
    var ivper = 0;
    if( this.state.ivToggleState )
    {
      ivper = this.state.ivper.substring(2);

      ivlim = this.state.ivlim; // values in db are already x 100
      if( this.state.ivmethod === 'Below' ) {
        ivlim *= -1;
      }
    }

    var vvlim = 0;
    if( this.state.vvixToggleState )
    {
      vvlim = this.state.vvixlim;
      if( this.state.vvixmethod === 'Below' ) {
        vvlim *= -1;
      }
    }

    var vix_lim = 0;
    if( this.state.vixToggleState )
    {
      vix_lim = this.state.vixlim;
      if( this.state.vixmethod === 'Below' ) {
        vix_lim *= -1;
      }
    }

    var cont_val = 'NONE';
    var contarray = this.state.vixContangoVal.replace(/\s+/g, '').toUpperCase().split('|');
    if( this.state.vixContangoToggleState && contarray.length > 0 ) {
      cont_val = contarray[0];
    }
    console.log('cont_val:', cont_val);

    var back_val = 'NONE';
    var backarray = this.state.vixBackwardVal.replace(/\s+/g, '').toUpperCase().split('|');
    if( this.state.vixBackwardToggleState && backarray.length > 0 ) {
      back_val = backarray[0];
    }
    console.log('back_val:', back_val);

    var closedays = this.state.closedteToggleState ? 0 : this.state.closedte;

    var winmeth = this.state.winmethod.replace(/\s+/g, '').toUpperCase();
    console.log('winmethod:', winmeth);

    var winth = this.state.winthresh/100.0;
    console.log('winthresh:', winth);

    // correctly account for loser mult display changes
    var loserthresh;
    var lvalid = this.getTradeNetType();
    if( lvalid === "DEBIT" ) {
      loserthresh = 1 - (this.state.losermult / 100.0);
    }
    else if( lvalid === "CREDIT") {
      loserthresh = 1 + (this.state.losermult / 100.0);
    }
    console.log('loserthresh:', loserthresh);

    var chng = this.state.dailyToggleState ? this.state.dailychange/100.0 : 0;
    console.log('chng:', chng);

    var crom_limW = this.state.cromlimW / 100.0;
    var crom_closeW = this.state.cromcloseW / 100.0;
    var crom_limL = this.state.cromlimL / 100.0;
    var crom_closeL = this.state.cromcloseL / 100.0;

    var trom = this.state.trom/100.0;
    console.log('trom:', trom);

    var igpnl = this.state.ignorepnl;
    if( this.state.allocmeth === 'percent' )
      igpnl = false;


    const formPayload = {
      stratnum: 1,    // make this some autoincrement value that is per user
      symbol: this.state.symbol,
      start: formatDateObjToString(this.state.startDate),
      end: formatDateObjToString(this.state.endDate),
      datespan: Math.abs(this.state.endDate - this.state.startDate),

      strattype: stype,
      dir: dtype,
      commish: this.state.commish,

      aum: this.state.aum,
      allocmeth: this.state.allocmeth,
      tradealloc: this.state.tradealloc,
      ignorepnl: igpnl,
      onetrade: this.state.oneTradeToggleState,
      expmarginlim: this.state.expmarginlim,
      execmeth: etype,

      dtemeth: this.state.dtemeth,
      mindte: this.state.mindte,
      dterange: this.state.dteRangeToggleState,
      maxdte: mxdte,
      entrydelta: this.state.entrydelta,
      flyright: this.state.flyright,
      spwidth: this.state.spwidth,
      chng: chng,
      vvixpre: this.state.vvixToggleState,
      vvixlim: vvlim,
      vixpre: this.state.vixToggleState,
      vixlim: vix_lim,
      usecontango: this.state.vixContangoToggleState,
      contangoval: cont_val,
      usebackward: this.state.vixBackwardToggleState,
      backwardval: back_val,
      hvper: hvper,
      hvlim: hvlim,
      ivper: ivper,
      ivlim: ivlim,

      usewa:    this.state.advToggleState,
      wa1right: this.state.wa1right,
      wa1ratio: this.state.wa1ratio,
      wa1delta: this.state.wa1delta,
      wa1width: this.state.wa1width,
      wa2right: this.state.wa2right,
      wa2ratio: this.state.wa2ratio,
      wa2delta: this.state.wa2delta,
      wa2width: this.state.wa2width,

      diagfardelta: this.state.diagfardelta,

      strad1ratio: this.state.strad1ratio,
      strad1delta: this.state.strad1delta,
      strad2ratio: this.state.strad2ratio,
      strad2delta: this.state.strad2delta,

      closedte: closedays,
      wintoggle: this.state.winnerToggleState,
      winmeth: winmeth,
      winnerthresh: winth,
      losetoggle: this.state.loserToggleState,
      losermult: loserthresh,

      usecrom: this.state.cromToggleState,
      cromlimW: crom_limW,
      cromcloseW: crom_closeW,
      cromlimL: crom_limL,
      cromcloseL: crom_closeL,

      // not user specified yet
      vixavg: 7,
      expromtarget: trom,
      uromtarget: 0,
      uromclose: 0.25,
      rsblock: false,

    };

    console.log('Send this in a GET request:', formPayload);

    // comment out for now during testing
    //this.handleClearForm(e);

    this.state.fetchBacktest(formPayload);
  }

  handleClearAdvanced()
  {
    this.setState({
      wa1right: '',
      wa1ratio: 1,
      wa1delta: 0,
      wa1width: 0,
      wa2right: '',
      wa2ratio: 1,
      wa2delta: 0,
      wa2width: 0,

      strad1ratio: 1,
      strad1delta: 0,
      strad2ratio: 1,
      strad2delta: 0,
    });
  }

  handleClearForm(e) {
    //e.preventDefault();
    console.log('in handleClearForm');

    this.setState({
      errormsg: '',
      symbol: '',
      startDate: getBTPastDate(365),
      endDate: getBTEndDataDate(),
      aum: '',
      allocmeth: 'dollars',
      tradealloc: '',
      expmarginlim: '',
      oneTradeToggleState: true,
      ignorepnl: true,
      strategytype: '',
      dir: '',
      commish: '',
      execmeth: 'Mid',

      dteRangeToggleState: false,
      dtemeth: 'closest',
      mindteLabel: 'Days to Expiry',
      mindte: '',
      maxdte: '',
      entrydelta: '',
      spwidth: '',
      dailyToggleState: false,
      dailychange: '',
      vvixLabelText: 'VVIX is Above/Below',
      vvixToggleState: false,
      vvixmethod: '',
      vvixlim: 100,
      vixLabelText: 'Above/Below',
      vixToggleState: false,
      contangoLabelText: 'Cash or Futures',
      vixContangoToggleState: false,
      vixContangoVal: '',
      backwardLabelText: 'Cash or Futures',
      vixBackwardToggleState: false,
      vixBackwardVal: '',
      vixmethod: '',
      vixlim: 16,
      hvToggleState: false,
      hvper: 'HV10',
      hvmethod: '',
      hvlim: 0,
      ivToggleState: false,
      ivper: 'IV30',
      ivmethod: '',
      ivlim: 0,

      flyright: '',
      advToggleState: false,
      wa1right: 'Call',
      wa1ratio: 1,
      wa1delta: .22,
      wa1width: 10,
      wa2right: 'Put',
      wa2ratio: 1,
      wa2delta: .22,
      wa2width: 10,

      strad1ratio: 1,
      strad1delta: .50,
      strad2ratio: 1,
      strad2delta: .50,

      diagfardelta: '',

      closedte: '',
      winmethod: 'Percent Price',
      winmethodtext: 'Percent of Margin Used',
      winthresh: '',
      trom: 5,
      losermult: '',

      cromToggleState: false,
      cromlabel: 'Increment',
      cromlimW: '',
      cromcloseW: '',
      cromlimL: '',
      cromcloseL: '',
      cromResetToggleState: false,

      help: true,
      tabValue: 0,
      snackopen: false,
      snacktext: '',
      snackhelp: '',

      allocleft: -22,
      allocicon: 'fa fa-usd',

      deltalabel: 'Delta',
      spwidthlabel: 'Spread Width',
      deltatooltip: 'Pick the minimum delta for the options (may be greater)',
      spwidthtooltip: 'Pick the spread width between short and long options',

      dteTooltipLabel: '',
      dteTooltipShow: false,
      dteRangeTooltipShow: false,
      lastBTDate: getBTEndDataDate(),
    });

    this.resetErrorMessages();
  }

  resetErrorMessages() {
    this.setState({
      errormsg: '',

      symbolErr: '',
      strategytypeErr: '',
      dirErr: '',
      aumErr: '',
      allocErr: '',
      commishErr: '',
      expMarginErr: '',
      mindteErr: '',
      maxdteErr: '',
      deltaErr: '',
      diagErr: '',
      widthErr: '',
      chngErr: '',
      vvixErr: '',
      vixErr: '',
      contangoErr: '',
      backwardErr: '',
      hvErr: '',
      ivErr: '',
      closedteErr: '',
      winErr: '',
      loseErr: '',
      cromlimErr: '',
      cromcloseErr: '',
      cromlimLErr: '',
      cromcloseLErr: '',
    });
  }

  loadPastRequest(req) {

    console.log('btformtabbed loadpastrequest...', req);
    console.log('hasprop usecrom', req.hasOwnProperty('usecrom') );

    var wintoggle = true;
    var winthr = req.winnerthresh*100;
    if( req.hasOwnProperty('wintoggle') ) {
      wintoggle = req.wintoggle == 'true';
      if( !wintoggle ) {
        winthr = '';
      }
    }

    this.setState({
      symbol: req.symbol,
      startDate: formatDateStringToObj(req.start),
      endDate: formatDateStringToObj(req.end),
      aum: req.aum,
      allocmeth: req.allocmeth,
      tradealloc: req.tradealloc,
      expmarginlim: req.expmarginlim,
      oneTradeToggleState: (req.onetrade === 'true'),
      ignorepnl: req.ignorepnl,
      strategytype: capitalizeFirstLetter(req.strattype),
      dir: capitalizeFirstLetter(req.dir),
      commish: req.commish,
      execmeth: capitalizeFirstLetter(req.execmeth),

      dtemeth: req.hasOwnProperty('dtemeth') ? req.dtemeth : 'closest',
      mindte: req.mindte,
      dteRangeToggleState: ((req.hasOwnProperty('dterange') && req.dterange == 'true') ? true : false),
      maxdte: req.maxdte,
      entrydelta: req.entrydelta,
      spwidth: req.spwidth,
      dailyToggleState: (req.chng == '0' ? false : true),
      dailychange: req.chng*100.0,
      vvixToggleState: (req.vvixlim != 0 ? true : false),
      vvixmethod: (req.vvixlim < 0 ? 'Below': 'Above'),
      vvixlim: Math.abs(req.vvixlim),
      vixToggleState: ((req.hasOwnProperty('vixlim') && req.vixlim != 0) ? true : false),
      vixmethod: (req.vixlim < 0 ? 'Below': 'Above'),
      vixlim: Math.abs(req.vixlim),
      vixContangoToggleState: ((req.hasOwnProperty('usecontango') && req.contangoval != 'NONE') ? true : false),
      vixBackwardToggleState: ((req.hasOwnProperty('usebackward') && req.backwardval != 'NONE') ? true : false),
      hvToggleState: (req.hvlim != 0 ? true : false),
      hvmethod: (req.hvlim < 0 ? 'Below': 'Above'),
      hvlim: Math.abs(req.hvlim),
      ivToggleState: ((req.hasOwnProperty('ivlim') && req.ivlim != 0) ? true : false),
      ivmethod: ((req.hasOwnProperty('ivlim') && req.ivlim < 0) ? 'Below': 'Above'),
      ivlim: (req.hasOwnProperty('ivlim') ? Math.abs(req.ivlim) : 0),

      cromToggleState: ((req.hasOwnProperty('usecrom') && req.usecrom == 'true' ) ? true : false),
      cromlimW: (req.hasOwnProperty('cromlimW') ? req.cromlimW*100 : (req.hasOwnProperty('cromlim') ? req.cromlim*100 : '')).toString(),
      cromcloseW: (req.hasOwnProperty('cromcloseW') ? req.cromcloseW*100 : (req.hasOwnProperty('cromclose') ? req.cromclose*100 : '')).toString(),
      cromlimL: (req.hasOwnProperty('cromlimL') ? req.cromlimL*100 : '').toString(),
      cromcloseL: (req.hasOwnProperty('cromcloseL') ? req.cromcloseL*100 : '').toString(),
      cromResetToggleState: ((req.hasOwnProperty('cromreset') && req.cromreset) ? true : false),

      flyright: req.flyright,
      advToggleState: (req.usewa === 'true'),

      diagfardelta: req.diagfardelta,

      closedte: req.closedte == 0 ? "" : req.closedte,
      closedteToggleState: req.closedte == 0 ? true : false,
      winnerToggleState: wintoggle,
      winmethod: req.winmeth,
      winthresh: winthr.toString(),
      //trom: 0,  // not used
      // losermult: (rdate < loserMultVersionChange ? req.losermult*100 : req.losermult),
    } , () => this.loadPastUXRequest(req) );

    this.resetErrorMessages();
  }

  loadPastUXRequest(req)
  {
    // only called after the state is updated for sure
    // we set the state, we now have to set the visible UX elements
    // recreate setting of strategy

    // correctly account for loser mult display changes
    var losetoggle = true;
    var loserthresh;
    var lvalid = this.getTradeNetType();
    if( lvalid === "DEBIT" ) {
      loserthresh = (1 - req.losermult) * 100.0;
    }
    else if( lvalid === "CREDIT") {
      loserthresh = (req.losermult - 1) * 100.0;
    }
    loserthresh = round(loserthresh,2);

    if( req.hasOwnProperty('losetoggle') ) {
      losetoggle = req.losetoggle == 'true';
      if( !losetoggle ) {
        loserthresh = '';
      }
    }

    this.setState({ losermult: loserthresh, loserToggleState: losetoggle }, () => console.log('losermult:', this.state.losermult, 'loserToggleState:', this.state.loserToggleState));


    console.log('ux update:', this.state.strategytype);
    this.handleStratTypeSelect(null, null, this.state.strategytype);
    this.handleDirSelect(null, null, this.state.dir);
    this.handleExecChange(null, null, this.state.execmeth);

    if( req.allocmeth === "dollars") {
      document.getElementById("ignoretradediv").style.display = 'block';
      this.setState({ allocleft: -22, allocicon: 'fa fa-usd' }, () => console.log('allocleft:', this.state.allocleft, 'allocicon:', this.state.allocicon));
    }
    else if( req.allocmeth === "percent") {
      document.getElementById("ignoretradediv").style.display = 'none';
      this.setState({ allocleft: 100, allocicon: 'fa fa-percent' }, () => console.log('allocleft:', this.state.allocleft, 'allocicon:', this.state.allocicon));
    }

    this.handleOneTradeChange(null, this.state.oneTradeToggleState);

    this.handleDTERangeToggle(null, this.state.dteRangeToggleState );
    this.handleAdvancedToggle(null, this.state.advToggleState);
    this.handleDailyToggle(null, this.state.dailyToggleState);
    this.handleVVIXToggle(null, this.state.vvixToggleState);
    this.handleVIXToggle(null, this.state.vixToggleState);

    if( this.state.vixContangoToggleState ) {
      var cidx = req.contangoval.indexOf('CASH') !== -1 ? 0 : 1;
      this.handleContangoSelect(null, cidx, this.state.contangoOptions[cidx]);
    }

    if( this.state.vixBackwardToggleState ) {
      var bidx = req.backwardval.indexOf('CASH') !== -1 ? 0 : 1;
      this.handleBackwardSelect(null, bidx, this.state.backwardOptions[bidx]);
    }

    this.handleHVToggle(null, this.state.hvToggleState);
    this.handleIVToggle(null, this.state.ivToggleState);

    console.log('usecrom:', req.usecrom, 'cromToggleState:', this.state.cromToggleState);
    this.handleCROMToggle(null, this.state.cromToggleState);
    this.handleCROMResetToggle(null, this.state.cromResetToggleState);

    this.setState({
      wa1right: req.wa1right,
      wa1ratio: Number(req.wa1ratio),
      wa1delta: Number(req.wa1delta),
      wa1width: Number(req.wa1width),
      wa2right: req.wa2right,
      wa2ratio: Number(req.wa2ratio),
      wa2delta: Number(req.wa2delta),
      wa2width: Number(req.wa2width),

      strad1ratio: Number(req.strad1ratio),
      strad1delta: Number(req.strad1delta),
      strad2ratio: Number(req.strad2ratio),
      strad2delta: Number(req.strad2delta),
    }, console.log('loadPastUXRequest wingadv vars set'));


  }

  preloadSymbol()  // gotta pass it in because setting the state var occurs async
  {
    if( this.state.symbol.length === 0 ) {
      console.log('no symbol entered');
      this.setState({ symbolErr: 'Symbol cannot be blank.' }, () => console.log('symbolErr:', this.state.symbolErr));
      return;
    }

    this.setState({ symbolErr: '' }, () => console.log('symbolErr:', this.state.symbolErr));

    // prepare payload
    // const { getIdToken } = this.props.auth;
    const formPayload = {
      // idtoken: getIdToken(),
      token: localStorage.getItem('token'),
      symbol: this.state.symbol,
    };

    // auth info
    const { getAccessToken } = this.props.auth;
    const API_URL = `${process.env.REACT_APP_API_BASE_URL}/Preload`;
    var token = getAccessToken();
    //console.log('token: ' + token );
    const headers = { 'Authorization': `Bearer ` + token }
    //console.log('headers: ', headers );

    var config = {
      'headers': headers,
      'params': formPayload
    }

    console.log('config', config);


    axios.get(API_URL, config)
      //.then(response => console.log("response message: ", response.data ))
      .then( this.symbolResponse )
      .catch(error => console.log("preload axios error: ", error.message ));

  }

  symbolResponse(json)
  {
    console.log("json:", json);

    // this.setState( {errormsg: json.Error} );
    if( json.data.Symbol === "-1") {
      this.setState({ symbolErr: 'Invalid symbol.' }, () => console.log('symbolErr:', this.state.symbolErr));
    }
    else {
      this.setState({ symbolErr: '' }, () => console.log('symbolErr:', this.state.symbolErr));
      console.log('Symbol Preloaded');
    }
  }

  handleSymbolChange(e) {
    this.setState({ symbol: e.target.value.toUpperCase() }, () => console.log('symbol:', this.state.symbol));
    //this.preloadSymbol(e.target.value.toUpperCase());
  }

  handleStartDateChange(e, sdate) {
    console.log('in handleStartDateChange', sdate);

    var ystart = sdate.getFullYear();
    console.log('ystart: ', ystart );
    if( ystart < btStartYear ) {
      this.setState({ snacktext: 'Our backtests go back to the start of 2013.' }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
      this.setState( {snackhelp: 'date'} );
      sdate = btStartDataDate;
      //document.getElementById("startdate-input").value = sdate;
    }

    this.setState({ startDate: sdate }, () => console.log('startDate:', this.state.startDate));
  }

  handleEndDateChange(e, edate) {
    console.log('in handleEndDateChange', edate);
    var today = new Date();

    //var datesplit = String(date).split('-');
    //var ystart = Number(datesplit[0]);
    if( edate.getFullYear() < btStartYear ) {
      this.setState({ snacktext: 'Our backtests go back to the start of 2013.' }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
      edate = btStartDataDate;
    }
    else if( edate.getTime() > today.getTime() )
    {
      var dayOffset = 1; // "yesterday"
      if( today.getDay() === 0 ) { // its sunday, subtract 2 days
        dayOffset = 2;
      }
      // var lastDate = today.setDate(today.getDate()+dayOffset);
      var lastDate = new Date(today - dayOffset*24*3600*1000);
      var lastDateStr = String(lastDate);
      var lastDateFmt = lastDateStr.substring(0, lastDateStr.indexOf(today.getFullYear())+4 );
      // var lastDate = new Date(new Date().setDate(new Date().getDate()+dayOffset));
      this.setState({ snacktext: 'You can run backtests only through ' + lastDateFmt + '.' }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
      this.setState( {snackhelp: 'date'} );

      edate = lastDate;
      console.log('new end date:', edate);
    }

    this.setState({ endDate: edate }, () => console.log('endDate:', this.state.endDate));
  }

  handleAUMChange(e) {
    this.setState({ aumErr: ''});
    var xaum = Number(e.target.value);
    if( xaum <= 0 ) {
      this.setState({ aumErr: 'Please enter a positive starting capital amount.' }, () => console.log('aumErr:', this.state.aumErr));
      this.setState({ snacktext: 'Please enter a positive starting capital amount.' }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
    }

    this.setState({ aum: e.target.value }, () => console.log('aum:', this.state.aum));
  }

  handleAllocMethChange(e) {
    switch( e.target.value )
    {
      case 'dollars':
        if( this.state.allocmeth !== "dollars" ) {
          this.setState({ allocmeth: e.target.value }, () => console.log('allocmeth:', this.state.allocmeth));
          this.setState({ tradealloc: (this.state.tradealloc * this.state.aum / 100) }, () => console.log('tradealloc:', this.state.tradealloc));
          this.setState({ expmarginlim: (this.state.expmarginlim * this.state.aum / 100) }, () => console.log('expmarginlim:', this.state.expmarginlim));
          document.getElementById("ignoretradediv").style.display = 'block';
          this.setState({ allocleft: -22, allocicon: 'fa fa-usd' }, () => console.log('allocleft:', this.state.allocleft, 'allocicon:', this.state.allocicon));
        }
        break;
      case 'percent':
        if( this.state.allocmeth !== "percent" ) {
          this.setState({ allocmeth: e.target.value }, () => console.log('allocmeth:', this.state.allocmeth));
          this.setState({ tradealloc: (this.state.tradealloc / this.state.aum * 100) }, () => console.log('tradealloc:', this.state.tradealloc));
          this.setState({ expmarginlim: (this.state.expmarginlim / this.state.aum * 100) }, () => console.log('expmarginlim:', this.state.expmarginlim));
          document.getElementById("ignoretradediv").style.display = 'none';
          this.setState({ allocleft: 100, allocicon: 'fa fa-percent' }, () => console.log('allocleft:', this.state.allocleft, 'allocicon:', this.state.allocicon));
        }
        break;
      default:
        console.log('switch default case... should never hit');
        break;
    }
  }

  handleDTEMethChange(e) {
    this.setState({ dtemeth: e.target.value }, () => console.log('dtemeth:', this.state.dtemeth));
  }

  handleDTERangeToggle(e, isToggled) {
    this.setState({ dteRangeToggleState: isToggled }, () => console.log('dteRangeToggleState:', this.state.dteRangeToggleState));
    this.setState({ mindteLabel: isToggled ? 'Min Days to Expiry' : 'Days to Expiry' }, () => console.log('mindteLabel:', this.state.mindteLabel));
    document.getElementById("closemindiv").style.display = isToggled ? 'none' : 'block';
    document.getElementById("maxdtediv").style.display = isToggled ? 'block' : 'none';
  }

  handleDTETooltip(elem, show) {
    switch( elem )
    {
      case 'range':
        this.setState({ dteRangeTooltipShow: show }, () => console.log('dteRangeTooltipShow:', this.state.dteRangeTooltipShow));
        break;
      case 'closest':
        this.setState({ dteTooltipLabel: 'Will pick the expiry closest to this number. DTE could end up being closer or farther.' }, () => console.log('dteTooltipLabel:', this.state.dteTooltipLabel));
        this.setState({ dteTooltipShow: show }, () => console.log('dteTooltipShow:', this.state.dteTooltipShow));
        // this.setState({ dteTooltipHoriz: 'left' }, () => console.log('dteTooltipHoriz:', this.state.dteTooltipHoriz));
        // this.setState({ dteTooltipVert: 'top' }, () => console.log('dteTooltipVert:', this.state.dteTooltipVert));
        break;
      case 'minimum':
        this.setState({ dteTooltipLabel: 'Will guarantee the expiry picked is further than this number. Note, if a product has only monthly expiries, the DTE expiry selected could be farther than expected.' }, () => console.log('dteTooltipLabel:', this.state.dteTooltipLabel));
        this.setState({ dteTooltipShow: show }, () => console.log('dteTooltipShow:', this.state.dteTooltipShow));
        // this.setState({ dteTooltipHoriz: 'left' }, () => console.log('dteTooltipHoriz:', this.state.dteTooltipHoriz));
        // this.setState({ dteTooltipVert: 'top' }, () => console.log('dteTooltipVert:', this.state.dteTooltipVert));
        break;
      default:
        break;
    }
  }

  handleTooltipToggle(e, isToggled) {
    this.setState({ tooltipToggleState: isToggled }, () => console.log('tooltipToggleState:', this.state.tooltipToggleState));
  }

  handleTooltip(elem) {
    if( !this.state.tooltipToggleState ) {
      return;
    }

    switch( elem )
    {
      case 'mindte':
        if( this.state.dteRangeToggleState )
        {
          tooltipOpenMap['mindte'] = !tooltipOpenMap['mindte'];
          this.setState({ tooltipMap: tooltipOpenMap }, () => console.log('tooltipMap[mindte]:', this.state.tooltipMap['mindte']) );
        }
        break;

      // must be last next to default
      case 'dir':
        if( this.state.strategytype !== 'Condor' ) {
          return;
        }
        // should fall thru to default
      default:
        tooltipOpenMap[elem] = !tooltipOpenMap[elem];
        this.setState({ tooltipMap: tooltipOpenMap }, () => console.log('tooltipMap[' + elem + ']:', this.state.tooltipMap[elem]) );
        break;
    }
  }

  handleTradeAllocChange(e) {
    this.setState({ allocErr: ''});
    var xalloc = Number(e.target.value);
    console.log('xalloc: ', xalloc);
    var alerr = '';
    if( xalloc < 0 ) {
      alerr = 'Capital per trade must be positive.';
      // e.target.value = this.state.aum;
    }
    else if( this.state.allocmeth === "dollars" ) {
      if( xalloc > this.state.aum ) {
        alerr = 'Capital per trade cannot be larger than total portfolio capital.';
        e.target.value = this.state.aum;
      }
    }
    else if( this.state.allocmeth === "percent" ) {
      if( xalloc > 100 ) {
        alerr = 'Percent allocation cannot be larger than 100% of total capital.';
        e.target.value = 100;
      }
    }

    this.setState({ allocErr: alerr }, () => console.log('allocErr:', this.state.allocErr));
    this.setState({ snacktext: alerr }, () => console.log('snacktext:', this.state.snacktext));
    this.setState( {snackopen: true} );

    this.setState({ tradealloc: e.target.value }, () => console.log('tradealloc:', this.state.tradealloc));
  }

  handleExpLimChange(e) {
    this.setState({ expMarginErr: ''});
    var expalloc = Number(e.target.value);
    console.log('expalloc: ', expalloc);
    var exerr = '';
    if( expalloc <= 0 ) {
      exerr = 'Capital per expiration must be positive.';
      // e.target.value = this.state.aum;
    }
    else if( expalloc < this.state.tradealloc ) {
      exerr = 'Capital per expiration cannot be less than capital per trade.';
      e.target.value = this.state.tradealloc;
    }
    else if( expalloc > this.state.aum ) {
      exerr = 'Capital per expiration cannot be larger than total portfolio capital.';
      e.target.value = this.state.aum;
    }

    this.setState({ expMarginErr: exerr }, () => console.log('expMarginErr:', this.state.expMarginErr));
    this.setState({ snacktext: exerr }, () => console.log('snacktext:', this.state.snacktext));
    this.setState( {snackopen: true} );

    this.setState({ expmarginlim: e.target.value }, () => console.log('expmarginlim:', this.state.expmarginlim));
  }

  handleOneTradeChange(e, isToggled) {
    this.setState({ oneTradeToggleState: isToggled }, () => console.log('oneTradeToggleState:', this.state.oneTradeToggleState));
    // console.log('isToggled:', isToggled);
    // if( isToggled ) {
    //   document.getElementById("expmarginlim-input").disabled = true;
    // }
    // else {
    //   document.getElementById("expmarginlim-input").disabled = false;
    // }
    // document.getElementById("maxallocdiv").style.visibility = isToggled ? 'hidden' : 'visible';
    // document.getElementById("spanallocpercent2").style.visibility = isToggled ? 'hidden' : 'visible';
  }

  handleIgnoreTradePnl(e, isToggled) {
    this.setState({ ignorepnl: isToggled }, () => console.log('ignorepnl:', this.state.ignorepnl));
  }

  handleStratTypeSelect(event, index, value) {

    this.setState({ strategytype: value }, () => this.setWinMethodText('strat') );
    this.setState({ strategytypeErr: ''});

    // these are defaults. want to make sure on every change, we're turning all default things off unless explicitly changed below
    document.getElementById("deltalabeldiv").style.display = 'block';
    document.getElementById("spwidthinputdiv").style.display = 'none';
    document.getElementById("flyrightselectdiv").style.display = 'none';
    document.getElementById("flyrightselectdiv").style.visibility = 'hidden';
    document.getElementById("advtogglediv").style.display = 'none';
    this.handleAdvancedToggle(event, false);  // reset this
    document.getElementById("wingadvdiv").style.display = 'none';
    document.getElementById("flyname").style.display = 'none';
    document.getElementById("diaginputdiv").style.display = 'none';
    document.getElementById("stradadvdiv").style.display = 'none';

    switch( value )
    {
      case 'Vertical':
        document.getElementById("spwidthinputdiv").style.display = 'block';
        document.getElementById("flyrightselectdiv").style.display = 'block';
        document.getElementById("flyrightselectdiv").style.visibility = 'visible';
        this.setState({ deltalabel: 'Delta'}, () => console.log('deltalabel:', this.state.deltalabel));
        this.setState({ deltatooltip: 'Minimum delta of the ' + this.state.dir.toLowerCase() + ' option (closer to the market)'}, () => console.log('deltatooltip:', this.state.deltatooltip));
        this.setState({ spwidthlabel: 'Spread Width'}, () => console.log('spwidthlabel:', this.state.spwidthlabel));
        this.setState({ spwidthtooltip: 'Strike distance between short and long options'}, () => console.log('spwidthtooltip:', this.state.spwidthtooltip));
        break;
      case 'Condor':
        document.getElementById("advtogglediv").style.display = 'none'; // should be block. but turning off until we fix the major bugs in advanced condors.
        document.getElementById("spwidthinputdiv").style.display = 'block';
        document.getElementById("flyrightselectdiv").style.display = 'none';
        document.getElementById("flyrightselectdiv").style.visibility = 'hidden';
        this.setState({ deltalabel: 'Delta'}, () => console.log('deltalabel:', this.state.deltalabel));
        var dirswitch = this.state.dir === "Long" ? "short" : "long";
        this.setState({ deltatooltip: 'Minimum delta of the ' + dirswitch + ' options (closer to the market)'}, () => console.log('deltatooltip:', this.state.deltatooltip));
        this.setState({ spwidthlabel: 'Spread Width'}, () => console.log('spwidthlabel:', this.state.spwidthlabel));
        this.setState({ spwidthtooltip: 'Strike distance between short and long options'}, () => console.log('spwidthtooltip:', this.state.spwidthtooltip));
        document.getElementById("wingadvdelta1").style.display = 'block';
        document.getElementById("wingadvdelta2").style.display = 'block';
        document.getElementById("wingadvdelta3").style.display = 'block';
        document.getElementById("wingwidthlabel").innerHTML = 'Spread Width';
        break;
      case 'Butterfly':
        document.getElementById("advtogglediv").style.display = 'block';
        document.getElementById("spwidthinputdiv").style.display = 'block';
        document.getElementById("flyrightselectdiv").style.display = 'block';
        document.getElementById("flyrightselectdiv").style.visibility = 'visible';
        this.setState({ deltalabel: 'Middle Strike Delta'}, () => console.log('deltalabel:', this.state.deltalabel));
        this.setState({ deltatooltip: 'Minimum delta of the middle strike'}, () => console.log('deltatooltip:', this.state.deltatooltip));
        this.setState({ spwidthlabel: 'Wing Width'}, () => console.log('spwidthlabel:', this.state.spwidthlabel));
        this.setState({ spwidthtooltip: 'Strike distance between the middle strike and the wings'}, () => console.log('spwidthtooltip:', this.state.spwidthtooltip));
        document.getElementById("wingadvdelta1").style.display = 'none';
        document.getElementById("wingadvdelta2").style.display = 'none';
        document.getElementById("wingadvdelta3").style.display = 'none';
        document.getElementById("wingwidthlabel").innerHTML = 'Wing Width';
        document.getElementById("flyname").style.display = 'block';
        break;
      case 'Calendar':
        document.getElementById("spwidthinputdiv").style.display = 'block';
        document.getElementById("flyrightselectdiv").style.display = 'block';
        document.getElementById("flyrightselectdiv").style.visibility = 'visible';
        this.setState({ deltalabel: 'Delta'}, () => console.log('deltalabel:', this.state.deltalabel));
        this.setState({ deltatooltip: 'Minimum delta of the near term option'}, () => console.log('deltatooltip:', this.state.deltatooltip));
        this.setState({ spwidthlabel: 'Expiration Width (Days)'}, () => console.log('spwidthlabel:', this.state.spwidthlabel));
        this.setState({ spwidthtooltip: 'Number of calendar days between the near and far term expiration'}, () => console.log('spwidthtooltip:', this.state.spwidthtooltip));
        break;
      case 'Diagonal':
        document.getElementById("spwidthinputdiv").style.display = 'block';
        document.getElementById("flyrightselectdiv").style.display = 'block';
        document.getElementById("flyrightselectdiv").style.visibility = 'visible';
        this.setState({ deltalabel: 'Near Term Delta'}, () => console.log('deltalabel:', this.state.deltalabel));
        this.setState({ deltatooltip: 'Minimum delta of the near term option'}, () => console.log('deltatooltip:', this.state.deltatooltip));
        this.setState({ spwidthlabel: 'Expiration Width (Days)'}, () => console.log('spwidthlabel:', this.state.spwidthlabel));
        this.setState({ spwidthtooltip: 'Number of calendar days between the near and far term expiration'}, () => console.log('spwidthtooltip:', this.state.spwidthtooltip));
        document.getElementById("diaginputdiv").style.display = 'block';
        break;
      case 'Straddle':
      case 'Strangle':
        document.getElementById("advtogglediv").style.display = 'block';
        document.getElementById("spwidthinputdiv").style.display = 'none';
        this.setState({ deltalabel: 'Delta'}, () => console.log('deltalabel:', this.state.deltalabel));
        this.setState({ deltatooltip: 'Minimum delta of the option (may be greater)'}, () => console.log('deltatooltip:', this.state.deltatooltip));
        break;
      case 'Call':
      case 'Put':
        console.log('stratchange to call/put...');
        document.getElementById("advtogglediv").style.display = 'none';
        document.getElementById("spwidthinputdiv").style.display = 'none';
        this.setState({ deltalabel: 'Delta'}, () => console.log('deltalabel:', this.state.deltalabel));
        this.setState({ deltatooltip: 'Minimum delta of the option (may be greater)'}, () => console.log('deltatooltip:', this.state.deltatooltip));
        break;
      default:
        break;
    }

    // reset advanced stats if we switch strategy
    this.handleClearAdvanced();
  }

  handleDirSelect(event, index, value) {
    this.setState({ dir: value }, () => this.setWinMethodText('dir') );
    this.setState({ dirErr: ''});

    if( this.state.strategytype === 'Vertical' || this.state.strategytype === 'Call' || this.state.strategytype === 'Put')
    {
      switch( value )
      {
        case 'Short':
          this.setState({ deltalabel: 'Short Delta'}, () => console.log('deltalabel:', this.state.deltalabel));
          break;
        case 'Long':
          this.setState({ deltalabel: 'Long Delta'}, () => console.log('deltalabel:', this.state.deltalabel));
          break;
        default:
          this.setState({ deltalabel: 'Delta'}, () => console.log('deltalabel:', this.state.deltalabel));
          break;
      }
    }
  }

  handleCommishChange(e) {
    var xcommish = Number(e.target.value);
    console.log('xcommish: ', xcommish);
    if( xcommish < 0 ) {
      this.setState({ commishErr: 'Commission cannot be less than zero.' }, () => console.log('commishErr:', this.state.commishErr));
      this.setState({ snacktext: 'Commission cannot be less than zero.' }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
      e.target.value = 0;
    }
    this.setState({ commish: e.target.value }, () => console.log('commish:', this.state.commish));
  }

  handleExecChange(event, index, value) {
    this.setState({ execmeth: value }, () => console.log('execmeth:', this.state.execmeth));
  }

  handleMinDTEChange(e) {
    this.setState({ mindteErr: ''});
    var xdte = Number(e.target.value);
    console.log('xdte: ', xdte);
    if( xdte <= 0 ) {
      this.setState({ mindteErr: 'Days to expiry must be greater than zero.' }, () => console.log('mindteErr:', this.state.mindteErr));
      this.setState({ snacktext: 'Days to expiry must be greater than zero.' }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
      // e.target.value = 30;
    }

    this.setState({ mindte: e.target.value }, () => console.log('mindte:', this.state.mindte));
  }

  handleMaxDTEChange(e) {
    this.setState({ maxdteErr: ''});
    var xdte = Number(e.target.value);
    console.log('xdte: ', xdte);
    if( xdte <= 0 ) {
      this.setState({ maxdteErr: 'Max days to expiry must be greater than zero.' }, () => console.log('maxdteErr:', this.state.maxdteErr));
      this.setState({ snacktext: 'Max days to expiry must be greater than zero.' }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
      // e.target.value = 30;
    }
    else if( xdte <= this.state.mindte ) {
      this.setState({ maxdteErr: 'Max days to expiry must be greater than min days to expiry.' }, () => console.log('maxdteErr:', this.state.maxdteErr));
      this.setState({ snacktext: 'Max days to expiry must be greater than min days to expiry.' }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
      // e.target.value = 30;
    }

    this.setState({ maxdte: e.target.value }, () => console.log('maxdte:', this.state.maxdte));
  }

  handleEntryDeltaChange(event, newValue) {
    this.setState({ deltaErr: ''});
    this.setState({ diagErr: ''});
    // console.log('xdelta: ', xdelta, 'id:', event.target.id);

    var diagfd = false;
    switch(event.target.id)
    {
      case 'entrydelta-input':
        this.setState({ entrydelta: newValue }, () => console.log('entrydelta:', this.state.entrydelta));
        break;
      case 'diagfardelta-input':
        diagfd = true;
        this.setState({ diagfardelta: newValue }, () => console.log('diagfardelta:', this.state.diagfardelta));
        break;
      default:
        console.log('switch default case... should never hit');
        break;
    }

    var xdelta = Number(newValue);
    if( xdelta <= 0 || xdelta >= 1 ) {
      if( diagfd ) {
        this.setState({ diagErr: 'Delta must be a positive number between 0 and 1 for calls and puts.' }, () => console.log('diagErr:', this.state.diagErr));
      }
      else {
        this.setState({ deltaErr: 'Delta must be a positive number between 0 and 1 for calls and puts.' }, () => console.log('deltaErr:', this.state.deltaErr));
      }

      this.setState({ snacktext: 'Delta must be a positive number between 0 and 1 for calls and puts.' }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
      // e.target.value = 0.5;
    }

  }

  handleSPWidthChange(event, newValue) {
    this.setState({ widthErr: ''});
    var xspw = Number(newValue);
    // console.log('xspw: ', xspw);
    if( xspw <= 0 ) {
      this.setState({ snacktext: 'Spread width must be greater than zero.' }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
      // e.target.value = 30;
    }

    this.setState({ spwidth: newValue }, () => console.log('spwidth:', this.state.spwidth));
  }

  handleDailyToggle(e, isToggled) {
    this.setState({ dailyToggleState: isToggled }, () => console.log('dailyToggleState:', this.state.dailyToggleState));
  }

  handleDailyChange(e) {
    this.setState({ chngErr: ''});
    this.setState({ dailychange: e.target.value }, () => console.log('dailychange:', this.state.dailychange));
  }

  handleVVIXToggle(e, isToggled) {
    this.setState({ vvixToggleState: isToggled }, () => console.log('vvixToggleState:', this.state.vvixToggleState));
    // if( !isToggled ) {
    //   this.setState({ vvixmethod: '' }, () => console.log('vvixmethod:', this.state.vvixmethod));
    //   this.setState({ vvixLabelText: 'VVIX is Above/Below...' }, () => console.log('vvixLabelText:', this.state.vvixLabelText));
    // }
  }

  handleVVIXChange(e) {
    this.setState({ vvixErr: ''});
    this.setState({ vvixlim: e.target.value }, () => console.log('vvixlim:', this.state.vvixlim));
  }

  handleVIXToggle(e, isToggled) {
    this.setState({ vixToggleState: isToggled }, () => console.log('vixToggleState:', this.state.vixToggleState));
  }

  handleVIXChange(e) {
    this.setState({ vixErr: ''});
    this.setState({ vixlim: e.target.value }, () => console.log('vixlim:', this.state.vixlim));
  }

  handleVolToggle(e, isToggled) {
    this.setState({ contangoErr: ''});
    this.setState({ backwardErr: ''});
    switch(e.target.id)
    {
      case 'contango-toggle':
        this.setState({ vixContangoToggleState: isToggled }, () => console.log('vixContangoToggleState:', this.state.vixContangoToggleState));
        break;
      case 'backward-toggle':
        this.setState({ vixBackwardToggleState: isToggled }, () => console.log('vixBackwardToggleState:', this.state.vixBackwardToggleState));
        break;
    }
  }

  handleContangoSelect(event, index, value) {
    this.setState({ contangoErr: ''});
    this.setState({ vixContangoVal: value }, () => console.log('vixContangoVal:', this.state.vixContangoVal));
    var conttxt = "Cash or Futures";
    switch(index)
    {
      case 0:
        conttxt = "Cash Contango";
        break;
      case 1:
        conttxt = "Futures Contango";
        break;
      default:
        conttxt = 'Cash or Futures';
        break;
    }
    this.setState({ contangoLabelText: conttxt }, () => console.log('contangoLabelText:', this.state.contangoLabelText));
  }

  handleBackwardSelect(event, index, value) {
    this.setState({ backwardErr: ''});
    this.setState({ vixBackwardVal: value }, () => console.log('vixBackwardVal:', this.state.vixBackwardVal));
    var backtxt = "Cash or Futures";
    switch(index)
    {
      case 0:
        backtxt = "Cash Backwardation";
        break;
      case 1:
        backtxt = "Futures Backwardation";
        break;
      default:
        backtxt = 'Cash or Futures';
        break;
    }
    this.setState({ backwardLabelText: backtxt }, () => console.log('backwardLabelText:', this.state.backwardLabelText));
  }

  handleHVToggle(e, isToggled) {
    this.setState({ hvToggleState: isToggled }, () => console.log('hvToggleState:', this.state.hvToggleState));
  }

  handleHVPerSelect(event, index, value) {
    this.setState({ hvper: value }, () => console.log('hvper:', this.state.hvper));
  }

  handleIVToggle(e, isToggled) {
    this.setState({ ivToggleState: isToggled }, () => console.log('ivToggleState:', this.state.ivToggleState));
  }

  handleIVPerSelect(event, index, value) {
    this.setState({ ivper: value }, () => console.log('ivper:', this.state.ivper));
  }

  handleVVIXSelect(event, index, value) {
    this.setState({ vvixmethod: value }, () => console.log('vvixmethod:', this.state.vvixmethod));
    this.setState({ vvixLabelText: "VVIX is" }, () => console.log('vvixLabelText:', this.state.vvixLabelText));
  }

  handleVIXSelect(event, index, value) {
    this.setState({ vixmethod: value }, () => console.log('vixmethod:', this.state.vixmethod));
    this.setState({ vixLabelText: "VIX is" }, () => console.log('vixLabelText:', this.state.vixLabelText));
  }

  handleHVSelect(event, index, value) {
    this.setState({ hvmethod: value }, () => console.log('hvmethod:', this.state.hvmethod));
  }

  handleHVChange(e) {
    this.setState({ hvErr: ''});
    if( e.target.value <= 0 ) {
      this.setState({ hvErr: 'HV limit must be greater than zero.' }, () => console.log('hvErr:', this.state.hvErr));
      this.setState({ snacktext: 'HV limit must be greater than zero.' }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
    }

    this.setState({ hvlim: e.target.value }, () => console.log('hvlim:', this.state.hvlim));
  }

  handleIVSelect(event, index, value) {
    this.setState({ ivmethod: value }, () => console.log('ivmethod:', this.state.ivmethod));
  }

  handleIVChange(e) {
    this.setState({ ivErr: ''});
    if( e.target.value <= 0 ) {
      this.setState({ ivErr: 'IV limit must be greater than zero.' }, () => console.log('ivErr:', this.state.ivErr));
      this.setState({ snacktext: 'IV limit must be greater than zero.' }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
    }

    this.setState({ ivlim: e.target.value }, () => console.log('ivlim:', this.state.ivlim));
  }

  handleCloseDTEChange(event, newValue) {
    var cdte = Number(newValue);
    console.log('cdte: ', cdte);
    if( cdte < 0 ) {
      this.setState({ closedteErr: 'Days to Close Before Expiry should be 0 or greater.' }, () => console.log('closedteErr:', this.state.closedteErr));
      this.setState({ snacktext: 'Days to Close Before Expiry should be 0 or greater.' }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
      newValue = 0;
    }
    else if( cdte >= this.state.mindte ) {
      var mindtetxt = this.state.mindte == "" ? 0 : this.state.mindte;
      var cderr = 'Days to Close Before Expiry must be less than days to expiry (' + mindtetxt + ').';
      this.setState({ closedteErr: cderr }, () => console.log('closedteErr:', this.state.closedteErr));
      this.setState({ snacktext: cderr }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
      newValue = Math.max(mindtetxt-1,0);
    }

    this.setState({ closedte: newValue, closedteToggleState: false }, () => console.log('closedte:', this.state.closedte, 'closedteToggleState:', this.state.closedteToggleState));
  }

  handleWinMethodSelect(e) {
    this.setState({ winmethod: e.target.value }, () => console.log('winmethod:', this.state.winmethod));
  }

  handleWinThreshChange(e) {
    var lvalid = this.getTradeNetType();
    var werr = '';
    if( lvalid === "DEBIT" ) {
      if( e.target.value < 0 ) {
        werr = 'Percentage of margin must be positive (and can also go over 100%).';
        e.target.value = 0;
      }
    }
    else if( lvalid === "CREDIT") {
      if( e.target.value > 100 ) {
        werr = 'A ' + this.state.dir + ' ' + this.state.strategytype + ' cannot capture more than 100% of total credit.';
        e.target.value = 100;
      }
      else if( e.target.value < 0 ) {
        werr = 'Percenage of credit to capture must be positive.';
        e.target.value = 0;
      }
      this.setState({ winErr: werr }, () => console.log('winErr:', this.state.winErr));
      this.setState({ snacktext: werr }, () => console.log('snacktext:', this.state.snacktext));
      this.setState( {snackopen: true} );
    }

    this.setState({ winthresh: e.target.value, winnerToggleState: true }, () => console.log('winthresh:', this.state.winthresh, 'winnerToggleState:', this.state.winnerToggleState));
  }

  handleCloseDTEToggle(e, isToggled) {
    this.setState({ closedteToggleState: isToggled }, () => console.log('closedteToggleState:', this.state.closedteToggleState));
  }

  handleWinnerToggle(e, isToggled) {
    this.setState({ winnerToggleState: isToggled }, () => console.log('winnerToggleState:', this.state.winnerToggleState));
  }

  handleLoserToggle(e, isToggled) {
    this.setState({ loserToggleState: isToggled }, () => console.log('loserToggleState:', this.state.loserToggleState));
  }

  handleCROMToggle(e, isToggled) {
    this.setState({ cromToggleState: isToggled }, () => console.log('cromToggleState:', this.state.cromToggleState));
  }

  handleCROMResetToggle(e, isToggled) {
    this.setState({ cromResetToggleState: isToggled }, () => console.log('cromResetToggleState:', this.state.cromResetToggleState));
  }

  handleCROMLimChange(e) {
    var cerr = '';
    if( e.target.value < 0 ) {
      cerr = 'Choose a positive percent. Positions will be reduced every time a multiple of this limit is reached.';
      e.target.value = 0;
    }
    console.log('cromlimchange: ' + e.target.id + "=" + e.target.value);
    switch(e.target.id)
    {
      case 'cromlimW-input':
        this.setState({ cromlimErr: cerr }, () => console.log('cromlimErr:', this.state.cromlimErr));
        this.setState({ cromlimW: e.target.value }, () => console.log('cromlimW:', this.state.cromlimW));
        break;
      case 'cromlimL-input':
        this.setState({ cromlimLErr: cerr }, () => console.log('cromlimLErr:', this.state.cromlimLErr));
        this.setState({ cromlimL: e.target.value }, () => console.log('cromlimL:', this.state.cromlimL));
        break;
    }

    this.setState({ snacktext: cerr }, () => console.log('snacktext:', this.state.snacktext));
    this.setState( {snackopen: true} );

    if( !this.state.cromToggleState ) {
      this.handleCROMToggle(null, true);
    }
  }

  handleCROMCloseChange(e) {
    var cerr = '';
    if( e.target.value < 0 ) {
      cerr = 'Choose a positive percent of the original position that will be reduced.';
      e.target.value = 0;
    }
    else if( e.target.value > 100 ) {
      cerr = 'You cannot reduce more than 100% of the position!';
      e.target.value = 100;
    }

    switch(e.target.id)
    {
      case 'cromcloseW-input':
        this.setState({ cromcloseErr: cerr }, () => console.log('cromcloseErr:', this.state.cromcloseErr));
        this.setState({ cromcloseW: e.target.value }, () => console.log('cromcloseW:', this.state.cromcloseW));
        break;
      case 'cromcloseL-input':
        this.setState({ cromcloseLErr: cerr }, () => console.log('cromcloseLErr:', this.state.cromcloseLErr));
        this.setState({ cromcloseL: e.target.value }, () => console.log('cromcloseL:', this.state.cromcloseL));
        break;
    }

    this.setState({ snacktext: cerr }, () => console.log('snacktext:', this.state.snacktext));
    this.setState( {snackopen: true} );

    if( !this.state.cromToggleState ) {
      this.handleCROMToggle(null, true);
    }
  }

  handleTROMChange(e) {
    this.setState({ trom: e.target.value }, () => console.log('trom:', this.state.trom));
  }

  getTradeNetType()
  {
    var key = this.state.strategytype + "|" + this.state.dir;
    var lvalid = this.state.stratValid[key];
    //console.log("key:", key, "typeof:", typeof(lvalid), "lvalid:", lvalid );
    // if( lvalid !== "DEBIT" && lvalid !== "CREDIT" ) {  // it may be IRON
    if( typeof(lvalid) === "object" ) {  // it may be IRON
      var xvalid = lvalid["Regular"];
      if( this.state.advToggleState && (this.state.wa1right !== this.state.wa2right) ) {
        xvalid = lvalid["Iron"];
      }
      else if( this.state.strategytype === "Condor" && !this.state.advToggleState) {
        xvalid = lvalid["Iron"];
      }

      lvalid = xvalid;
    }
    return lvalid;
  }

  setWinMethodText(trigger) {
    if( trigger === 'strat') {
      console.log('strategytype:', this.state.strategytype);
      // need to toggle this here once we're sure the state has updated
      this.handleLegPNLVisibility();
    }
    else if(trigger === 'dir') {
      console.log('dir:', this.state.dir);
    }
    else if( trigger === 'wa1right') {
      console.log('wa1right:', this.state.wa1right);
    }
    else if( trigger === 'wa2right') {
      console.log('wa2right:', this.state.wa2right)
    }

    var lvalid = this.getTradeNetType();
    if( lvalid === "DEBIT" ) {
      this.setState( {winmethodtext: 'Percentage of Margin Used'}, () => console.log('winmethodtext', this.state.winmethodtext) );
      this.setState( {cromlabel: 'Percent Increment of ROM'}, () => console.log('cromlabel', this.state.cromlabel) );
    }
    else if( lvalid === "CREDIT") {
      this.setState( {winmethodtext: 'Percentage of Credit Received'}, () => console.log('winmethodtext', this.state.winmethodtext) );
      this.setState( {cromlabel: 'Percent Increment of Credit'}, () => console.log('cromlabel', this.state.cromlabel) );
    }

    // payoff diagram
    this.makePayoffChart();
  }

  makePayoffChart() {

    if( this.state.strategytype != '' && this.state.dir != '' ) {
      console.log('constructing makePayoffChart:', this.state.dir, this.state.strategytype);
      var sM = -1;  // short multiplier
      if( this.state.dir == 'Long') { sM = 1; } else { sM = -1; }

      var payoffDataDict = {}
      payoffDataDict["Call"] = [{x:0,y:sM*-20},{x:1,y:sM*-20},{x:2,y:sM*-20},{x:3,y:sM*0},{x:4,y:sM*20},{x:5,y:sM*40}];//,{x:6,y:sM*40},{x:7,y:sM*60}];
      payoffDataDict["Put"] = [{x:0,y:sM*40},{x:1,y:sM*20},{x:2,y:sM*0},{x:3,y:sM*-20},{x:4,y:sM*-20},{x:5,y:sM*-20}];//,{x:6,y:sM*-20},{x:7,y:sM*-20}];
      payoffDataDict["Vertical"] = [{x:0,y:sM*-20},{x:1,y:sM*-20},{x:2,y:sM*-20},{x:3,y:sM*20},{x:4,y:sM*20},{x:5,y:sM*20}];//,{x:6,y:sM*20},{x:7,y:sM*20}];
      payoffDataDict["Straddle"] = [{x:0,y:sM*20},{x:1,y:sM*0},{x:2,y:sM*-20},{x:3,y:sM*0},{x:4,y:sM*20}];//,{x:5,y:sM*20},{x:6,y:sM*20},{x:7,y:sM*20}];
      payoffDataDict["Strangle"] = [{x:0,y:sM*60},{x:1,y:sM*20},{x:2,y:sM*-20},{x:3,y:sM*-20},{x:4,y:sM*20},{x:5,y:sM*60}];//,{x:6,y:sM*20},{x:7,y:sM*20}];
      payoffDataDict["Calendar"] = [{x:0,y:sM*-30},{x:1,y:sM*-25},{x:2,y:sM*-10},{x:3,y:sM*10},{x:4,y:sM*30},{x:5,y:sM*10},{x:6,y:sM*-10},{x:7,y:sM*-25},{x:8,y:sM*-30}];
      payoffDataDict["Diagonal"] = [{x:0,y:sM*-30},{x:1,y:sM*-20},{x:2,y:sM*0},{x:3,y:sM*30},{x:4,y:sM*0},{x:5,y:sM*-10},{x:6,y:sM*-15}];//,{x:7,y:sM*-15}];
      payoffDataDict["Butterfly"] = [{x:0,y:sM*-30},{x:1,y:sM*-30},{x:2,y:sM*30},{x:3,y:sM*-30},{x:4,y:sM*-30}];//,{x:5,y:sM*-20}];//,{x:6,y:sM*20},{x:7,y:sM*20}];
      payoffDataDict["Condor"] = [{x:0,y:sM*30},{x:1,y:sM*30},{x:2,y:sM*-30},{x:3,y:sM*-30},{x:4,y:sM*30},{x:5,y:sM*30}];//,{x:6,y:sM*20},{x:7,y:sM*20}];


      var tension = 0;
      if( this.state.strategytype == 'Calendar' || this.state.strategytype == 'Diagonal') {
        tension = 100;
      }
      console.log('tension:', tension);

      var titleText = this.state.dir + ' ' + this.state.strategytype;
      if( this.state.strategytype == 'Vertical') {
        if( this.state.dir == 'Long') {
          titleText = 'Long Call / Short Put Vertical';
        }
        else {
          titleText = 'Short Call / Long Put Vertical';
        }
      }

      var label = [];
      for(var i = 0; i < payoffDataDict[this.state.strategytype].length; i++) {
        label.push('');
      }
      //console.log('data len:', payoffDataDict[this.state.strategytype].length, "label len:", label.length);

      // for options diagram charting
      const line = {
        labels: label,//['', '', '', '', '', ''],// '', ''],
        datasets: [
          {
            ///label: 'Long Call',
            //backgroundColor: blueGrey300,
            borderColor: muicolors.pinkA400,
            borderWidth: 3,
            lineTension: 0,
            data: payoffDataDict[this.state.strategytype],
          }
        ],
      };

      const lineOpts = {
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        title: {
          display: true,
          fontFamily: 'Lobster, cursive',
          fontSize: 20,
          text: titleText,
        },
        scales: {
          xAxes: [{
            display: true,
            drawTicks: false,
            gridLines: {
              color: muicolors.transparent,
              zeroLineColor: muicolors.darkBlack
            },
            ticks: {
              ticks: {
                major: {
                  fontColor: muicolors.fullWhite,
                },
                minor: {
                  fontColor: muicolors.fullWhite,
                }
              }
            },
          }],
          yAxes: [{
            display: true,
            drawTicks: false,
            gridLines: {
              color: muicolors.faintBlack,
              zeroLineColor: muicolors.darkBlack
            },
            ticks: {
              suggestedMin: -40,
              suggestedMax: 40,
              stepSize: 40,
              major: {
                fontColor: muicolors.fullWhite,
              },
              minor: {
                fontColor: muicolors.fullWhite,
              }
            },
          }],
        },
        elements: {
          line: {
            borderColor: muicolors.pinkA400,
            borderWidth: 3,
            lineTension: tension,
            cubicInterpolationMode: 'default',
          },
          point: {
            radius: 0,
            hitRadius: 0,
            hoverRadius: 0,
          },
        }
      }

      this.setState( {payoffChart: line} );
      this.setState( {payoffChartOpts: lineOpts} );

    }
  }

  getLoserGuideText() {
    var lvalid = this.getTradeNetType();
    var linfo = '';
    if( lvalid === "DEBIT" ) {
        linfo = 'A ' + this.state.dir + ' ' + this.state.strategytype + ' requires a loser threshold between 0 and 100 (% of capital loss).';
    }
    else if( lvalid === "CREDIT") {
      linfo = 'A ' + this.state.dir + ' ' + this.state.strategytype + ' requires a loser threshold that is a percentage of credit received (can be greater than 100% too).';
    }
    return linfo;
  }

  handleLoserMultChange(e) {
    var lvalid = this.getTradeNetType();
    var lerr = '';
    var lmult = e.target.value;
    if( lvalid === "DEBIT" ) {
      if( e.target.value > 100 || e.target.value < 0 ) {
        lerr = this.getLoserGuideText();
        lmult = e.target.value > 100 ? 100 : 0;
      }
    }
    else if( lvalid === "CREDIT") {
      if( e.target.value < 0 ) {
        lerr = this.getLoserGuideText();
        lmult = 0;
      }
    }

    this.setState({ loseErr: lerr }, () => console.log('loseErr:', this.state.loseErr));
    //this.setState({ snacktext: lerr }, () => console.log('snacktext:', this.state.snacktext));
    this.setState( {snackopen: true} );

    this.setState({ losermult: lmult, loserToggleState: true }, () => console.log('losermult:', this.state.losermult, 'loserToggleState:', this.state.loserToggleState));

  }

  handleAdvancedToggle(e, isToggled) {
    this.setState({ advToggleState: isToggled }, () => console.log('advToggleState:', this.state.advToggleState));

    if( this.state.strategytype === 'Condor' || this.state.strategytype === 'Butterfly')
    {
      document.getElementById("wingadvdiv").style.display = isToggled ? 'block' : 'none';
      document.getElementById("spwidthinputdiv").style.display = isToggled ? 'none' : 'block';
      document.getElementById("flyrightselectdiv").style.display = isToggled ? 'none' : 'block';
    }
    else if( this.state.strategytype === 'Straddle' || this.state.strategytype === 'Strangle')
    {
      document.getElementById("stradadvdiv").style.display = isToggled ? 'block' : 'none';
      document.getElementById("deltalabeldiv").style.display = isToggled ? 'none' : 'block';
      //document.getElementById("spwidthinputdiv").style.display = isToggled ? 'none' : 'block';
      //document.getElementById("flyrightselectdiv").style.display = isToggled ? 'none' : 'block';
    }

    if( this.state.strategytype === 'Condor' )
    {
      document.getElementById("deltalabeldiv").style.display = isToggled ? 'none' : 'block';
    }

    this.handleClearAdvanced();
  }

  handleFlyrightChange(event, target, value) {
    this.setState({ flyright: value }, () => console.log('flyright:', this.state.flyright));
  }

  handleWingAdvChange(e) {
    switch(e.target.name)
    {
      case 'fly-right-select':
        this.setState({ flyright: e.target.value }, () => console.log('flyright:', this.state.flyright));
        break;
      case 'wa1-right-select':
        this.setState({ wa1right: e.target.value }, () => this.setWinMethodText('wa1right'));
        break;
      case 'wa1-ratio-input':
        this.setState({ wa1ratio: e.target.value }, () => console.log('wa1ratio:', this.state.wa1ratio));
        break;
      case 'wa1-delta-input':
        this.setState({ wa1delta: e.target.value }, () => console.log('wa1delta:', this.state.wa1delta));
        break;
      case 'wa1-width-input':
        this.setState({ wa1width: e.target.value }, () => console.log('wa1width:', this.state.wa1width));
        break;

      case 'wa2-right-select':
        this.setState({ wa2right: e.target.value }, () => this.setWinMethodText('wa2right'));
        break;
      case 'wa2-ratio-input':
        this.setState({ wa2ratio: e.target.value }, () => console.log('wa2ratio:', this.state.wa2ratio));
        break;
      case 'wa2-delta-input':
        this.setState({ wa2delta: e.target.value }, () => console.log('wa2delta:', this.state.wa2delta));
        break;
      case 'wa2-width-input':
        this.setState({ wa2width: e.target.value }, () => console.log('wa2width:', this.state.wa2width));
        break;

        // advanced straddle stuff
      case 'strad1-ratio-input':
        this.setState({ strad1ratio: e.target.value }, () => console.log('strad1ratio:', this.state.strad1ratio));
        break;
      case 'strad1-delta-input':
        this.setState({ strad1delta: e.target.value }, () => console.log('strad1delta:', this.state.strad1delta));
        break;
      case 'strad2-ratio-input':
        this.setState({ strad2ratio: e.target.value }, () => console.log('strad2ratio:', this.state.strad2ratio));
        break;
      case 'strad2-delta-input':
        this.setState({ strad2delta: e.target.value }, () => console.log('strad2delta:', this.state.strad2delta));
        break;

      default:
        console.log('switch default case... should never hit');
        break;
    }
  }

  handleLegPNLVisibility()
  {
    // we're not using this for now, just return
    return;
    switch( this.state.strategytype )
    {
        case 'Straddle':
        case 'Strangle':
        case 'Condor':
          document.getElementById("mgmtlegpnl").style.display = 'block';
          break;
        default:
          document.getElementById("mgmtlegpnl").style.display = 'none';
          break;
    }
  }

  handleSnackClose() {
    this.setState({ snackopen: false });
  };

  // setHelpText(elem)
  // {
  //   var title = document.getElementById("spanhelptitle");
  //   var text = document.getElementById("spanhelptext");
  //   switch(elem)
  //   {
  //     case 'symbol':
  //       title.innerHTML = 'Symbol';
  //       text.innerHTML = "Enter the symbol.  Case insensitive.  We support over 5000 symbols inclusive of equities, ETFs, and index options.  Futures options not supported as of now.";
  //       break;
  //     case 'date':
  //       title.innerHTML = 'Start/End Date';
  //       text.innerHTML = "Enter the dates to backtest (inclusive).  Stabilized price snapshots are taken between 15:45 and 16:00 EST to avoid abnormal prices right at the close.<br/>Data for our backtests begins in 2013 to present.";
  //       break;
  //     case 'portcap':
  //       title.innerHTML = 'Portfolio Capital';
  //       text.innerHTML = 'We follow a portfolio approach to backtesting. If you only want to "simulate" this one trade, set the capital per trade equal to the portfolio capital.  Using capital per trade is superior to just picking the number of contracts since over time, backtest results can be skewed if the capital invested changes due to drastic stock price changes and/or splits.';
  //       break;
  //     case 'tradecap':
  //       title.innerHTML = 'Capital Per Trade';
  //       text.innerHTML = 'The amount of capital to allocate per trade.  Setting a percentage will have the effect of compounding results.  Otherwise a fixed amount is used throughout the backtest.';
  //       break;
  //     case 'maxalloc':
  //       title.innerHTML = 'Max Capital Per Expiration'
  //       text.innerHTML = 'When capital per trade is less than portfolio capital, it is possible to enter a new trade while a previous trade is still open (subject to entry conditions).  This field prevents an o<br/>verconcentration of capital on a single expiration.';
  //       break;
  //     case 'onetrade':
  //       title.innerHTML = 'Only One Trade';
  //       text.innerHTML = 'Even if entry conditions for a new trade are met, new trades will not be entered unless the portfolio is flat.';
  //       break;
  //     case 'ignorepnl':
  //       title.innerHTML = 'Ignore Trade PNL';
  //       text.innerHTML = 'When ignoring trade pnl, all trades will execute based on dollar allocation regardless of the value of the portfolio.  If a strategy consistently loses money and the portfolio hits zero, trades will still execute based on the specified capital for trade.  <br/>While this is unrealistic, it is useful to see if a strategy regains profitability over the time period in question. <br/><br/>This option is not applicable to percentage based capital allocation since running pnl is included by default.';
  //       break;
  //     case 'strattype':
  //       title.innerHTML = 'Strategy Type';
  //       text.innerHTML = 'Common strategy types are supported.  More complex strategies will be added shortly.';
  //       break;
  //     case 'dir':
  //       title.innerHTML = 'Direction';
  //       text.innerHTML = 'Long or short.  Depending on strategy type, will determine Whether the trade will produce a net credit or debit.<br/>Long calls, puts, vertical spreads, butterflies, and calendars will produce a debit.  Long condors will produce a credit.  Going short will produce the opposite.';
  //       break;
  //     case 'commish':
  //       title.innerHTML = 'Commission';
  //       text.innerHTML = 'To see gross pnl results, set the commission per contract to zero.  Advanced commission handling coming soon.';
  //       break;
  //     case 'mindte':
  //       title.innerHTML = 'Days To Expiry';
  //       text.innerHTML = 'Number of days away to choose the expiration date.  If this date is not an options expiration date, the closest expiry before or after will be chosen.<br/><br/>You can toggle between "Closest" and "Minimum" options to decide whether to select the closest expiry to this number, or ensure that expiries are definitely after this number.<br/><br/>You can also select a range of DTEs for the expiry to fall within.  In this situation, expiries must fall within this range or a trade will not occur.';
  //       break;
  //     case 'delta':
  //       title.innerHTML = 'Delta';
  //       text.innerHTML = 'The strike price with the delta closest to this value is chosen.';
  //       break;
  //     case 'spwidth':
  //       if( this.state.strategytype === 'Calendar' || this.state.strategytype === 'Diagonal') {
  //         title.innerHTML = 'Expiration Width';
  //         text.innerHTML = 'Used for calendar spreads and diagonals, this is the number of calendar days betweeen the 2 expiries. The near term expiration is chosen based on the <i>Days To Expiry</i> and then the far term is chosen closest to the <i>Expiration Width</i> specified.<br/>Ex. For a 2 week calendar spread, specify a value of 14.';
  //       }
  //       else {
  //         title.innerHTML = 'Spread Width';
  //         text.innerHTML = 'For vertical spreads, the width between the short and long strikes.';
  //       }
  //       break;
  //     case 'unbal':
  //       title.innerHTML = 'Unbalanced Spreads';
  //       text.innerHTML = 'This allows advanced control over the wings of the spreads.  You will be able to customize the delta, size ratio, side(right), and width for each spread independently.';
  //       break;
  //     case 'wingad':
  //       title.innerHTML = 'Advanced Wingspreads';
  //       text.innerHTML = 'Iron versions of the spreads can be created by selecting one put and one call spread.  Different deltas and different widths can be selected for each spread to create a custom delta profile for the position.  The size ratios between the legs can also be customized (<i>ex.</i> A 1-3-2 butterfly instead of a standard 1-2-1) or a condor with fewer call spreads than put spreads.';
  //       break;
  //     case 'chng':
  //       title.innerHTML = 'Daily Change';
  //       text.innerHTML = 'Whether to only enter trades if there is a daily percentage change of a minimum amount. Useful for combining with vertical spreads (ex. selling into strength)<br/>Can be specified as a positive or negative value.';
  //       break;
  //     case 'vvix':
  //       title.innerHTML = 'Use VVIX Check';
  //       text.innerHTML = 'Only execute entry trades when VVIX is below a certain level.  Useful for short gamma positions.  More options coming soon.';
  //       break;
  //     case 'vix':
  //       title.innerHTML = 'Use VIX Check';
  //       text.innerHTML = 'Only execute entry trades when VIX is below a certain level.  Useful for short gamma positions.  More options coming soon.';
  //       break;
  //     case 'closedte':
  //       title.innerHTML = 'Days to Close Before Expiry';
  //       text.innerHTML = 'If no other exit conditons have been met, whether to close the trade X days before expiry.  Useful for managing short gamma or short theta risk.';
  //       break;
  //     case 'winex':
  //     case 'winthresh':
  //       title.innerHTML = 'Winner Exit Method & Threshold';
  //       text.innerHTML = 'The method by which to exit winning trades.<br/><br/><u>For debit trades</u>: this will be a percentage winner based on margin (<i>Ex.</i> If a long call is bought for $1.50, a 20% winner on margin would be calculated as $150 of margin × 1.20).<br/><br/><u>For credit trades</u>: Winners will be determined based on percent of credit, which means the percentage of the initial credit received that should be captured (<i>Ex.</i> If $1.50 credit is received, specifying an exit threshold of 50% would exit below 0.75).<br/><i>Note:</i>Percent of margin is not used since the amount of credit generated will vary from trade to trade.  It is possible that the desired ROM on margin cannot be achieved for a particular trade depending on IV levels.';
  //       //<br/><strong>Percent Delta</strong>: For spreads, close the trade if the short option delta is 𝓧% of the entry delta (<i>Ex.</i> If the entry delta is .20, a value of 50% would close the trade when the short option delta drops below .10 delta)';
  //       break;
  //     case 'trom':
  //       title.innerHTML = 'Target ROM Per Expiration';
  //       text.innerHTML = 'Can be combined with existing winner exit methods.  Useful if there are multiple trades on a particular expiration date.  Will close all trades at that expiry if a certain return on margin is achieved.';
  //       break;
  //     case 'loser':
  //       title.innerHTML = 'Loser Threshold';
  //       text.innerHTML = '<u>For credit spreads</u>, the multiple of the credit received to close the whole trade (Ex. If $1.50 of credit is received, a value of 200% will close the trade if the spread value reaches $3.00). <i>A value over 100% should be specified.</i><br/><br/><u>For debit spreads</u>, a value less than 100% should be specified, expressed as the amount of value still left in the spread (ex. For a trade put on for $1.50, if you wanted to close at a 20% loss (spread value of $1.20), you would specify a loser threshold of 80%). <i>A value less than 100% should be specified.</i>';
  //       break;
  //     case 'hv':
  //       title.innerHTML = 'Historical Volatility';
  //       text.innerHTML = 'We allow you to specify historical volatility criteria of the product for entry.  If you are familiar with how the HV of your product behaves, you can pick an HV period (ex. HV20) and specify entry above or below a certain percentage.  Note that the period is in trading days, HV20 refers to a calendar month.  More advanced options coming soon.';
  //       break;
  //     case 'iv':
  //       title.innerHTML = 'Implied Volatility';
  //       text.innerHTML = 'We allow you to specify implied volatility criteria of the product for entry.  If you are familiar with how the IV of your product behaves, you can pick an IV period (ex. IV30) and specify entry above or below a certain percentage.  Note that the period is in calendar days and is an interpolated value using monthly expirations.  More advanced options coming soon.';
  //       break;
  //     case 'mgmtpnl':
  //       title.innerHTML = 'Manage By Current PNL';
  //       text.innerHTML = 'Here you can manage the position size by the current pnl as the trade is progressing.  If specified pnl limits are reached (for winning or losing trades), you can trim position size by a certain percentage. <br/><br/><strong>Note:</strong> Credit and debit trades are handled differently.<br/><br/><u>For debit trades</u>, the percent increment is a return on margin.<br/><i>For example</i>, if total margin of the trade is $10000, a 10% increment will trigger if there is a $1000 gain or loss on the position.<br/><br/><u>For credit trades</u>, the percent increment is a percent of total credit received (regardless of how much margin the trade consumes).<br/><i>For example</i>, if $2.00 in credit is received, a 25% increment will trigger every 50 cents.  For winning trades, this will happen if the position price goes down to $1.50.  For losing trades, at $2.50.';
  //       break;
  //     default:
  //       title.innerHTML = '';
  //       text.innerHTML = '';
  //       break;
  //   }

  // }

  handleTabChange(tabval)
  {
     this.setState({ tabValue: tabval }, () => console.log('tabValue:', this.state.tabValue));
  }

  render() {
    return (
      <form name="btform" action="" method="get" encType="multipart/form-data" className="form-horizontal" onSubmit={this.handleFormSubmit}>
        <div className="row">
          <div className="col">
            {this.state.errormsg}
          </div>
        </div>

        <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
          <Tabs value={this.state.tabValue} onChange={this.handleTabChange} >
            <Tab label="General" value={0} onClick={this.handleTabChange.bind(this,0)} >
              <div className="row">
                <div className="col">
                  <Card>
                    <CardTitle title={
                      <div className="row">
                        <div className="col-md-9">
                          General
                        </div>
                        <div className="col-md-3">
                          <Toggle
                            label="Show Tooltips"
                            defaultToggled={true}
                            labelPosition="right"
                            style={toggleStyles.toggle}
                            onToggle={this.handleTooltipToggle}
                            toggled={this.state.tooltipToggleState}
                            labelStyle={toggleStyles.toggleLabel}
                          />
                        </div>
                      </div>
                    }/>
                    <CardText>
                    <div style={muistyles.reduceCardMargin25}>
                      <div className="form-group row">
                        <div className="col-sm-6 col-md-4" >
                          <div className="col-md-12">
                            <TextField id="symbol-input" name="symbol-input" type="text" value={this.state.symbol} onChange={this.handleSymbolChange} onBlur={this.preloadSymbol} floatingLabelText="Symbol" errorText={this.state.symbolErr} floatingLabelStyle={muistyles.floatingLabelStyle} />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4" >
                          <label className="col-md-6 form-control-label" htmlFor="startdate-input" style={muistyles.robotoFont}>Start Date</label>
                          <div className="col-md-6">
                            <DatePicker id={'startdate-input'} hintText="Start Date" container="inline" value={this.state.startDate} onChange={this.handleStartDateChange} minDate={btStartDataDate} maxDate={this.state.lastBTDate} autoOk={true} locale="en-US" shouldDisableDate={btDisabledDate} firstDayOfWeek={0} />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-4" >
                          <label className="col-md-6 form-control-label" htmlFor="enddate-input" style={muistyles.robotoFont}>End Date</label>
                          <div className="col-md-6">
                          <DatePicker id={'enddate-input'} hintText="End Date" container="inline" value={this.state.endDate} onChange={this.handleEndDateChange} minDate={btStartDataDate} maxDate={this.state.lastBTDate} autoOk={true} locale="en-US" shouldDisableDate={btDisabledDate} firstDayOfWeek={0} />
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-6 col-md-4" >
                          <div className="col-md-12">
                            <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                              <SelectField
                                id={'strategy-select'}
                                value={this.state.strategytype}
                                onChange={this.handleStratTypeSelect}
                                floatingLabelText="Strategy Type"
                                floatingLabelStyle={muistyles.floatingLabelStyle}
                                errorText={this.state.strategytypeErr}
                              >
                                { this.state.strategyOptions.map( (opt, index) =>
                                  <MenuItem key={index} value={opt} primaryText={opt} />
                                )}
                              </SelectField>
                            </MuiThemeProvider>
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-4" >
                          <div id="dirdiv">
                          <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                            <SelectField
                              id={'dir-select'}
                              value={this.state.dir}
                              onChange={this.handleDirSelect}
                              floatingLabelText="Direction"
                              floatingLabelStyle={muistyles.floatingLabelStyle}
                              errorText={this.state.dirErr}
                            >
                              { this.state.dirOptions.map( (opt, index) =>
                                <MenuItem key={index} value={opt} primaryText={opt} />
                              )}
                            </SelectField>
                          </MuiThemeProvider>
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-4" >
                          <div className="col-md-12">
                            <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                              <SelectField
                                id={'execmeth-select'}
                                value={this.state.execmeth}
                                onChange={this.handleExecChange}
                                floatingLabelText="Execution Method"
                                floatingLabelStyle={muistyles.floatingLabelStyle}
                              >
                                { this.state.execOptions.map( (opt, index) =>
                                  <MenuItem key={index} value={opt} primaryText={opt} />
                                )}
                              </SelectField>
                            </MuiThemeProvider>
                          </div>
                        </div>
                      </div>

                      <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                        <Divider style={radioStyles.dividerGap} />
                      </MuiThemeProvider>

                      <div className="form-group row">
                        <div className="col-md-8">
                          <div className="form-group row">
                            {/* <div className="col-sm-1 col-md-1">
                            </div> */}
                            <div className="col-sm-6 col-md-6" >
                              <div id="portcapdiv" className="col-md-12">
                                <IconTextField2 label="Portfolio Capital" left={-22} icon="fa fa-usd" type="text" controlFunc={this.handleAUMChange} content={this.state.aum} error={this.state.aumErr} />
                                {/* <TextFieldIcon floatingLabelText={'Portfolio Capital'} floatingLabelStyle={muistyles.floatingLabelStyle} icon={<CurrencyUsdIcon />} type={'text'}
                                  iconPosition={'before'} fullWidth={false} onChange={this.handleAUMChange} value={this.state.aum} errorText={this.state.aumErr} /> */}
                              </div>
                            </div>

                            <div className="col-sm-6 col-md-6" >
                              <div className="col-md-12">
                                <IconTextField2 label="Commission Per Contract" left={-22} icon="fa fa-usd" type="text" controlFunc={this.handleCommishChange} content={this.state.commish} error={this.state.commishErr} />
                                {/* <TextFieldIcon floatingLabelText={'Commission Per Contract'} floatingLabelStyle={muistyles.floatingLabelStyle} icon={<CurrencyUsdIcon />} type={'text'}
                                  iconPosition={'before'} fullWidth={false} onChange={this.handleCommishChange} value={this.state.commish} errorText={this.state.commishErr} /> */}
                              </div>
                            </div>
                          </div>

                          <div className="form-group row">
                            {/* <div className="col-sm-1 col-md-1">
                            </div> */}
                            <div className="col-sm-6 col-md-6" >
                              <div className="col-md-6 controls">
                                <IconTextField2 id={'alloc-input'} label="Capital Per Trade" left={this.state.allocleft} icon={this.state.allocicon} type="text" controlFunc={this.handleTradeAllocChange} content={this.state.tradealloc} error={this.state.allocErr} />
                                {/* <TextFieldIcon id={'alloc-input'} floatingLabelText={'Capital Per Trade'} floatingLabelStyle={muistyles.floatingLabelStyle} icon={<CurrencyUsdIcon />} type={'text'}
                                  iconPosition={'before'} fullWidth={false} onChange={this.handleTradeAllocChange} value={this.state.tradealloc} errorText={this.state.allocErr} /> */}
                                <div id="tradecapdiv">
                                  <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                                    <RadioButtonGroup name="alloc-method" defaultSelected="dollars" valueSelected={this.state.allocmeth} onChange={this.handleAllocMethChange}>
                                      <RadioButton
                                        value="dollars"
                                        label="Fixed Dollars"
                                        style={radioStyles.radioButton}
                                        labelStyle={radioStyles.radioNoWrap}
                                      />

                                      <RadioButton
                                        value="percent"
                                        label="Percent of Portfolio"
                                        style={radioStyles.radioButton}
                                        labelStyle={radioStyles.radioNoWrap}
                                      />
                                    </RadioButtonGroup>
                                  </MuiThemeProvider>
                                </div>

                                <div style={toggleStyles.missingDiv}>
                                  <div id="ignoretradediv" >
                                    <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                                      <Toggle
                                        label="Ignore Trade PNL"
                                        defaultToggled={true}
                                        labelPosition="right"
                                        style={toggleStyles.toggle}
                                        onToggle={this.handleIgnoreTradePnl}
                                        labelStyle={toggleStyles.toggleNoWrap}
                                      />
                                    </MuiThemeProvider>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div style={muistyles.shadedCard} className="col-sm-6 col-md-6" >
                              <br/><br/>
                              <div id="onetradediv">
                                <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                                  <Toggle
                                    label="Only 1 trade open at a time"
                                    defaultToggled={true}
                                    labelPosition="right"
                                    style={toggleStyles.toggle}
                                    onToggle={this.handleOneTradeChange}
                                    toggled={this.state.oneTradeToggleState}
                                  />
                                </MuiThemeProvider>
                              </div>
                              <div id="maxallocdiv" >
                                <div className="col-md-12">
                                  <IconTextField2 id={'expmarginlim-input'} label="Max Capital Per Expiration" left={this.state.allocleft} icon={this.state.allocicon} type="text"
                                  controlFunc={this.handleExpLimChange} content={this.state.expmarginlim} disabled={this.state.oneTradeToggleState} error={this.state.expMarginErr} />
                                  {/* <TextFieldIcon id={'expmarginlim-input'} floatingLabelText={'Max Capital Per Expiration'} floatingLabelStyle={muistyles.floatingLabelStyle} icon={<CurrencyUsdIcon />} type={'text'}
                                    iconPosition={'before'} fullWidth={false} onChange={this.handleExpLimChange} value={this.state.expmarginlim} errorText={this.state.expMarginErr} disabled={this.state.oneTradeToggleState} /> */}
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div className="col-md-4">
                          {/* <div className="row col-sm-12 col-md-12">
                          <br/>
                          <Card style={muistyles.roundedCard}>
                            <CardTitle titleStyle={muistyles.smallerCardTitle} title="Example Payoff Diagram"></CardTitle>
                            <CardText>
                              <Line data={this.state.payoffChart} options={this.state.payoffChartOpts} height={150}/>
                            </CardText>
                          </Card>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    </CardText>
                  </Card>
                </div>
              </div>
            </Tab>
            <Tab label="Trade Entry" value={1} onClick={this.handleTabChange.bind(this,1)}>
              <div className="row">
                <div className="col-sm-12 col-lg-12">
                  <Card>
                    <CardTitle title={
                      <div className="row">
                        <div className="col-md-9">
                          Trade Entry
                        </div>
                        <div className="col-md-3">
                          <Toggle
                            label="Show Tooltips"
                            defaultToggled={true}
                            labelPosition="right"
                            style={toggleStyles.toggle}
                            onToggle={this.handleTooltipToggle}
                            toggled={this.state.tooltipToggleState}
                            labelStyle={toggleStyles.toggleLabel}
                          />
                        </div>
                      </div>
                    }/>
                    <CardText>
                    <div style={muistyles.reduceCardMargin25}>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <Card style={muistyles.roundedCard}>
                            <CardTitle title={
                              <div className="row">
                                <div className="col-md-9">
                                  Legs
                                </div>
                                <div className="col-md-3">
                                  <div id="advtogglediv" className="form-group row"  style={toggleStyles.missingDiv} >
                                    <div id="advancedToggleDiv">
                                      <Toggle
                                        label="Advanced"
                                        defaultToggled={false}
                                        labelPosition="right"
                                        style={toggleStyles.toggle}
                                        onToggle={this.handleAdvancedToggle}
                                        toggled={this.state.advToggleState}
                                        labelStyle={toggleStyles.toggleLabel}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }/>
                            <CardText>
                              <div style={muistyles.reduceCardMargin40} id="maindeltadiv" className="form-group row" >
                                <div id="deltalabeldiv" className="col-md-6">
                                  <TextField id="entrydelta-input" type="text" floatingLabelText={this.state.deltalabel} value={this.state.entrydelta} onChange={this.handleEntryDeltaChange}
                                    errorText={this.state.deltaErr} floatingLabelStyle={muistyles.floatingLabelStyle} />
                                </div>
                                <div className="col-md-6">
                                  <div id="flyrightdiv" className="form-group" >
                                    <div id="flyrightselectdiv" style={toggleStyles.missingDiv}>
                                      <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                                        <SelectField
                                          id={'fly-right-select'}
                                          value={this.state.flyright}
                                          onChange={this.handleFlyrightChange}
                                          floatingLabelText={'Right'}
                                          floatingLabelStyle={muistyles.floatingLabelStyle}
                                        >
                                          { this.state.rightOptions.map( (opt, index) =>
                                            <MenuItem key={index} value={opt} primaryText={opt} />
                                          )}
                                        </SelectField>
                                      </MuiThemeProvider>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div style={muistyles.reduceCardMargin40} id="diagdeltadiv" className="form-group row" >
                                <div className="col-md-4">
                                  <div id="diaginputdiv" style={toggleStyles.missingDiv}>
                                    <TextField id="diagfardelta-input" type="text" floatingLabelText="Far Term Delta" value={this.state.diagfardelta} onChange={this.handleEntryDeltaChange}
                                      errorText={this.state.diagErr} floatingLabelStyle={muistyles.floatingLabelStyle} />
                                  </div>
                                </div>
                              </div>




                              <div style={muistyles.reduceCardMargin10} id="spwidthdiv" className="form-group row" >
                                <div className="col-md-6">
                                  <div id="spwidthinputdiv" style={toggleStyles.missingDiv}>
                                    <TextField id="spwidth-input" type="text" floatingLabelText={this.state.spwidthlabel} value={this.state.spwidth} onChange={this.handleSPWidthChange}
                                      errorText={this.state.widthErr} floatingLabelStyle={muistyles.floatingLabelStyle} />
                                  </div>
                                </div>
                              </div>

                              <div id="wingadvdiv" className="form-group row"  style={toggleStyles.missingDiv} >
                                <div className="col-md-12">
                                  <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                                    <Card>
                                      <CardTitle title="Advanced" />
                                      <CardText>
                                        <div id="wingcdiv1" className="form-group row">
                                          <div className="col-md-3">
                                            <label id="wingrightlabel" className="form-control-label">Right</label>
                                          </div>
                                          <div className="col-md-3">
                                            <label id="wingratiolabel" className="form-control-label">Ratio</label>
                                          </div>
                                          <div id="wingadvdelta1" className="col-md-3">
                                            <label id="wingdeltalabel" className="form-control-label">Short Delta</label>
                                          </div>
                                          <div className="col-md-3">
                                            <label id="wingwidthlabel" className="form-control-label">Spread Width</label>
                                          </div>
                                        </div>
                                        <div id="wingcdiv2" className="form-group row">
                                          <div className="col-md-3">
                                            <Select options={this.state.rightOptions} id={'wa1-right-select'} name={'wa1-right-select'} clsname={'form-control'} controlFunc={this.handleWingAdvChange} selectedOption={this.state.wa1right} placeholder={'Spread 1'} />
                                          </div>
                                          <div className="col-md-3">
                                            <SingleInput inputType={'number'} id={'wa1-ratio-input'} name={'wa1-ratio-input'} clsname={'form-control wspWidth'} controlFunc={this.handleWingAdvChange} content={this.state.wa1ratio} placeholder={'1'} />
                                          </div>
                                          <div id="wingadvdelta2" className="col-md-3">
                                            <SingleInput inputType={'number'} id={'wa1-delta-input'} name={'wa1-delta-input'} clsname={'form-control wspWidth'} controlFunc={this.handleWingAdvChange} content={this.state.wa1delta} placeholder={'.22'} />
                                          </div>
                                          <div className="col-md-3">
                                            <SingleInput inputType={'number'} id={'wa1-width-input'} name={'wa1-width-input'} clsname={'form-control wspWidth'} controlFunc={this.handleWingAdvChange} content={this.state.wa1width} placeholder={'10'} />
                                          </div>
                                        </div>
                                        <div id="wingcdiv3" className="form-group row">
                                          <div className="col-md-3">
                                            <Select options={this.state.rightOptions} id={'wa2-right-select'} name={'wa2-right-select'} clsname={'form-control'} controlFunc={this.handleWingAdvChange} selectedOption={this.state.wa2right} placeholder={'Spread 2'} />
                                          </div>
                                          <div className="col-md-3">
                                            <SingleInput inputType={'number'} id={'wa2-ratio-input'} name={'wa2-ratio-input'} clsname={'form-control wspWidth'} controlFunc={this.handleWingAdvChange} content={this.state.wa2ratio} placeholder={'1'} />
                                          </div>
                                          <div id="wingadvdelta3" className="col-md-3">
                                            <SingleInput inputType={'number'} id={'wa2-delta-input'} name={'wa2-delta-input'} clsname={'form-control wspWidth'} controlFunc={this.handleWingAdvChange} content={this.state.wa2delta} placeholder={'.22'} />
                                          </div>
                                          <div className="col-md-3">
                                            <SingleInput inputType={'number'} id={'wa2-width-input'} name={'wa2-width-input'} clsname={'form-control wspWidth'} controlFunc={this.handleWingAdvChange} content={this.state.wa2width} placeholder={'10'} />
                                          </div>
                                        </div>
                                        <div id="wingcdiv4" className="form-group row">
                                          <Divider/>
                                          <div id="flyname" style={toggleStyles.missingDiv} className="figure-caption pt-2">
                                            &nbsp;This is a&thinsp;
                                            {this.state.wa1ratio}-{+this.state.wa1ratio + +this.state.wa2ratio}-{this.state.wa2ratio}
                                            &thinsp;butterfly.
                                          </div>
                                        </div>
                                      </CardText>
                                    </Card>
                                  </MuiThemeProvider>
                                </div>
                              </div>

                              <div id="stradadvdiv" className="form-group row"  style={toggleStyles.missingDiv} >
                                <div className="col-md-12">
                                  <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                                    <Card>
                                      <CardTitle title="Advanced" />
                                      <CardText>
                                        <div id="stradcdiv1" className="form-group row">
                                          <div className="col-md-4">
                                            <label id="stradrightlabel" className="form-control-label">Right</label>
                                          </div>
                                          <div className="col-md-4">
                                            <label id="stradratiolabel" className="form-control-label">Ratio</label>
                                          </div>
                                          <div id="stradadvdelta1" className="col-md-4">
                                            <label id="straddeltalabel" className="form-control-label">Delta</label>
                                          </div>
                                        </div>
                                        <div id="stradcdiv2" className="form-group row">
                                          <div className="col-md-4">
                                            Put
                                          </div>
                                          <div className="col-md-4">
                                            <SingleInput inputType={'number'} id={'strad1-ratio-input'} name={'strad1-ratio-input'} clsname={'form-control wspWidth'} controlFunc={this.handleWingAdvChange} content={this.state.strad1ratio} placeholder={'1'} />
                                          </div>
                                          <div id="stradadvdelta2" className="col-md-4">
                                            <SingleInput inputType={'number'} id={'strad1-delta-input'} name={'strad1-delta-input'} clsname={'form-control wspWidth'} controlFunc={this.handleWingAdvChange} content={this.state.strad1delta} placeholder={'.22'} />
                                          </div>
                                        </div>
                                        <div id="stradcdiv3" className="form-group row">
                                          <div className="col-md-4">
                                            Call
                                          </div>
                                          <div className="col-md-4">
                                            <SingleInput inputType={'number'} id={'strad2-ratio-input'} name={'strad2-ratio-input'} clsname={'form-control wspWidth'} controlFunc={this.handleWingAdvChange} content={this.state.strad2ratio} placeholder={'1'} />
                                          </div>
                                          <div id="stradadvdelta3" className="col-md-4">
                                            <SingleInput inputType={'number'} id={'strad2-delta-input'} name={'strad2-delta-input'} clsname={'form-control wspWidth'} controlFunc={this.handleWingAdvChange} content={this.state.strad2delta} placeholder={'.22'} />
                                          </div>
                                        </div>
                                      </CardText>
                                    </Card>
                                  </MuiThemeProvider>
                                </div>
                              </div>
                            </CardText>
                          </Card>
                        </div>
                        <div className="col-md-6" >
                          <Card style={muistyles.roundedCard}>
                            <CardTitle title={
                              <div className="row">
                                <div className="col-md-8">
                                  Expiration
                                </div>
                                <div className="col-md-4" id="dteRangeDiv" style={muistyles.shadedCard}>
                                  <Toggle
                                    label="Use DTE Range"
                                    defaultToggled={false}
                                    labelPosition="right"
                                    style={toggleStyles.toggle}
                                    onToggle={this.handleDTERangeToggle}
                                    toggled={this.state.dteRangeToggleState}
                                    labelStyle={toggleStyles.toggleLabel}
                                  />
                                </div>
                              </div>
                            }/>
                            <CardText>
                              <div style={muistyles.reduceCardMargin40} className="row">
                                <div className="col-md-6">
                                  <TextField id="mindte-input" type="text" floatingLabelText={this.state.mindteLabel} value={this.state.mindte} onChange={this.handleMinDTEChange}
                                    fullWidth={false} errorText={this.state.mindteErr} floatingLabelStyle={muistyles.floatingLabelStyle} />
                                </div>
                                <div id="maxdtediv" className="col-md-6" style={toggleStyles.missingDiv}>
                                  <TextField id="maxdte-input" type="text" floatingLabelText="Max Days to Expiry" value={this.state.maxdte} onChange={this.handleMaxDTEChange}
                                    errorText={this.state.maxdteErr} floatingLabelStyle={muistyles.floatingLabelStyle} />
                                </div>
                                <div id="closemindiv" className="col-md-6">
                                  <RadioButtonGroup name="dte-method" defaultSelected="closest" valueSelected={this.state.dtemeth} onChange={this.handleDTEMethChange}>
                                    <RadioButton
                                      id="dteRangeClosest"
                                      value="closest"
                                      label="Closest"
                                      style={radioStyles.radioButton}
                                      labelStyle={radioStyles.radioNoWrap}
                                    />
                                    <RadioButton
                                      id="dteRangeMininum"
                                      value="minimum"
                                      label="Minimum"
                                      style={radioStyles.radioButton}
                                      labelStyle={radioStyles.radioNoWrap}
                                    />
                                  </RadioButtonGroup>
                                </div>
                              </div>
                            </CardText>
                          </Card>
                        </div>
                      </div>

                      <hr className="mx-3 my-0 pb-3"/>

                      <div className="form-group row">
                        <div className="col-md-6">
                          <Card style={muistyles.shadedCard}>
                            <CardTitle title="Market Volatility"></CardTitle>
                            <CardText>
                              <div className="row" id="vixToggleDiv">
                                <div className="col-md-12">
                                  <div className="row" >
                                    <div className="col-md-4">
                                      <Toggle
                                        label="Use VIX Level"
                                        defaultToggled={false}
                                        labelPosition="right"
                                        style={toggleStyles.toggle}
                                        onToggle={this.handleVIXToggle}
                                        toggled={this.state.vixToggleState}
                                      />
                                    </div>
                                    <div id="vixdiv" className="col-md-8" style={{position: 'relative', display: 'inline-block'}}>
                                      {/* <div className="input-group"> */}
                                      <div className="row">
                                        <div className="col-md-8 input-group">

                                          <SelectField
                                            id={'vix-select'}
                                            value={this.state.vixmethod}
                                            onChange={this.handleVIXSelect}
                                            floatingLabelText={this.state.vixLabelText}
                                            floatingLabelStyle={muistyles.floatingLabelStyle}
                                            disabled={!this.state.vixToggleState}
                                            style={toggleStyles.select}
                                            errorText={this.state.vixErr}
                                          >
                                            { this.state.abOptions.map( (opt, index) =>
                                              <MenuItem key={index} value={opt} primaryText={opt} />
                                            )}
                                          </SelectField>
                                        </div>

                                        {/* <div className="input-group"> */}

                                        <div className="col-md-4 input-group-btn">
                                          <SingleInput inputType={'number'} id={'vix-input'} name={'vix-input'} clsname={'form-control form-group-two'} controlFunc={this.handleVIXChange} content={this.state.vixlim} disabled={ (!this.state.vixToggleState) } />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row" id="vvixToggleDiv">
                                <div className="col-md-12">
                                  <div className="row" >
                                    <div className="col-md-4">
                                      <Toggle
                                        label="Use VVIX Level"
                                        defaultToggled={false}
                                        labelPosition="right"
                                        style={toggleStyles.toggle}
                                        onToggle={this.handleVVIXToggle}
                                        toggled={this.state.vvixToggleState}
                                      />
                                    </div>
                                    <div id="vvixdiv" className="col-md-8">
                                      <div className="row">
                                        <div className="col-md-8 input-group">
                                          <SelectField
                                            id={'vvix-select'}
                                            value={this.state.vvixmethod}
                                            onChange={this.handleVVIXSelect}
                                            floatingLabelText={this.state.vvixLabelText}
                                            floatingLabelStyle={muistyles.floatingLabelStyle}
                                            disabled={!this.state.vvixToggleState}
                                            style={toggleStyles.select}
                                            errorText={this.state.vvixErr}
                                          >
                                            { this.state.abOptions.map( (opt, index) =>
                                              <MenuItem key={index} value={opt} primaryText={opt} />
                                            )}
                                          </SelectField>
                                        </div>
                                        <div className="col-md-4 input-group-btn">
                                          <SingleInput inputType={'number'} id={'vvix-input'} name={'vvix-input'} clsname={'form-control form-group-two'} controlFunc={this.handleVVIXChange} content={this.state.vvixlim} disabled={ (!this.state.vvixToggleState) } />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <Divider style={radioStyles.dividerGap} />

                              <div className="row">
                                <div className="col-md-6">
                                  <Toggle
                                    id="contango-toggle"
                                    label="Only allow trades if VIX is in contango"
                                    defaultToggled={false}
                                    labelPosition="right"
                                    style={toggleStyles.toggle}
                                    onToggle={this.handleVolToggle}
                                    toggled={this.state.vixContangoToggleState}
                                  />
                                </div>
                                <div className="col-md-6 input-group-btn">
                                  <SelectField
                                    id={'contango-select'}
                                    value={this.state.vixContangoVal}
                                    onChange={this.handleContangoSelect}
                                    floatingLabelText={this.state.contangoLabelText}
                                    floatingLabelStyle={muistyles.floatingLabelStyle}
                                    disabled={!this.state.vixContangoToggleState}
                                    style={toggleStyles.select}
                                    errorText={this.state.contangoErr}
                                  >
                                    { this.state.contangoOptions.map( (opt, index) =>
                                      <MenuItem key={index} value={opt} label={opt.split('|')[1]} primaryText={opt.split('|')[0]} />
                                    )}
                                  </SelectField>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <Toggle
                                    id="backward-toggle"
                                    label="Only allow trades if VIX is in backwardation"
                                    defaultToggled={false}
                                    labelPosition="right"
                                    style={toggleStyles.toggle}
                                    onToggle={this.handleVolToggle}
                                    toggled={this.state.vixBackwardToggleState}
                                  />
                                </div>
                                <div className="col-md-6 input-group-btn">
                                  <SelectField
                                    id={'backward-select'}
                                    value={this.state.vixBackwardVal}
                                    onChange={this.handleBackwardSelect}
                                    floatingLabelText={this.state.backwardLabelText}
                                    floatingLabelStyle={muistyles.floatingLabelStyle}
                                    disabled={!this.state.vixBackwardToggleState}
                                    style={toggleStyles.select}
                                    errorText={this.state.backwardErr}
                                  >
                                    { this.state.backwardOptions.map( (opt, index) =>
                                      <MenuItem key={index} value={opt} label={opt.split('|')[1]} primaryText={opt.split('|')[0]} />
                                    )}
                                  </SelectField>
                                </div>
                              </div>
                            </CardText>
                          </Card>
                        </div>
                        <div className="col-md-6" id="dailyToggleDiv">
                          <Card style={muistyles.shadedCard}>
                            <CardTitle title="Daily Change"></CardTitle>
                            <CardText>
                              <div className="form-group row" >
                                <div className="col-md-6 form-control-label">
                                  <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                                    <Toggle
                                      label="Use Daily Change"
                                      defaultToggled={false}
                                      labelPosition="right"
                                      style={toggleStyles.toggle}
                                      onToggle={this.handleDailyToggle}
                                      toggled={this.state.dailyToggleState}
                                    />
                                  </MuiThemeProvider>
                                </div>
                                {/* <label className="col-md-5 form-control-label" htmlFor="dailychange-input">Daily Change</label> */}
                                <div id="dailychangediv" className="col-md-6">
                                  <div className="input-group form-group-dc">
                                    {/* <input type="text" id="dailychange-input" name="dailychange-input" className="form-control" placeholder="0.75"/> */}
                                    <SingleInput inputType={'number'} id={'dailychange-input'} name={'dailychange-input'} clsname={'form-control'} controlFunc={this.handleDailyChange} content={this.state.dailychange} placeholder={'0.75'} disabled={ (!this.state.dailyToggleState) } />
                                    <span className="input-group-addon">%</span>
                                  </div>
                                </div>
                              </div>
                            </CardText>
                          </Card>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-md-6" id="hvToggleDiv" >
                          <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                            <Card style={muistyles.shadedCard}>
                              <CardTitle title={
                              <div className="row">
                                <div className="col-md-7">
                                  Historical Volatility
                                </div>
                                <div className="col-md-5">
                                  <Toggle
                                    label="Use Historical Volatility"
                                    defaultToggled={false}
                                    labelPosition="right"
                                    style={toggleStyles.toggle}
                                    onToggle={this.handleHVToggle}
                                    toggled={this.state.hvToggleState}
                                    labelStyle={toggleStyles.toggleLabel}
                                  />
                                </div>
                              </div>
                              }/>
                              <CardText>
                                <div style={muistyles.reduceCardMargin40} className="row col-md-12">
                                  <div className="input-group">
                                    <SelectField
                                      id={'hvper-select'}
                                      value={this.state.hvper}
                                      onChange={this.handleHVPerSelect}
                                      floatingLabelText='HV Period'
                                      floatingLabelStyle={muistyles.floatingLabelStyle}
                                      disabled={!this.state.hvToggleState}
                                    >
                                      { this.state.hvOptions.map( (opt, index) =>
                                        <MenuItem key={index} value={opt} primaryText={opt} />
                                      )}
                                    </SelectField>
                                    <SelectField
                                      id={'hv-select'}
                                      value={this.state.hvmethod}
                                      onChange={this.handleHVSelect}
                                      floatingLabelText='Above/Below'
                                      floatingLabelStyle={muistyles.floatingLabelStyle}
                                      disabled={!this.state.hvToggleState}
                                    >
                                      { this.state.abOptions.map( (opt, index) =>
                                        <MenuItem key={index} value={opt} primaryText={opt} />
                                      )}
                                    </SelectField>
                                  </div>
                                  <div className="input-group">
                                    <SingleInput inputType={'number'} id={'hv-input'} name={'hv-input'} clsname={'form-control'} controlFunc={this.handleHVChange} content={this.state.hvlim} placeholder={'15'} disabled={ (!this.state.hvToggleState) } />
                                    <span className="input-group-addon">%</span>
                                  </div>
                                </div>
                              </CardText>
                            </Card>
                          </MuiThemeProvider>
                        </div>

                        <div className="col-md-6" id="ivToggleDiv" >
                          <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                            <Card style={muistyles.shadedCard}>
                              <CardTitle title={
                                <div className="row">
                                  <div className="col-md-7">
                                    Implied Volatility
                                  </div>
                                  <div className="col-md-5">
                                    <Toggle
                                      label="Use Implied Volatility"
                                      defaultToggled={false}
                                      labelPosition="right"
                                      style={toggleStyles.toggle}
                                      onToggle={this.handleIVToggle}
                                      toggled={this.state.ivToggleState}
                                      labelStyle={toggleStyles.toggleLabel}
                                    />
                                  </div>
                                </div>
                              }/>
                              <CardText>
                                <div style={muistyles.reduceCardMargin40} className="row col-md-12">
                                  <div className="input-group">
                                    <SelectField
                                      id={'ivper-select'}
                                      value={this.state.ivper}
                                      onChange={this.handleIVPerSelect}
                                      floatingLabelText='IV Period'
                                      floatingLabelStyle={muistyles.floatingLabelStyle}
                                      disabled={!this.state.ivToggleState}
                                    >
                                      { this.state.ivOptions.map( (opt, index) =>
                                        <MenuItem key={index} value={opt} primaryText={opt} />
                                      )}
                                    </SelectField>
                                    <SelectField
                                      id={'iv-select'}
                                      value={this.state.ivmethod}
                                      onChange={this.handleIVSelect}
                                      floatingLabelText='Above/Below'
                                      floatingLabelStyle={muistyles.floatingLabelStyle}
                                      disabled={!this.state.ivToggleState}
                                    >
                                      { this.state.abOptions.map( (opt, index) =>
                                        <MenuItem key={index} value={opt} primaryText={opt} />
                                      )}
                                    </SelectField>
                                  </div>
                                  <div className="input-group">
                                    <SingleInput inputType={'number'} id={'iv-input'} name={'iv-input'} clsname={'form-control'} controlFunc={this.handleIVChange} content={this.state.ivlim} placeholder={'15'} disabled={ (!this.state.ivToggleState) } />
                                    <span className="input-group-addon">%</span>
                                  </div>
                                </div>
                              </CardText>
                            </Card>
                          </MuiThemeProvider>
                        </div>
                      </div>
                    </div>
                    </CardText>
                  </Card>
                </div>
              </div>
            </Tab>
            <Tab label="Trade Management" value={2} onClick={this.handleTabChange.bind(this,2)}>
              <div className="row">
                <div className="col-sm-12 col-lg-12">
                  <Card>
                    <CardTitle title={
                      <div className="row">
                        <div className="col-md-9">
                          Trade Management
                        </div>
                        <div className="col-md-3">
                          <Toggle
                            label="Show Tooltips"
                            defaultToggled={true}
                            labelPosition="right"
                            style={toggleStyles.toggle}
                            onToggle={this.handleTooltipToggle}
                            toggled={this.state.tooltipToggleState}
                            labelStyle={toggleStyles.toggleLabel}
                          />
                        </div>
                      </div>
                    }/>
                    <CardText>
                    <div style={muistyles.reduceCardMargin25}>
                      <div className="form-group row">
                        <div className="col-md-6" id="currpnlToggleDiv" >
                          <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                            <Card style={muistyles.shadedCard}>
                              <CardTitle title={
                              <div className="row">
                                <div className="col-md-8">
                                  Manage By Current PNL
                                </div>
                                <div className="col-md-4">
                                  <Toggle
                                    label="Active"
                                    defaultToggled={false}
                                    labelPosition="right"
                                    style={toggleStyles.toggle}
                                    onToggle={this.handleCROMToggle}
                                    toggled={this.state.cromToggleState}
                                    labelStyle={toggleStyles.toggleLabel}
                                  />
                                </div>
                              </div>
                              }/>
                              <CardText>
                                <div style={muistyles.reduceCardMargin25} className="row">
                                  <div className="col-md-6">
                                    <div className="row col-md-12">
                                      <label style={muistyles.cardText16} className="form-control-label">Winning trades</label>
                                    </div>
                                    <div style={muistyles.reduceCardMargin10} className="row col-md-12">
                                      <TextFieldIcon id='cromlimW-input' floatingLabelText={this.state.cromlabel} floatingLabelStyle={muistyles.floatingLabelStyle} icon={<PercentIcon />} type={'text'}
                                        fullWidth={true} onChange={this.handleCROMLimChange} value={this.state.cromlimW} errorText={this.state.cromlimWErr} disabled={!this.state.cromToggleState} />
                                    </div>
                                    <div style={muistyles.reduceCardMargin10} className="row col-md-12">
                                      <TextFieldIcon id='cromcloseW-input' floatingLabelText={'To Reduce'} floatingLabelStyle={muistyles.floatingLabelStyle} icon={<PercentIcon />} type={'text'}
                                        fullWidth={true} onChange={this.handleCROMCloseChange} value={this.state.cromcloseW} errorText={this.state.cromcloseWErr} disabled={!this.state.cromToggleState} />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="row col-md-12">
                                      <label style={muistyles.cardText16} className="form-control-label">Losing trades</label>
                                    </div>
                                    <div style={muistyles.reduceCardMargin10} className="row col-md-12">
                                      <TextFieldIcon id='cromlimL-input' floatingLabelText={this.state.cromlabel} floatingLabelStyle={muistyles.floatingLabelStyle} icon={<PercentIcon />} type={'text'}
                                        fullWidth={true} onChange={this.handleCROMLimChange} value={this.state.cromlimL} errorText={this.state.cromlimLErr} disabled={!this.state.cromToggleState} />
                                    </div>
                                    <div style={muistyles.reduceCardMargin10} className="row col-md-12">
                                      <TextFieldIcon id='cromcloseL-input' floatingLabelText={'To Reduce'} floatingLabelStyle={muistyles.floatingLabelStyle} icon={<PercentIcon />} type={'text'}
                                        fullWidth={true} onChange={this.handleCROMCloseChange} value={this.state.cromcloseL} errorText={this.state.cromcloseLErr} disabled={!this.state.cromToggleState} />
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="row col-md-12">
                                  <Toggle
                                  label="Reset increments on a decline"
                                  defaultToggled={false}
                                  labelPosition="right"
                                  style={toggleStyles.toggle}
                                  onToggle={this.handleCROMResetToggle}
                                  toggled={this.state.mgmtPNLToggleState}
                                  />
                                </div> */}
                              </CardText>
                            </Card>
                          </MuiThemeProvider>
                        </div>

                        {/* <div className="col-md-6" >
                          <div id="mgmtlegpnl" style={toggleStyles.missingDiv}>
                            <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                              <Card>
                                <CardTitle title="Manage Leg PNL">
                                  <Toggle
                                    label="Active"
                                    defaultToggled={false}
                                    labelPosition="right"
                                    style={toggleStyles.toggle}
                                    // onToggle={this.handleIVToggle}
                                    // toggled={this.state.ivToggleState}
                                  />
                                </CardTitle>

                                <CardText>
                                  <div className="row col-md-12">

                                  </div>
                                </CardText>
                              </Card>
                            </MuiThemeProvider>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    </CardText>
                  </Card>
                  {/* </div> */}
                </div>
              </div>
            </Tab>
            <Tab label="Trade Exit" value={3} onClick={this.handleTabChange.bind(this,3)}>
              <div className="row">
                <div className="col-sm-12 col-lg-12">
                  <Card>
                    <CardTitle title={
                      <div className="row">
                        <div className="col-md-9">
                          Trade Exit
                        </div>
                        <div className="col-md-3">
                          <Toggle
                            label="Show Tooltips"
                            defaultToggled={true}
                            labelPosition="right"
                            style={toggleStyles.toggle}
                            onToggle={this.handleTooltipToggle}
                            toggled={this.state.tooltipToggleState}
                            labelStyle={toggleStyles.toggleLabel}
                          />
                        </div>
                      </div>
                    }/>
                    <CardText>
                    <div style={muistyles.reduceCardMargin25}>
                      <div className="form-group row" >
                        <div className="col-md-6">
                          <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                            <Card style={muistyles.roundedCard}>
                              <CardTitle title={
                              <div className="row">
                                <div className="col-md-8">
                                  Time Based Exit
                                </div>
                                <div className="col-md-4">
                                  <Toggle
                                    label="Hold Until Expiration"
                                    defaultToggled={true}
                                    labelPosition="right"
                                    style={toggleStyles.toggle}
                                    onToggle={this.handleCloseDTEToggle}
                                    toggled={this.state.closedteToggleState}
                                    labelStyle={toggleStyles.toggleLabel}
                                  />
                                </div>
                              </div>
                              }/>
                              <CardText>
                                <div style={muistyles.reduceCardMargin25} className="row col-md-12">
                                  <TextField id="closedte-input" type="text" floatingLabelText="Days to Close Before Expiry" value={this.state.closedte} onChange={this.handleCloseDTEChange}
                                    errorText={this.state.closedteErr} floatingLabelStyle={muistyles.floatingLabelStyle} />
                                </div>
                              </CardText>
                            </Card>
                          </MuiThemeProvider>
                        </div>
                      </div>
                      <hr className="mx-3 my-0 pb-3"/>
                      <div className="form-group row">
                        <div className="col-md-6">
                          <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                            <Card style={muistyles.roundedCard}>
                              <CardTitle title={
                              <div className="row">
                                <div className="col-md-8">
                                  Winner Exit Management
                                </div>
                                <div className="col-md-4">
                                  <Toggle
                                    label="Active"
                                    defaultToggled={false}
                                    labelPosition="right"
                                    style={toggleStyles.toggle}
                                    onToggle={this.handleWinnerToggle}
                                    toggled={this.state.winnerToggleState}
                                    labelStyle={toggleStyles.toggleLabel}
                                  />
                                </div>
                              </div>
                              }/>
                              <CardText>
                                <div style={muistyles.reduceCardMargin10} className="row col-md-12" >
                                  <label style={muistyles.cardText16} className="form-control-label" htmlFor="winner-select">Exit Method: {this.state.winmethodtext}</label>
                                </div>
                                <div style={muistyles.reduceCardMargin10} className="row col-md-12" >
                                  <TextFieldIcon floatingLabelText={'Threshold'} floatingLabelStyle={muistyles.floatingLabelStyle} icon={<PercentIcon />} type={'text'}
                                    fullWidth={false} onChange={this.handleWinThreshChange} value={this.state.winthresh} errorText={this.state.winErr} />
                                </div>
                              </CardText>
                            </Card>
                          </MuiThemeProvider>
                          {/* <select id="winner-select" name="winner-select" className="form-control">
                            <option value="0">Fixed Price</option>
                            <option value="1">Percent Price</option>
                            <option value="2">Percent Delta</option>
                          </select> */}
                        </div>
                        <div className="col-md-6">
                          <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
                            <Card style={muistyles.roundedCard}>
                              <CardTitle title={
                              <div className="row">
                                <div className="col-md-8">
                                  Loser Exit Management
                                </div>
                                <div className="col-md-4">
                                  <Toggle
                                    label="Active"
                                    defaultToggled={false}
                                    labelPosition="right"
                                    style={toggleStyles.toggle}
                                    onToggle={this.handleLoserToggle}
                                    toggled={this.state.loserToggleState}
                                    labelStyle={toggleStyles.toggleLabel}
                                  />
                                </div>
                              </div>
                              }/>
                              <CardText>
                                <div style={muistyles.reduceCardMargin10} className="row col-md-12" >
                                  <label style={muistyles.cardText16} className="form-control-label" htmlFor="loser-input">{this.getLoserGuideText()}</label>
                                </div>
                                <div style={muistyles.reduceCardMargin10} className="row col-md-12" >
                                  <TextFieldIcon id="loser-input" floatingLabelText={'Threshold'} floatingLabelStyle={muistyles.floatingLabelStyle} icon={<PercentIcon />} type={'text'}
                                    fullWidth={false} onChange={this.handleLoserMultChange} value={this.state.losermult} errorText={this.state.loseErr} />
                                </div>
                              </CardText>
                            </Card>
                          </MuiThemeProvider>
                        </div>
                      </div>
                      <hr className="mx-3 my-0 pb-3"/>
                      {/* <div className="form-group row" >
                        <label className="col-md-6 form-control-label" htmlFor="trom-input">Target ROM Per Expiration</label>
                        <div className="col-md-6">
                          <div className="input-group">
                            <SingleInput inputType={'number'} name={'trom-input'} clsname={'form-control'} controlFunc={this.handleTROMChange} content={this.state.trom} placeholder={'5'} />
                            <span className="input-group-addon">%</span>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    </CardText>
                  </Card>
                </div>
              </div>
            </Tab>
          </Tabs>
        </MuiThemeProvider>

        <div className="row">
          <div className="col-sm-6 col-lg-6">
            <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
              <RaisedButton
                label="Submit"
                primary={true}
                fullWidth={true}
                style={muistyles.button}
                icon={<FontIcon className="fa fa-rocket" />}
                onClick={this.handleFormSubmit}
              />
            </MuiThemeProvider>
          </div>
          <div className="col-sm-6 col-lg-6">
            <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
              <RaisedButton
                label="Reset"
                labelStyle={muistyles.boldtxt}
                // secondary={true}
                fullWidth={true}
                style={muistyles.button}
                icon={<ResetIcon />}
                onClick={this.handleClearForm}
              />
            </MuiThemeProvider>
          </div>
        </div>

        <MuiThemeProvider muiTheme={getMuiTheme(lightBaseTheme, btioDarkTheme)}>
          <Snackbar
            open={this.state.snackopen}
            message={this.state.snacktext}
            autoHideDuration={5000}
            onRequestClose={this.handleSnackClose}
            // action="help"
            // onClick={this.handleSnackAction}
          />
        </MuiThemeProvider>

        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['dir']} target="dirdiv" toggle={this.handleTooltip.bind(this,'dir')}>For condors, long means regular (credit) condors.  Short means reverse (debit) condors.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['onetrade']} target="onetradediv" toggle={this.handleTooltip.bind(this,'onetrade')}>Even if entry conditions for a new trade are met, new trades will not be entered unless the portfolio is flat.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['maxalloc']} target="maxallocdiv" toggle={this.handleTooltip.bind(this,'maxalloc')}>If multiple trades are entered, setting this limits the concentration of trades on an individual expiry.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['execmeth']} target="execmeth-select" toggle={this.handleTooltip.bind(this,'execmeth')}>Execution at Mid selects midpoint prices between Bid and Ask. SmartMid normalizes volatility to select midpoint prices.</Tooltip>
        <Tooltip placement="top-start" delay={tooltipDelay} isOpen={this.state.tooltipMap['portcap']} target="portcapdiv" toggle={this.handleTooltip.bind(this,'portcap')}>A portfolio capital value larger than capital per trade can be set to allow multiple open trades or to use percentage allocation.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['tradecap']} target="tradecapdiv" toggle={this.handleTooltip.bind(this,'tradecap')}>Fixed Dollars uses a constant amount of capital. Percent of Portfolio will use the current value of the portfolio, inclusive of previous trades' pnl.</Tooltip>

        <Tooltip placement="right" delay={tooltipDelay} isOpen={this.state.tooltipMap['entrydelta']} target="entrydelta-input" toggle={this.handleTooltip.bind(this,'entrydelta')}>{this.state.deltatooltip}</Tooltip>
        <Tooltip placement="right" delay={tooltipDelay} isOpen={this.state.tooltipMap['diagfardelta']} target="diagfardelta-input" toggle={this.handleTooltip.bind(this,'diagfardelta')}>Minimum delta of the far term option</Tooltip>
        <Tooltip placement="right" delay={tooltipDelay} isOpen={this.state.tooltipMap['spwidth']} target="spwidth-input" toggle={this.handleTooltip.bind(this,'spwidth')}>{this.state.spwidthtooltip}</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['advanced']} target="advancedToggleDiv" toggle={this.handleTooltip.bind(this,'advanced')}>Advanced customization over the strategy</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['wingratio']} target="wingratiolabel" toggle={this.handleTooltip.bind(this,'wingratio')}>Adjust the ratio between the butterfly strikes</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['stradratio']} target="stradratiolabel" toggle={this.handleTooltip.bind(this,'stradratio')}>Adjust the ratio between the put and call options</Tooltip>

        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['dterange']} target="dteRangeDiv" toggle={this.handleTooltip.bind(this,'dterange')}>Using a DTE Range will ensure an expiry falls within a range, effectively placing an upper limit on DTE.</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['dteclosest']} target="dteRangeClosest" toggle={this.handleTooltip.bind(this,'dteclosest')}>Will pick the expiry closest to this number. DTE could end up being closer or farther.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['dteminimum']} target="dteRangeMininum" toggle={this.handleTooltip.bind(this,'dteminimum')}>Will guarantee the expiry picked is further than this number. <br/>Note: if a product has only monthly expiries, the DTE expiry selected could be much farther than expected.</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['mindte']} target="mindte-input" toggle={this.handleTooltip.bind(this,'mindte')}>Pick expiries starting this many calendar days away (inclusive)</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['maxdte']} target="maxdte-input" toggle={this.handleTooltip.bind(this,'maxdte')}>Limit expiries ending up to this many calendar days away (not inclusive)</Tooltip>

        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['vix']} target="vixToggleDiv" toggle={this.handleTooltip.bind(this,'vix')}>Only allow trades that meet a certain VIX threshold</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['vvix']} target="vvixToggleDiv" toggle={this.handleTooltip.bind(this,'vvix')}>Only allow trades that meet a certain volatility of VIX threshold</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['contango']} target="contango-select" toggle={this.handleTooltip.bind(this,'contango')}>Cash contango is VIX cash below VX Month 1.<br/>Futures contango is VX Month 1 below Month 2</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['backward']} target="backward-select" toggle={this.handleTooltip.bind(this,'backward')}>Cash backwardation is VIX cash above VX Month 1.<br/>Futures backwardation is VX Month 1 above Month 2</Tooltip>

        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['daily']} target="dailyToggleDiv" toggle={this.handleTooltip.bind(this,'daily')}>Only allow trades if the product moves a certain percentage (up or down)</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['hv']} target="hvToggleDiv" toggle={this.handleTooltip.bind(this,'hv')}>Only allow trades that meet a certain HV threshold</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['iv']} target="ivToggleDiv" toggle={this.handleTooltip.bind(this,'iv')}>Only allow trades that meet a certain IV threshold</Tooltip>

        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['currpnl']} target="currpnlToggleDiv" toggle={this.handleTooltip.bind(this,'currpnl')}>Allows incremental closing of winning or losing trades that meet certain thresholds</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['cromlimW']} target="cromlimW-input" toggle={this.handleTooltip.bind(this,'cromlimW')}>Everytime the position value increases by this percent, the position will be trimmed</Tooltip>
        <Tooltip placement="top" delay={tooltipDelay} isOpen={this.state.tooltipMap['cromlimL']} target="cromlimL-input" toggle={this.handleTooltip.bind(this,'cromlimL')}>Everytime the position value decreases by this percent, the position will be trimmed</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['cromcloseW']} target="cromcloseW-input" toggle={this.handleTooltip.bind(this,'cromcloseW')}>The percent of the original position size to be closed when a winning threshold is hit</Tooltip>
        <Tooltip placement="bottom" delay={tooltipDelay} isOpen={this.state.tooltipMap['cromcloseL']} target="cromcloseL-input" toggle={this.handleTooltip.bind(this,'cromcloseL')}>The percent of the original position size to be closed when a losing threshold is hit</Tooltip>

        <Tooltip placement="right" delay={tooltipDelay} isOpen={this.state.tooltipMap['closedte']} target="closedte-input" toggle={this.handleTooltip.bind(this,'closedte')}>If no other exit conditions have been met, close the entire position if this number of calendar days until expiration is reached</Tooltip>

        {/* <Tooltip placement="right" delay={tooltipDelay} isOpen={this.state.tooltipMap['x']} target="dteRangeToggle" toggle={this.handleTooltip.bind(this,'x')}> </Tooltip> */}



      </form>

    )
  }
}

export default BTFormTabbed;
